import { FiberManualRecord } from "@mui/icons-material";
import { Box } from "@mui/material";

export default function ColoredLabel({ color, label }) {
    return <Box component="span" sx={{
        display: "inline-block"
    }}>
        <FiberManualRecord sx={{
            mx: 1,
            color,
            verticalAlign: "middle"
        }} />
        <Box component="span" sx={{
            verticalAlign: "middle"
        }}>
            {
                label
            }
        </Box>
    </Box>
}