import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Paper } from "@mui/material";
import { Edit } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import { useSnackbar } from "notistack";

import FormBuilder from "../../../components/FormBuilder";
import NTMXGrid from "../../../components/NTMXGrid";
import HoverableButton from "../../../components/layout/HoverableButton";
import EditOrganizationModal from "../../../components/modals/EditOrganizationModal";
import { FORM_TYPE } from "../../../constants/FormBuilder";
import { getErrorMessage, put, SHOPS } from "../../../services/Client";
import { useGetCategories, useGetShopsByOrganization } from "../../../services/ContentManager";
import { responsiveWidth } from "../../../services/useBreakpoint";

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.secondary.main
        }
    },
    alignLeftHoverableButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    }
}));


export default function ShopManagement({ hideFields, tagFilters }) {

    const { t } = useTranslation();
    let { id: oid } = useParams();
    let classes = useStyles();
    let { shops, status } = useGetShopsByOrganization(oid);
    let [editShop, setEditShop] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    let queryClient = useQueryClient();
    const [filter, setFilter] = useState([]);
    const { categories, status: categoriesStatus } = useGetCategories();

    function saveEditOrganization(organizations) {
        enqueueSnackbar(t('saving...'), { variant: "info" });
        put(SHOPS, { elem: editShop.id, body: { organizations: organizations }, })
            .then(() => {
                enqueueSnackbar(t('saved'), { variant: "success" });
            })
            .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: "error" }))
            .finally(() => queryClient.invalidateQueries([SHOPS, { organization: oid }]));
    }

    const columns = [
        {
            headerName: t('id'),
            field: 'id',
            width: 150,
            hide: hideFields,
        },
        {
            headerName: t('name'),
            field: 'name',
            width: responsiveWidth({ viewWidth: 10, maxWidth: 400, minWidth: 150 }),
        },
        {
            headerName: t('description'),
            field: 'description',
            width: responsiveWidth({ viewWidth: 10, maxWidth: 400, minWidth: 150 }),
        },
        {
            headerName: t('address'),
            field: 'address',
            renderCell: params => params.row.address ?? '' + (params.row.number ? ', ' + params.row.number : ''),
            width: responsiveWidth({ viewWidth: 10, maxWidth: 400, minWidth: 150 }),
        },
        {
            headerName: t('city'),
            field: 'city',
            width: responsiveWidth({ viewWidth: 10, maxWidth: 400, minWidth: 150 }),
        },
        {
            headerName: t('phoneNumber'),
            field: 'phoneNumber',
            renderCell: params => (params.row.phonePrefix || '') + ' ' + params.row.phoneNumber,
            width: responsiveWidth({ viewWidth: 10, maxWidth: 400, minWidth: 150 }),
        },
        {
            headerName: t('webAddress'),
            field: 'website',
            width: responsiveWidth({ viewWidth: 10, maxWidth: 400, minWidth: 150 }),
        },
        !hideFields && {
            headerName: t('organization'),
            field: 'organizations',
            width: responsiveWidth({ viewWidth: 10, maxWidth: 400, minWidth: 250 }),
            valueGetter: (params) => params.row.organizations.map(o => o.title).join(', '),
            renderCell: (params) =>
                <div className={classes.alignLeftHoverableButton}>
                    <HoverableButton
                        item={{
                            icon: <Edit />,
                            name: params.row.organizations.map(o => o.title).join(', ')
                        }}
                        onClick={() => setEditShop({ id: params.row.id, organizations: params.row.organizations })}
                        classes={{ root: classes.root }}
                    />
                </div>
        },
        !hideFields && {
            headerName: t('iban'),
            field: 'iban',
            width: responsiveWidth({ viewWidth: 10, maxWidth: 400, minWidth: 150 }),
        },
        !hideFields && {
            headerName: t('bic'),
            field: 'bic',
            width: responsiveWidth({ viewWidth: 10, maxWidth: 400, minWidth: 150 }),
        },
        !hideFields && {
            headerName: t('vendorID'),
            field: 'vendorUid',
            width: responsiveWidth({ viewWidth: 10, maxWidth: 400, minWidth: 150 }),
            //hide: true
        },
        !hideFields && {
            headerName: t('businessNameVendor'),
            field: 'vendorBusinessName',
            width: responsiveWidth({ viewWidth: 10, maxWidth: 400, minWidth: 150 }),
        },
        !hideFields && {
            headerName: t('vendorEmail'),
            field: 'vendorEmail',
            width: responsiveWidth({ viewWidth: 10, maxWidth: 400, minWidth: 150 }),
        },
        // {
        //     headerName: t('shopTag'),
        //     field: 'categories',
        //     width: responsiveWidth({viewWidth: 10, maxWidth: 400, minWidth: 150}),
        //     valueGetter: (params) => params.value && params.value.reduce((prev, current) =>
        //         (prev ? prev + ', ' : '') + t((categories.find(c => c.id === current) || {}).translationKey), null),
        //     renderCell: params => <Tooltip
        //         title={(params.row.categories || []).map((v, k) => <span key={k}>{v} <br/></span>)}>
        //         <div>{params.value}</div>
        //     </Tooltip>
        // },
    ];

    function getShops(shops) {
        shops = shops ? [...shops].sort((a, b) => b.id - a.id) : [];
        if (tagFilters && filter.length > 0)
            shops = shops.filter(s => filter.some(f => s.categories.includes(f)))
        return shops;
    }

    return <Paper style={{ padding: "2rem" }}>

        {tagFilters && <FormBuilder
            fields={[
                {
                    key: 'categories',
                    label: t('filterCategories'),
                    type: FORM_TYPE.categories,
                    params: {
                        categories,
                    },
                    loading: categoriesStatus === 'loading',
                    required: false,
                    onChange: values => setFilter(values)
                },
            ]}
            formData={{ categories: filter }}
        />}

        <NTMXGrid
            key={"vendor-shops-table"}
            columns={columns.filter(c => !!c)}
            rows={getShops(shops)}
            title={t('shops')}
            getRowId={(row) => shops && row.id}
            loading={status === "loading"}
        />

        <EditOrganizationModal open={!!editShop} oid={editShop ? editShop.organizations : []}
            onClose={() => setEditShop(null)} onSubmit={saveEditOrganization} />
    </Paper>
}
