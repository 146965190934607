import React from "react";
import {BarChart, Bar, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis, ZAxis, CartesianGrid} from "recharts";
import {useTheme} from "@mui/material/styles";
import ResponsiveCircularLoading from "../ResponsiveCircularLoading";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Paper} from "@mui/material";
import {value} from "lodash/seq";

export function TooltipContent({active, payload, label}) {
    return <Paper sx={{p: 1}}>
        {payload && payload.find(p => p.name === 'value') && payload.find(p => p.name === 'value').value}
    </Paper>
}

/**
 * Crea un chart a bolle
 * @param data {[]}
 * @param loading
 * @param label
 * @param data[].field {string}
 * @param data[].value {number}
 * @returns {JSX.Element}
 * @constructor
 */
export default function NTMBarChart({data, loading, label}) {
    const theme = useTheme();
    data = data ?? [];
    if (loading) return <ResponsiveCircularLoading/>;

    return (<Grid container rowGap={2}>
        <Grid item xs={12}>
            <Typography variant={'h6'}>{label}</Typography>
        </Grid>
        <Grid item xs={12}>
            {loading && <ResponsiveCircularLoading/>}
            {!loading && <Box sx={{
                marginLeft: '-60px',
                '& .recharts-rectangle.recharts-tooltip-cursor': {
                    fill: 'none'
                }
            }}>
                <ResponsiveContainer width={"100%"} height={200}>
                    <BarChart
                        data={data}
                        margin={{top: 10, right: 0, bottom: 0, left: 0,}}
                    >
                        <XAxis
                            type="category"
                            dataKey="field"
                            tickLine={false}
                            axisLine={false}
                        />
                        <YAxis
                            tick={true}
                            tickLine={true}
                            axisLine={true}
                            label={false}
                        />
                        <Tooltip content={<TooltipContent/>}/>
                        <Bar dataKey="value" barSize={20} fill={theme.palette.primary.main}/>
                        <CartesianGrid strokeDasharray="2 10" vertical={false} />
                        <Tooltip cursor={{strokeDasharray: '3 3'}} wrapperStyle={{zIndex: 100}}
                                 content={<TooltipContent/>}/>
                    </BarChart>
                </ResponsiveContainer>
            </Box>}
        </Grid>
    </Grid>);
}