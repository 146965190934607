import Button from "@mui/material/Button";
import StartIconButton from "./StartIconButton";
import {ArrowDownward, ArrowDropDown, ArrowDropUp, ArrowUpward} from "@mui/icons-material";
import {Menu, MenuItem} from "@mui/material";
import React from "react";

/**
 * Button che apre un menù
 * @param title button title
 * @param {function(value)} onChange
 * @param {[{}]} items
 * @param {any} items[].text
 * @param {any} items[].value
 * @returns {JSX.Element}
 * @constructor
 */
export function MenuButton({title, onChange, items}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (value) => {
        handleClose();
        onChange(value);
    }
    return (
        <div>
            <StartIconButton
                endIcon={open ? <ArrowDropUp/> : <ArrowDropDown/>}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                title={title}
            />
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {
                    items.map(i => <MenuItem key={ typeof i.value === 'object'? Object.values(i.value) : i.value} onClick={() => handleChange(i.value)}>{i.text}</MenuItem>)
                }
            </Menu>
        </div>
    );
}