import { ExpandMore, Replay, Save } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next"
import StartIconButton from "./buttons/StartIconButton"
import ResponsiveCircularLoading from "./ResponsiveCircularLoading";

const useStyles = makeStyles(theme => ({
    container: {
        padding: "2rem",
        backgroundColor: theme.palette.primary.light,
        width: "auto"
    },
    header: {
        fontWeight: "bold",
    },
    expansionPanel: {
        margin: theme.spacing(1),
    },
    expanded: {
        border: "3px solid" + theme.palette.primary.dark,
    },
}));

export function Pill({ pill, index, onClick, lg = 6, xs = 12, expandIconColor = null, onExpandedChange = () => { }, accordionWidth = "100%" }) {

    let classes = useStyles();
    let [expanded, setExpanded] = useState(false);

    return (
        <Grid item lg={lg} xs={xs} key={index} onClick={onClick}>
            <Accordion
                sx={{ width: accordionWidth, justifySelf: "center" }}
                TransitionProps={{ unmountOnExit: true }}
                classes={{ root: classes.expansionPanel, expanded: classes.expanded }}
                square
                onChange={(e, expanded) => {
                    setExpanded(expanded);
                    onExpandedChange(expanded);
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMore color={expandIconColor ? expandIconColor : (expanded ? "secondary" : "primary")} />}
                    aria-controls="panel1a-content"
                    id={pill.id}
                >
                    <Grid alignItems={"center"} container direction="row">
                        <Typography sx={{ width: '100%' }} color={"textPrimary"}
                            className={classes.header}>{pill.title}</Typography>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    {
                        pill.component
                    }
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
}

export default function SettingsPills({ onRestore, disabled, onSave, checkErrorsFunc, pills, loading }) {
    const { t } = useTranslation();
    return <Grid container justifyContent="space-between">
        <Grid container justifyContent={"space-between"}>
            <Grid>
                <StartIconButton
                    onClick={() => {
                        onRestore();
                    }}
                    size="large" title={t('restore')} startIcon={<Replay />}
                    disabled={disabled} />
            </Grid>
            <Grid>
                <StartIconButton
                    onClick={onSave} size="large" title={t('save')}
                    startIcon={<Save />}
                    disabled={(disabled) || checkErrorsFunc()} />
            </Grid>

        </Grid>

        <Grid container xs={12} sx={
            {
                padding: "2rem",
                bgcolor: 'primary.light',
                width: "auto"
            }
        }>

            {
                loading ? <ResponsiveCircularLoading /> :
                    pills.map((pill, index) => <Pill pill={pill} index={index} />)
            }

        </Grid>
    </Grid>
}