import {useTranslation} from "react-i18next";

export default function useGetUsefulValues (national) {

    const {t} = useTranslation();

    let returnValues = [
        {
            id: 0,
            name: t('kmUrbanRoutes')
        },
        {
            id: 1,
            name: t('kmBikeForHomeWork')
        },
        {
            id: 2,
            name: t('numberSessionHomeWork')
        },
        {
            id: 3,
            name: t('initiativePoints')
        },
        {
            id: 4,
            name: t('numberSession')
        },
        {
            id: 5,
            name: t('nationalPoints')
        },
        {
            id: 6,
            name: t('nationalKmRoutes')
        },
    ]

    if(national === false) return returnValues.slice(0, 5)
    else if(national === true) return returnValues.slice(5)
    else return returnValues

}