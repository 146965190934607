import {Button, ClickAwayListener, Grid, Hidden, IconButton, Paper, Tooltip, Typography} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import TextInput, {TextInputContainer} from "../forms/TextInput";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import React, {useEffect, useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import {useGetErrorMessages} from "../../constants/errorMessages";
import {isEmpty} from "../../services/helper";
import {COUPONS, get, put, SHOPS} from "../../services/Client";
import {getRequestError} from "../../services/VendorManager";
import ScanQrModal from "../modals/ScanQrModal";
import CouponValidation from "./CouponValidation";
import clsx from "clsx";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import useGlobalStyles from "../../services/useGlobalStyles";
import {Camera, CameraAlt} from "@mui/icons-material";
import {filterShops} from "../../services/couponRedeemManager";

const useStyles = makeStyles(theme => ({
    couponContainer: {
        "&.MuiPaper-root": {
            backgroundColor: theme.palette.primary.light,
        }
    },
    button: {
        marginTop: '8px'
    }
}));

/**
 * sezione per effettuare la redeem dei coupon, quando viene convalidato il coupon viene mostrata la pagina di conferma
 * @param shops
 * @param onCouponDetailsOpen {function(boolean)} quando viene validato un coupon viene mostrato la pagina dei dettagli del coupon
 *
 * @param shop
 * @returns {JSX.Element}
 * @constructor
 */
export default function CouponRedeemSection({shops, onCouponDetailsOpen, shop = null}) {
    const globalClasses = useGlobalStyles();
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const errorMessages = useGetErrorMessages();
    const {t} = useTranslation();

    const [openInfo, setOpenInfo] = useState(false);
    const [code, setCode] = useState();
    const [errorCoupon, setErrorCoupon] = useState('');
    const [openQr, setOpenQr] = useState(false);
    const [coupon, setCoupon] = useState({});
    const [openCoupon, setOpenCoupon] = useState(false);
    useEffect(() => {
        onCouponDetailsOpen(openCoupon);
    }, [openCoupon]);

    if (shop) {//forzo la lista di shop a uno solo così da mostrarne solo uno
        shops = [shop];
    }

    /**
     * manda una richiesta per verificare il coupon e mostra una modale di dettaglio
     */
    function handleVerifyCoupon() {
        setErrorCoupon('');
        if (isEmpty(code)) {//controllo che coupon sia pieno
            setErrorCoupon(errorMessages.MANDATORY);
            return;
        }
        get(SHOPS + '/' + (shop ? shop.id : shops.find(s => s.isPrimary).id) + '/' + COUPONS + '/' + code + '/verify')//faccio la verifica con il primo Shop. verrà comunque preso il vendor corrispondente
            .then((res) => {
                //se non ci sono shop allora il coupon non è validabile. Controllo da fare solo se si accede con shortlink
                if (shop && filterShops([shop], res).length === 0) {
                    setErrorCoupon(() => t('couponsNotRedeemableByShop'));
                    return;
                }
                setCoupon(res);
                setOpenCoupon(true);
            })
            .catch(e => {
                setErrorCoupon(() => getRequestError(e));
            });
    }

    function handleConsumeCoupon(shopId) {
        setOpenCoupon(false);//chiudo la modale
        setErrorCoupon('');
        put(SHOPS + '/' + shopId + '/' + COUPONS, {elem: code}, !!shop)
            .then((res) => {
                enqueueSnackbar(t('couponRedeemed'), {variant: 'success'});
            })
            .catch(e => {
                setErrorCoupon(() => getRequestError(e));
            });
    }

    function handleOpenQr() {
        setOpenQr(true);
    }

    //testo mostrato nel tooltip
    let tooltipText = t('howToUseCouponRedeemSection');

    return (
        <>
            {
                openCoupon ?
                    <CouponValidation coupon={coupon} shops={shops} onSave={handleConsumeCoupon}
                                      onClose={() => setOpenCoupon(false)}/>
                    :
                    <Grid container
                          direction="row"
                          justifyContent='center'
                          alignItems="center"
                          className={globalClasses.marginBottom}
                    >
                        {/*-- text field con il coupon --*/}
                        <Paper className={clsx(classes.couponContainer, globalClasses.padding)}>
                            <Grid container
                                  direction="row"
                                  justifyContent='center'
                                  alignItems="flex-start"
                            >
                                <Grid item xs={12} className={globalClasses.marginBottom}>
                                    <Grid container
                                          direction="row"
                                          justifyContent='center'
                                          alignItems="center"
                                    >
                                        <Typography className={globalClasses.bold + ' ' + globalClasses.textCenter}
                                                    variant={'h5'}>
                                            {t('validateCoupon')}
                                        </Typography>

                                        {/*info tooltip icon*/}
                                        <Hidden mdUp>
                                            <ClickAwayListener onClickAway={() => setOpenInfo(false)}>
                                                <Tooltip
                                                    title={
                                                        <Typography variant={'caption'}>
                                                            {
                                                                tooltipText
                                                            }
                                                        </Typography>
                                                    }
                                                    PopperProps={{
                                                        disablePortal: true,
                                                    }}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    onClose={() => setOpenInfo(false)}
                                                    open={openInfo}
                                                >

                                                    <IconButton
                                                        onClick={() => setOpenInfo(true)}
                                                    >
                                                        <InfoIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </ClickAwayListener>
                                        </Hidden>
                                    </Grid>
                                    {/*---- se é md o lg mostra il testo completo sopra il pulsante -----*/}
                                    <Hidden smDown>
                                        <Grid
                                            className={clsx(globalClasses.textCenter, globalClasses.marginBottomSmall)}>
                                            <Typography variant={'caption'}>
                                                {
                                                    tooltipText
                                                }
                                            </Typography>
                                        </Grid>
                                    </Hidden>
                                </Grid>
                                <Grid item xs={12} md={9} className={globalClasses.paddingMdUp}>
                                    <TextInput
                                        label={t('insertCoupon')}
                                        type="text"
                                        onTextChange={(value) => {
                                            setCode(value);
                                            setErrorCoupon(null);
                                        }}
                                        color={'primary'}
                                        InputProps={{
                                            endAdornment: <IconButton onClick={handleOpenQr}>
                                                <CameraAlt/>
                                            </IconButton>
                                        }}
                                        value={code || ''}
                                        error={errorCoupon}
                                    />
                                    {
                                        !errorCoupon && <div className={globalClasses.marginSubsError}/>
                                    }
                                </Grid>
                                <Grid item xs={12} md={3}
                                      className={clsx(globalClasses.paddingMdUp, classes.button)}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={handleVerifyCoupon}
                                    >
                                        {t('validate')}
                                    </Button>
                                </Grid>
                                {/*{*/}
                                {/*    (sid && shops.length > 1) && //mostra le preferenze del negozio solo se se ne sono più di uno*/}
                                {/*    <Grid container xs={12} direction={'row-reverse'} alignItems={"center"}*/}
                                {/*          className={globalClasses.paddingMdUp}>*/}
                                {/*        <Grid item xs={12} md={"auto"}>*/}
                                {/*            <Button*/}
                                {/*                color="primary"*/}
                                {/*                onClick={handleRemoveShopPreferences}*/}
                                {/*            >*/}
                                {/*                Rimuovi preferenze negozio*/}
                                {/*            </Button>*/}
                                {/*        </Grid>*/}
                                {/*        <Grid item xs={12} md={"auto"} style={{paddingLeft: 8}}>*/}
                                {/*            <Typography>È stato selezionato il*/}
                                {/*                negozio: <b>{shops.find(s => s.id + '' === sid).name}</b></Typography>*/}
                                {/*        </Grid>*/}
                                {/*    </Grid>*/}
                                {/*}*/}
                            </Grid>
                        </Paper>
                    </Grid>

            }
            <ScanQrModal open={openQr} onClose={() => setOpenQr(false)} onScan={(text) => setCode(text)}
                         onError={(e) => setErrorCoupon(e.message)}/>
        </>
    );
}