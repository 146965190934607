import { Close, InfoOutlined } from "@mui/icons-material";
import { Grid, IconButton, Popover, Typography } from "@mui/material";
import { useState } from "react";

export default function Info({ children }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = (event) => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <IconButton onClick={handlePopoverOpen} size={'small'}>
                <InfoOutlined></InfoOutlined>
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Grid container >
                    {/* <Grid xs={12} textAlign='right'>
                        <IconButton size="small" onClick={handlePopoverClose}>
                            <Close size="small"></Close>
                        </IconButton>
                    </Grid> */}
                    <Grid xs={12} sx={{p:2}}>
                        {children}
                    </Grid>
                </Grid>
            </Popover>
        </>
    )
}