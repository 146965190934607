import {useGetOrganization} from "../../services/ContentManager";
import {Polygon} from "@react-google-maps/api";
import React from "react";


export default function OrganizationAreaLayer({organizationId, overrideOrganization}){

    let {organization} = useGetOrganization(organizationId, !overrideOrganization);
    if(overrideOrganization)
        organization = overrideOrganization;

    return <>
        {
            organization.geojson && JSON.parse(organization.geojson).map((p,i) =>
                <Polygon path={p} key={i}/>)
        }
    </>
}