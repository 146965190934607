import {isEmpty, isValidPhone, isValidPostalCode} from "./helper";
import i18next from "../i18n";

/**
 * valida i dati di un form
 * @param data oggetto con i dati da validare
 * @param fields {[{field:string,error:string,validation:function(data):string|null|boolean}|{field:string}|{field:string,error:string}]} informazioni su come effettuare la validazione, il controllo di default è quello sul required. validation può ritornare o l'errore da mostrare oppure un valore booleano
 * @returns {[valid:boolean,error:{}]}//flag per definire se è valido, oggetto con messaggi di errore
 */
export function validateForm(data, fields) {
    let errors = {};
    let isValid = true;
    fields.forEach((item) => {
        let element = item.path ? data[item.path][item.field] : data[item.field];
        if (!item.validation && isEmpty(element)) {
            errors[item.field] = item.error || i18next.t('mandatoryField');
            isValid = false;
        }
        if (typeof item.validation === 'function') {
            let valid = item.validation(data);
            if (typeof valid === 'boolean' && !valid) {
                errors[item.field] = item.error || i18next.t('mandatoryField');
                isValid = false;
            } else if (typeof valid === 'string') {
                errors[item.field] = valid;
                isValid = false;
            }
        }
    });
    return [errors, isValid];
}

const ibanPatterns = {
    'AD': /^AD\d{2}[0-9]{4}[0-9]{4}[0-9]{12}$/,  // Andorra
    'AE': /^AE\d{2}[0-9]{4}[0-9]{15}$/,  // United Arab Emirates
    'AF': /^AF\d{2}[A-Z0-9]{4}\d{16}$/,  // Afghanistan
    'AG': /^AG\d{2}[A-Z]{4}\d{16}$/,  // Antigua and Barbuda
    'AI': /^AI\d{2}[A-Z]{4}\d{16}$/,  // Anguilla
    'AL': /^AL\d{2}[0-9]{8}[0-9]{16}$/,  // Albania
    'AM': /^AM\d{2}[A-Z]{4}\d{4}[A-Z0-9]{12}$/,  // Armenia
    'AO': /^AO\d{2}\d{21}$/,  // Angola
    'AR': /^AR\d{2}\d{4}\d{16}$/,  // Argentina
    'AS': /^AS\d{2}[A-Z]{4}\d{16}$/,  // American Samoa
    'AT': /^AT\d{2}\d{5}\d{11}$/,  // Austria
    'AU': /^AU\d{2}[A-Z]{4}\d{6}\d{6}$/,  // Australia
    'AW': /^AW\d{2}[A-Z]{4}\d{16}$/,  // Aruba
    'AX': /^AX\d{2}\d{6}\d{6}\d{6}$/,  // Åland Islands
    'AZ': /^AZ\d{2}[A-Z]{4}\d{20}$/,  // Azerbaijan
    'BA': /^BA\d{2}\d{3}\d{3}\d{8}\d{2}$/,  // Bosnia and Herzegovina
    'BB': /^BB\d{2}[A-Z]{4}\d{16}$/,  // Barbados
    'BD': /^BD\d{2}[A-Z]{4}\d{4}[0-9A-Z]{10}$/,  // Bangladesh
    'BE': /^BE\d{2}\d{3}\d{7}\d{2}$/,  // Belgium
    'BF': /^BF\d{2}\d{5}\d{5}$/,  // Burkina Faso
    'BG': /^BG\d{2}[A-Z]{4}\d{6}[A-Z0-9]{8}$/,  // Bulgaria
    'BH': /^BH\d{2}[A-Z]{4}\d{14}$/,  // Bahrain
    'BI': /^BI\d{2}[0-9]{3}[0-9]{4}[0-9]{16}$/,  // Burundi
    'BJ': /^BJ\d{2}\d{5}\d{5}$/,  // Benin
    'BL': /^BL\d{2}[A-Z]{4}\d{15}$/,  // Saint Barthélemy
    'BM': /^BM\d{2}[A-Z]{4}\d{16}$/,  // Bermuda
    'BN': /^BN\d{2}[A-Z]{4}\d{15}$/,  // Brunei
    'BO': /^BO\d{2}[A-Z]{4}\d{14}$/,  // Bolivia
    'BQ': /^BQ\d{2}[A-Z]{4}\d{16}$/,  // Bonaire, Sint Eustatius and Saba
    'BR': /^BR\d{2}\d{4}\d{4}\d{15}[A-Z0-9]{2}$/,  // Brazil
    'BS': /^BS\d{2}[A-Z]{4}\d{16}$/,  // Bahamas
    'BT': /^BT\d{2}[A-Z]{4}\d{6}[A-Z0-9]{12}$/,  // Bhutan
    'BV': /^BV\d{2}\d{3}\d{7}$/,  // Bouvet Island
    'BW': /^BW\d{2}\d{16}$/,  // Botswana
    'BY': /^BY\d{2}[A-Z0-9]{4}\d{4}[A-Z0-9]{16}$/,  // Belarus
    'BZ': /^BZ\d{2}[A-Z]{4}\d{17}$/,  // Belize
    'CA': /^CA\d{2}[A-Z]{4}\d{16}$/,  // Canada
    'CC': /^CC\d{2}[A-Z]{4}\d{16}$/,  // Cocos (Keeling) Islands
    'CD': /^CD\d{2}\d{23}$/,  // Democratic Republic of the Congo
    'CF': /^CF\d{2}\d{5}\d{5}$/,  // Central African Republic
    'CG': /^CG\d{2}\d{11}\d{5}$/,  // Congo
    'CH': /^CH\d{2}[A-Z0-9]{5}\d{12}$/,  // Switzerland
    'CI': /^CI\d{2}\d{5}\d{5}$/,  // Côte d'Ivoire
    'CK': /^CK\d{2}[A-Z]{4}\d{15}$/,  // Cook Islands
    'CL': /^CL\d{2}\d{4}\d{20}$/,  // Chile
    'CM': /^CM\d{2}\d{5}\d{6}\d{6}$/,  // Cameroon
    'CN': /^CN\d{2}\d{12}$/,  // China
    'CO': /^CO\d{2}[A-Z0-9]{4}\d{12}$/,  // Colombia
    'CR': /^CR\d{2}[0-9]{4}[0-9]{14}$/,  // Costa Rica
    'CU': /^CU\d{2}[A-Z0-9]{4}\d{16}$/,  // Cuba
    'CV': /^CV\d{2}[A-Z0-9]{4}\d{14}$/,  // Cape Verde
    'CW': /^CW\d{2}[A-Z]{4}\d{16}$/,  // Curaçao
    'CX': /^CX\d{2}[A-Z]{4}\d{16}$/,  // Christmas Island
    'CY': /^CY\d{2}[0-9]{3}[0-9]{5}[0-9]{16}$/,  // Cyprus
    'CZ': /^CZ\d{2}\d{4}\d{6}\d{10}$/,  // Czech Republic
    'DE': /^DE\d{2}\d{8}\d{10}$/,  // Germany
    'DJ': /^DJ\d{2}[0-9]{3}[0-9]{12}[0-9]{8}$/,  // Djibouti
    'DK': /^DK\d{2}\d{4}\d{10}$/,  // Denmark
    'DM': /^DM\d{2}[A-Z]{4}\d{20}$/,  // Dominica
    'DO': /^DO\d{2}[A-Z0-9]{4}\d{20}$/,  // Dominican Republic
    'DZ': /^DZ\d{2}\d{20}$/,  // Algeria
    'EC': /^EC\d{2}[A-Z]{6}\d{16}$/,  // Ecuador
    'EE': /^EE\d{2}\d{4}\d{12}$/,  // Estonia
    'EG': /^EG\d{2}[0-9]{4}[0-9]{14}[0-9]{7}$/,  // Egypt
    'EH': /^EH\d{2}[A-Z0-9]{4}\d{16}$/,  // Western Sahara
    'ER': /^ER\d{2}[A-Z0-9]{4}\d{19}$/,  // Eritrea
    'ES': /^ES\d{2}[0-9]{4}[0-9]{4}[0-9]{1}[0-9]{11}$/,  // Spain
    'ET': /^ET\d{2}[A-Z0-9]{4}\d{16}$/,  // Ethiopia
    'FI': /^FI\d{2}\d{6}\d{8}$/,  // Finland
    'FJ': /^FJ\d{2}[A-Z0-9]{4}\d{15}$/,  // Fiji
    'FM': /^FM\d{2}[A-Z]{4}\d{16}$/,  // Micronesia
    'FO': /^FO\d{2}[0-9]{4}[0-9]{10}$/,  // Faroe Islands
    'FR': /^FR\d{2}[0-9]{5}[0-9]{11}[A-Z0-9]{7}$/,  // France
    'GA': /^GA\d{2}\d{5}\d{5}$/,  // Gabon
    'GB': /^GB\d{2}[A-Z]{4}\d{6}\d{8}$/,  // United Kingdom
    'GD': /^GD\d{2}[A-Z]{4}\d{16}$/,  // Grenada
    'GE': /^GE\d{2}[A-Z]{2}\d{16}$/,  // Georgia
    'GF': /^GF\d{2}[A-Z]{4}\d{15}$/,  // French Guiana
    'GG': /^GG\d{2}[A-Z]{4}\d{16}$/,  // Guernsey
    'GH': /^GH\d{2}[A-Z0-9]{4}\d{10}$/,  // Ghana
    'GI': /^GI\d{2}[A-Z]{4}\d{15}$/,  // Gibraltar
    'GL': /^GL\d{2}[0-9]{4}[0-9]{10}$/,  // Greenland
    'GM': /^GM\d{2}[A-Z0-9]{4}\d{15}$/,  // Gambia
    'GN': /^GN\d{2}[A-Z0-9]{4}\d{15}$/,  // Guinea
    'GP': /^GP\d{2}[A-Z]{4}\d{15}$/,  // Guadeloupe
    'GQ': /^GQ\d{2}[A-Z0-9]{4}\d{15}$/,  // Equatorial Guinea
    'GR': /^GR\d{2}[0-9]{3}[0-9]{4}[0-9]{16}$/,  // Greece
    'GT': /^GT\d{2}[A-Z]{4}\d{20}$/,  // Guatemala
    'GU': /^GU\d{2}[A-Z]{4}\d{16}$/,  // Guam
    'GW': /^GW\d{2}[A-Z0-9]{4}\d{11}$/,  // Guinea-Bissau
    'GY': /^GY\d{2}[A-Z0-9]{4}\d{15}$/,  // Guyana
    'HK': /^HK\d{2}[A-Z0-9]{4}\d{15}$/,  // Hong Kong
    'HM': /^HM\d{2}[A-Z]{4}\d{16}$/,  // Heard Island and McDonald Islands
    'HN': /^HN\d{2}[A-Z]{4}\d{14}$/,  // Honduras
    'HR': /^HR\d{2}\d{7}\d{10}$/,  // Croatia
    'HT': /^HT\d{2}[A-Z0-9]{4}\d{16}$/,  // Haiti
    'HU': /^HU\d{2}[0-9]{4}[0-9]{8}[0-9]{12}$/,  // Hungary
    'ID': /^ID\d{2}[A-Z0-9]{3}\d{13}$/,  // Indonesia
    'IE': /^IE\d{2}[A-Z]{4}\d{6}\d{8}$/,  // Ireland
    'IL': /^IL\d{2}[0-9]{3}[0-9]{16}$/,  // Israel
    'IM': /^IM\d{2}[A-Z]{4}\d{15}$/,  // Isle of Man
    'IN': /^IN\d{2}[A-Z0-9]{4}\d{14}$/,  // India
    'IO': /^IO\d{2}[A-Z]{4}\d{15}$/,  // British Indian Ocean Territory
    'IQ': /^IQ\d{2}[A-Z0-9]{4}\d{15}$/,  // Iraq
    'IR': /^IR\d{2}[A-Z0-9]{4}\d{13}$/,  // Iran
    'IS': /^IS\d{2}[0-9]{4}[0-9]{6}[0-9]{12}$/,  // Iceland
    'IT': /^IT\d{2}[A-Z0-9]{1}[0-9]{5}[0-9]{4}[A-Z0-9]{13}$/,  // Italy
    'JE': /^JE\d{2}[A-Z]{4}\d{16}$/,  // Jersey
    'JM': /^JM\d{2}[A-Z]{4}\d{16}$/,  // Jamaica
    'JO': /^JO\d{2}[A-Z]{4}[0-9]{12}[0-9]{10}$/,  // Jordan
    'JP': /^JP\d{2}[A-Z0-9]{4}\d{15}$/,  // Japan
    'KE': /^KE\d{2}[A-Z0-9]{4}\d{16}$/,  // Kenya
    'KG': /^KG\d{2}[A-Z0-9]{4}\d{16}$/,  // Kyrgyzstan
    'KH': /^KH\d{2}[A-Z0-9]{4}\d{14}$/,  // Cambodia
    'KI': /^KI\d{2}[A-Z]{4}\d{16}$/,  // Kiribati
    'KM': /^KM\d{2}[A-Z0-9]{4}\d{13}$/,  // Comoros
    'KN': /^KN\d{2}[A-Z]{4}\d{16}$/,  // Saint Kitts and Nevis
    'KP': /^KP\d{2}[A-Z0-9]{4}\d{15}$/,  // North Korea
    'KR': /^KR\d{2}[A-Z0-9]{4}\d{16}$/,  // South Korea
    'KW': /^KW\d{2}[A-Z]{4}\d{22}$/,  // Kuwait
    'KY': /^KY\d{2}[A-Z]{4}\d{16}$/,  // Cayman Islands
    'KZ': /^KZ\d{2}\d{3}\d{13}$/,  // Kazakhstan
    'LA': /^LA\d{2}[A-Z0-9]{4}\d{14}$/,  // Laos
    'LB': /^LB\d{2}[A-Z0-9]{4}\d{20}$/,  // Lebanon
    'LC': /^LC\d{2}[A-Z]{4}\d{24}$/,  // Saint Lucia
    'LI': /^LI\d{2}[A-Z0-9]{5}\d{12}$/,  // Liechtenstein
    'LK': /^LK\d{2}[A-Z0-9]{4}\d{14}$/,  // Sri Lanka
    'LR': /^LR\d{2}[A-Z0-9]{4}\d{14}$/,  // Liberia
    'LS': /^LS\d{2}[A-Z0-9]{4}\d{14}$/,  // Lesotho
    'LT': /^LT\d{2}[A-Z0-9]{5}\d{11}$/,  // Lithuania
    'LU': /^LU\d{2}[A-Z0-9]{3}\d{13}$/,  // Luxembourg
    'LV': /^LV\d{2}[A-Z]{4}\d{13}$/,  // Latvia
    'LY': /^LY\d{2}[0-9]{5}[0-9]{16}$/,  // Libya
    'MA': /^MA\d{2}[A-Z0-9]{6}\d{13}$/,  // Morocco
    'MC': /^MC\d{2}[0-9]{5}[0-9]{18}$/,  // Monaco
    'MD': /^MD\d{2}[A-Z0-9]{2}\d{18}$/,  // Moldova
    'ME': /^ME\d{2}[0-9]{3}[0-9]{15}$/,  // Montenegro
    'MF': /^MF\d{2}[A-Z]{4}\d{15}$/,  // Saint Martin
    'MG': /^MG\d{2}[A-Z0-9]{4}\d{18}$/,  // Madagascar
    'MH': /^MH\d{2}[A-Z]{4}\d{16}$/,  // Marshall Islands
    'MK': /^MK\d{2}[0-9]{3}[0-9]{10}[0-9]{2}$/,  // North Macedonia
    'ML': /^ML\d{2}[A-Z0-9]{4}\d{11}$/,  // Mali
    'MM': /^MM\d{2}[A-Z0-9]{4}\d{16}$/,  // Myanmar
    'MN': /^MN\d{2}[0-9]{4}[0-9]{12}$/,  // Mongolia
    'MO': /^MO\d{2}[A-Z0-9]{4}\d{15}$/,  // Macau
    'MP': /^MP\d{2}[A-Z]{4}\d{16}$/,  // Northern Mariana Islands
    'MQ': /^MQ\d{2}[A-Z]{4}\d{15}$/,  // Martinique
    'MR': /^MR\d{2}[0-9]{5}[0-9]{5}[0-9]{13}$/,  // Mauritania
    'MS': /^MS\d{2}[A-Z]{4}\d{16}$/,  // Montserrat
    'MT': /^MT\d{2}[A-Z]{4}\d{5}\d{18}$/,  // Malta
    'MU': /^MU\d{2}[A-Z]{4}[0-9]{2}[A-Z0-9]{17}[A-Z]{3}$/,  // Mauritius
    'MV': /^MV\d{2}[A-Z0-9]{4}\d{14}$/,  // Maldives
    'MW': /^MW\d{2}[A-Z0-9]{4}\d{16}$/,  // Malawi
    'MX': /^MX\d{2}[A-Z]{4}\d{14}$/,  // Mexico
    'MY': /^MY\d{2}[A-Z]{4}\d{15}$/,  // Malaysia
    'MZ': /^MZ\d{2}[A-Z0-9]{4}\d{15}$/,  // Mozambique
    'NA': /^NA\d{2}[A-Z0-9]{4}\d{17}$/,  // Namibia
    'NC': /^NC\d{2}[A-Z]{4}\d{15}$/,  // New Caledonia
    'NE': /^NE\d{2}[A-Z0-9]{4}\d{15}$/,  // Niger
    'NF': /^NF\d{2}[A-Z]{4}\d{16}$/,  // Norfolk Island
    'NG': /^NG\d{2}[A-Z0-9]{4}\d{15}$/,  // Nigeria
    'NI': /^NI\d{2}[A-Z]{4}[0-9]{20}$/,  // Nicaragua
    'NL': /^NL\d{2}[A-Z]{4}\d{10}$/,  // Netherlands
    'NO': /^NO\d{2}[0-9]{4}[0-9]{7}$/,  // Norway
    'NP': /^NP\d{2}[A-Z]{4}\d{14}$/,  // Nepal
    'NR': /^NR\d{2}[A-Z]{4}\d{15}$/,  // Nauru
    'NU': /^NU\d{2}[A-Z]{4}\d{16}$/,  // Niue
    'NZ': /^NZ\d{2}[A-Z0-9]{4}\d{15}$/,  // New Zealand
    'OM': /^OM\d{2}[0-9]{4}[0-9]{15}$/,  // Sultanate of Oman
    'PA': /^PA\d{2}[A-Z]{4}\d{16}$/,  // Panama
    'PE': /^PE\d{2}[A-Z0-9]{4}\d{17}$/,  // Peru
    'PF': /^PF\d{2}[A-Z]{4}\d{15}$/,  // French Polynesia
    'PG': /^PG\d{2}[A-Z]{4}\d{16}$/,  // Papua New Guinea
    'PH': /^PH\d{2}[A-Z0-9]{4}\d{15}$/,  // Philippines
    'PK': /^PK\d{2}[A-Z0-9]{4}\d{16}$/,  // Pakistan
    'PL': /^PL\d{2}\d{8}\d{16}$/,  // Poland
    'PM': /^PM\d{2}[A-Z]{4}\d{15}$/,  // Saint Pierre and Miquelon
    'PN': /^PN\d{2}[A-Z]{4}\d{16}$/,  // Pitcairn Islands
    'PR': /^PR\d{2}[A-Z]{4}\d{15}$/,  // Puerto Rico
    'PT': /^PT\d{2}[0-9]{4}[0-9]{4}[0-9]{13}$/,  // Portugal
    'PW': /^PW\d{2}[A-Z]{4}\d{16}$/,  // Palau
    'PY': /^PY\d{2}[A-Z0-9]{4}\d{16}$/,  // Paraguay
    'QA': /^QA\d{2}[A-Z]{4}\d{21}$/,  // Qatar
    'RE': /^RE\d{2}[A-Z]{4}\d{15}$/,  // Réunion
    'RO': /^RO\d{2}[A-Z]{4}\d{16}$/,  // Romania
    'RS': /^RS\d{2}[0-9]{3}[0-9]{15}$/,  // Serbia
    'RU': /^RU\d{2}[0-9]{4}[0-9]{15}[0-9]{10}$/,  // Russia
    'RW': /^RW\d{2}[A-Z0-9]{4}\d{16}$/,  // Rwanda
    'SA': /^SA\d{2}[0-9]{4}[0-9]{16}$/,  // Saudi Arabia
    'SB': /^SB\d{2}[A-Z]{4}\d{16}$/,  // Solomon Islands
    'SC': /^SC\d{2}[A-Z0-9]{4}[0-9]{20}[A-Z]{3}$/,  // Seychelles
    'SD': /^SD\d{2}[0-9]{4}[0-9]{10}$/,  // Sudan
    'SE': /^SE\d{2}[0-9]{3}[0-9]{17}$/,  // Sweden
    'SG': /^SG\d{2}[A-Z]{4}\d{15}$/,  // Singapore
    'SH': /^SH\d{2}[A-Z]{4}\d{15}$/,  // Saint Helena
    'SI': /^SI\d{2}[0-9]{5}[0-9]{10}$/,  // Slovenia
    'SJ': /^SJ\d{2}[A-Z]{4}\d{15}$/,  // Svalbard and Jan Mayen
    'SK': /^SK\d{2}\d{4}\d{6}\d{10}$/,  // Slovakia
    'SL': /^SL\d{2}[A-Z0-9]{4}\d{11}$/,  // Sierra Leone
    'SM': /^SM\d{2}[A-Z0-9]{1,5}[0-9]{5}[0-9]{13}$/,  // San Marino
    'SN': /^SN\d{2}[A-Z0-9]{4}\d{15}$/,  // Senegal
    'SO': /^SO\d{2}[0-9]{4}[0-9]{15}$/,  // Somalia
    'SR': /^SR\d{2}[A-Z0-9]{4}\d{15}$/,  // Suriname
    'SS': /^SS\d{2}[A-Z0-9]{4}\d{18}$/,  // South Sudan
    'ST': /^ST\d{2}[0-9]{4}[0-9]{17}$/,  // Sao Tome and Principe
    'SV': /^SV\d{2}[A-Z]{4}\d{20}$/,  // El Salvador
    'SX': /^SX\d{2}[A-Z]{4}\d{16}$/,  // Sint Maarten
    'SY': /^SY\d{2}[A-Z]{4}\d{16}$/,  // Syria
    'SZ': /^SZ\d{2}[A-Z0-9]{4}\d{16}$/,  // Eswatini
    'TC': /^TC\d{2}[A-Z]{4}\d{16}$/,  // Turks and Caicos Islands
    'TD': /^TD\d{2}\d{5}\d{5}$/,  // Chad
    'TF': /^TF\d{2}[A-Z]{4}\d{15}$/,  // French Southern Territories
    'TG': /^TG\d{2}[A-Z0-9]{4}\d{15}$/,  // Togo
    'TH': /^TH\d{2}[A-Z0-9]{4}\d{15}$/,  // Thailand
    'TJ': /^TJ\d{2}[A-Z0-9]{4}\d{13}$/,  // Tajikistan
    'TK': /^TK\d{2}[A-Z]{4}\d{16}$/,  // Tokelau
    'TL': /^TL\d{2}[0-9]{3}[0-9]{15}[0-9]{1}$/,  // Timor-Leste
    'TM': /^TM\d{2}[A-Z0-9]{4}\d{16}$/,  // Turkmenistan
    'TN': /^TN\d{2}[A-Z0-9]{4}\d{16}$/,  // Tunisia
    'TO': /^TO\d{2}[A-Z]{4}\d{16}$/,  // Tonga
    'TR': /^TR\d{2}[A-Z0-9]{5}\d{1}[A-Z0-9]{16}$/,  // Turkey
    'TT': /^TT\d{2}[A-Z]{4}\d{15}$/,  // Trinidad and Tobago
    'TV': /^TV\d{2}[A-Z]{4}\d{15}$/,  // Tuvalu
    'TZ': /^TZ\d{2}[A-Z0-9]{4}\d{16}$/,  // Tanzania
    'UA': /^UA\d{2}[0-9]{6}[0-9]{10}[0-9]{9}$/,  // Ukraine
    'UG': /^UG\d{2}[A-Z0-9]{4}\d{16}$/,  // Uganda
    'UM': /^UM\d{2}[A-Z]{4}\d{16}$/,  // United States Minor Outlying Islands
    'US': /^US\d{2}[A-Z]{4}\d{15}$/,  // United States
    'UY': /^UY\d{2}[A-Z0-9]{4}\d{13}$/,  // Uruguay
    'UZ': /^UZ\d{2}[A-Z0-9]{4}\d{20}$/,  // Uzbekistan
    'VA': /^VA\d{2}[0-9]{4}[0-9]{14}$/,  // Vatican City
    'VC': /^VC\d{2}[A-Z]{4}\d{16}$/,  // Saint Vincent and the Grenadines
    'VE': /^VE\d{2}[A-Z0-9]{4}\d{20}$/,  // Venezuela
    'VG': /^VG\d{2}[A-Z]{4}\d{16}$/,  // British Virgin Islands
    'VI': /^VI\d{2}[A-Z]{4}\d{16}$/,  // U.S. Virgin Islands
    'VN': /^VN\d{2}[A-Z0-9]{4}\d{15}$/,  // Vietnam
    'VU': /^VU\d{2}[A-Z]{4}\d{16}$/,  // Vanuatu
    'WF': /^WF\d{2}[A-Z]{4}\d{15}$/,  // Wallis and Futuna
    'WS': /^WS\d{2}[A-Z]{4}\d{16}$/,  // Samoa
    'XA': /^XA\d{2}[A-Z0-9]{4}\d{16}$/,  // Unrecognized region (example)
    'XB': /^XB\d{2}[A-Z0-9]{4}\d{16}$/,  // Unrecognized region (example)
    'XC': /^XC\d{2}[A-Z0-9]{4}\d{16}$/,  // Unrecognized region (example)
    'XD': /^XD\d{2}[A-Z0-9]{4}\d{16}$/,  // Unrecognized region (example)
    'YE': /^YE\d{2}[A-Z0-9]{4}[0-9]{22}$/,  // Yemen
    'YT': /^YT\d{2}[A-Z]{4}\d{15}$/,  // Mayotte
    'ZA': /^ZA\d{2}[A-Z0-9]{4}\d{15}$/,  // South Africa
    'ZM': /^ZM\d{2}[A-Z0-9]{4}\d{16}$/,  // Zambia
    'ZW': /^ZW\d{2}[A-Z0-9]{4}\d{16}$/,  // Zimbabwe
};
/**
 *
 * @param data oggetto data con tutti i campi del form
 * @returns {({}|boolean)[]}
 */
export function validateVendorData(data) {

    return validateForm(
        data,
        [
            {
                field: 'businessName',
            },
            {
                field: 'address',
                validation: (data) => !((isEmpty(data.address) || isEmpty(data.number) || isEmpty(data.postalCode) || isEmpty(data.city) || isEmpty(data.province) || isEmpty(data.country)))
            },
            {
                field: 'phone',
                validation: (data) => isValidPhone(data.phone),
                error: i18next.t('invalidPhone')
            },
            {
                field: 'vatNumber',
            },
            {
                field: 'postalCode',
                validation: (data) => isValidPostalCode(data.postalCode),
                error: i18next.t('postalCodeNotValid')
            },
        ]
    );
    // if(!isValidVat(data.vatNumber)){
    //     errors.vatNumber = INVALID_VAT;
    //     isValid = false;
    // }
    // if(!isValidIBAN(data.iban)){
    //     errors.iban = INVALID_IBAN;
    //     isValid = false;
    // }
}

/**
 *
 * @param data oggetto data con tutti i campi del form
 * @returns {({}|boolean)[]}
 */
export function validateVendorAndShopData(data) {

    return validateForm(
        data,
        [
            {
                field: 'businessName',
            },
            {
                field: 'address',
                validation: (data) => !((isEmpty(data.address) || isEmpty(data.number) || isEmpty(data.postalCode) || isEmpty(data.city) || isEmpty(data.province) || isEmpty(data.country)))
            },
            {
                field: 'phone',
                validation: (data) => isValidPhone(data.phone),
                error: i18next.t('invalidPhone')
            },
            {
                field: 'vatNumber',
            },
            {
                field: 'postalCode',
                validation: (data) => isValidPostalCode(data.postalCode),
                error: i18next.t('postalCodeNotValid')
            },
            {
                field: 'name',
                path: 'firstShop'
            },
            {
                field: 'claim',
                path: 'firstShop'
            },
            {
                field: 'description',
                path: 'firstShop'
            },
            {
                field: 'website',
                path: 'firstShop'
            },
            {
                field: 'categories',
                path: 'firstShop'
            },
            {
                field: 'iban',
                path: 'firstShop',
                validation: (data) => isEmpty(data.firstShop.iban) || validateIBAN(data.firstShop.iban),
                error: i18next.t('invalidIBAN')
            },
            {
                field: 'bic',
                path: 'firstShop',
                validation: (data) => isEmpty(data.firstShop.bic) || validateSWIFT(data.firstShop.bic),
                error: i18next.t('invalidBic')
            },
        ]
    );

    function validateIBAN(iban) {
        const countryCode = iban.substring(0, 2);

        if (!ibanPatterns.hasOwnProperty(countryCode)) {
           return false;
        }

        const ibanPattern = ibanPatterns[countryCode];
        if (!ibanPattern) return false;
        return ibanPattern.test(iban);
    }


    function validateSWIFT(bic) {
        const swiftRegex = /^[A-Z]{4}[A-Z]{2}[A-Z\d]{2}([A-Z\d]{3})?$/;
        return swiftRegex.test(bic);
    };

    // if(!isValidVat(data.vatNumber)){
    //     errors.vatNumber = INVALID_VAT;
    //     isValid = false;
    // }
    // if(!isValidIBAN(data.iban)){
    //     errors.iban = INVALID_IBAN;
    //     isValid = false;
    // }
}

/**
 * valida nome,email,password
 * @param newData
 * @returns {({}|boolean)[]}
 * @private
 */
export function validateEmailPass(newData) {
    return validateForm(
        newData,
        [
            {
                field: 'firstName',
            },
            {
                field: 'lastName',
            },
            {
                field: 'email',
            },
            {
                field: 'newPassword',
            },
            {
                field: 'repeatPassword',
                validation: (data) => {
                    return data.newPassword === data.repeatPassword;
                },
                error: i18next.t('passwordNotMatch')
            },
            {
                field: 'lastName'
            }
        ]
    );
}

/**
 * valida nome,email,password
 * @param newData
 * @returns {({}|boolean)[]}
 * @private
 */
export function validatePrivacyTerms(newData) {
    return validateForm(
        newData,
        [
            {
                field: 'privacyTermsAccepted',
                validation: (data) => {
                    return data.privacyTermsAccepted != undefined && data.privacyTermsAccepted == true;}
            },
        ]
    );
}