import {useParams} from "react-router-dom";
import {useGetOrganizationAchievements,useGetOrganizationCustomField} from "../../services/ContentManager";
import AchievementsManager from "../AchievementsManager";


export default function OrganizationAchievements(){

    let {id} = useParams();
    let {achievements = []} = useGetOrganizationAchievements(id);
    let {customField = [], status} = useGetOrganizationCustomField(id);

    return <AchievementsManager achievements={achievements} loading={status === "loading"} organizationId={id}  customField={customField}/>
}