import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";
import Autocomplete from '@mui/material/Autocomplete';
import {TextField, Typography} from "@mui/material";

export function NTMAutocomplete({color, textFieldParams, error, ...params}) {
    const useStyles = makeStyles(theme => ({
        root: {
            '& label': {
                color: color === 'light' ? theme.palette.primary.light : theme.palette.secondary.main,
                fontStyle: "italic",
                '&.MuiInputLabel-shrink': {
                    fontStyle: "normal"
                },
                '&.Mui-focused': {
                    color: theme.palette.secondary.main,
                },
                '&:hover': {
                    color: theme.palette.secondary.main,
                },
            },
            '& .MuiOutlinedInput-root': {
                borderRadius: 0,
                '& fieldset': {
                    borderColor: color === 'light' ? theme.palette.primary.light : theme.palette.secondary.main,
                },
                '&:hover fieldset': {
                    borderColor: theme.palette.secondary.main,
                },
                '&.Mui-focused fieldset': {
                    borderColor: theme.palette.secondary.main,
                    borderWidth: "3px"
                },
            },
            "& .Mui-error": {
                color: color === 'light' ? theme.palette.error.light : theme.palette.error.main,
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: color === 'light' ? theme.palette.error.light : theme.palette.error.main,
                }
            },
        },
        input: {
            color: color === 'light' ? theme.palette.primary.light : theme.palette.secondary.main,
            '&:focused': {
                borderColor: theme.palette.secondary.main,
            },
            '&:hover': {
                color: theme.palette.secondary.main,
            },
            '&::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: theme.palette.secondary.main,
                opacity: 1 /* Firefox */
            },
            '&:-ms-input-placeholder': { /* Internet Explorer 10-11 */
                color: theme.palette.secondary.main,
            },
            '&::-ms-input-placeholder': { /* Microsoft Edge */
                color: theme.palette.secondary.main,
            }
        },
        colorGrey: {
            color: 'rgba(0, 0, 0, 0.54)'
        },
        error: {
            color: color === 'light' ? theme.palette.error.light : theme.palette.error.main,
        }
    }));

    const classes = useStyles();

    return (
        <Grid container direction={"column"}>
            <Grid item xs={12}>
                <Autocomplete
                    {...params}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            {...textFieldParams}
                            variant="outlined"
                            fullWidth
                            classes={{root: classes.root}}
                            error={!!error}
                        />
                    }
                />
            </Grid>

            <Grid item xs={12}>
                <Typography className={classes.error} variant={"caption"}>{error}</Typography>
            </Grid>
        </Grid>
    );
}