import React from "react";
import NTMXGrid, {timestampFormatter, timestampFormatterWithTimezone} from "../../components/NTMXGrid";
import {Grid, Tooltip, Typography} from "@mui/material";
import RenderBoolean from "../../components/cellRender/RenderBoolean";
import {responsiveWidth} from "../../services/useBreakpoint";
import {useTranslation} from "react-i18next";

/**
 * Mostra la lista di coupon non modificabili
 * @param coupons
 * @param title
 * @param isArticleHistory se impostato a false nasconde alcune colonne per essere usato nello storico degli articoli
 * @returns {JSX.Element}
 * @constructor
 */
export default function CouponList({coupons, title, isArticleHistory = false}) {

    const {t} = useTranslation();

    const columns = [];

    if (!isArticleHistory) columns.push({
        headerName: t('status'),
        field: 'refund',
        width: responsiveWidth({viewWidth: 10, minWidth: 80}),
        renderCell: (params) => <Tooltip title={params.row.refundDate ? "Rimborsato" : "Da rimborsare"}>
            <Grid container direction={'row'} alignItems={'center'} alingContent={'center'}>
                <Grid item xs={4}>
                    <RenderBoolean
                        params={params}
                        overrideValue={!!params.row.refundDate}
                    />
                </Grid>
                <Grid item xs={8}>
                    <Typography
                        variant={'caption'}>{params.row.refundDate ? t('refunded') : t('toRefunded')}</Typography>
                </Grid>

            </Grid>
        </Tooltip>,
    });
    columns.push({
        headerName: t('code'),
        field: 'code',
        width: responsiveWidth({viewWidth: isArticleHistory ? 28 : 10, minWidth: 100}),
    });
    columns.push({
        headerName: t('redemptionDate'),
        field: 'redemptionDate',
        width: responsiveWidth({viewWidth: isArticleHistory ? 30 : 25, minWidth: 150}),
        ...timestampFormatterWithTimezone
    })
    if (!isArticleHistory) columns.push(
        {
            headerName: t('refundDate'),
            field: 'refundDate_date',
            width: responsiveWidth({viewWidth: 25, minWidth: 150}),
            ...timestampFormatterWithTimezone
        }
    )

    if (!isArticleHistory) columns.push(
        {
            headerName: t('title'),
            field: 'title',
            width: responsiveWidth({viewWidth: 15, minWidth: 100}),
        }
    )
    if (!isArticleHistory) columns.push(
        {
            headerName: t('value'),
            field: 'value',
            renderCell: params => params.value + t('currency'),
            width: responsiveWidth({viewWidth: 10, minWidth: 100}),
        }
    )
    if (!isArticleHistory) {
        columns.push(
            {
                headerName: t('shopId'),
                //hide: true,
                field: 'shopId',
                width: 220
            }, {
                headerName: t('shop'),
                field: 'shopName',
                //hide: true,
                width: 220
            }
        )
    }
    if (isArticleHistory) {
        columns.push(
            {
                headerName: 'Username',
                field: 'username',
                width: responsiveWidth({viewWidth: 35, minWidth: 100}),
            },
            // {
            //     headerName: 'Nome',
            //     field: 'firstName',
            //     width: responsiveWidth({viewWidth: 20, minWidth: 100})
            // },
            // {
            //     headerName: 'Cognome',
            //     field: 'lastName',
            //     width: responsiveWidth({viewWidth: 20, minWidth: 100})
            // }
        );
    }

    return (
        <NTMXGrid
            key={"vendor-coupon-table"}
            columns={columns}
            rows={coupons || []}
            title={title}
            getRowId={(row) => coupons && row.code}
        />
    );
}
