import BaseModal from "./BaseModal";
import React,{useEffect,useState} from "react";
import Grid from "@mui/material/Grid";
import TextInput,{TextInputContainer} from "../forms/TextInput";
import {Alert,MenuItem} from "@mui/material";
import FileInput from "../forms/FileInput";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import RadioAwardType from "../forms/RadioAwardType";
import {SettingsTooltip} from "../forms/CustomTooltip";

export default function CustomAwardModal({open, onClose, onSubmit, defaultValues = {}, singleWin, organizations, warningMessage}) {

    let {id} = useParams();
    const {t} = useTranslation();
    let [values, setValues] = useState({positionType: 0,type: 0});
    let [error, setError] = useState({});

    useEffect(() => {
        let newValues = {...defaultValues};
        newValues["positionType"] = 0;
        if(newValues.type === null || newValues.type === undefined) newValues["type"] = 0;
        if(newValues.type === "MONEY") newValues.type = 0;
        if(newValues.type === "POINTS") newValues.type = 1;
        if(organizations.length === 1) newValues.organization = organizations[0].id;

        setValues(newValues);
    }, [open])

    function isInt(value) {
        return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
    }

    const close = () => {
        setValues({positionType: 0,type: 0})
        setError({})
        onClose();
    }

    const save = () => {
        if(!values.name) {
            setError({name: t('mandatoryField')});
            return;
        }
        if(values.value && values.value < 0){
            setError({value: t('invalidField')});
            return;
        }
        Object.keys(values).forEach(k => {
            if(isInt(values[k])) {
                return values[k] = parseFloat(values[k]);
            }})
        onSubmit(values);
        close()
    }

    const onTextChange = (value, name) => {
        let newValues = {...values};
        newValues[name] = value;
        setError({});
        setValues(newValues);
    }

    return (
        <BaseModal open={open} onClose={close} onSave={save}>
            {warningMessage &&
                <Grid item xs={12} style={{marginBottom : "0.5rem", padding: "16px"}}>
                    <Alert variant="outlined" severity="warning">{warningMessage}</Alert>
                </Grid>
            }
            <Grid container spacing={4} style={{margin: 0, width: "100%", marginBottom: "1rem"}}>

                    <TextInputContainer xs={12} label={t('name')} value={values.name}
                               type="string" required
                               onTextChange={(value) => {onTextChange(value, "name")}}
                               error={error.name}/>

                <Grid item xs={12} style={{padding: "16px"}}>
                    <RadioAwardType value={values.type} onChange={(value) => {onTextChange(value, "type")}} label={t('valueType')}/>
                </Grid>
                {
                    (values.type === 0 || values.type === 1 || values.type === 3 || values.type === 5) &&

                        <TextInputContainer xs={12} required label={values.type === 1 ? t('pointsValue') : t('euroValue')} value={values.value}
                                   type="number"
                                   min={1} step={1}
                                   onTextChange={(value) => {onTextChange(value, "value")}}
                                   error={error.value}/>

                }

                <TextInputContainer xs={12} label={t('description')} value={values.description}
                           type="string" multiline
                           onTextChange={(value) => {onTextChange(value, "description")}}
                           error={error.description}/>

                {!singleWin &&
                    <Grid item xs={12} style={{ padding: "16px" }}>
                        <SettingsTooltip title={t('valueNullMeaning')}>
                            <div>
                                <TextInput label={t('winningsAllowed')} value={values.winningsAllowed}
                                       type="number"
                                       min={1} step={1}
                                       onTextChange={(value) => onTextChange(value,"winningsAllowed")}
                                       error={error.winningsAllowed}
                                />
                            </div>
                        </SettingsTooltip>
                    </Grid>
                }
                {
                    organizations && organizations.length > 0 &&
                    <TextInputContainer xs={12} select value={values.organization}
                                        label={t('selectOrganization')}
                                        disabled={organizations.length === 1}
                                        onTextChange={(value) => onTextChange(value, "organization")}>
                        {organizations.map(o => <MenuItem value={o.id}>{o.title}</MenuItem>)}
                    </TextInputContainer>
                }
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={11}>
                        <FileInput folder={"custom/awards"} prefix={id} onRequestSave={(e) => setValues({ ...values, "imageUrl": e })} label={t('dragImage')}/>
                    </Grid>
                </Grid>
            </Grid>
        </BaseModal>
    );
}
