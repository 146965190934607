import BaseModal from "./BaseModal";
import TextInput from "../forms/TextInput";
import React,{useEffect,useState} from "react";
import {FormControlLabel,Switch,MenuItem,Grid} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {useGetVerificationRequiredStrings} from "../tables/SessionTable";

const useStyles = makeStyles(theme => ({
    switchLabel: {
        color: theme.palette.primary.contrastText,
        paddingLeft: theme.spacing(2),
        '&.Mui-focused' : {
            color : theme.palette.primary.contrastText,
        }
    },
    switchButton: {
        "&.Mui-checked": {
            color: theme.palette.primary.contrastText
        },
    },
}));

export default function EditVerificationRequiredModal({open, onSubmit, onClose, defaultValue}) {

    let classes = useStyles();
    let [value, setValue] = useState({isSendingMessage: false});
    let [titleError, setTitleError] = useState(null);
    const verificationRequiredStrings = useGetVerificationRequiredStrings();

    useEffect(() => {
        let newValue  = {}
        newValue.verificationRequired = defaultValue
        newValue.isSendingMessage = false
        setValue(newValue)
    }, [open])

    const submit = () => {
        if(value.isSendingMessage && !value.title) {
            setTitleError("Campo obbligatorio");
            return;
        }
        onSubmit(value)
        onClose()
    }

    function onChange(field, newLabel) {
        let newValue  = {...value}
        newValue[field] = newLabel
        setValue(newValue)
        setTitleError(null)
    }

    return <BaseModal open={open} onClose={onClose} onSave={submit}>
        <Grid container spacing={4} style={{margin: 0, width: "100%", marginBottom: "1rem"}}>
            <Grid item xs={12}>
                <TextInput label={"Revisione"} value={value.verificationRequired} onTextChange={v => onChange("verificationRequired", v)} select>
                    {verificationRequiredStrings.map((t, i) => <MenuItem key={i} value={t}>{t}</MenuItem>)}
                </TextInput>
            </Grid>

            <Grid item xs={12}>
                <FormControlLabel
                    className={classes.switchLabel}
                    control={<Switch checked={value.isSendingMessage} name={"Notifica all'utente"}
                                     onChange={event => onChange("isSendingMessage", event.target.checked)}
                                     className={classes.switchButton}/>}
                    label={"Notifica all'utente"}
                />
            </Grid>
            {
                value.isSendingMessage &&
                <>
                    <Grid item xs={12}>
                        <TextInput label={"Titolo Notifica"} value={value.title} onTextChange={v => onChange("title",v)} error={titleError}/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextInput label={"Corpo Notifica"} value={value.body} onTextChange={v => onChange("body", v)} multiline/>
                    </Grid>
                </>
            }
            <div style={{width: "15rem"}}/>
        </Grid>
    </BaseModal>
}