import { Grid, Pagination } from "@mui/material";
import { useState, useEffect } from "react";

export default function ListPagination({
    paginationSize = 12,
    elements,
    renderElement,
    gridContainerSx = {},
    paginationContainerSx = {},
    paginationSx = { width: '100%' } }) {

    const [pagination, setPagination] = useState(0);

    return (

        <Grid container sx={gridContainerSx}>
            {
                elements
                    .sort((a, b) => b.primary ? 1 : b.id - a.id)
                    .slice((pagination * paginationSize), ((pagination + 1) * paginationSize))
                    .map(renderElement)
            }
            <Grid item xs={12} marginTop={2} sx={paginationContainerSx}>
                <Pagination sx={paginationSx} page={pagination + 1} onChange={(event, value) => setPagination(value - 1)} count={Math.ceil(elements.length / paginationSize)} color="primary" />
            </Grid>
        </Grid>
    )
}