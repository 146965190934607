import Grid from "@mui/material/Grid";
import makeStyles from '@mui/styles/makeStyles';
import React,{useEffect} from "react";
import BaseModal from "./BaseModal";
import {useGetCities} from "../../services/ContentManager";
import {useTranslation} from "react-i18next";
import NTMSelect from "../NTMSelect";

const useStyles = makeStyles((theme) => ({
    grid: {
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(23)
    }
}));

export default function SelectCitiesModal({open, onClose, onSubmit}){

    const {t} = useTranslation();
    let cities = useGetCities();
    let classes = useStyles();
    const [selectedCity, setSelectedCity] = React.useState("");
    const [formattedCities, setFormattedCities] = React.useState([]);

    useEffect(() => {
        let fCities = []
        cities.map(c => fCities.push({value : c.istatId, text: c.city}))
        setFormattedCities(fCities)
    }, [cities])

    const close = () => {
        setSelectedCity("")
        onClose()
    }

    const save = () => {
        setSelectedCity("")
        onSubmit(selectedCity)
    }

    return (
        <BaseModal open={open} onClose={close} onSave={save}>

            <Grid container justifyContent={"center"} className={classes.grid}>

                    <NTMSelect label={t('selectCity')} value={selectedCity} maxItemsSize={3}
                               required items={formattedCities} color={"light"} alwaysOpen
                               onChange={city => setSelectedCity(city)}/>

            </Grid>
            <div style={{width: "23rem"}}/>
        </BaseModal>
    );
}