import React, {useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import CouponPreview from "./CouponPreview";
import CouponArticlePreview from "./CouponArticlePreview";
import ShopSelect from "./ShopsSelect";
import {useGetErrorMessages} from "../../constants/errorMessages";
import {Button, Grid} from "@mui/material";
import useGlobalStyles from "../../services/useGlobalStyles";
import {t} from "i18next";
import {AWARD_TYPE as awardType} from "../../constants/awardType";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import TextInput from "../forms/TextInput";
import {filterShops} from "../../services/couponRedeemManager";

/**
 * Mostra una modale con i dettagli del coupon, e una lista di Shop che possono fare la redeem.
 * @param coupon coupon da mostrare
 * @param shops lista completa di Shop
 * @param open
 * @param onClose
 * @param onSave {function(shopId)}
 * @returns {JSX.Element}
 * @constructor
 */
export default function CouponValidation({coupon, shops, onClose, onSave}) {
    const errorMessages = useGetErrorMessages();
    const [shop, setShop] = useState(null);
    const [error, setError] = useState('');
    //mostro solo gli Shop che possono fare la redeem del coupon
    let filteredShops = [];
    if (coupon.awardType === awardType.CITYHALL)//tutti gli uffici possono riscattare un articolo
        filteredShops = shops;
    else
    filteredShops = filterShops(shops, coupon);
    let selectedShopId = shop ? shop.id : filteredShops.length === 1 ? filteredShops[0].id : null;//se c'è un solo Shop selezionalo di default
    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation();

    function save() {
        setError('');
        if (!selectedShopId) {
            setError(errorMessages.MANDATORY);
            return;
        }
        setShop(null);//pulisco lo stato
        onSave(selectedShopId);
    }

    function close() {
        setError('');
        setShop(null);//pulisco lo stato
        onClose();
    }

    return (
        <Grid container direction='row' alignContent={'center'} alignItems={'center'}>
            <Grid item xs={12}>
                <Grid container direction={'row'} alignContent={'center'} alignItems={'center'}>
                    <Grid xs/>
                    {
                        filteredShops.length === 1 ?
                            <TextInput
                                value={filteredShops[0].name}
                                readOnly
                                color={'primary'}
                            />
                            :
                            <ShopSelect coupon={coupon} shops={filteredShops}
                                        onChange={(shopId) => setShop(shops.find(s => s.id === shopId))}
                                        shopId={selectedShopId} error={error}/>
                    }
                    <Grid xs/>
                </Grid>
            </Grid>
            {
                coupon.articleId ?
                    <Grid item xs={12}>
                        <CouponArticlePreview coupon={coupon}/>
                    </Grid>
                    :
                    <Grid item xs={12}>
                        <CouponPreview coupon={coupon}/>
                    </Grid>
            }
            <Grid item xs={12}>
                <Grid container direction={"row"} spacing={2}>
                    <Grid item xs={6}>
                        <Grid container direction={"row-reverse"}>
                            <Button
                                color={'primary'}
                                variant="contained"
                                onClick={save}
                            >
                                {t('confirm')}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container>
                            <Button
                                color={"secondary"}
                                variant="contained"
                                onClick={close}
                            >
                                {t('abort')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>)
}