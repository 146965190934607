import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import Avatar from '@mui/material/Avatar';
import { TextInputContainer } from '../forms/TextInput';
import BaseModal from './BaseModal';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    previewTitle: {
        color: theme.palette.primary.light,
        paddingBottom: "0.5rem",
        paddingTop: "2rem",
    },
    preview: {
        color: theme.palette.primary.light,
        "white-space": "pre-line",
        maxHeight: "15vh",
        overflow: "auto",
        "&::-webkit-scrollbar": {
            width: 5,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "10px"
        },
    },
    select: {
        color: theme.palette.primary.main,
        width: '100%',
        '& label': {
            color: theme.palette.secondary.main,
            fontStyle: "italic",
            '&.MuiInputLabel-shrink': {
                fontStyle: "normal",
            },
            '&.Mui-focused': {
                color: theme.palette.secondary.main,
                borderWidth: "3px"
            },
            '&:hover': {
                color: theme.palette.secondary.main,
            },
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
            '& fieldset': {
                borderColor: theme.palette.secondary.main,
                borderWidth: "1px"
            },
            '&:hover fieldset': {
                borderColor: theme.palette.secondary.main,
                borderWidth: "1px"
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.secondary.main,
                borderWidth: "3px"
            },
        },
    },
    selectDisplayProps: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    }
}));

export default function SendMessageModal({ open, onClose, onSave, targets, title = "", body = "", organizations }) {
    const { t } = useTranslation();
    let [values, setValues] = useState({ title, body });
    let [selectedImageUrl, setSelectedImageUrl] = useState(organizations[0]?.notificationLogo);
    let [error, setError] = useState({});
    let classes = useStyles();

    useEffect(() => {
        setValues({ title, body });
        setError({});
    }, [open, title, body]);

    const save = () => {
        if (!values.title) {
            setError({ title: t('mandatoryField') });
            return;
        }
        onSave({ ...values, imageUrl: selectedImageUrl });
    };

    const onTextChange = (value, name) => {
        let newValues = { ...values };
        setError({});
        newValues[name] = value;
        setValues(newValues);
    };

    const handleOrgChange = (event) => {
        setSelectedImageUrl(event.target.value);
    };

    return (
        <BaseModal open={open} onClose={onClose} onSave={save}>
            <Grid container spacing={4} style={{ margin: 0, width: "100%", marginBottom: "1.5rem" }}>
                <TextInputContainer
                    xs={12}
                    label={t('title')}
                    value={values.title}
                    type="string"
                    error={error.title}
                    onTextChange={(value) => onTextChange(value, "title")}
                />
                <TextInputContainer
                    xs={12}
                    label={t('body')}
                    value={values.body}
                    type="string"
                    multiline={true}
                    onTextChange={(value) => onTextChange(value, "body")}
                />
                {organizations && organizations.length > 1 &&
                    <Grid item xs={12} style={{ padding: "16px" }}>
                        <FormControl className={classes.select}>
                            <InputLabel id="org-select-label">{t('initiativeLogo')}</InputLabel>
                            <Select
                                labelId="org-select-label"
                                id="org-select"
                                value={selectedImageUrl}
                                onChange={handleOrgChange}
                                label={t('selectLogo')}
                                renderValue={(selected) => {
                                    const selectedOrg = organizations.find(org => org.notificationLogo === selected);
                                    return (
                                        <div className={classes.selectDisplayProps}>
                                            <Avatar src={selected} alt={selectedOrg.title} />
                                            {selectedOrg.title}
                                        </div>
                                    );
                                }}
                            >
                                <MenuItem key={"noid"} value={null}>
                                    PinBike
                                </MenuItem>
                                {organizations.map((org) => (
                                    <MenuItem key={org.id} value={org.notificationLogo}>
                                        <ListItemIcon>
                                            <Avatar src={org.notificationLogo} alt={org.title} />
                                        </ListItemIcon>
                                        {org.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                }
                {targets && (
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={8} className={classes.previewTitle}>
                            {t('alertSendNotification', { count: targets.length })}
                        </Grid>
                        <Grid container xs={8} className={classes.preview}>
                            {targets.map((t, i) => (
                                <div key={i}>
                                    {i + 1}. {t.username}, {t.email}
                                </div>
                            ))}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </BaseModal>
    );
}
