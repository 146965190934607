import {useTranslation} from "react-i18next";
import {useHistory,useParams} from "react-router-dom";
import {useGetOrganizationAwardPositions,useGetOrganizationSettings} from "../../services/ContentManager";
import NTMXGrid,{timestampFormatterWithTimezone} from "../../components/NTMXGrid";
import RenderCellAvatar from "../../components/cellRender/RenderCellAvatar";
import React,{useEffect,useState} from "react";
import {useAwardTypeNames} from "../../constants/awardType";
import RenderCell from "../../components/cellRender/RenderCell";
import StartIconButton from "../../components/buttons/StartIconButton";
import {Grid,IconButton} from "@mui/material";
import AwardPositionManager from "./AwardPosition/AwardPositionManager";
import AddIcon from "@mui/icons-material/Add";
import RenderCoordinates from "../../components/cellRender/RenderCoordinates";
import ConfirmIconButton from "../../components/buttons/ConfirmIconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {AWARD_POSITIONS,AWARDS,deleteElem,getErrorMessage,ORGANIZATIONS,POSITIONS} from "../../services/Client";
import {useSnackbar} from "notistack";
import {useQueryClient} from "react-query";
import EditIcon from "@mui/icons-material/Edit";
import dayjs from "dayjs";

export default function AwardPositions() {

    const {t} = useTranslation();
    const {id, filter, actionId} = useParams();
    let {awardPositions, status} = useGetOrganizationAwardPositions(id)
    let history = useHistory();
    const typeLabels = useAwardTypeNames()
    const {enqueueSnackbar} = useSnackbar();
    let queryClient = useQueryClient();

    let [timezone, setTimezone] = useState();
    let {settings = []} = useGetOrganizationSettings(id);
    useEffect(() => {
        let setting = settings.find(o => o.organizationSetting === "timezone")
        if(setting) setTimezone(setting.value || setting.defaultValue)
    },[settings])

    if(actionId) return <AwardPositionManager award={awardPositions.find(a => a.id == actionId)} timezone={timezone}/>

    const defaultColumns = [
        {
            headerName: t('logo'),
            field: 'imageUrl',
            width: 130,
            renderCell: (params) => <RenderCellAvatar params={params}/>
        },
        {
            headerName : t('name'),
            field : 'name',
            width : 200,
        },
        {
            headerName : t('description'),
            field : 'description',
            width : 200,
        },
        {
            headerName: t('startDate'),
            field: 'startDate_date',
            width: 280,
            //hide: true,
            ...timestampFormatterWithTimezone
        },
        {
            headerName: t('endDate'),
            field: 'endDate_date',
            width: 280,
            //hide: true,
            ...timestampFormatterWithTimezone
        },
        {
            headerName: t('timeSlotStart'),
            field: 'startTime',
            width: 200,
            valueGetter: ({value}) => {
                if(!value && value !== 0) return ""
                let temp = dayjs.tz("00:00", "HH:mm", "utc").add(dayjs.duration(value * 1000))
                return `${temp.tz(timezone).format("HH:mm")} (${timezone})`
            }
        },
        {
            headerName: t('timeSlotEnd'),
            field: 'endTime',
            width: 200,
            valueGetter: ({value}) => {
                if(!value && value !== 0) return ""
                let temp = dayjs.tz("00:00", "HH:mm", "utc").add(dayjs.duration(value * 1000))
                return `${temp.tz(timezone).format("HH:mm")}  (${timezone})`
            }
        },
        {
            headerName: t('value'),
            field: 'value',
            width: 200
        },
        {
            headerName: t('type'),
            field: 'type',
            width: 200,
            valueGetter: (params) => params.value || params.value === 0 ? typeLabels[params.value] : ""
        },
        {
            headerName: t('address'),
            field: 'address',
            width: 220,
        },
        {
            headerName: t('houseNumber'),
            field: 'number',
            width: 220,
        },
        {
            headerName: t('city'),
            field: 'cityName',
            width: 220,
        },
        {
            headerName: t('coordinates'),
            field: 'coordinates',
            width: 300,
            valueGetter: (params) =>  (params.row.latitude && params.row.longitude) ? `${params.row.latitude || ''} - ${params.row.longitude || ''}` : "",
            renderCell: (params) => <RenderCoordinates lat={params.row.latitude} lng={params.row.longitude} radius={params.row.radius * 1000}/>
        },
        {
            headerName: t('tolerance'),
            field: 'radius',
            width: 220,
            valueGetter: ({value}) => value ? value * 1000 : "",
            renderCell: (params) => {
                return <RenderCell params={params} measureUnit="m"/>
            }
        },
        {
            headerName: t('sessionMinDistance'),
            field: 'sessionMinDistance',
            width: 220,
            valueGetter: ({value}) => value ? value * 1000 : "",
            renderCell: (params) => {
                return <RenderCell params={params} measureUnit="m"/>
            }
        },
        {
            headerName: t('expirationDays'),
            field: 'expirationDays',
            width: 220,
        },
        {
            headerName: t('winningsAllowed'),
            field: 'winningsAllowed',
            width: 300,
            renderCell: (params) => params.value || "∞"
        },
        {
            headerName: ' ',
            field: 'editDelete',
            width: 108,
            sortable: false,
            disableColumnMenu: true,
            disableExport: true,
            hideable: false,
            renderCell: (params => {
                if(params.row.endDate < new Date()) return;
                return <Grid container direction={"row"}>
                    <ConfirmIconButton
                        onConfirm={() => deleteAwardPosition(params.row['id'])}
                        title={t('confirmDeletion')}
                        text={t('requestDefinitiveEliminationAwardPosition') + " \"" + params.row['name']+"\"?"}
                        warningMessage={params.row.startDate < new Date() && t('warningMessageAwardPositionDelete')}
                    >
                        <DeleteIcon/>
                    </ConfirmIconButton>
                    <IconButton onClick={() => history.push(`/dashboard/${id}/${filter}/${params.row.id}`)} aria-label="edit"><EditIcon/></IconButton>
                </Grid>
            }),
        }
    ]

    const deleteAwardPosition = (awardId) => {
        enqueueSnackbar(t('saving...'), {variant: "info"});
        deleteElem(`${AWARDS}/${POSITIONS}/${awardId}`)
            .then(() => enqueueSnackbar(t('removed'), {variant: "success"}))
            .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
            .finally(() => queryClient.invalidateQueries([ORGANIZATIONS, {id: id}, AWARD_POSITIONS]));
    }

    awardPositions = awardPositions.slice().sort((a , b) => b.endDate - a.endDate).map(ap => ({ ...ap, timezone}));
    return <div>
        <NTMXGrid
            key={"organization-award-positions"}
            rows={awardPositions || []}
            columns={defaultColumns}
            loading={status === "loading"}
            title={t('awardPositions')}
            rightButton={<Grid container justifyContent={"flex-end"}>
                            <StartIconButton onClick={() =>  history.push(`/dashboard/${id}/${filter}/create`)}
                                             title={t('addAward')}
                                             startIcon={<AddIcon/>}/>
                        </Grid>}
            initialState={{pinnedColumns: {right: ['editDelete']}}}
            />
    </div>
}
