import React from "react";
import {get, OFFLINE_SESSIONS, } from "../services/Client";
import {Paper} from "@mui/material";
import {useQuery} from "react-query";
import NTMXGrid,{numberFormatter,timestampFormatter} from "../components/NTMXGrid";
import {useTranslation} from "react-i18next";

export function OfflineSessionPage(){

	const {t} = useTranslation();
	const {status, data, error} = useQuery(OFFLINE_SESSIONS, () => get(OFFLINE_SESSIONS));

	function getValue(params, field){
		if(!params.row[field]) return ""
		return params.row[field]
	}

	const defaultColumns = [
		{
			headerName: t('sessionID'),
			field: 'id',
			width: 310,
			//hide:true
		},
		{
			headerName: t('userID'),
			field: 'user',
			width: 280,
			//hide: true
		},
		{
			headerName: t('email'),
			field: 'email',
			width: 220,
			//hide: true
		},
		{
			headerName: t('username'),
			field: 'username',
			width: 170
		},
		{
			headerName: t('sensor'),
			field: 'sensor',
			width: 170,
			//hide:true
		},
		{
			headerName: t('startDate'),
			field: 'startTime_Date',
			width: 180,
			valueGetter: params => getValue(params, "startTime"),
			...timestampFormatter
		},
		{
			headerName: t('startTime'),
			field: 'startTime_time',
			width: 180,
			...timestampFormatter
		},
		{
			headerName: t('endDate'),
			field: 'endTime:Date',
			width: 180,
			valueGetter: params => getValue(params, "endTime"),
			...timestampFormatter
		},
		{
			headerName: t('endTime'),
			field: 'endTime_time',
			width: 280,
			...timestampFormatter
		},
		{
			headerName: t('startRevolutions'),
			field: 'startRevs',
			width: 180,
			...numberFormatter
			//hide: true
		},
		{
			headerName: t('endRevolutions'),
			field: 'endRevs',
			width: 280,
			...numberFormatter
			//hide: true
		},
		{
			headerName: t('distance'),
			field: 'distance',
			width: 280,
			...numberFormatter
		}
	];

	return <Paper style={{padding: "2rem"}}>
		<NTMXGrid
			key={"offline-sessions"}
			columns={defaultColumns}
			rows={data || []}
			title={t('offlineSessions')}
			loading={status === "loading"}
			getRowId={(row) => data && row.id}
		/>
	</Paper>
}
