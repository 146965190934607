import {MenuItem, Select} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {languages} from "../constants/lenguages";

export default function LanguageSelect({value, onChange, color='white'}) {
    const {t} = useTranslation();
    return (
        <Select
            sx={{color}}
            value={(value || '').substring(0,2)}
            onChange={onChange}
        >
            {
                languages.map(l => <MenuItem key={"ls-" + l.alternativeCode} value={l.alternativeCode}>{l.label}</MenuItem>)
            }
        </Select>
    );
}