import React from "react";

import NTMXGrid from "../../components/NTMXGrid";
import {useGetVendors} from "../../services/ContentManager";
import {useSnackbar} from "notistack";
import {useQueryClient} from "react-query";
import RenderBoolean from "../../components/cellRender/RenderBoolean";
import {getErrorMessage, put, VENDORS} from "../../services/Client";
import {Link} from "react-router-dom";
import {IconButton, Paper, Tooltip} from "@mui/material";
import {Visibility} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

export default function VendorManagement() {

    const {t} = useTranslation();
    let queryClient = useQueryClient();
    const {enqueueSnackbar} = useSnackbar()
    let {vendors, status} = useGetVendors()

    const saveEditVendor = (id, field, value, row) => {
        enqueueSnackbar(t('saving...'), {variant: "info"});
        put(VENDORS, {elem: row.uid, body: {[field]: value},})
            .then(() => {
                enqueueSnackbar(t('saved'), {variant: "success"});
            })
            .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
            .finally(() => queryClient.invalidateQueries([VENDORS]));
    };

    const columns = [
        {
            headerName: t('vendorID'),
            field: 'uid',
            width: 220,
            //hide: true,
        }, {
            headerName: ' ',
            field: 'visible',
            width: 80,
            renderCell: (params) => <Tooltip title={params.row.visible ? t('visible') : t('hidden')}>
                <div><RenderBoolean params={params} saveEdit={saveEditVendor}/></div>
            </Tooltip>
        }, {
            headerName: t('businessName'),
            field: 'businessName',
            minWidth: 220,
        }, {
            headerName: t('address'),
            field: 'address',
            minWidth: 170,
        },
        {
            headerName: t('postalCode'),
            field: 'postalCode',
            minWidth: 170,
        }, {
            headerName: t('city'),
            field: 'city',
            minWidth: 170,
        }, {
            headerName: t('province'),
            field: 'province',
            minWidth: 170,
        }, {
            headerName: t('country'),
            field: 'country',
            minWidth: 170,
        }, {
            headerName: t('vatNumber'),
            field: 'vatNumber',
            minWidth: 150,
        }, {
            headerName: t('email'),
            field: 'email',
            minWidth: 170,
        }, {
            headerName: t('firstName'),
            field: 'firstName',
            minWidth: 170,
        }, {
            headerName: t('lastName'),
            field: 'lastName',
            minWidth: 170,
        }, {
            headerName: t('phoneNumber'),
            field: 'phone',
            minWidth: 150,
        }, {
            headerName: t('releasedCoupon'),
            field: 'totalValueReleasedCoupon',
            minWidth: 170,
        }, {
            headerName: t('assignedCoupon'),
            field: 'numberAssignedCoupon',
            minWidth: 170,
        }, {
            headerName: t('details'),
            field: 'id',
            minWidth: 130,
            renderCell: (params) => <Link
                to={"/vendors/" + params.row.uid}><IconButton><Visibility/></IconButton></Link>
        },
    ];


    return <Paper style={{padding: "2rem"}}>
        <NTMXGrid
            key={"vendors-list-table"}
            columns={columns}
            rows={[...vendors].sort((a, b) => b.id - a.id) || []}
            title={t('vendors')}
            getRowId={(row) => vendors && row.uid}
            loading={status === "loading"}
        />
    </Paper>

}
