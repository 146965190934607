import styled from "@emotion/styled";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Button, CssBaseline, Divider, FormControl, Tab, Tabs, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { MenuButton } from "../../components/buttons/MenuButton";
import StartIconButton from "../../components/buttons/StartIconButton";
import InfoGrid from "../../components/InfoGrid";
import LanguageSelect from "../../components/LanguageSelect";
import ResponsiveCircularLoading from "../../components/ResponsiveCircularLoading";
import logoEU from "../../images/EU-nl.png";
import logoEIT from "../../images/logo-EIT.svg";
import logoERDF from "../../images/logo_erdf.png";
import logoUIA from "../../images/UIA_logo.png";
import logoVOXPOP from "../../images/VOXPOP_LOGO.png";
import logo from "../../images/pinbike.gif";
import Statistics from "../Statistics";
import OpenDataMap from "./OpenDataMap";
import OpenDataShops from "./OpenDataShops";
import OpenDataAchievements from "./OpenDataAchievements";
import OpenDataRanks from "./OpenDataRanks";
import { useGetPublicOrganization, useGetPublicOrganizations, useGetPublicOrganizationSettings } from "services/ContentOpenManager";
import logoPinBike from "../../images/splash.png";
import { Alert, CircularProgress, MenuItem, Select } from "../../../node_modules/@mui/material/index";
import PropTypes from "prop-types";

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      style={{ height: 'calc(100% - 48px)' }}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, height: '100%' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function NotAffectedByIntervalAlert({textToShow}){
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
      <Alert
        variant='outlined'
        severity='info'
        sx={(theme) => ({
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          fontSize: '1rem',
          margin: '0 auto',
          maxWidth: 600,
          width: 'auto',
          borderColor: theme.palette.error.main,
          color: theme.palette.error.main, 
          bgcolor: 'background.paper',
          '& .MuiAlert-icon': {
            color: theme.palette.error.main
          },   
          [theme.breakpoints.between('sm', 'md')]: {
            maxWidth: '40%',
          },
          [theme.breakpoints.down('sm')]: {
            maxWidth: '80%',
          },
        })}
      >
        {textToShow}
      </Alert>
    </Box>
  );
};

NotAffectedByIntervalAlert.propTypes = {
  textToShow: PropTypes.string
}

const StyledTab = styled((props) => <Tab {...props} />)(
  ({ theme }) => ({
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    // color: theme.palette.secondary.light,
    // '&.Mui-selected': {
    //     color: '#fff',
    // },
  }),
);

const useStyles = makeStyles(theme => ({
  page: {
    padding: theme.spacing(2)
  },
  filterBar: {
    padding: theme.spacing(4),
    paddingBottom: 0
  },
  title: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: 40
  },
  notificationLogo: {
    width: "5vw",
    color: theme.palette.primary.main,
    fontSize: 60,
  },
  initiativeLogo: {
    maxWidth: "80vw",
    maxHeight: "17vh",
  },
}));


export default function OpenDataPage() {
  let { id } = useParams();
  let isAll = id == "all" ? true : false;
  const { t, i18n } = useTranslation();
  const [value, setValue] = useState(isAll ? 'statistics' : 'map');
  const theme = useTheme();
  let classes = useStyles();
  let { status: organizationsStatus, organizations } = useGetPublicOrganizations();
  let { status, organization } = useGetPublicOrganization(id, isAll);
  const { settings = [], status: settingsStatus } = useGetPublicOrganizationSettings(id, {
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: dayjs.duration(1, 'day').asMilliseconds(),
  });
  const [selectedPeriod, setSelectedPeriod] = useState({});
  const [isShowMap, setIsShowMap] = useState(true);

  const lastMonth = () => {
    const today = new Date();
    const firstDayOfThisMonth = new Date(
      today.getFullYear(),
      today.getMonth(),
      1
    );
    const lastDayOfPreviousMonth = new Date(firstDayOfThisMonth - 1);
    return lastDayOfPreviousMonth.toISOString().substring(0, 10);
  };

  let interval = {
    start: (
      settings.find(
        (s) => s.organizationSetting === "startDateUrbanPoints"
      ) || {
        defaultValue: "2019-01-01",
        organizationSetting: "startDateUrbanPoints",
        value: "2019-01-01",
      }
    ).value,
    end: (
      settings.find((s) => s.organizationSetting === "endDateUrbanPoints") || {
        defaultValue: lastMonth(),
        organizationSetting: "endDateUrbanPoints",
        value: lastMonth(),
      }
    ).value,
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getMonthsFromDateInterval = (interval) => {
    if (!interval) return [];
  
    const months = [];
    const start = dayjs(interval.start);
    const end = dayjs(interval.end);
    const today = dayjs().startOf('day');
    let current = start.clone();
  
    // Itera sui mesi all'interno dell'intervallo
    while (current.isBefore(end)) {
      let endOfMonth = current.endOf('month');
  
      // Se la fine del mese supera la data di fine dell'intervallo, la limita
      if (endOfMonth.isAfter(end)) {
        endOfMonth = end.clone();
      }
  
      // Esclude il mese attuale e futuri
      if (!endOfMonth.isBefore(today)) {
        break;
      }
  
      // Aggiunge il mese alla lista con le informazioni necessarie
      months.push({
        text: current.format('MMMM YYYY'),
        value: {
          start: current.format('YYYY-MM-DD'),
          end: endOfMonth.format('YYYY-MM-DD'),
          label: current.format('MMMM YYYY'),
        }
      });
  
      // Passa al mese successivo
      current = current.add(1, 'month').startOf('month');
    }
  
    // Aggiunge l'opzione per selezionare l'intero periodo
    months.push({
      text: t('allIniziativePeriod'),
      value: {
        start: start.format('YYYY-MM-DD'),
          end: id === 31 || id === 32 ? '2023-12-31' : end.format('YYYY-MM-DD'),
        label: t('allIniziativePeriod'),
      }
    });
  
    return months;
  };

  const changePeriod = (sp) => {
    setSelectedPeriod(sp);

    if (isAll) {
      const startDate = new Date(sp.start);
      const endDate = new Date(sp.end);
      const timeDiff = endDate - startDate;
      const daysDiff = timeDiff / (1000 * 60 * 60 * 24);
      if (daysDiff > 31) {
        setIsShowMap(false);
      } else {
        setIsShowMap(true);
      }
    }
  }

  useEffect(() => {
    let sp;
    if (settingsStatus === 'success') {
      sp = getMonthsFromDateInterval(interval)[0].value;
    } else {
      //  set default date to all section
      sp = isAll ? {
        start: "2019-01-01",
        end: lastMonth(),
        label: t('allIniziativePeriod'),
      } : {
        start: "2023-08-01",
        end: "2023-08-31",
        label: "Aug 2023",
      };
    }
    setSelectedPeriod(sp);

    if (isAll) {
      const startDate = new Date(sp.start);
      const endDate = new Date(sp.end);
      const timeDiff = endDate - startDate;
      const daysDiff = timeDiff / (1000 * 60 * 60 * 24);
      if (daysDiff > 31) {
        setIsShowMap(false);
      } else {
        setIsShowMap(true);
      }
    }

  }, [settingsStatus]);

  if (status === "loading") return <ResponsiveCircularLoading />;

  const isBicification = String((settings.find(s => s.organizationSetting === 'isBicification') || {}).value) === "true";
  const openDataForm = (settings.find(s => s.organizationSetting === 'openDataUserForm') || {}).value;
  return (
    <Grid container sx={{
      display: 'flex',
      minHeight: '100vh',
      maxWidth: '100vw'
    }}>
      <CssBaseline />
      <main style={{
        flexGrow: 1,
        minHeight: '100%',
      }}>
        <Box sx={{
          maxWidth: theme.breakpoints.values.lg,
          marginLeft: 'auto',
          marginRight: 'auto',
          p: 3,
        }}>
          <header>
            <Grid container sx={{ mb: 3 }}>
              <Grid item xs={12} align={"center"}>
                <Typography variant={'h3'} sx={{
                  padding: theme.spacing(1),
                  fontWeight: "bold",
                  color: theme.palette.primary.main,
                  textAlign: "center",
                  textTransform: 'uppercase'
                }}
                >
                  {organization.title || t('initiatives')}
                </Typography>
              </Grid>
              {organization.initiativeLogo ? (
                <Grid item xs={12} align={"center"}>
                  <img className={classes.initiativeLogo} src={organization.initiativeLogo} />
                </Grid>
              ) : (
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  {isAll ? (
                    <img src={logoPinBike} />
                  ) : organization.notificationLogo ?
                    <img className={classes.notificationLogo} src={organization.notificationLogo} />
                    :
                    <AccountBalanceIcon className={classes.notificationLogo} sx={{ fontSize: 60 }} />}
                  <div className={classes.title}>{organization.title}</div>
                </Grid>
              )}
            </Grid>
            {interval.start && (
              <Grid container direction="column" sx={{ mb: 3 }}>
                <Grid item>
                  <Typography variant={'h6'} sx={{ px: 1 }} align="center">
                    {t('iniziativePeriod')}
                  </Typography>
                </Grid>

                <Grid item sx={{
                  fontSize: '1.3em',
                  '& .MuiBox-root': {
                    borderBottom: 'none',
                    m: 0
                  }
                }}>
                  <InfoGrid infos={[
                    {
                      isDate: true,
                      field: 'start',
                      label: t('startDate'),
                      xs: '6',
                      md: 'auto'
                    }, {
                      isDate: true,
                      field: 'end',
                      label: t('endDate'),
                      xs: '6',
                      md: 'auto'
                    },
                  ]} data={interval} />
                </Grid>
              </Grid>
            )}
            <Grid container direction="column" sx={{ mb: 3 }}>
              <Grid item>
                <Typography variant={'h6'} sx={{ px: 1 }} align="center">
                  {t('showedValuesMonth')}
                </Typography>
              </Grid>

              <Grid item textAlign={'center'} sx={{
                fontSize: '1.3em',
                '& .MuiBox-root': {
                  borderBottom: 'none',
                  m: 0
                }
              }}>
                <MenuButton
                  title={selectedPeriod.label}
                  items={getMonthsFromDateInterval(interval)}
                  onChange={v => changePeriod(v)}
                />
              </Grid>
            </Grid>
          </header>
        </Box>

        <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 2 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            centered
          >
            <StyledTab label={t('map')} value="map" />
            <StyledTab label={t('statistics')} value="statistics" />
            {/* <StyledTab label={t('achievements')} value="achievements" /> */}
            <StyledTab label={t('ranks')} value="ranks" />
            <StyledTab label={t('shops')} value="shops" />
          </Tabs>
        </Box>

        <Box sx={{
          marginLeft: 'auto',
          marginRight: 'auto',
          p: 3,
        }}>
          <TabPanel value={value} index={'map'}>

            {isShowMap ? <OpenDataMap interval={selectedPeriod} organization={organization} />
              : <Typography variant={'h6'} sx={{ px: 1 }} align="center">
                {t('heatmapUnavailable')}
              </Typography>}

          </TabPanel>
          <TabPanel value={value} index={'statistics'}>
            <Statistics disableDateInterval overrideInterval={selectedPeriod} startDateUrbanPoints={interval.start} endDateUrbanPoints={interval.end} isOdp={true} />
          </TabPanel>
          {/* <TabPanel value={value} index={'achievements'}>
						<OpenDataAchievements interval={selectedPeriod} organization={id}></OpenDataAchievements>
					</TabPanel> */}
          <TabPanel value={value} index={'ranks'}>
          <NotAffectedByIntervalAlert textToShow={t('notAffectedByTimeIntervalAlert')}/>
            <OpenDataRanks interval={selectedPeriod}></OpenDataRanks>
          </TabPanel>
          <TabPanel value={value} index={'shops'}>
            <OpenDataShops></OpenDataShops>
          </TabPanel>
        </Box>

        <Divider sx={{ py: 1 }} />

        <Grid
          container
          alignItems="center"
          justifyContent="space-around" // Distribuisce equamente gli elementi
          spacing={2} // Spaziatura tra elementi
          sx={{ p: 3 }}
        >
          {/* Sezione "Regulation" */}
          {!isAll && (
            <Grid item xs="auto" textAlign="center">
              <Typography variant="h6">{t('regulation')}</Typography>
              <StartIconButton
                title={t('openPdf')}
                startIcon={<PictureAsPdfIcon />}
                href={organization.regulationLink}
                target="_blank"
              />
            </Grid>
          )}

          {/* Sezione "User Form" */}
          {dayjs(interval.end).diff(dayjs(new Date())) > 0 && (
            <Grid item xs="auto" textAlign="center">
              <Typography variant="h6">{t('userForm')}</Typography>
              <Button
                variant="contained"
                href={openDataForm}
                target="_blank"
                disabled={!openDataForm}
              >
                {t('join')}
              </Button>
            </Grid>
          )}

          {/* Sezione "Iniziative (Select con i link)" */}
          <Grid item xs="auto" textAlign="center">
            <Typography variant="h6">{t('otherInitiatives')}</Typography>
            {organizationsStatus === 'loading' ? (
              <CircularProgress size={24} />
            ) : (
              <FormControl sx={{ minWidth: 200, margin: "16px", textAlign: "left" }}>
                <Select
                  displayEmpty
                  value={id === 'all' ? '' : id}
                  size="small"
                  onChange={(e) => window.open(`/open/${e.target.value}`, '_blank')}
                >
                  <MenuItem disabled value="">
                    {t('selectInitiative')}
                  </MenuItem>
                  {organizations?.map((org) => (
                    <MenuItem key={org.id} value={org.id}>
                      {org.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>

          {/* Sezione "Page Language" */}
          <Grid item xs="auto" textAlign="center">
            <Typography variant="h6">{t('pageLanguage')}</Typography>
            <FormControl sx={{ minWidth: 120, margin: "16px" }} size="small">
              <LanguageSelect
                value={i18n.language}
                onChange={({ target }) => i18n.changeLanguage(target.value)}
                color="black"
              />
            </FormControl>
          </Grid>
        </Grid>



        <footer style={{ marginBottom: '0px' }}>
          <Grid container alignItems={'center'} justifyContent={'space-evenly'}
            sx={{ bgcolor: '#32323a57', p: 2 }}>
            {isBicification &&
              <Grid item md={8} sx={{ py: { md: 2, xs: 1 }, px: { md: 3, xs: 1 } }}>
                <Box sx={{ width: '100%', maxWidth: '50em', ml: 3 }}>
                  <img style={{ width: 'calc(50% - 8px)', marginRight: '16px' }} src={logoEIT}
                    alt="EIT Urban Mobility logo" />
                  <img style={{ width: 'calc(50% - 8px)' }} src={logoEU} alt="Co-funded by EU" />
                </Box>
              </Grid>}
            {id == 30 &&
              <>
                <Grid item md={3} sx={{ py: { md: 2, xs: 1 }, px: { md: 3, xs: 1 } }}>
                  <Box sx={{ ml: 3 }}>
                    <img style={{ width: '100%', maxWidth: '20em', verticalAlign: 'middle' }} src={logoERDF}
                      alt="European Regional Development Fund" />
                  </Box>
                </Grid>
                <Grid item md={3} sx={{ py: { md: 2, xs: 1 }, px: { md: 3, xs: 1 } }}>
                  <Box sx={{ ml: 3 }}>
                    <img style={{ width: '100%', maxWidth: '20em', verticalAlign: 'middle' }} src={logoUIA}
                      alt="Urban Innovative Actions" />
                  </Box>
                </Grid>
                <Grid item md={3} sx={{ py: { md: 2, xs: 1 }, px: { md: 3, xs: 1 } }}>
                  <Box sx={{ ml: 3, mr: 3 }}>
                    <img style={{ width: '100%', maxWidth: '20em', maxHeight: '20em', verticalAlign: 'middle' }} src={logoVOXPOP}
                      alt="VoxPop Lisbon Mobility Goes Digital" />
                  </Box>
                </Grid>
              </>}
            <Grid item md={id == 30 ? 3 : 4} sx={{ py: { md: 2, xs: 1 }, px: { md: 3, xs: 1 } }}
              textAlign={isBicification || id == 30 ? 'right' : 'center'}>
              <Box sx={{ mr: 3 }}>
                <img style={{ width: '100%', maxWidth: '20em', verticalAlign: 'middle' }}
                  src={logo} alt="logo" />
              </Box>
            </Grid>
            {id == 30 &&
              <Grid item md={12} sx={{ px: { md: 3, xs: 1 } }}>
                <Box sx={{ ml: 3, mr: 3 }}>
                  <Typography variant={'caption'}>
                    Project co-financed by the European Regional Development Fund through the Urban Innovative Actions (UIA) initiative.
                  </Typography>
                </Box>
              </Grid>}
          </Grid>
        </footer>
      </main>
    </Grid>
  );
}
