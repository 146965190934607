import { useParams } from "react-router-dom";
import { useGetSurveies } from "../../services/ContentManager";
import NTMXGrid, { timestampFormatter } from "../../components/NTMXGrid";
import React, { useContext, useState } from "react";
import { useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import Link from "@mui/material/Link";
import SurveyModal from "components/modals/SurveyModal";
import { Grid } from "../../../node_modules/@mui/material/index";
import StartIconButton from "components/buttons/StartIconButton";
import { SURVEIES, getErrorMessage, post, put } from "services/Client";
import RenderCell from "components/cellRender/RenderCell";
import { useAwardTypeNames } from "constants/awardType";

export default function Surveies() {

    const { t } = useTranslation();
    let { id } = useParams();
    let { surveies, status } = useGetSurveies(id);
    const { enqueueSnackbar } = useSnackbar();
    const typeLabels = useAwardTypeNames()
    let queryClient = useQueryClient();
    let [isGenerating, setIsGenerating] = useState(false);

    const defaultColumns = [
        {
            headerName: t('id'),
            field: 'id',
            width: 160,
        }, {
            headerName: t('name'),
            field: 'awardName',
            width: 200
        }, {
            headerName: t('description'),
            field: 'awardDescription',
            width: 200
        },  {
            headerName: t('type'),
            field: 'awardType',
            width: 200,
            valueGetter: (params) => params.value || params.value === 0 ? typeLabels[params.value] : ""
        }, {
            headerName: t('value'),
            field: 'awardValue',
            width: 160,
            valueGetter: (params) => params.value + " " + (params.row.awardType === 1 ? (params.row.awardCommunity === 1 ? t('natinalPoints') : t('points')) : t('euro'))
        }, {
            headerName: t('link'),
            field: 'link',
            width: 220,
            renderCell: (params) =>
                <Link href={params.row.link} target="_blank" style={{ whiteSpace: 'pre-line' }}>{params.row.link}</Link>
        }, {
            field: 'startDate',
            headerName: t('startDate'),
            width: 280,
            renderCell: (params) => <RenderCell params={params} required type="date" />,
        }, {
            field: 'endDate',
            headerName: t('endDate'),
            width: 280,
            renderCell: (params) => <RenderCell params={params} required type="date" />,
        },
    ];

    const generateSurvey = (values) => {

        let convertedValues = { ...values };
        convertedValues["startDate"] = Date.parse(values["startDate"])
        convertedValues["endDate"] = Date.parse(values["endDate"])

        enqueueSnackbar(t('saving...'), { variant: "info" });
        post(SURVEIES + "/" + id, { body: convertedValues })
            .then(() => enqueueSnackbar(t('saved'), { variant: "success" }))
            .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: "error" }))
            .finally(() => queryClient.invalidateQueries([SURVEIES, { id: id }]));
    }

    return <div>
        <NTMXGrid
            key={"manager-serveies-tables"}
            columns={defaultColumns}
            rows={surveies || []}
            getRowId={(row) => surveies && row.id}
            title={t('surveies')}
            loading={status === "loading"}
            rightButton={<Grid container justifyContent={"flex-end"}>
                <StartIconButton onClick={() => setIsGenerating(true)} title={t('generateCodes')} startIcon={<AddIcon />} />
            </Grid>}
        />
        <SurveyModal open={!!isGenerating} onClose={() => setIsGenerating(false)} onSubmit={generateSurvey} />
    </div>
}

