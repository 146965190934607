import BaseModal from "./BaseModal";
import {TextInputContainer} from "../forms/TextInput";
import React, {useState} from "react";
import Grid from "@mui/material/Grid";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {firebaseAuth} from "../../firebase";
import {useSnackbar} from "notistack";
import firebase from "firebase";
import {put, USERS} from "../../services/Client";
import {useQueryClient} from "react-query";
import {useTranslation} from "react-i18next";


export default function ChangeCredentialsModal({open, onClose}) {

    const {t} = useTranslation();
    let [newEmail, setNewEmail] = useState("");
    let [currentPassword, setCurrentPassword] = useState("");
    let [newPassword, setNewPassword] = useState("");
    let [repeatPassword, setRepeatPassword] = useState("");

    let [error, setError] = useState({});

    let [showPasswords, setShowPasswords] = useState(false);

    const {enqueueSnackbar} = useSnackbar();
    let queryClient = useQueryClient();

    const save = () => {

        if (currentPassword === "") {
            setError({old: t('mandatoryField')});
            return;
        }
        if (newPassword !== repeatPassword) {
            setError({repeat: t('passwordNotMatch'), new: t('passwordNotMatch')});
            return;
        }

        let user = firebaseAuth.currentUser;
        const credential = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);
        const currentEmail = user.email;
        firebaseAuth.currentUser.reauthenticateWithCredential(credential).then(() => {

            if (newEmail !== currentEmail) {
                firebaseAuth.currentUser.updateEmail(newEmail).then(() => {

                    put(USERS, {body: {email: newEmail}, elem: user.uid})
                        .then(() => enqueueSnackbar(t('emailSaved'), {variant: "success"}))
                        .catch(() => {
                            firebaseAuth.currentUser.updateEmail(currentEmail).then(() => {
                                enqueueSnackbar(t('errorSavingNewEmail'), {variant: "error"});
                            })
                        })
                        .finally(() => queryClient.invalidateQueries([USERS, {user: user.uid}]));

                }).catch(() => enqueueSnackbar(t('errorSavingNewEmail'), {variant: "error"}))

            } else {
                enqueueSnackbar(t('sameCurrentAndNewEmail'), {variant: "info"});
            }

            if (newPassword) {
                firebaseAuth.currentUser.updatePassword(newPassword).then(() => {
                    enqueueSnackbar(t('newPasswordSaved'), {variant: "success"});
                }).catch(() => enqueueSnackbar(t('errorSavingNewPassword'), {variant: "error"}))
            }

        }).catch(() => enqueueSnackbar(t('authenticationError'), {variant: "error"}))

        close();
    }

    const close = () => {
        setNewEmail("");
        setCurrentPassword("");
        setNewPassword("");
        setRepeatPassword("");
        setError({});
        onClose();
    }

    return (
        <BaseModal open={open} onClose={close} onSave={save}
                   iconButton={showPasswords ? <VisibilityOff/> : <Visibility/>}
                   onClickButton={() => setShowPasswords(!showPasswords)}>
            <Grid container spacing={4} style={{margin: 0, width: "100%", marginBottom: "1.5rem"}}>

                    <TextInputContainer xs={6} label={t('newEmail')} value={newEmail}
                               onTextChange={(value) => {
                                   setNewEmail(value.trim());
                                   setError({});
                               }} error={error.email}/>

                    <TextInputContainer xs={6} required label={t('currentPassword')} value={currentPassword}
                               type={showPasswords ? "text" : "password"}
                               onTextChange={(value) => {
                                   setCurrentPassword(value.trim());
                                   setError({});
                               }} error={error.old}/>

                    <TextInputContainer xs={6} label={t('newPassword')} value={newPassword} type={showPasswords ? "text" : "password"}
                               onTextChange={(value) => {
                                   setNewPassword(value.trim());
                                   setError({});
                               }} error={error.new}/>

                    <TextInputContainer xs={6} label={t('repeatPassword')} value={repeatPassword}
                               type={showPasswords ? "text" : "password"}
                               onTextChange={(value) => {
                                   setRepeatPassword(value.trim());
                                   setError({});
                               }} error={error.repeat}/>

            </Grid>
        </BaseModal>
    )

}