import { useTranslation } from "react-i18next";
import { useGetAllCustomFields, useGetAllSettings } from "../../services/ContentManager";
import ResponsiveCircularLoading from "../../components/ResponsiveCircularLoading";
import NTMXGrid from "components/NTMXGrid";
import RenderBoolean from "components/cellRender/RenderBoolean";
import { Paper } from "../../../node_modules/@mui/material/index";
import renderCellExpand from "components/cellRender/renderCellExpand";

export function GeneralChecklist() {
    const { t } = useTranslation();
    let { settings = [], status } = useGetAllSettings();
    const { customFields = [] } = useGetAllCustomFields();
    const { columns, rows } = prepareGridData(settings, customFields);  // Prepara le colonne e le righe

    function prepareGridData(settings, customFields) {
        // Trova tutte le organizzazioni uniche
        const organizations = [...new Set(settings.map(item => item.organization))];

        // Trova tutte le impostazioni uniche (organizationSetting)
        const organizationSettings = [...new Set(settings.map(item => item.organizationSetting))];

        // Tiene traccia dinamicamente di quante colonne CustomFields creare
        let customFieldsColumnNumber = Math.max(...Object.values(customFields.reduce((accumulatore, cf) => {
            accumulatore[cf.organization] = (accumulatore[cf.organization] || 0) + 1;
            return accumulatore;
        }, {})) || []);

        // Definisci le colonne
        const columns = [
            { headerName: t('organization'), field: 'organization', width: 200 },
            { headerName: t('title'), field: 'organizationTitle', width: 200 },
            { headerName: t('isActive'), field: 'isActive', width: 120, type: "boolean", renderCell: (params) => <RenderBoolean params={params} /> },
            ...organizationSettings.map(setting => ({
                headerName: t(setting),   // Nome della colonna basato sull'impostazione
                field: setting,           // Usa il nome dell'impostazione come campo
                width: 200
            })),

            ...Array.from({ length: customFieldsColumnNumber }, (_, i) => ({
                headerName: `CustomField - ${i + 1}`,
                field: `CustomField - ${i + 1}`,
                width: 275,
                renderCell: (params) => {
                    let values = (params.value ?? []).map((e, index) => ({ key: index ,showValue: e }));
            
                    return values.length > 0
                        ? renderCellExpand(values, params.colDef)
                        : "-"
                }
            }))
            
        ];

        // Definisci le righe
        const rows = organizations.map(org => {
            // Filtra i valori dell'organizzazione corrente
            const orgSettings = settings.filter(item => item.organization === org);

            // Trova i campi personalizzati per questa organizzazione
            const orgCustomFields = customFields
                .filter(cf => cf.organization === org)
                .sort((cf1, cf2) => cf1.id - cf2.id); // Ordina per id asc

            // Trova le date di inizio e fine dell'iniziativa
            const startDateUrbanPoints = orgSettings.find(s => s.organizationSetting === 'startDateUrbanPoints')?.value;
            const endDateUrbanPoints = orgSettings.find(s => s.organizationSetting === 'endDateUrbanPoints')?.value;

            // Calcola se l'iniziativa è attiva in base alle date
            const isActive = startDateUrbanPoints && endDateUrbanPoints
                ? new Date(startDateUrbanPoints) <= new Date() && new Date(endDateUrbanPoints) >= new Date()
                : false;

            // Crea l'oggetto riga per l'organizzazione
            const row = {
                organization: org,   // Definisci la chiave organization
                organizationTitle: orgSettings[0]?.organizationTitle || '',
                isActive,            // Indica se l'iniziativa è attiva (true/false)
            };

            // Aggiungi le impostazioni come colonne
            orgSettings.forEach(setting => {
                row[setting.organizationSetting] = setting.value;  // Inserisci il valore nella cella corretta
            });

            // Aggiungi i campi personalizzati alla riga
            for (let i = 0; i < orgCustomFields.length; i++) {
                let currentCustomField = orgCustomFields[i];

                const info = [
                    `Nome: ${currentCustomField.name ?? ''}`,
                    `Descrizione: ${currentCustomField?.description ?? ''}`,
                    'Impostazioni:'
                ];

                currentCustomField?.settings?.forEach(s =>
                    info.push(`- ${s.organizationSetting}: ${s.value ?? ''}`)
                );
                row[`CustomField - ${i + 1}`] = info;
            }

            return row;
        }).sort((a, b) => a.organization - b.organization);;

        return { columns, rows };
    }

    return (
        <Paper style={{ padding: "2rem" }}>
            {
                status === 'loading' ? <ResponsiveCircularLoading /> :
                    <NTMXGrid
                        columns={columns}
                        rows={rows || []}
                        getRowId={(row) => "organization-settings-" + row.organization}
                        title={t('settings')}
                    />
            }
        </Paper>
    )
}
