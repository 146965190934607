import {useTranslation} from "react-i18next";

export const FIELD_TYPE = {
    textField: 0,
    date: 1,
    image: 2,
    creator: 3
}

/*
0 -> TYPE_DRINKING_FOUNTAIN
1 -> TYPE_PARKING
2 -> TYPE_REPAIRING_STATION
3 -> TYPE_REPORT
 */
export const usePublicUtilityType = () => {
    const {t} = useTranslation();
    return [
        t('drinkingFountains'),
        t('parking'),
        t('repairingStation'),
        t('report'),
    ]
}

/*
CATEGORY_OTHER = 0
CATEGORY_ROAD_HOLES = 1
CATEGORY_LIGHTING = 2
CATEGORY_SIGNS = 3
CATEGORY_OCCUPATION = 4
 */
export const useReportType = () => {
    const {t} = useTranslation();
    return [
        t('other'),
        t('roadHoles'),
        t('lighting'),
        t('sign'),
        t('occupation'),
        t('Obstacle')
    ]
}