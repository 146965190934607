import {useTranslation} from "react-i18next";
import {usePlafondFields, useVendorsManagerFields} from "./settingsFields";
import {useGetCities, useGetPlafond} from "../../../services/ContentManager";
import Grid from "@mui/material/Grid";
import RenderFields from "./RenderFields";
import {CircularProgress} from "@mui/material";
import NTMSelect from "../../../components/NTMSelect";
import React, {useState} from "react";
import {TextItem} from "../../../components/TextItem";
import {useParams} from "react-router-dom";
import ResponsiveCircularLoading from "../../../components/ResponsiveCircularLoading";

export default function Plafond({values, setValues, onError}) {
    let {id} = useParams();
    const {t} = useTranslation();
    let fields = usePlafondFields(values, setValues);
    let {status, plafond} = useGetPlafond(id);
    let [error, setError] = useState({});

    const onTextChange = (prop) => (value) => {
        let newValues = {...values};
        newValues[prop] = value;
        setValues(newValues);
        checkError(prop, value)
    }

    const checkError = (prop, value) => {
        if (error && error[prop]) {
            delete error[prop]
            if (Object.keys(error).length === 0) onError(false)
        }
        if (prop == "plafond" && value < plafond.spent) {
            onError(true);
            setError({...error, plafond: t('plafondError')})
        }
    }


    return <Grid container spacing={4} style={{margin: 0, width: "100%", marginBottom: "0.5rem"}}>

        <RenderFields fields={fields} values={values} error={error} onTextChange={onTextChange}/>
        {
            values.isPlafond + "" === "true" && (status === 'loading' ?
                <ResponsiveCircularLoading/>
                :
                <>
                    <Grid item xs={12} md={6}>
                        <TextItem value={plafond.available} label={t('plafondAvailable')}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextItem value={plafond.spent} label={t('plafondSpent')}/>
                    </Grid>
                </>)
        }


    </Grid>;
}