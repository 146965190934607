import React,{useEffect,useState} from "react";
import Grid from "@mui/material/Grid";
import {useRefundsFields} from "./settingsFields";
import RenderFields from "./RenderFields";
import {useTranslation} from "react-i18next";

export default function Refunds({values, setValues, onError}){

    const {t} = useTranslation();
    let [error, setError] = useState({});
    let fields = useRefundsFields(values, setValues)

    useEffect(() => {
        checkError("minThresholdForRefund",values.minThresholdForRefund);
    }, [values])

    const checkError = (prop, value) => {
        if(error && error[ prop ]) {
            delete error[ prop ]
            if(Object.keys(error).length === 0) onError(false)
        }
        if(prop === "minThresholdForRefund" && !value) {
            onError(true);
            setError({...error, minThresholdForRefund : t('mandatoryField')})
        }
    }

    const onTextChange = (prop) => (value) => {
        let newValues = {...values};
        newValues[prop] = value;
        setValues(newValues);
        checkError(prop, value)
    }

    return <Grid container spacing={4} style={{margin : 0,width : "100%",marginBottom : "0.5rem"}}>

        <RenderFields fields={fields} values={values} error={error} onTextChange={onTextChange}/>

    </Grid>

}