import {Visibility, VisibilityOff} from "@mui/icons-material";
import {Grid, Typography} from "@mui/material";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import StartIconButton from "../../components/buttons/StartIconButton";
import NTMButton from "../../components/NTMButton";
import {markerType} from "../../constants/markerType";
import AreaMap from "../Dashboard/AreaMap";
import OpenDataHeatmap from "./OpenDataHeatmap";

/**
 *
 * @param {{start, end}} interval
 * @returns
 */
export default function OpenDataMap({interval, organization}) {

	const {t} = useTranslation();
	const [markersToShow, setMarkersToShow] = useState('heatmap');
	const [isShowingOrganizationArea, setIsShowingOrganizationArea] = useState(false);

	let options = ['heatmap', markerType.DRINKINGFOUNTAINS, markerType.REPAIRING_STATION, markerType.PARKING];

	return (
		<>
			<Grid container justifyContent="space-between" sx={{mb: 2}}>
				<Grid item/>
				<Grid item>
					<Grid container>
						{
							options.map(option => <Grid item key={[option, 'openDataMap']}>
								<NTMButton
									textVariant
									onClick={() => setMarkersToShow(option)}
									title={t(option)} selected={markersToShow === option}
								/>
							</Grid>)
						}
					</Grid>
				</Grid>
				<Grid item/>
			</Grid>
			{
				markersToShow === 'heatmap' &&
				<>
					<OpenDataHeatmap interval={interval} organization={organization}/>
				</>
			}
			{
				markersToShow !== 'heatmap' &&
				<>
					<Grid container justifyContent="space-between" xs={12}>
						<Grid item sx={{
							width: '25%',
							minWidth: t('hideInitiativeArea').length + 'ex'
						}}>
							<StartIconButton
								title={isShowingOrganizationArea ? t('hideInitiativeArea') : t('showInitiativeArea')}
								onClick={() => setIsShowingOrganizationArea(prevState => !prevState)}
								startIcon={isShowingOrganizationArea ? <VisibilityOff/> : <Visibility/>}/>
						</Grid>
						<Grid item alignSelf={'center'}>
							<Typography variant="h6" color={'primary'}>{t(markersToShow + 'Description')}</Typography>
						</Grid>
						<Grid item sx={{
							width: '25%',
						}}>
						</Grid>
					</Grid>
					<AreaMap
						isShowingOrganizationArea={isShowingOrganizationArea}
						markersToShow={markersToShow} disabled overrideOrganization={organization}
					/>
				</>
			}
		</>
	);
}
