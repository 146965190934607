import BaseModal from "./BaseModal";
import React,{useState} from "react";
import TextInput from "../forms/TextInput";
import dayjs from "dayjs";
import {Grid} from "@mui/material";

export default function EditCouponDateModal({open, onClose, onSubmit}){

    const today = dayjs(new Date()).format("YYYY-MM-DD");
    let [date, setDate] = useState(today);

    const save = () => {
        onSubmit(date)
        onClose()
    }

    return <BaseModal open={open} onClose={onClose} onSave={save}>
        <Grid container spacing={4} style={{margin: 0, width: "100%", marginBottom: "1rem"}}>
            <Grid item xs={12}>
                <TextInput required label={"Data rimborso"} value={date}
                           type="date"
                           onTextChange={setDate}/>
            </Grid>
        </Grid>
    </BaseModal>
}
