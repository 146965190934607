import React, {useEffect, useState} from "react";
import {useGetArticleCoupons, useGetArticles, useGetOfficeArticles} from "../../../services/ContentManager";
import CircularLoading from "../../../components/CircularLoading";
import {useQueryClient} from "react-query";
import {useSnackbar} from "notistack";
import NTMXGrid, {timestampFormatter} from "../../../components/NTMXGrid";
import {Button, Grid} from "@mui/material";
import {useHistory, useParams} from "react-router-dom";
import {responsiveWidth} from "../../../services/useBreakpoint";
import {RenderUserUidRedirect} from "../../../components/cellRender/RenderUserRedirect";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import makeStyles from '@mui/styles/makeStyles';
import CouponList from "../../../components/VendorComponents/CouponList";
import {useTranslation} from "react-i18next";

const storageKey = "articles-table-columns";

const useStyles = makeStyles((theme) => ({
    backButton: {
        color: theme.palette.primary.main,
    },
    backButtonIcon: {
        margin: theme.spacing(1)
    },
    marginBottom: {
        marginBottom: theme.spacing(2)
    },
    formControl: {
        width: '100%'
    },
    spacing: {
        width: '100%',
        height: '4em'
    },
    paddingMdUp: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3)
        },
    },
}));

/**
 * se è inserito un oid non vene considerato l' uid e viceversa
 * @param uid
 * @param articles
 * @returns {JSX.Element}
 * @constructor
 */
export default function ArticleHistory({uid, goBack, oid = null, aid = null}) {
    const classes = useStyles();
    const {t} = useTranslation();
    let queryClient = useQueryClient();
    const {enqueueSnackbar} = useSnackbar();
    let history = useHistory();

    let {id} = useParams();
    let {articles, statusArticles} = useGetArticles(uid,);
    let {articles: officesArticles, status: statusOfficesArticles} = useGetOfficeArticles(oid);
    let article = null;
    if (oid) {
        article = officesArticles.find(a => a.id === aid);
    } else {
        article = articles.find(a => a.id + '' === id);
    }

    let {coupons, status} = useGetArticleCoupons(aid ?? id);

    if (status === 'loading' || statusArticles === 'loading' || statusOfficesArticles === 'loading' || !article)
        return <CircularLoading/>

    return (
        <Grid>
            <Grid container justifyContent="space-between" className={classes.marginBottom}>
                <Button
                    onClick={(e) => goBack()}
                    className={classes.backButton}
                >
                    <ArrowBackIcon className={classes.backButtonIcon}/>
                    {t('goBack')}
                </Button>
            </Grid>
            <CouponList coupons={coupons} isArticleHistory={true} title={<>{t('historyOf')} <b>{article.title}</b></>}/>
        </Grid>
    );
}