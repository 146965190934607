import {Button, Grid, Typography} from "@mui/material";
import clsx from "clsx";
import FilterAndSearchBar from "../layout/FilterAndSearchBar";
import ClickableItem from "./ClickableItem";
import ShopElementText from "./ShopElementText";
import React, {useState} from "react";
import useGlobalStyles from "../../services/useGlobalStyles";
import {useTranslation} from "react-i18next";
import {useGetOrganizations} from "../../services/ContentManager";
import CircularLoading from "../CircularLoading";

export default function ShopList({shops, onShopDelete, onShopClick, changeIsVisibile, disabled = false, newShopLabel}) {
    const globalClasses = useGlobalStyles();
    const {t} = useTranslation();

    let [search, setSearch] = useState('');

    return (
        shops.length > 0 ?
            <Grid>
                {/*--ricerca con action add ---*/}
                <Grid className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                    <FilterAndSearchBar
                        onSearch={setSearch}
                        addElement={!disabled ? (() => {
                                onShopClick({});
                            })
                            :
                            null
                        }
                        addText={newShopLabel || t('addNewShop')}
                    />
                </Grid>
                <Grid container
                      direction="row"
                      justifyContent='flex-start'
                      alignItems="center"
                >
                    {
                        shops.sort((a, b) => b.primary ? 1 : b.id - a.id).map((s) => {
                            return (!search || s.name.toLowerCase().includes(search.toLowerCase())) &&
                                <ClickableItem key={s.id} item={s} logo={s.logo}
                                               onCLick={onShopClick}
                                               text={<ShopElementText shop={s}/>}
                                               onDelete={!s.isPrimary && onShopDelete}
                                               isAdmin={disabled}
                                               isVisible={s.visible}
                                               changeIsVisibile={!disabled && changeIsVisibile}
                                />
                        })
                    }
                </Grid>
            </Grid>
            :
            //nessun negozio inserito
            <>
                {
                    !disabled ?
                        <Grid style={{textAlign: 'center'}}>
                            <Typography>{t('noShopCreatedYet')}</Typography>
                            <br/>
                            <Button
                                variant="contained"
                                color={'primary'}
                                onClick={() => onShopClick({})}
                            >
                                {newShopLabel || t('createShop')}
                            </Button>
                        </Grid>
                        :
                        <Grid style={{textAlign: 'center'}}>
                            <Typography>{t('thereAreNoShops')}</Typography>
                        </Grid>
                }
            </>
    )
}