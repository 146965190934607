import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Grid, ListItemText, MenuItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import dayjs from "dayjs";

import MultiSelectInput from "../forms/MultiSelectInput";
import BaseModal from "./BaseModal";
import ResponsiveCircularLoading from "../ResponsiveCircularLoading";
import { useGetOrganizations } from "../../services/ContentManager";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
            '& fieldset': {
                borderColor: theme.palette.secondary.main,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.secondary.main,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.secondary.main,
                borderWidth: "3px"
            },
        },
    },
    input: {
        minWidth: "11rem",
        height: "3vh",
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.dark,
        '&:focused': {
            borderColor: theme.palette.secondary.main,
        },
        '&:hover': {
            color: theme.palette.secondary.main,
        },
        '&::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: theme.palette.secondary.main,
            opacity: 1 /* Firefox */
        },
        '&:-ms-input-placeholder': { /* Internet Explorer 10-11 */
            color: theme.palette.secondary.main,
        },
        '&::-ms-input-placeholder': { /* Microsoft Edge */
            color: theme.palette.secondary.main,
        }
    },
    grid: {
        padding: theme.spacing(2)
    }
}));

export default function EditOrganizationModal({ open, onClose, onSubmit, oid }) {

    const { t } = useTranslation();
    let classes = useStyles();
    let { organizations, status } = useGetOrganizations();
    let [selectedOrganization, setSelectedOrganization] = useState(oid);

    const handleChange = (value) => {
        const ids = new Set();
        const duplicati = new Set();

        value.forEach((elem) => {
            const id = elem.id;
            if (ids.has(id)) {
                duplicati.add(id);
            } else {
                ids.add(id);
            }
        });
        const correctValue = value.filter(v => !duplicati.has(v.id));
        console.log(correctValue);
        setSelectedOrganization(correctValue);
    };

    useEffect(() => {
        setSelectedOrganization(oid);
    }, [oid]);

    const save = () => {
        onSubmit(selectedOrganization)
        onClose()
    }

    return <BaseModal open={open} onClose={onClose} onSave={save}>
        {
            status === 'loading' ?
                <ResponsiveCircularLoading />
                :
                <Grid container spacing={4} style={{ margin: 0, width: "100%", marginBottom: "1rem" }}>
                    <Grid item xs={12}>
                        <MultiSelectInput
                            value={selectedOrganization}
                            label={t('organization')}
                            onChange={handleChange}
                            labelColor="secondary"
                            inputClass={classes.input}
                            rootClass={classes.root}
                            renderValue={(o) => o.map(o => o.title).join(', ')}>

                            {organizations.map(o =>
                                <MenuItem key={o.id} value={o}>
                                    <Checkbox checked={selectedOrganization.map(so => so.id).indexOf(o.id) > -1} />
                                    <ListItemText primary={o.title} />
                                </MenuItem>)}
                        </MultiSelectInput>
                    </Grid>
                </Grid>
        }
    </BaseModal>
}
