import { useState } from "react";
import { useEffect } from "react";
import { firebaseDatabase, firebaseStorage } from "../firebase";
import { svgToString } from "./helper";

function _deleteFirebaseElement(url) {
    let ref = null;
    try {
        ref = firebaseStorage.refFromURL(url)
    } catch (e) {
        console.log(e, 'ERROR Remove ' + url);
    }
    if (ref) {
        ref.delete()
            .then(() => {
                console.log('removed ' + url);
            })
            .catch(() => {
                console.log('ERROR Remove ' + url);
            });
    }
}

/**
 * elimina tutte le immagini nella lista da firebase
 * @param list vettore di stringa con gli url delle immagini da eliminare
 */
export function removeImagesFirebase(list) {

    if (!list)
        return

    list.forEach(url => {
        _deleteFirebaseElement(url)
    });
}

export function useSynchronizedData(firebaseRef, deps = []) {

    const [data, setData] = useState([])

    const eventHandler = querySnapShot => {
        let newData = [];
        if (querySnapShot.val()) {
            Object.entries(querySnapShot.val()).forEach(([key, value]) => {
                newData.push({ key, ...value });
            });
        }
        setData(newData);
    };

    const load = () => {
        firebaseRef.on('value', eventHandler);
    }

    const unLoad = () => {
        firebaseRef.off("value", eventHandler);
    }

    useEffect(() => {
        load();
        return unLoad;
    }, deps);

    return [data, setData]
}

export async function saveIconFirebase(mui, icon) {
    let ref = firebaseStorage.ref().child(`/achievement icons`);
    let res = null;
    try {
        res = await ref.listAll();
    } catch (e) {
        console.log('Error List All', e);
        return false
    }
    let save = true;
    res.items.forEach((itemRef) => {
        if (itemRef.name.replace('.svg', '') === icon)
            save = false
    });
    //controlla che il file non esista gia, se non esiste caricalo
    if (save) {
        try {
            await ref.child(`/${icon}.svg`).putString(svgToString(mui[icon]))
        } catch (e) {
            console.log('Error uploading svg', e);
            return false
        }
        console.log('salvato');
    }
    return true;
}
