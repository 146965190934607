import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from "react";
import {
    Button, FormControlLabel,
    Grid, Switch,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TextInput from "../../components/forms/TextInput";
import { useHistory, useParams } from "react-router-dom";
import { firebaseAuth } from "../../firebase";
import { useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import firebase from "firebase/app";
import "firebase/auth";
import { deleteElem, put, VENDORS } from "../../services/Client";
import VendorData from "../../components/VendorComponents/VendorData";
import { isEmpty } from "../../services/helper";
import { useGetVendors } from "../../services/ContentManager";
import { validateVendorData } from "../../services/ValidationFormManager";
import { useGetErrorMessages } from "../../constants/errorMessages";
import { useTranslation } from "react-i18next";
import i18next from "../../i18n";
import ConfirmButton from '../../components/buttons/ConfirmButton';
import { Delete } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    backButton: {
        color: theme.palette.primary.main,
    },
    backButtonIcon: {
        margin: theme.spacing(1)
    },
    marginBottom: {
        marginBottom: theme.spacing(2)
    },
    formControl: {
        width: '100%'
    },
    paddingMdUp: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3)
        },
    },
}));

export default function VendorProfile({ }) {

    const { t } = useTranslation();
    const classes = useStyles();
    const errorMessages = useGetErrorMessages();
    let history = useHistory();
    let queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    let user = firebaseAuth.currentUser;

    const { uid } = useParams();

    let { vendors: vendor, status } = useGetVendors(uid);

    const [data, setData] = useState({});
    const [editPassword, setEditPassword] = useState(false);
    const [error, setError] = useState({});
    useEffect(() => {
        setData({ ...vendor, email: user.email });
    }, [vendor]);

    const handleOnChange = (text, field) => {
        setData({ ...data, [field]: text });
    };

    function save() {
        setError({});
        let newData = { ...data };

        //---controllo-----
        if (isEmpty(newData.email)) {
            setError({ email: errorMessages.MANDATORY });
            return;
        }

        if (editPassword) {

            if (isEmpty(newData.currentPassword)) {
                setError({ currentPassword: errorMessages.MANDATORY });
                return;
            }
            if (newData.newPassword !== newData.repeatPassword) {
                alert(t('passwordNotMatch'));
                setError({
                    repeatPassword: errorMessages.PASSWORD_NOT_MATCH,
                });
                return;
            }
        }

        let [errorsData, isValidData] = validateVendorData(newData);

        setError({ ...errorsData });
        if (!isValidData) {
            enqueueSnackbar(errorMessages.ERROR_FORM, { variant: "error" });
            return;
        }

        const currentEmail = user.email;

        if (editPassword || newData.email !== currentEmail) {
            //autentica l'utente per sicurezza
            const credential = firebase.auth.EmailAuthProvider.credential(user.email, newData.currentPassword);
            firebaseAuth.currentUser.reauthenticateWithCredential(credential).then(async () => {
                try {
                    //---modifica l'email solo se e' cambiata
                    if (newData.email !== currentEmail) {
                        await firebaseAuth.currentUser.updateEmail(newData.email);
                    }

                    //---modifica passwrd
                    if (editPassword && newData.newPassword) {
                        await firebaseAuth.currentUser.updatePassword(newData.newPassword);
                    }
                } catch (e) {
                    enqueueSnackbar(errorMessages.ERROR_FORM, { variant: "error" });
                    return;
                }

                //cancella password prima di mandare la richiesta;
                delete newData.newPassword;
                delete newData.repeatPassword;
                delete newData.currentPassword;

                try {
                    await put(VENDORS, { body: newData, elem: user.uid });
                } catch (e) {
                    //riporta la mail allo stato precedente
                    await firebaseAuth.currentUser.updateEmail(currentEmail)
                    enqueueSnackbar(errorMessages.NEW_EMAIL_ERROR, { variant: "error" });
                    return;
                } finally {
                    await queryClient.invalidateQueries([VENDORS, { vendor: firebaseAuth.currentUser.uid }]);
                }

                enqueueSnackbar(t('userDataSaved'), { variant: "success" });
                history.goBack();

            }).catch(() => {
                setError({ currentPassword: errorMessages.INCORRECT_PASSWORD });
            });
        } else {
            put(VENDORS, { body: newData, elem: user.uid })
                .then(() => enqueueSnackbar(t('userDataSaved'), { variant: "success" }))
                .catch(e => enqueueSnackbar(errorMessages.NEW_EMAIL_ERROR, { variant: "error" }))
                .finally(() => queryClient.invalidateQueries([VENDORS, { vendor: firebaseAuth.currentUser.uid }]))
        }
    }

    function deleteVendor() {
        enqueueSnackbar(t('saving...'), {variant: "info"});
        deleteElem(VENDORS, { elem: user.uid })
            .then(() => history.go(0))
            .catch(e => enqueueSnackbar(t('errorOccurred'), { variant: "error" }))
    }

    return (
        (<div>
            <Grid container justifyContent="space-between" className={classes.marginBottom}>
                <Button
                    onClick={() => history.goBack()}
                    className={classes.backButton}
                >
                    <ArrowBackIcon className={classes.backButtonIcon} />
                    {t('goBack')}
                </Button>
            </Grid>
            <Grid container
                direction="row"
                alignItems="flex-start"
                className={classes.marginBottom}
            >
                {/*--- email  --*/}
                <Grid item xs={12} className={[classes.marginBottom, classes.paddingMdUp].join(' ')}>
                    <TextInput
                        label={t('email')}
                        type="text"
                        color={'primary'}
                        value={data.email || ''}
                        onTextChange={(text) => handleOnChange(text, 'email')}
                        error={error.email}
                    />
                </Grid>


                <Grid item xs={12} className={[classes.marginBottom, classes.paddingMdUp].join(' ')}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={editPassword}
                                onChange={(e) => setEditPassword(e.target.checked)}
                                name="editPassword"
                                color="secondary"
                            />
                        }
                        label={t('editPassword')}
                    />
                </Grid>

                {/*password*/}
                {
                    editPassword &&
                    <>

                        {/*--- password  --*/}
                        <Grid item xs={12} className={[classes.marginBottom, classes.paddingMdUp].join(' ')}>
                            <TextInput
                                label={t('currentPassword')}
                                type="password"
                                color={'primary'}
                                value={data.currentPassword || ''}
                                onTextChange={(text) => handleOnChange(text, 'currentPassword')}
                                error={error.currentPassword}
                            />
                        </Grid>
                        {/*--- nuova password  --*/}
                        <Grid item xs={12} md={6} className={[classes.marginBottom, classes.paddingMdUp].join(' ')}>
                            <TextInput
                                label={t('newPassword')}
                                type="password"
                                color={'primary'}
                                value={data.newPassword || ''}
                                onTextChange={(text) => handleOnChange(text, 'newPassword')}
                                error={error.newPassword}
                            />
                        </Grid>
                        {/*--- ripeti password  --*/}
                        <Grid item xs={12} md={6} className={[classes.marginBottom, classes.paddingMdUp].join(' ')}>
                            <TextInput
                                label={t('repeatPassword')}
                                type="password"
                                color={'primary'}
                                value={data.repeatPassword || ''}
                                onTextChange={(text) => handleOnChange(text, 'repeatPassword')}
                                error={error.repeatPassword}
                            />
                        </Grid></>
                }
            </Grid>
            {/*--spacing--*/}
            <Grid container className={classes.marginBottom} style={{ width: '100%' }} />
            <VendorData handleOnChange={handleOnChange} vendor={data} errors={error} />
            {/*--spacing--*/}
            <Grid container className={classes.marginBottom} style={{ width: '100%' }} />
            <Grid container
                justifyContent={'center'}
                direction={'row'}
            >
                <Grid item xs={12} md={3}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => save()}
                    >
                        {t('save')}
                    </Button>
                </Grid>
            </Grid>

            <Grid container >
                <Grid item>
                    <ConfirmButton
                    buttonProps={{
                        color:"primary",
                        startIcon:<Delete />,
                        title:t('deleteAccount')
                    }}
                        onConfirm={() => deleteVendor()}
                        title={t('confirmDeletion')}
                        text={t('confirmVendorDelete')}
                    >
                    </ConfirmButton>
                </Grid>
            </Grid>
        </div>)
    );
}
