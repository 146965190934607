import {useHistory} from "react-router-dom";

export function filterShops(shops, coupon) {
    return shops.filter((s) => (coupon.articleShopId && s.id === coupon.articleShopId) || (!coupon.articleShopId && s.organizations && s.organizations.map(o => o.id).includes(coupon.organizationId)));
}

/**
 * Genera lo short link, se non è possibile generarlo allora ritorna null
 * @returns {*|string}
 */
export function generatePublicLink(shop) {
    return shop.publicLink ? `${window.location.host}/riscatta/${shop.id}-${shop.publicLink}` : null
}

/**
 * il testo inserito dall'utente viene convertito per avere solo lettere e numeri
 */
export function urlEncode(url) {
    if (!url)
        return null;
    return url
        .replace(/([^_a-zA-Z\d\s:])/g, '')
        .replaceAll(' ', '_')
        .replace(/__+/g, '_')
}