import BaseModal from "./BaseModal";
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import TextInput, { TextInputContainer } from "../forms/TextInput";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import RadioAwardType from "components/forms/RadioAwardType";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from "../../../node_modules/@mui/material/index";
import RadioButtons from "components/forms/RadioButtons";
import { makeStyles } from "../../../node_modules/@mui/styles/index";

const useStyles = makeStyles(theme => ({
    radioButton: {
        "&.Mui-checked": {
            color: theme.palette.primary.main
        },
    },
    radioLabel: {
        color: theme.palette.primary.light,
        display: 'flex',
        alignContent: "space-between",
        '&.Mui-focused': {
            color: theme.palette.primary.light
        }
    },
}));

export default function SurveyModal({ open, onClose, onSubmit }) {

    const { t } = useTranslation();
    const today = dayjs(new Date()).format("YYYY-MM-DD");
    let [values, setValues] = useState({ awardName: "", awardDescription: "", awardType: 1, awardValue: 0, awardCommunity: 0, link: "", startDate: today, endDate: today });
    let [endError, setEndError] = useState(false);

    const classes = useStyles();

    const save = () => {
        if (values.startDate > values.endDate) {
            setEndError(true)
            return;
        }
        onSubmit(values)
        onClose()
    }

    const onTextChange = (value, name) => {
        let newValues = { ...values };
        console.log(value)
        if (typeof value === "string") {
            newValues[name] = value.trim();
        } else {
            newValues[name] = value;
        }
        setValues(newValues);
    }

    return <BaseModal open={open} onClose={onClose} onSave={save}>
        <Grid container spacing={4} style={{ margin: 0, width: "100%", marginBottom: "1rem" }}>

            <TextInputContainer xs={12} required label={t('name')} value={values.awardName}
                onTextChange={(value) => { onTextChange(value, "awardName") }} />

            <TextInputContainer xs={12} required label={t('description')} value={values.awardDescription}
                onTextChange={(value) => { onTextChange(value, "awardDescription") }} />

            <TextInputContainer xs={12} required label={t('link')} value={values.link}
                onTextChange={(value) => { onTextChange(value, "link") }} />

            <TextInputContainer xs={12} required label={t('startDate')} value={values.startDate}
                type="date" min={today}
                onTextChange={(value) => { onTextChange(value, "startDate") }} />

            <TextInputContainer xs={12} required label={t('endDate')} value={values.endDate}
                type="date" min={today}
                error={endError && t('endDate')}
                onTextChange={(value) => { onTextChange(value, "endDate") }} />

            <Grid item xs={12}>
                <Typography variant={"h6"} style={{ color: "white" }}>{t('award')}</Typography>
            </Grid>
            <Grid item xs={12} style={{ padding: "16px" }}>
                <RadioAwardType value={values.awardType} onChange={(value) => { onTextChange(value, "awardType") }} label={t('valueType')} />
            </Grid>
            {
                (values.awardType === 0 || values.awardType === 1 || values.awardType === 3) &&
                <TextInputContainer xs={12} required label={values.awardType === 1 ? t('pointsValue') : t('euroValue')} value={values.awardValue}
                    type="number" min={0} step={1}
                    onTextChange={(value) => { onTextChange(value, "awardValue") }} />

            }
            {
                (values.awardType === 1) &&
                <Grid item xs={12} style={{ padding: "16px" }}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend" sx={{ color: "primary.light", '&.Mui-focused': { color: "primary.light" } }}>{t('points')}</FormLabel>
                        <RadioGroup row value={values.awardCommunity} className={classes.radioLabel}
                            onChange={(event) => onTextChange(parseInt(event.target.value), "awardCommunity")}>
                            <FormControlLabel value={0} control={<Radio sx={{ color: "primary.light", '&.Mui-checked': { color: "secondary.main" } }} />} label={t('initiativePoints')} />
                            <FormControlLabel value={1} control={<Radio sx={{ color: "primary.light", '&.Mui-checked': { color: "secondary.main" } }} />} label={t('nationalPoints')} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            }

        </Grid>
    </BaseModal>
}