import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import SettingsPills from "../../../components/SettingsPills";
import { AbilityContext } from "../../../services/Can";
import {
    CUSTOMFIELDS,
    ORGANIZATIONS,
    SETTINGS,
    deleteElem,
    getErrorMessage,
    post, put,
} from "../../../services/Client";
import { useGetOrganizationCustomField, useGetOrganizationSettings } from "../../../services/ContentManager";
import { resources } from "../../../services/ability";
import AwardLimitSettings from "./AwardLimitSettings";
import CouponsExpirationDateSettings from "./CouponsExpirationDateSettings";
import CustomFieldManager from "./CustomFieldManager";
import GeneralSettingsManager from "./GeneralSettingsManager";
import GlobalRanksAchievementsManager from "./GlobalRanksAchievementsManager";
import HomeWorkRefunds from "./HomeWorkRefunds";
import Co2Settings from "./Co2Settings";
import OpenDataSettings from "./OpenDataSettings";
import Plafond from "./Plafond";
import Refunds from "./Refunds";
import UrbanPathRefunds from "./UrbanPathRefunds";
import UrbanPointsSettings from "./UrbanPointsSettings";
import VendorsManager from "./VendorsManager";
import { SHOW_WIP } from '../../../constants/debug';
import CarpoolingSettings from './CarpoolingSettings';

const useStyles = makeStyles(theme => ({
    container: {
        padding: "2rem",
        backgroundColor: theme.palette.primary.light,
        width: "auto"
    },
    header: {
        fontWeight: "bold",
    },
    expansionPanel: {
        margin: theme.spacing(1),
    },
    expanded: {
        border: "3px solid" + theme.palette.primary.dark,
    },
}));

const replacer = (key, value) => {          //funzione usata per il confronto degli oggetti con JSON.stringify
    if (typeof value === 'boolean') return String(value);
    return value;
}

export default function Settings() {

    const { t } = useTranslation();
    let classes = useStyles();
    let { id } = useParams();
    let { settings = [], status: settingsStatus } = useGetOrganizationSettings(id);
    let [values, setValues] = useState({});
    let [originalSettings, setOriginalSettings] = useState({});
    let [errors, setErrors] = useState({});
    let { customField = [] } = useGetOrganizationCustomField(id);
    let [editedCustomField, setEditedCustomField] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    let queryClient = useQueryClient();
    const ability = useContext(AbilityContext);

    useEffect(() => {
        if (settings) {
            let newValues = {}
            settings.map(setting => {
                newValues[setting.organizationSetting] = setting.value
            })
            if (newValues["homeWorkPointsTolerance"]) newValues["homeWorkPointsTolerance"] *= 1000
            if (newValues["homeWorkPathTolerancePerc"]) newValues["homeWorkPathTolerancePerc"] *= 100

            // if (newValues["awardRankingLimit"]) newValues["hasAwardRankingLimit"] = true;
            // if (newValues["awardAchievementLimit"]) newValues["hasAwardAchievementLimit"] = true;
            // if (newValues["awardPositionLimit"]) newValues["hasAwardPositionLimit"] = true;
            // if (newValues["awardOrganizationLimit"]) newValues["hasAwardOrganizationLimit"] = true;

            setValues(newValues)
            setOriginalSettings(newValues)
        }
    }, [settings])

    useEffect(() => {
        if (customField) setEditedCustomField(JSON.parse(JSON.stringify(customField)))
    }, [customField])

    const pills = [
        {
            title: t('initiativePoints'),
            component: <UrbanPointsSettings values={values} setValues={setValues} onError={(value) => setErrors({
                ...errors,
                urbanPointsSettingsErrors: value
            })} />
        },
        {
            title: t('refund'),
            component: <Refunds values={values} setValues={setValues}
                onError={(value) => setErrors({ ...errors, refundsErrors: value })} />
        },
        {
            title: t('couponsExpirationDate'),
            component: <CouponsExpirationDateSettings values={values} setValues={setValues} />
        },
        {
            title: t('homeWorkRefund'),
            component: <HomeWorkRefunds values={values} setValues={setValues}
                onError={(value) => setErrors({ ...errors, homeWorkRefundsErrors: value })} />
        },
        {
            title: t('urbanPathRefund'),
            component: <UrbanPathRefunds values={values} setValues={setValues}
                onError={(value) => setErrors({ ...errors, urbanPathRefundsErrors: value })} />
        },
        {
            title: t('jollyValueCategories'),
            component: <CustomFieldManager settings={values} setSettings={setValues} customField={editedCustomField}
                setCustomField={setEditedCustomField} />
        },
        {
            title: t('globalRanksAchievements'),
            component: <GlobalRanksAchievementsManager values={values} setValues={setValues} />
        },
        {
            title: t('vendorsAndShops'),
            component: <VendorsManager values={values} setValues={setValues} />
        },
        {
            title: t('generalSettings'),
            component: <GeneralSettingsManager values={values} setValues={setValues} />
        },
        {
            title: t('plafond'),
            component: <Plafond values={values} setValues={setValues}
                onError={(value) => setErrors({ ...errors, plafondErrors: value })} />
        },
        {
            title: t('userAwardLimit'),
            component: <AwardLimitSettings values={values} setValues={setValues}
                onError={(value) => setErrors({ ...errors, awardLimitErrors: value })} />
        },
        {
            title: t('carpooling'),
            component: <CarpoolingSettings values={values} setValues={setValues} onError={(value) => setErrors({ ...errors, carpoolingErrors: value })}/>
        },
    ];
    if (SHOW_WIP) {
        pills.push({
            title: t('co2'),
            component: <Co2Settings values={values} setValues={setValues}
                onError={(value) => setErrors({ ...errors, intermodalityErrors: value })} />
        });
    }
    if (ability.can("write", resources.OPEN_DATA_SETTINGS))
        pills.push({
            title: t('openData'),
            component: <OpenDataSettings values={values} setValues={setValues} />
        });

    const checkErrors = () => {
        return !!(errors.urbanPointsSettingsErrors || errors.refundsErrors || errors.homeWorkRefundsErrors || errors.urbanPathRefundsErrors || errors.plafondErrors || errors.awardLimitErrors || errors.carpoolingErrors);
    }

    const save = () => {

        let showedError = false;
        let showedSaved = false;

        enqueueSnackbar(t('saving...'), { variant: "info" });

        if (JSON.stringify(originalSettings, replacer) !== JSON.stringify(values, replacer)) {
            let settings = [];
            let editedValues = deleteUselessTimeBonus();

            Object.entries(editedValues).forEach(setting => {
                if (setting[0] === "homeWorkPointsTolerance") settings.push({
                    organizationSetting: setting[0],
                    value: setting[1] / 1000
                });
                else if (setting[0] === "homeWorkPathTolerancePerc") settings.push({
                    organizationSetting: setting[0],
                    value: setting[1] / 100
                });
                else settings.push({ organizationSetting: setting[0], value: setting[1] });
            })

            post(ORGANIZATIONS + "/" + id + "/" + SETTINGS, { body: settings })
                .then(() => {
                    enqueueSnackbar(t('saved'), { variant: "success" })
                    showedSaved = true
                    setValues(editedValues)
                })
                .catch(e => {
                    enqueueSnackbar(getErrorMessage(e), { variant: "error" })
                    showedError = true
                })
                .finally(() => queryClient.invalidateQueries([ORGANIZATIONS, { id: id }, SETTINGS]));
        }

        if (JSON.stringify(customField) !== JSON.stringify(editedCustomField)) {

            for (let i = 0; i < 3; i++) {
                if (!customField[i] && !!editedCustomField[i]) {
                    post(ORGANIZATIONS + "/" + id + "/" + CUSTOMFIELDS, { body: editedCustomField[i] })
                        .then(() => {
                            !showedSaved && enqueueSnackbar(t('saved'), { variant: "success" })
                            showedSaved = true
                        })
                        .catch(e => {
                            !showedError && enqueueSnackbar(getErrorMessage(e), { variant: "error" })
                            showedError = true
                        })
                        .finally(() => queryClient.invalidateQueries([ORGANIZATIONS, { id: id }, CUSTOMFIELDS]));
                } else if (!!customField[i] && !editedCustomField[i]) {
                    deleteElem(ORGANIZATIONS + "/" + id + "/" + CUSTOMFIELDS + "/" + customField[i].id)
                        .then(() => {
                            !showedSaved && enqueueSnackbar(t('saved'), { variant: "success" })
                            showedSaved = true
                        })
                        .catch(e => {
                            !showedError && enqueueSnackbar(getErrorMessage(e), { variant: "error" })
                            showedError = true
                        })
                        .finally(() => queryClient.invalidateQueries([ORGANIZATIONS, { id: id }, CUSTOMFIELDS]));
                } else if (JSON.stringify(customField[i]) !== JSON.stringify(editedCustomField[i])) {
                    put(ORGANIZATIONS + "/" + id + "/" + CUSTOMFIELDS + "/" + customField[i].id, { body: editedCustomField[i] })
                        .then(() => {
                            !showedSaved && enqueueSnackbar(t('saved'), { variant: "success" })
                            showedSaved = true
                        })
                        .catch(e => {
                            !showedError && enqueueSnackbar(getErrorMessage(e), { variant: "error" })
                            showedError = true
                        })
                        .finally(() => queryClient.invalidateQueries([ORGANIZATIONS, { id: id }, CUSTOMFIELDS]));
                }
            }

        }

    }

    const deleteUselessTimeBonus = () => {
        let newValues = { ...values };
        for (let i = 2; i <= 5; i++) {
            if (newValues["startTimeBonus_" + i] === undefined || newValues["startTimeBonus_" + i] === null ||
                newValues["endTimeBonus_" + i] === undefined || newValues["endTimeBonus_" + i] === null) {
                for (let j = i; j < 5; j++) {
                    let tempStartTime = newValues["startTimeBonus_" + (j)];
                    let tempEndTime = newValues["endTimeBonus_" + (j)];
                    newValues["startTimeBonus_" + (j)] = newValues["startTimeBonus_" + (j + 1)];
                    newValues["endTimeBonus_" + (j)] = newValues["endTimeBonus_" + (j + 1)];
                    newValues["startTimeBonus_" + (j + 1)] = tempStartTime;
                    newValues["endTimeBonus_" + (j + 1)] = tempEndTime;
                }
            }
        }
        return newValues
    }

    return (
        <SettingsPills
            onRestore={() => {
                setValues({ ...originalSettings })
                setEditedCustomField(JSON.parse(JSON.stringify(customField)))
            }}
            disabled={JSON.stringify(originalSettings, replacer) === JSON.stringify(values, replacer) && JSON.stringify(customField) === JSON.stringify(editedCustomField)}
            onSave={save}
            checkErrorsFunc={checkErrors}
            pills={pills}
            loading={settingsStatus === "loading"}
        />
    );
}
