import { useTranslation } from "react-i18next";


export const COUPON_STATUS = {
    COUPON_ASSIGNED: 0, COUPON_REVOKED: 1, COUPON_SKIPPED: 2, NO_FUNDS: 3, COUPON_PARTIALLY_ASSIGNED: 4, COUPON_SKIPPED_USER_LIMIT: 5
}

export default function useGetCouponStatusTranslations() {
    const { t } = useTranslation();

    return [
        t('couponAssigned'),
        t('couponRevoked'),
        t('couponSkipped'),
        t('noFounds'),
        t('couponPartiallyAssigned'),
        t('couponSkippedUserLimit')
    ]
}