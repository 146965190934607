import Grid from "@mui/material/Grid";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import RenderFields from "./RenderFields";
import { useCarpoolingFields } from "./settingsFields";
import { useState } from "react";

export default function CarpoolingSettings({ values, setValues, onError }) {
    let { id } = useParams();
    const { t } = useTranslation();
    let fields = useCarpoolingFields(values, setValues);
    let [error, setError] = useState({});

    const onTextChange = (prop) => (value) => {
        let newValues = { ...values };
        newValues[prop] = value;
        setValues(newValues);
        checkError(prop, value);
    }

    //unused
    const checkError = (prop, value) => {
        if (error && error[prop]) {
            delete error[prop]
            if (Object.keys(error).length === 0) onError(false)
        }
    }

    return <Grid container spacing={4} style={{ margin: 0, width: "100%", marginBottom: "0.5rem" }}>

        <RenderFields fields={fields} values={values} onTextChange={onTextChange} error={error} />

    </Grid>;
}