import Link from "@mui/material/Link";
import React from "react";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";

export default function CopyableLink({url}) {
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();

    function handleOnLinkClick(e, url) {
        e.stopPropagation();
        navigator.clipboard.writeText(url).then(() => enqueueSnackbar(t('copied'), {variant: 'success'}));
    }

    return (
        <Link
            sx={{cursor: 'pointer'}}
            onClick={(e) => handleOnLinkClick(e, url)}
        >
            {url}
        </Link>
    );
}