import {useCallback, useEffect, useState} from "react";
import {debounce} from "lodash";

const getDeviceConfig = (width) => {
    if (width < 320) {
        return 'xs';
    } else if (width >= 320 && width < 720) {
        return 'sm';
    } else if (width >= 720 && width < 1024) {
        return 'md';
    } else if (width >= 1024) {
        return 'lg';
    }
};

/**
 * indica, in base alla grandezza della finestra se è xs,sm,md,lg
 * @returns {string}
 */
const useBreakpoint = () => {
    const [brkPnt, setBrkPnt] = useState(() => getDeviceConfig(window.innerWidth));

    useEffect(() => {
        window.addEventListener('resize', setBrkPnt(getDeviceConfig(window.innerWidth)));
        return () => window.removeEventListener('resize', setBrkPnt(getDeviceConfig(window.innerWidth)));
    }, []);

    return brkPnt;
}
export default useBreakpoint;

/**
 * calcola la dimensione di un elemento dato in input la percentuale di lunghezza della finestra, limitata ad una lunghezza massima
 * @param viewWidth percentuale della dimensione della finestra
 * @param maxWidth lunghezza massiam
 * @param minWidth
 */
export function responsiveWidth({viewWidth, maxWidth = undefined, minWidth = 0}) {
    viewWidth = viewWidth / 100;
    //48 : padding del contenitore più esterno, 64 dimensione del menù laterale
    let width = (window.innerWidth - (getDeviceConfig(window.innerWidth) === 'sm' ? 48 : 48 + 64)) * viewWidth;
    return Math.round(width > maxWidth ? maxWidth : (width < minWidth) ? minWidth : width);
}

/**
 * useEffect che esegue la funzione callback al resize della pagina
 * @param callBack
 */
export function useResizeEffect(callBack, deps=[]) {
    const handleResize = useCallback(
        debounce(() => {
            callBack();
        }, 100),
        []
    , deps);

    useEffect(() => {callBack();}, deps);

    return useEffect(() => {
        callBack();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
}