import {useTranslation} from "react-i18next";

export default function useGetRankingFilter (customField) {
    const {t} = useTranslation();

    let rankingFilter = [
        {
            id : 0,
            name : t('sessionType')
        },
        {
            id : 1,
            name : t('yearsOld')
        },
        {
            id : 2,
            name : t('gender')
        }
    ];
    if(customField) {
        customField.map((cf, i) => rankingFilter.push({
            //cf-id-index
            id : "cf-" + cf.id,
            name : cf.name,
        }))
    }
    return rankingFilter;
}