import {useTranslation} from "react-i18next";
import {useQueryClient} from "react-query";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router-dom";
import {isAdmin as getIsAdmin} from "../../services/PermissionManager";
import React, {useContext, useState} from "react";
import {UserContext} from "../App";
import {useGetOrganizationOffices, useGetOrganizations, useGetShops} from "../../services/ContentManager";
import CircularLoading from "../../components/CircularLoading";
import {deleteElem, OFFICES, ORGANIZATIONS, put, SHOPS, VENDORS} from "../../services/Client";
import {SHOP_ROUTE} from "../../constants/vendors";
import ShopList from "../../components/VendorComponents/ShopsList";
import Office from "./Office";
import CouponRedeemSection from "../../components/VendorComponents/CouponRedeemSection";
import {Grid} from "@mui/material";

export default function Offices({oid}) {
    const {t} = useTranslation();
    const queryClient = useQueryClient();
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();
    const [office, setOffice] = useState(null);
    const [isCouponOpen, setIsCouponOpen] = useState(false);//nascondere tutto se si apre la schermata dei coupon

    const {status: organizationStatus, organizations, error} = useGetOrganizations(!oid);//se è un manager l'oid viene preso da backend
    let organizationId = oid ?? (organizations[0] || []).id;
    const {offices, statusOffices} = useGetOrganizationOffices(organizationId);

    if (statusOffices === 'loading' || organizationStatus === 'loading')
        return <CircularLoading/>

    function onOfficeDelete(office) {
        enqueueSnackbar(t('saving...'), {variant: "info"});
        deleteElem(ORGANIZATIONS + '/' + organizationId + '/' + OFFICES, {elem: office.id})
            .then(() => {
                queryClient.invalidateQueries([OFFICES, {oid: organizationId}]).then(() => enqueueSnackbar(t('shopDeleted'), {variant: "success"}));

            })
            .catch((e) => {
                enqueueSnackbar(t('shopCantBeDeleted'), {variant: "error"});
                console.log(e)
            })
    }

    function onChangeVisible(shop) {
        enqueueSnackbar(t('saving...'), {variant: "info"});
        put(SHOPS + '/' + shop.id, {body: {visible: !shop.visible}})
            .then(() => {
                queryClient.invalidateQueries([OFFICES, {oid: organizationId}]).then(() => enqueueSnackbar(t('saved'), {variant: "success"}));

            })
            .catch((e) => {
                enqueueSnackbar(t('errorSavingData'), {variant: "error"});
                console.log(e)
            })
    }

    function onOfficeClick(office) {
        setOffice(office);
    }

    async function goBack() {
        await queryClient.invalidateQueries([OFFICES, {oid: organizationId}]);
        setOffice(null);
    }

    return (
        !office && offices.length > 0 ?
            (
                <Grid container direction={'column'}>
                    <Grid item>
                        <CouponRedeemSection
                            shops={offices}
                            onCouponDetailsOpen={setIsCouponOpen}
                        />
                    </Grid>
                    {
                        !isCouponOpen &&
                        <>
                            <ShopList shops={offices} onShopClick={onOfficeClick} onShopDelete={onOfficeDelete}
                                      changeIsVisibile={onChangeVisible} newShopLabel={t('newOffice')}/>
                        </>
                    }
                </Grid>
            )
            :
            <Office oid={organizationId}
                    isFirstShop={offices.length === 0}
                    sid={(office ?? {}).id}
                    goBack={goBack}/>
    );
}