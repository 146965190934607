import Grid from "@mui/material/Grid";
import { TextInputContainer } from "../../../components/forms/TextInput";
import DeleteIcon from "@mui/icons-material/Delete";
import StartIconButton from "../../../components/buttons/StartIconButton";
import React from "react";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useCustomFieldManagerFields } from "./settingsFields";
import RenderFields from "./RenderFields";
import { useTranslation } from "react-i18next";
import { Divider, InputAdornment, Typography } from "../../../../node_modules/@mui/material/index";

export default function CustomFieldManager({ settings, setSettings, customField, setCustomField }) {

    const { t } = useTranslation();
    let fields = useCustomFieldManagerFields(settings, setSettings);

    const onTextChange = (index, prop) => (value) => {
        let newCustomField = [...customField];
        newCustomField[index][prop] = value;
        setCustomField(newCustomField)
    }

    const deleteField = (index) => {
        let newCustomField = [...customField];
        newCustomField.splice(index, 1)
        setCustomField(newCustomField)
    }

    const addField = () => {
        let newCustomField = [...customField];
        console.log(settings)
        let list = [];
        list.push({ organizationSetting: "euroMaxRefundInADay", value: settings.euroMaxRefundInADay });
        list.push({ organizationSetting: "euroMaxRefundInAMonth", value: settings.euroMaxRefundInAMonth });
        list.push({ organizationSetting: "pointsPerKmInitiativeBike", value: settings.pointsPerKmInitiativeBike });
        list.push({ organizationSetting: "pointsPerKmInitiativeElectricBike", value: settings.pointsPerKmInitiativeElectricBike });
        list.push({ organizationSetting: "pointsPerKmInitiativeUgo", value: settings.pointsPerKmInitiativeUgo });
        list.push({ organizationSetting: "euroValueKmUrbanPathBike", value: settings.euroValueKmUrbanPathBike });
        list.push({ organizationSetting: "euroValueKmUrbanPathElectricBike", value: settings.euroValueKmUrbanPathElectricBike });
        list.push({ organizationSetting: "euroValueKmUrbanPathUgo", value: settings.euroValueKmUrbanPathUgo });
        list.push({ organizationSetting: "valueKmHomeWorkBike", value: settings.valueKmHomeWorkBike });
        list.push({ organizationSetting: "valueKmHomeWorkElectricBike", value: settings.valueKmHomeWorkElectricBike });
        list.push({ organizationSetting: "valueKmHomeWorkUgo", value: settings.valueKmHomeWorkUgo });
        newCustomField.push({ organizationSetting: "", description: "", settings: list })
        setCustomField(newCustomField)
    }

    const onCustomFieldSettingsChange = (index, settingName, prop) => (value) => {
        let newCustomFields = [...customField];
        newCustomFields[index].settings.find(setting => setting.organizationSetting === settingName)[prop] = value;
        setCustomField(newCustomFields);
    };

    return (
        <Grid container spacing={4} style={{ margin: 0, width: "100%", marginBottom: "0.5rem" }} >

            <RenderFields fields={fields} values={settings} />

            {
                customField.map((cF, i) => <>
                    {console.log(cF)}

                    <TextInputContainer xs={5} label={t('name')}
                        value={cF.name} color={"dark"}
                        whiteBackground required
                        onTextChange={onTextChange(i, "name")}
                        InputLabelProps={{
                            shrink: true,
                        }} />

                    <TextInputContainer xs={6} label={t('description')}
                        value={cF.description} color={"dark"}
                        whiteBackground required
                        onTextChange={onTextChange(i, "description")}
                        InputLabelProps={{
                            shrink: true,
                        }} />

                    <Grid item xs={1} style={{ padding: "16px" }}>
                        <IconButton onClick={() => deleteField(i)} color={"primary"} size="large">
                            <DeleteIcon />
                        </IconButton>
                    </Grid>

                    {/* Mostra e gestisci i settings */}
                    {cF.settings.find(setting => setting.organizationSetting === "euroMaxRefundInADay") && (
                        <TextInputContainer
                            xs={5}
                            type="number"
                            label={t('euroMaxRefundInADay')}
                            startAdornment={<InputAdornment position="start">{t('currency')}</InputAdornment>}
                            value={cF.settings.find(setting => setting.organizationSetting === "euroMaxRefundInADay").value}
                            color={"dark"}
                            min={0}
                            required
                            onTextChange={onCustomFieldSettingsChange(i, "euroMaxRefundInADay", "value")}
                            InputLabelProps={{ shrink: true }}
                        />
                    )}
                    {cF.settings.find(setting => setting.organizationSetting === "euroMaxRefundInAMonth") && (
                        <TextInputContainer
                            xs={5}
                            type="number"
                            label={t('euroMaxRefundInAMonth')}
                            startAdornment={<InputAdornment position="start">{t('currency')}</InputAdornment>}
                            value={cF.settings.find(setting => setting.organizationSetting === "euroMaxRefundInAMonth").value}
                            color={"dark"}
                            min={0}
                            required
                            onTextChange={onCustomFieldSettingsChange(i, "euroMaxRefundInAMonth", "value")}
                            InputLabelProps={{ shrink: true }}
                        />
                    )}


                    <Grid xs="12" sx={{ my: 1 }}>
                        <Typography textAlign={'center'} sx={{ color: 'gray' }} >{t('initiativePoints')}</Typography>
                    </Grid>
                    {cF.settings.find(setting => setting.organizationSetting === "pointsPerKmInitiativeBike") && (
                        <TextInputContainer
                            xs={4}
                            type="number"
                            label={t('bike')}
                            startAdornment={<InputAdornment position="start">{t('points')}</InputAdornment>}
                            value={cF.settings.find(setting => setting.organizationSetting === "pointsPerKmInitiativeBike").value}
                            color={"dark"}
                            min={0}
                            required
                            onTextChange={onCustomFieldSettingsChange(i, "pointsPerKmInitiativeBike", "value")}
                            InputLabelProps={{ shrink: true }}
                        />
                    )}
                    {cF.settings.find(setting => setting.organizationSetting === "pointsPerKmInitiativeElectricBike") && (
                        <TextInputContainer
                            xs={4}
                            type="number"
                            label={t('eBike')}
                            startAdornment={<InputAdornment position="start">{t('points')}</InputAdornment>}
                            value={cF.settings.find(setting => setting.organizationSetting === "pointsPerKmInitiativeElectricBike").value}
                            color={"dark"}
                            min={0}
                            required
                            onTextChange={onCustomFieldSettingsChange(i, "pointsPerKmInitiativeElectricBike", "value")}
                            InputLabelProps={{ shrink: true }}
                        />
                    )}
                    {cF.settings.find(setting => setting.organizationSetting === "pointsPerKmInitiativeUgo") && (
                        <TextInputContainer
                            xs={4}
                            type="number"
                            label={t('ugo')}
                            startAdornment={<InputAdornment position="start">{t('points')}</InputAdornment>}
                            value={cF.settings.find(setting => setting.organizationSetting === "pointsPerKmInitiativeUgo").value}
                            color={"dark"}
                            min={0}
                            required
                            onTextChange={onCustomFieldSettingsChange(i, "pointsPerKmInitiativeUgo", "value")}
                            InputLabelProps={{ shrink: true }}
                        />
                    )}


                    <Grid xs="12" sx={{ my: 1 }}>
                        <Typography textAlign={'center'} sx={{ color: 'gray' }} >{t('urbanPathRefund')}</Typography>
                    </Grid>
                    {cF.settings.find(setting => setting.organizationSetting === "euroValueKmUrbanPathBike") && (
                        <TextInputContainer
                            xs={4}
                            type="number"
                            label={t('bike')}
                            startAdornment={<InputAdornment position="start">{t('currency')}</InputAdornment>}
                            value={cF.settings.find(setting => setting.organizationSetting === "euroValueKmUrbanPathBike").value}
                            color={"dark"}
                            min={0}
                            required
                            onTextChange={onCustomFieldSettingsChange(i, "euroValueKmUrbanPathBike", "value")}
                            InputLabelProps={{ shrink: true }}
                        />
                    )}
                    {cF.settings.find(setting => setting.organizationSetting === "euroValueKmUrbanPathElectricBike") && (
                        <TextInputContainer
                            xs={4}
                            type="number"
                            label={t('eBike')}
                            startAdornment={<InputAdornment position="start">{t('currency')}</InputAdornment>}
                            value={cF.settings.find(setting => setting.organizationSetting === "euroValueKmUrbanPathElectricBike").value}
                            color={"dark"}
                            min={0}
                            required
                            onTextChange={onCustomFieldSettingsChange(i, "euroValueKmUrbanPathElectricBike", "value")}
                            InputLabelProps={{ shrink: true }}
                        />
                    )}
                    {cF.settings.find(setting => setting.organizationSetting === "euroValueKmUrbanPathUgo") && (
                        <TextInputContainer
                            xs={4}
                            type="number"
                            label={t('ugo')}
                            startAdornment={<InputAdornment position="start">{t('currency')}</InputAdornment>}
                            value={cF.settings.find(setting => setting.organizationSetting === "euroValueKmUrbanPathUgo").value}
                            color={"dark"}
                            min={0}
                            required
                            onTextChange={onCustomFieldSettingsChange(i, "euroValueKmUrbanPathUgo", "value")}
                            InputLabelProps={{ shrink: true }}
                        />
                    )}


                    <Grid xs="12" sx={{ my: 1 }}>
                        <Typography textAlign={'center'} sx={{ color: 'gray' }} >{t('homeWorkRefund')}</Typography>
                    </Grid>
                    {cF.settings.find(setting => setting.organizationSetting === "valueKmHomeWorkBike") && (
                        <TextInputContainer
                            xs={4}
                            type="number"
                            label={t('bike')}
                            startAdornment={<InputAdornment position="start">{t('currency')}</InputAdornment>}
                            value={cF.settings.find(setting => setting.organizationSetting === "valueKmHomeWorkBike").value}
                            color={"dark"}
                            min={0}
                            required
                            onTextChange={onCustomFieldSettingsChange(i, "valueKmHomeWorkBike", "value")}
                            InputLabelProps={{ shrink: true }}
                        />
                    )}
                    {cF.settings.find(setting => setting.organizationSetting === "valueKmHomeWorkElectricBike") && (
                        <TextInputContainer
                            xs={4}
                            type="number"
                            label={t('eBike')}
                            startAdornment={<InputAdornment position="start">{t('currency')}</InputAdornment>}
                            value={cF.settings.find(setting => setting.organizationSetting === "valueKmHomeWorkElectricBike").value}
                            color={"dark"}
                            min={0}
                            required
                            onTextChange={onCustomFieldSettingsChange(i, "valueKmHomeWorkElectricBike", "value")}
                            InputLabelProps={{ shrink: true }}
                        />
                    )}
                    {cF.settings.find(setting => setting.organizationSetting === "valueKmHomeWorkUgo") && (
                        <TextInputContainer
                            xs={4}
                            type="number"
                            label={t('ugo')}
                            startAdornment={<InputAdornment position="start">{t('currency')}</InputAdornment>}
                            value={cF.settings.find(setting => setting.organizationSetting === "valueKmHomeWorkUgo").value}
                            color={"dark"}
                            min={0}
                            required
                            onTextChange={onCustomFieldSettingsChange(i, "valueKmHomeWorkUgo", "value")}
                            InputLabelProps={{ shrink: true }}
                        />
                    )}


                    <Grid xs="12" sx={{ mb: 2 }}>
                        <Divider />
                    </Grid>
                </>)
            }

            {customField.length < 3 &&
                <Grid container justifyContent={"center"}>
                    <StartIconButton onClick={() => addField()} color={"primary"} startIcon={<AddIcon />}
                        title={t('addNewJollyValue')} />
                </Grid>
            }

        </Grid>
    );
}
