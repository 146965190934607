import React from "react";
import {Grid, Typography} from "@mui/material";
import {TextItem} from "../TextItem";
import makeStyles from '@mui/styles/makeStyles';
import HoverableButton from "../layout/HoverableButton";
import InfoIcon from "@mui/icons-material/Info";
import Link from "@mui/material/Link";
import clsx from "clsx";
import useGlobalStyles from "../../services/useGlobalStyles";
import {unixToString} from "../../services/helper";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.primary.main
        }
    },
    paddingLeft: {
        paddingLeft: theme.spacing(3),
    },
    textItem: {
        color: "white",
        borderColor: theme.palette.primary.light,
        height: "100%",
    },
}));

export default function CouponArticlePreview({coupon}) {
    const classes = useStyles();
    const globalClasses = useGlobalStyles();
    const {t} = useTranslation();
    return <Grid container>

        {/*--- tipologia --*/}
        <Grid item xs={12} md={4}
              className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>

            <TextItem xs={12} className={classes.textItem}
                      label={t('awardType')}
                      value={t('awardsFromCommunity')}
                      color={"light"}
                      alignCenter
            />

        </Grid>

        {/*--- valore --*/}
        <Grid item xs={12} md={4}
              className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>

            <TextItem xs={12} className={classes.textItem}
                      label={t('value')}
                      value={(coupon.value || '') + t('currency')}
                      color={"light"}
                      alignCenter
            />

        </Grid>

        {/*--- data emissione --*/}
        <Grid item xs={12} md={4}
              className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
            <TextItem xs={12} className={classes.textItem}
                      label={t('emissionDate')}
                      value={unixToString(coupon.emissionDate) || ''}
                      color={"light"}
                      alignCenter
            />
        </Grid>

        <Grid item xs={12} className={globalClasses.marginBottom}>
            <Typography variant={'h6'} align={'center'}>{t('award')}: <b>{coupon.articleTitle}</b> </Typography>
        </Grid>

        {/*--- immagine ---*/}
        <Grid container direction={'row'} justifyContent="center"
              alignItems="center" className={globalClasses.marginBottom}>
            <Grid item xs={12} md={3}>
                <img src={coupon.articleImage || ''} alt="article image" width={'100%'}/>
                <Link href={"/vendor/article/" + coupon.articleId} target="_blank" underline='none'>
                    <HoverableButton
                        item={{
                            icon: <InfoIcon/>,
                            name: t('showAward')
                        }}
                        classes={{root: classes.root}}
                    />
                </Link>
            </Grid>
        </Grid>

    </Grid>
}