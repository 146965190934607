import React from "react";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import { TextItem } from "./TextItem";
import {isStringAndContainsSubstring} from "../services/checkRankingFilter";

/**
 * genera una griglia di informazioni in base all'array dato in input
 * @param {[]} infos array [{field,label,isDate,findValueOptions//opzioni su cui effettuare la ricerca del tipo [{id, name}] }]
 * @param {[]} data
 * @returns 
 */
export default function InfoGrid({ infos, data, color = 'light', sx, children }) {
    return (
        <Grid container sx={sx} alignItems='stretch'>
            <Grid item flexGrow={1} sx={{ height: '100%' }} ></Grid>
            {
                infos.map((i, k) => {
                    if (!i)
                        return <></>;
                    let element = data[i.field];
                    let value = element ?? '-';
                    if (i.isDate)
                        value = dayjs(new Date(element)).format("DD/MM/YYYY");
                    if (i.findValueOptions) {
                        value = (i.findValueOptions.find(option => {
                            // Questa condizione va a coprire la casistica per i custom fields che hanno come id questo pattern -> cf-* dove * indica l'id del customfield nel db ed è presente nel campo filterValue dell'achievement/ranking
                            if (isStringAndContainsSubstring(option.id, "cf-")) {
                                return data.filterValue != null && option.id.split("-")[1] === data.filterValue;
                            } else {
                                return option.id == element;
                            }
                        }) || {name: '-'}).name;
                    }

                    return (
                        <TextItem sx={{ px: 2, height: '100%' }} value={i.formatValue ? i.formatValue(value, data) : value} label={i.label} xs={i.xs || 6} md={i.md || 4} key={k} color={color} />
                    )
                })
            }
            {children}
            <Grid item flexGrow={1}></Grid>
        </Grid>
    )
}