import Grid from "@mui/material/Grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import StartIconButton from "../../../components/buttons/StartIconButton";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import SaveIcon from "@mui/icons-material/Save";
import TextInput, {TextInputContainer} from "../../../components/forms/TextInput";
import RadioAwardType from "../../../components/forms/RadioAwardType";
import FileInput from "../../../components/forms/FileInput";
import FormBuilder from "../../../components/FormBuilder";
import {FORM_TYPE} from "../../../constants/FormBuilder";
import InputAdornment from "@mui/material/InputAdornment";
import {SettingsTooltip} from "../../../components/forms/CustomTooltip";
import dayjs from "dayjs";
import {
    AWARD_POSITIONS,
    AWARDS,
    getErrorMessage,
    ORGANIZATIONS,
    POSITIONS,
    post,
    put,
} from "../../../services/Client";
import {useSnackbar} from "notistack";
import {useQueryClient} from "react-query";
import {Alert} from "@mui/material";
import {formatAddress} from "../../../services/helper";

export default function AwardPositionManager({award, timezone}) {

    const {t} = useTranslation();
    const {id, filter} = useParams();
    let history = useHistory();
    let [values, setValues] = useState({type: 0});
    let [error, setError] = useState({});
    const {enqueueSnackbar} = useSnackbar();
    let queryClient = useQueryClient();

    useEffect(() => {
        if (award) {
            let newValues = {...award};
            newValues.radius *= 1000;
            newValues.sessionMinDistance *= 1000;
            newValues.startDate = dayjs(newValues.startDate).format("YYYY-MM-DD");
            newValues.endDate = dayjs(newValues.endDate).format("YYYY-MM-DD");
            newValues.startTime = dayjs.tz("00:00", "HH:mm", "utc").add(dayjs.duration(newValues.startTime * 1000)).tz(timezone).format("HH:mm")
            newValues.endTime = dayjs.tz("00:00", "HH:mm", "utc").add(dayjs.duration(newValues.endTime * 1000)).tz(timezone).format("HH:mm")
            newValues.addressInput = formatAddress(newValues)

            setValues(newValues)
        }
    }, [award])

    const goBack = () => {
        history.push(`/dashboard/${id}/${filter}`)
    }

    const getSecondOfDayInUTC = (time) => {
        let newTime = dayjs.tz(time, "HH:mm", timezone).utc()
        return (60 * (newTime.minute() + (60 * newTime.hour())))
    }

    const save = () => {

        if (hasErrors()) return;

        let returnValues = {...values}
        returnValues.radius = values.radius / 1000;
        returnValues.sessionMinDistance = values.sessionMinDistance / 1000;
        returnValues.startDate = dayjs.tz(Date.parse(values["startDate"]), timezone).hour(0).utc().unix() * 1000//data di inizio => RIPORTO A MEZZANOTTE => CONVERTO IN UTC => salvo timestamp
        returnValues.endDate = dayjs.tz(Date.parse(values["endDate"]), timezone).hour(23).minute(59).second(59).utc().unix() * 1000 //data di fine => la riporto alle 23:59:59 e converto in UTC => salvo timestamp
        returnValues.startTime = getSecondOfDayInUTC(values["startTime"])
        returnValues.endTime = getSecondOfDayInUTC(values["endTime"])
        returnValues.address = returnValues.addressInput;
        delete returnValues.city;
        delete returnValues.number;

        enqueueSnackbar(t('saving...'), {variant: "info"});
        if (returnValues.id) {
            put(`${AWARDS}/${POSITIONS}/${returnValues.id}`, {body: returnValues})
                .then(() => {
                    enqueueSnackbar(t('saved'), {variant: "success"})
                    goBack()
                })
                .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
                .finally(() => queryClient.invalidateQueries([ORGANIZATIONS, {id: id}, AWARD_POSITIONS]));
        } else {
            returnValues.organization = id;
            post(`${AWARDS}/${POSITIONS}`, {body: returnValues})
                .then(() => {
                    enqueueSnackbar(t('saved'), {variant: "success"})
                    goBack()
                })
                .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
                .finally(() => queryClient.invalidateQueries([ORGANIZATIONS, {id: id}, AWARD_POSITIONS]));
        }
    }

    const hasErrors = () => {

        let toCheckFields = ["name", "startDate", "endDate", "startTime", "endTime", "radius", "sessionMinDistance", "addressInput"]
        if(values.type !== 2) toCheckFields.splice(1, 0, "value");

        let error
        toCheckFields.every(c => {
            if (values[c] === null || values[c] === undefined || values[c] === "") {
                error = {[c]: t('mandatoryField')}
                return false;
            }
            return true;
        })
        if (error) {
            setError(error)
            return true
        }
        if (values.endDate < values.startDate) {
            setError({"endDate": t('endDateLessThanStartDate')})
            return true;
        }
        return false;
    }

    const onTextChange = (value, name) => {
        let newValues = {...values};
        newValues[name] = value;
        if(name === "endTime" && value === "00:00") newValues[name] = "23:59";
        setValues(newValues);
        setError({})
    }

    const autocompleteAddressField = [{
        key: 'addressInput',
        label: `${t('address')}*`,
        xs: 12, md: 12,
        noGlobalClasses: true,
        radius: values.radius,
        type: FORM_TYPE.addressAutocompleteWithMap
    }]

    return <Grid>
        <Grid container justifyContent="space-between">
            <Grid item>
                <StartIconButton
                    title={t('goBack')} startIcon={<ArrowBackIcon/>}
                    onClick={goBack}/>
            </Grid>
            <Grid item>
                <StartIconButton title={t('save')} onClick={save} startIcon={<SaveIcon/>}/>
            </Grid>
        </Grid>
        {award && award.startDate < new Date() &&
            <Grid item xs={12} sx={{marginBottom: "0.5rem", padding: "16px"}}>
                <Alert variant="outlined" severity="warning">{t('warningMessageAwardPositionEdit')}</Alert>
            </Grid>
        }
        <Grid container direction="row">
            <Grid item xs={12} md={6}>
                <Grid container justifyContent="center">
                    <Grid sx={{width: "13rem"}}>
                        <FileInput folder={"custom/awards"} prefix={id} label={t('dragNewLogo')}
                                   onRequestSave={(e) => setValues({...values, "imageUrl": e})}
                        />
                    </Grid>
                </Grid>
                <TextInputContainer xs={12} label={t('name')} value={values.name}
                                    type="string" required color={"dark"}
                                    InputLabelProps={values.name && {shrink: true}}
                                    onTextChange={(value) => {
                                        onTextChange(value, "name")
                                    }}
                                    error={error.name}/>

                <TextInputContainer xs={12} label={t('description')} value={values.description}
                                    type="string" multiline color={"dark"}
                                    InputLabelProps={values.description && {shrink: true}}
                                    onTextChange={(value) => {
                                        onTextChange(value, "description")
                                    }}/>

                <Grid item xs={12} style={{padding: "16px"}}>
                    <RadioAwardType value={values.type} label={t('valueType')} color="dark"
                                    onChange={(value) => {
                                        onTextChange(value, "type")
                                    }}/>
                </Grid>
                {
                    (values.type === 0 || values.type === 1 || values.type === 3 || values.type === 5) &&

                    <TextInputContainer xs={12} required label={values.type === 1 ? t('pointsValue') : t('euroValue')}
                                        value={values.value}
                                        type="number" color={"dark"} InputLabelProps={values.value && {shrink: true}}
                                        min={1} step={1} error={error.value}
                                        onTextChange={(value) => {
                                            onTextChange(value, "value")
                                        }}/>

                }
                <Grid item xs={12} style={{padding: "16px"}}>
                    <SettingsTooltip title={t('expirationDaysTooltip')}>
                        <div>
                            <TextInput label={t('expirationDays')} value={values.expirationDays}
                                       type="number" min={0} step={1} color={"dark"}
                                       InputLabelProps={values.expirationDays && {shrink: true}}
                                       onTextChange={(value) => {
                                           onTextChange(value, "expirationDays")
                                       }}/>
                        </div>
                    </SettingsTooltip>
                </Grid>
                <Grid item xs={12} style={{padding: "16px"}}>
                    <SettingsTooltip title={t('winningsAllowedTooltip')}>
                        <div>
                            <TextInput label={t('winningsAllowed')} value={values.winningsAllowed}
                                       type="number" min={0} step={1} color={"dark"}
                                       InputLabelProps={values.winningsAllowed && {shrink: true}}
                                       onTextChange={(value) => {
                                           onTextChange(value, "winningsAllowed")
                                       }}/>
                        </div>
                    </SettingsTooltip>
                </Grid>

            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container>
                    <TextInputContainer xs={6} label={t('startDate')} value={values.startDate} required
                                        type="date" color={"dark"} InputLabelProps={{shrink: true}}
                                        onTextChange={(value) => {
                                            onTextChange(value, "startDate")
                                        }}
                                        error={error.startDate}/>
                    <TextInputContainer xs={6} label={t('endDate')} value={values.endDate} required
                                        type="date" color={"dark"} InputLabelProps={{shrink: true}}
                                        onTextChange={(value) => {
                                            onTextChange(value, "endDate")
                                        }}
                                        error={error.endDate}/>
                </Grid>
                <Grid container>
                    <Grid xs={12} style={{paddingLeft: "16px", paddingTop: "8px"}}>
                        {`${t('consideredTimezone')}: ${timezone}`}
                    </Grid>
                    <TextInputContainer xs={6} label={t('timeSlotStart')} value={values.startTime} required
                                        type="time" color={"dark"} InputLabelProps={{shrink: true}}
                                        onTextChange={(value) => {
                                            onTextChange(value, "startTime")
                                        }}
                                        error={error.startTime}/>
                    <TextInputContainer xs={6} label={t('timeSlotEnd')} value={values.endTime} required
                                        type="time" color={"dark"} InputLabelProps={{shrink: true}}
                                        onTextChange={(value) => {
                                            onTextChange(value, "endTime")
                                        }}
                                        error={error.endTime}/>
                </Grid>
                <TextInputContainer xs={12} required label={t('toleranceRadius')} value={values.radius}
                                    type="number" color={"dark"}
                                    min={1} step={1}
                                    startAdornment={<InputAdornment position="start">m</InputAdornment>}
                                    onTextChange={(value) => {
                                        onTextChange(value, "radius")
                                    }}
                                    error={error.radius}/>
                <TextInputContainer xs={12} required label={t('sessionMinDistance')} value={values.sessionMinDistance}
                                    type="number" color={"dark"}
                                    min={1} step={1}
                                    startAdornment={<InputAdornment position="start">m</InputAdornment>}
                                    onTextChange={(value) => {
                                        onTextChange(value, "sessionMinDistance")
                                    }}
                                    error={error.sessionMinDistance}/>

                <Grid item xs={12} style={{padding: "16px"}}>
                    <FormBuilder fields={autocompleteAddressField} formData={values} setFormData={setValues}
                                 formError={error}/>
                </Grid>

            </Grid>
        </Grid>

    </Grid>
}
