import { useGetAchievement } from "../../services/ContentManager";
import makeStyles from '@mui/styles/makeStyles';
import { CircularProgress, Typography } from "@mui/material";
import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dayjs from "dayjs";
import NTMXGrid from "../../components/NTMXGrid";
import { TextItem } from "../../components/TextItem";
import RenderUserRedirect from "../../components/cellRender/RenderUserRedirect";
import { useTranslation } from "react-i18next";
import RenderBoolean from "../../components/cellRender/RenderBoolean";
import useGetUsefulValues from "../../constants/rankingUsefulValues";
import useGetRankingFilter from "../../constants/rankingFilter";
import { TextInputContainer } from "../../components/forms/TextInput";
import MenuItem from "@mui/material/MenuItem";
import GenderSelect from "../../components/GenderSelect";
import useGetSessionType from "../../constants/sessionType";
import {useAwardType} from "../../constants/awardType";
import { genderCode } from "../../constants/genderCode";
import InfoGrid from "../../components/InfoGrid";
import { StyledSvgIcon } from "../../components/modals/CreateAchievementsModal";
import * as mui from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
    backButton: {
        color: theme.palette.primary.main,
    },
    backButtonIcon: {
        margin: theme.spacing(1)
    },
    title: {
        padding: theme.spacing(1),
        fontWeight: "bold",
        color: theme.palette.primary.main,
        fontSize: 35,
        textAlign: "center"
    }
}));

export default function AchievementInfo({ goBack, achievementId, target, national, customField }) {

    const { t } = useTranslation();
    let classes = useStyles();
    const { achievement = {}, status } = useGetAchievement(achievementId);
    const usefulValues = useGetUsefulValues(national);
    const achievementsFilter = useGetRankingFilter(customField);
    const sessionType = useGetSessionType();
    const typeLabels = useAwardType()

    const format = (row, field, timezone) => {
        if (!row) return;
        let value = row[field], template = "DD/MM/YYYY HH:mm:ss";
        if (!value) return "";
        else if (!timezone) return dayjs(new Date(value)).format(template);
        else return dayjs.tz(new Date(value), timezone).format(template) + " (" + timezone + ")";
    }

    const defaultColumns = [
        {
            headerName: t('userID'),
            field: 'user',
            width: 280,
        },
        {
            headerName: t('username'),
            field: 'username',
            width: 200,
            renderCell: (params) => <RenderUserRedirect value={params.value} url={params.row.user} />
        },
        {
            headerName: t('points'),
            field: 'score',
            width: 200,
            renderCell: params => <div>{Math.min(params.value || 0, achievement.target)}</div>
        },
        {
            headerName: t('winner'),
            field: 'fullfilled',
            width: 200,
            renderCell: params => <RenderBoolean params={params} />
        },
        {
            headerName: t('winDate'),
            field: 'timestamp',
            type: 'dateTime',
            width: 260,
            valueGetter: ({ row, field }) => {
                if (row.timezone) return format(row, field, row.timezone)
                else return format(row, field)
            },
        },
    ];
    const infos = [
        {
            isDate: true,
            field: 'startDate',
            label: t('startDate'),
        }, {
            isDate: true,
            field: 'endDate',
            label: t('endDate'),
        }, {
            field: 'duration',
            label: t('daysDuration'),
        }, {
            findValueOptions: usefulValues,
            field: 'value',
            label: t('value'),
        }, {
            field: 'target',
            label: t('target'),
        }, {
            findValueOptions: achievementsFilter,
            field: 'filter',
            label: t('filterCategory'),
        },
        achievement.filter !== null && achievement.filter === 0 && {
            findValueOptions: sessionType,
            field: 'filterValue',
            label: t('valueFilter'),
        },
        achievement.filter !== null && achievement.filter === 1 && {
            field: 'filterValue',
            label: t('valueFilter'),
        },
        // achievement.filter !== null && achievement.filter === 1 && {
        //     field: 'minAge',
        //     label: t('minAge'),
        // },
        // achievement.filter !== null && achievement.filter === 1 && {
        //     field: 'maxAge',
        //     label: t('maxAge'),
        // },
        achievement.filter !== null && achievement.filter === 2 && {
            findValueOptions: Object.values(genderCode).map(c => ({ ...c, name: t(c.value) })),
            field: 'filterValue',
            label: t('gender'),
        }, {
            field: 'awardValue',
            label: t('award'),
        }, {
            findValueOptions: typeLabels,
            field: 'awardType',
            label: t('awardType'),
        }
    ]

    if (status === "loading") return <Grid container><CircularProgress /></Grid>;

    return <Grid container justifyContent="space-between">
        <Grid container justifyContent="space-between">
            <Button onClick={goBack} className={classes.backButton}>
                <ArrowBackIcon className={classes.backButtonIcon} />
                {t('goBack')}
            </Button>
        </Grid>
        {achievement &&
            <>
                <Grid xs={12}>
                    <div className={classes.title}>{achievement.name}</div>
                </Grid>
                <Grid item xs={12}>
                    <InfoGrid infos={infos} data={achievement} />
                </Grid>
                {
                    achievement.icon &&
                    <Grid item xs={12} sx={{mt:2,ml:2}}>
                        <Typography>{t('usernameIcon')}</Typography>
                        <Grid container
                            sx={{
                                '&>svg.MuiSvgIcon-root': (theme) => ({
                                    color: achievement.color,
                                })
                            }}
                        >
                            <StyledSvgIcon
                                component={mui[achievement.icon]}
                                fontSize="large"
                                tabIndex={-1}
                            />
                        </Grid>
                    </Grid>
                }
            </>
        }
        <NTMXGrid
            key={"achievements-tables"}
            columns={defaultColumns}
            rows={achievement.users || []}
            getRowId={(row) => row && row.user}
        />
    </Grid>
}
