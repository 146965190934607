import Button from "@mui/material/Button";
import React from "react";
import { Box } from "@mui/system";
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';

export default function NTMButton({
    startIcon,
    onClick,
    title,
    href,
    target,
    disabled,
    size,
    className,
    selected,
    textVariant = false,
    outlinedVariant = false,
    sx
}) {

    let variant = selected ? 'contained' : "outlined";
    let style = {};
    if (textVariant) {
        variant = 'text';
        startIcon = selected ? <CircleIcon /> : <CircleOutlinedIcon />;
        style = { backgroundColor: selected && textVariant && 'primary.highlight' };
    }
    if (outlinedVariant) {
        variant = "outlined";
    }

    return (<Box sx={{
        margin: 1, '& .Mui-disabled.MuiButton-root.MuiButton-outlined': {
            borderColor: 'transparent'
        },
    }}>
        <Button
            variant={variant}
            color="primary"
            startIcon={startIcon}
            onClick={onClick}
            sx={{ margin: 1, ...style, ...sx }}
            className={className}
            href={href}
            target={target}
            disabled={disabled}
            size={size}
        >
            {title}
        </Button>
    </Box>

    );
}
