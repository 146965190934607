import {useParams} from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import {useGetOrganizationSeats} from "../../services/ContentManager";
import React, {useState} from "react";
import NTMXGrid from "../../components/NTMXGrid";
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmIconButton from "../../components/buttons/ConfirmIconButton";
import {deleteElem, getErrorMessage, ORGANIZATIONS, post, put, SEATS} from "../../services/Client";
import {useSnackbar} from "notistack";
import {useQueryClient} from "react-query";
import Grid from "@mui/material/Grid";
import StartIconButton from "../../components/buttons/StartIconButton";
import AddIcon from "@mui/icons-material/Add";
import CreateSeatModal from "../../components/modals/CreateSeatModal";
import EditableCoordinates from "../../components/cellRender/EditableCoordinates";
import RenderCell from "../../components/cellRender/RenderCell";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    iconButton: {
        color: theme.palette.text.primary
    }
}));

function getCoordinates(params) {
    if (!params.row['latitude'] && !params.row['longitude']) return ""
    return `${params.row['latitude'] || ''} - ${
        params.row['longitude'] || ''
    }`;
}

export default function Seats() {

    const {t} = useTranslation();
    let classes = useStyles();
    let {id} = useParams();
    let {seats, status} = useGetOrganizationSeats(id);
    seats = seats.slice().sort((a, b) => b.id - a.id)
    let [isCreating, setIsCreating] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    let queryClient = useQueryClient();

    const deleteSeat = (sid) => {
        deleteElem(ORGANIZATIONS + "/" + id + "/" + SEATS + "/" + sid)
            .then(() => enqueueSnackbar(t('removed'), {variant: "success"}))
            .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
            .finally(() => queryClient.invalidateQueries([ORGANIZATIONS, {id: id}, SEATS]));
    }

    const createSeat = (values) => {
        enqueueSnackbar(t('saving...'), {variant: "info"});
        post(ORGANIZATIONS + "/" + id + "/" + SEATS, {body: values})
            .then(() => enqueueSnackbar(t('saved'), {variant: "success"}))
            .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
            .finally(() => queryClient.invalidateQueries([ORGANIZATIONS, {id: id}, SEATS]));
    }

    const saveEdit = (sid, newValues) => {
        enqueueSnackbar(t('saving...'), {variant: "info"});
        put(ORGANIZATIONS + "/" + id + "/" + SEATS + "/" + sid, {body: newValues})
            .then(() => enqueueSnackbar(t('saved'), {variant: "success"}))
            .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
            .finally(() => queryClient.invalidateQueries([ORGANIZATIONS, {id: id}, SEATS]));
    }

    const defaultColumns = [
        {
            headerName: t('name'),
            field: 'name',
            width: 220,
            renderCell: (params) => <RenderCell params={params}
                                                saveEdit={(sid, field, value) => saveEdit(sid, {[field]: value})}
                                                required/>
        },
        {
            headerName: t('address'),
            field: 'address',
            width: 220,
        },
        {
            headerName: t('houseNumber'),
            field: 'number',
            width: 220,
        },
        {
            headerName: t('city'),
            field: 'cityName',
            width: 220,
        },
        {
            headerName: t('coordinates'),
            field: 'coordinates',
            width: 300,
            renderCell: (params) => <EditableCoordinates latitude={params.row.latitude ? params.row.latitude : ""}
                                                         longitude={params.row.longitude ? params.row.longitude : ""}
                                                         onChange={(lat, lng) => saveEdit(params.id, {
                                                             latitude: lat,
                                                             longitude: lng
                                                         })}/>,
        },
        {
            headerName: t('tolerance'),
            field: 'destinationTolerance',
            width: 220,
            valueGetter: ({value}) => value ? value * 1000 : "",
            renderCell: (params) => {
                return <RenderCell params={params} required type="number" min={0} step={0.01} measureUnit="m"
                                   saveEdit={(sid, field, value) => saveEdit(sid, {[field]: value / 1000})}/>
            }
        },
        {
            headerName: ' ',
            field: 'delete',
            width: 67,
            renderCell: (params => {
                return <ConfirmIconButton
                    onConfirm={() => deleteSeat(params.row['id'])}
                    title={t('confirmDeletion')}
                    text={t('requestDefinitiveEliminationSeat') + " \"" + params.row['name'] + "\""}
                >
                    <DeleteIcon className={classes.iconButton}/>
                </ConfirmIconButton>
            }),
        }
    ];

    return <div>
        <NTMXGrid
            key={"manager-seats-table"}
            columns={defaultColumns}
            rows={seats || []}
            title={t('seats')}
            loading={status === "loading"}
            rightButton={
                <Grid container justifyContent={"flex-end"}>
                    <StartIconButton onClick={() => setIsCreating(true)} title={t('addSeat')} startIcon={<AddIcon/>}/>
                </Grid>}
        />
        <CreateSeatModal open={!!isCreating} onClose={() => setIsCreating(false)} onSubmit={createSeat}/>
    </div>
}
