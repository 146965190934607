import React,{useEffect,useState} from "react";
import BaseModal from "./BaseModal";
import Grid from "@mui/material/Grid";
import {TextInputContainer} from "../forms/TextInput";
import FileInput from "../forms/FileInput";
import {useParams} from "react-router-dom";
import {Alert} from "@mui/material";
import {useTranslation} from "react-i18next";
import RadioAwardType from "../forms/RadioAwardType";

export default function AchievementAwardModal({open, onClose, onSubmit, defaultValues = {}, warningMessage}){

    const {t} = useTranslation();
    let {id} = useParams();
    let [values, setValues] = useState({type: 0});
    let [error, setError] = useState({});

    useEffect(() => {
        let newValues = {...defaultValues};
        if(newValues.type === null || newValues.type === undefined) newValues["type"] = 0;
        setValues(newValues);
    }, [open])

    const close = () => {
        setValues({type: 0})
        setError({})
        onClose();
    }

    const save = () => {
        if(!values.name) {
            setError({name: t('mandatoryField')});
            return;
        }
        if(values.type === 0 || values.type === 1 || values.type === 3)
        {
            if(!values.value || values.value < 0){
                setError({value: t('invalidValue')});
                return;
            }
        }
        
        onSubmit(values);
        close()
    }

    const onTextChange = (value, name) => {
        let newValues = {...values};
        newValues[name] = value;
        setError({});
        setValues(newValues);
    }

    return (
        <BaseModal open={open} onClose={close} onSave={save}>
            <Grid container spacing={4} style={{margin: 0, width: "100%", marginBottom: "1rem"}}>

                {warningMessage &&
                    <Grid item xs={12} style={{marginBottom : "0.5rem", padding: "16px"}}>
                        <Alert variant="outlined" severity="warning">{warningMessage}</Alert>
                    </Grid>
                }

                <TextInputContainer xs={12} label={t('name')} value={values.name}
                           type="string" required
                           onTextChange={(value) => {onTextChange(value, "name")}}
                           error={error.name}/>

                <Grid item xs={12} style={{padding: "16px"}}>
                    <RadioAwardType value={values.type} onChange={(value) => {onTextChange(value, "type")}} label={t('valueType')}/>
                </Grid>
                {
                    (values.type === 0 || values.type === 1 || values.type === 3 || values.type === 5) &&
                    <TextInputContainer xs={12} required label={values.type === 1 ? t('pointsValue') : t('euroValue')}
                                        value={values.value}
                                        type="number"
                                        min={1} step={1}
                                        onTextChange={(value) => {
                                            onTextChange(value,"value")
                                        }}
                                        error={error.value}/>
                }

                <TextInputContainer xs={12} label={t('description')} value={values.description}
                           type="string" multiline
                           onTextChange={(value) => {onTextChange(value, "description")}}
                           error={error.description}/>

                <Grid item xs={12} style={{padding: "16px"}}>
                    <FileInput folder={"organizations/awards/achievements"} prefix={id} onRequestSave={(e) => setValues({ ...values, "imageUrl": e })} label={t('dragImage')}/>
                </Grid>
            </Grid>
        </BaseModal>
    );

}