import { useTranslation } from "react-i18next";
import NTMXGrid, { numberFormatter, secondsToDuration, timestampFormatter, timestampFormatterWithTimezone } from "../../components/NTMXGrid";
import RenderCell from "../../components/cellRender/RenderCell";
import useGetSessionType from "../../constants/sessionType";
import RenderBoolean from "../../components/cellRender/RenderBoolean";
import { useContext } from "react";
import { resources } from "../../services/ability";
import { AbilityContext } from "../../services/Can";
import { LEG_COLOR, useGetLegTypeTranslation } from "../../constants/legType";
import ColoredLabel from "../../components/ColoredLabel";
import { useGetSessionStatusTranslation } from "../../constants/SessionStatus";

export function LegTable({ session, loading }) {
    const sessionType = useGetSessionType();
    const { t } = useTranslation();
    const ability = useContext(AbilityContext)
    const canReadPrivateData = ability.can("read", resources.PRIVATEUSERDATA)
    const legTypeTranslation = useGetLegTypeTranslation();
    const sessionStatusTranslation = useGetSessionStatusTranslation();

    const defaultColumns = [
        {
            headerName: t('legType'),
            field: 'type',
            width: 280,
            valueGetter: ({ value, row }) => legTypeTranslation[value],
            renderCell: ({ value, row }) => {
                return <>
                    <ColoredLabel
                        color={LEG_COLOR[row.type]}
                        label={value}
                    ></ColoredLabel>
                </>
            },
        },
        {
            headerName: t('status'),
            field: 'status',
            valueGetter: ({ value, row }) => {
                return sessionStatusTranslation[value]
            }
        },
        canReadPrivateData && {
            headerName: t('valid'),
            field: 'valid',
            width: 120,
            type: "boolean",
            renderCell: (params) => <RenderBoolean params={params} />
        },
        canReadPrivateData && {
            headerName: t('certificated'),
            field: 'certificated',
            width: 120,
            type: "boolean",
            renderCell: (params) => <RenderBoolean params={params} />
        },
        {
            headerName: t('startDate'),
            field: 'startTime_Date',
            width: 180,
            ...timestampFormatter
        },
        {
            headerName: t('startTime'),
            field: 'startTime_Time',
            width: 280,
            ...timestampFormatterWithTimezone
        },
        {
            headerName: t('endDate'),
            field: 'endTime_Date',
            width: 180,
            ...timestampFormatter
        },
        {
            headerName: t('endTime'),
            field: 'endTime_Time',
            width: 280,
            ...timestampFormatterWithTimezone,
        },
        {
            headerName: t('duration'),
            field: 'duration',
            width: 180,
            ...secondsToDuration
        },
        canReadPrivateData && {
            headerName: t('gyroDistance'),
            field: 'gyroDistance',
            width: 200,
            renderCell: (params) => <RenderCell params={params} measureUnit="km" type="number" />,
            ...numberFormatter
        },
        canReadPrivateData && {
            headerName: t('gpsDistance'),
            field: 'gpsDistance',
            width: 200,
            renderCell: (params) => <RenderCell params={params} measureUnit="km" type="number" />,
            ...numberFormatter
        },
        canReadPrivateData && {
            headerName: t('googleMapsDistance'),
            field: 'gmapsDistance',
            width: 200,
            renderCell: (params) => <RenderCell params={params} measureUnit="km" type="number" />,
            ...numberFormatter
        }, {
            headerName: t('nationalKm'),
            field: 'nationalKm',
            width: 280,
            renderCell: (params) => <RenderCell params={params} measureUnit="km" type="number" />,
            ...numberFormatter
        }, {
            headerName: t('nationalPoints'),
            field: 'nationalPoints',
            width: 280,
            ...numberFormatter
        }, {
            headerName: t('totalKm'),
            field: 'totalKm',
            width: 280,
            renderCell: (params) => <RenderCell params={params} measureUnit="km" type="number" />,
            ...numberFormatter
        }, {
            headerName: t('euro'),
            field: 'euro',
            width: 280,
            renderCell: (params) => <RenderCell params={params} measureUnit={t('currency')} type="number" />,
            ...numberFormatter
        },
        {
            headerName: t('co2'),
            field: 'co2',
            width: 120,
            //hide: true,
            renderCell: (params) => <RenderCell params={params} measureUnit="g" type="number" />,
            ...numberFormatter
        },
        canReadPrivateData && {
            headerName: t('wheelDiameter'),
            field: 'wheelDiameter',
            width: 280,
            ...numberFormatter
            //hide: true,
            //renderCell: (params) => <RenderCell params = {params} saveEdit = {saveEditSession} type="number" decimals={2}/>
        },
        canReadPrivateData && {
            headerName: t('description'),
            field: 'description',
            width: 280,
            //hide: true,
            renderCell: (params) => <RenderCell params={params} />
        }, canReadPrivateData && {
            headerName: t('sensorStartBattery'),
            field: 'startBattery',
            width: 280,
            renderCell: (params) => params.value === 0 || params.value ? params.value + "%" : "",
            ...numberFormatter
            //hide: true
        },
        canReadPrivateData && {
            headerName: t('sensorEndBattery'),
            field: 'endBattery',
            width: 280,
            renderCell: (params) => params.value === 0 || params.value ? params.value + "%" : "",
            ...numberFormatter
            //hide: true
        },
        canReadPrivateData && {
            headerName: t('phoneStartBattery'),
            field: 'phoneStartBattery',
            width: 280,
            renderCell: (params) => params.value === 0 || params.value ? params.value + "%" : "",
            ...numberFormatter
            //hide: true
        },
        canReadPrivateData && {
            headerName: t('phoneEndBattery'),
            field: 'phoneEndBattery',
            width: 280,
            renderCell: (params) => params.value === 0 || params.value ? params.value + "%" : "",
            ...numberFormatter
            //hide: true
        },
        canReadPrivateData && {
            headerName: t('sensor'),
            field: "sensor",
            width: 250,
            //hide: true
        },
        canReadPrivateData && {
            headerName: t('sensorName'),
            field: "sensorName",
            width: 250,
            //hide: true
        },
        canReadPrivateData && {
            headerName: t('firmware'),
            field: "firmware",
            width: 250,
            //hide: true
        },
    ]

    return (
        <>
            {session && session.legs &&
                <NTMXGrid
                    key={"session-leg-table"}
                    columns={defaultColumns}
                    rows={session.legs}
                    title={t('legs')}
                    loading={loading}
                    getRowId={(row) => row.id}
                />

            }
        </>
    )
}