import Grid from "@mui/material/Grid";
import NTMXGrid from "../components/NTMXGrid";
import React,{useContext,useState} from "react";
import StartIconButton from "../components/buttons/StartIconButton";
import CreateIcon from '@mui/icons-material/Create';
import {AWARDS,getErrorMessage,post,put} from "../services/Client";
import {useSnackbar} from "notistack";
import {useQueryClient} from "react-query";
import {useGetCustomAwards,useGetOrganizations} from "../services/ContentManager";
import CustomAwardModal from "../components/modals/CustomAwardModal";
import {useTranslation} from "react-i18next";
import {useAwardTypeNames} from "../constants/awardType";
import EditIcon from "@mui/icons-material/Edit";
import {IconButton} from "@mui/material";
import {AbilityContext} from "../services/Can";
import {resources} from "../services/ability";

export default function CustomAwards() {

    const {t} = useTranslation();
    let [isEditing, setIsEditing] = useState(false);
    let [editAward, setEditAward] = useState({});
    let {customAwards, status} = useGetCustomAwards()
    const typeLabels = useAwardTypeNames()
    const ability = useContext(AbilityContext);
    let { organizations = [] } = useGetOrganizations(); //tutte le organizzazioni a cui puo' accedere l'utente loggato

    const {enqueueSnackbar} = useSnackbar();
    let queryClient = useQueryClient();
    customAwards = customAwards.slice().filter(c => c.issuer !== 2).sort((a, b) => b.id - a.id); //rimuovo quelli creati dal sistema

    const defaultColumns = [
        {
            headerName: t('id'),
            field: 'id',
            width: 200,
        },
        {
            headerName: t('name'),
            field: 'name',
            width: 200,
        },
        {
            headerName: t('description'),
            field: 'description',
            width: 200
        },
        {
            headerName: t('value'),
            field: 'value',
            width: 200
        },
        {
            headerName: t('type'),
            field: 'type',
            width: 200,
            valueGetter: (params) => params.value || params.value === 0 ? typeLabels[params.value] : ""
        },
        {
            headerName: t('winningsAllowed'),
            field: 'winningsAllowed',
            width: 300,
            renderCell: (params) => params.value || "∞"
        },
        {
            headerName: ' ',
            field: 'editDelete',
            width: 60,
            sortable: false,
            disableColumnMenu: true,
            resizable: false,
            disableExport: true,
            hideable:false,
            renderCell: (params) => {
                if(params.row.endDate < new Date()) return;
                return <Grid container direction={"row"}>
                    <IconButton aria-label="edit"
                                onClick={() => {
                                    setEditAward(params.row)
                                    setIsEditing(true)
                                }}
                    >
                        <EditIcon/>
                    </IconButton>
                </Grid>
            },
        }
    ];

    const edit = (values) => {
        if(values.organization === "all") delete values.organization
        if(values.id) {
            enqueueSnackbar(t('saving...'), {variant: "info"});
            put(`${AWARDS}/customs/${values.id}`, {body: values})
                .then(() => enqueueSnackbar(t('saved'), {variant: "success"}))
                .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
                .finally(() => queryClient.invalidateQueries("AwardsCustom"));
        } else {
            enqueueSnackbar(t('saving...'), {variant: "info"});
            post(`${AWARDS}/customs`, {body: values})
                .then(() => enqueueSnackbar(t('saved'), {variant: "success"}))
                .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
                .finally(() => queryClient.invalidateQueries("AwardsCustom"));
        }
    }

    return <Grid>
        <NTMXGrid
            key={"custom-awards-table"}
            columns={defaultColumns}
            rows={customAwards || []}
            title={t('customAwards')}
            loading={status === "loading"}
            rightButton={<Grid container justifyContent={"flex-end"}>
                <StartIconButton onClick={() => setIsEditing(true)} title={t('createAward')}
                                 startIcon={<CreateIcon/>}/>
            </Grid>}
            initialState={{ pinnedColumns: { right: ['editDelete'] } }}
        />
        <CustomAwardModal open={!!isEditing}
                          onClose={() => {
                              setIsEditing(false)
                              setEditAward({})
                          }}
                          onSubmit={edit} defaultValues={editAward}
                          warningMessage={Object.keys(editAward).length !== 0 && t('editCustomAwardWarningMessage')}
                          organizations={
                                ability.can("write", resources.ALL_ORG_CUSTOM_AWARD) ?
                                    [{id: "all", title: t('all')}, ...organizations] :
                                    organizations
                          }
        />
    </Grid>

}
