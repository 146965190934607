import {DEFAULT_FIELD, FORM_TYPE, VALIDATION_EXCLUDED_TYPES} from "../constants/FormBuilder";

/**
 * genera un vettore di elementi da passare alla funzione di validazione in bae ai campi passati in input
 * @param fields
 * @param validations
 */
export function generateValidations(fields, validations) {
    fields.forEach(field => {
        if (!field) {
            return;
        }
        let newField = {...DEFAULT_FIELD, ...field};
        //deve essere effettuata la validazione se no è un componente custom e se o è richiesto o in caso contrario se ha una validazione
        if (toValidateField(newField)) {
            validations.push(
                {
                    field: newField.key,
                    validation: newField.validation,
                    customErrorMessage: newField.customErrorMessage
                }
            );
        }
        if (newField.customFields) {
            generateValidations(newField.customFields, validations);
        }
    });
}

/**
 * identifica se un campo deve essere validato o meno
 * @param field
 */
export function toValidateField(field) {
    if (field.hidden) {//un campo nascosto non viene validato
        return false;
    }
    if (field.validation)//se un campo ha un valore di validazione esso deve essere eseguito forzatamente
        return true;
    if (VALIDATION_EXCLUDED_TYPES.includes(field.type))//alcuni campi sono esclusi di default
    {
        return false;
    } else if (!field.required) { // se non è obbligatorio e non ha una validazione allora non deve essere controllato
        return false;
    }
    return true;
}

/**
 * seleziona la porzione di address
 */
export function getAddressComponents(place) {
    return (component) => (place.address_components.find(a => a.types.find(t => t === component)) || {}).long_name
}