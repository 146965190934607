import React, {useEffect, useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import List from "@mui/material/List";
import HoverableListItem from "./HoverableListItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import clsx from "clsx";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import {useHistory} from "react-router-dom";
import {Box} from "@mui/material";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        zIndex: 1,
        backgroundColor: theme.palette.primary.dark,
        paddingLeft: 4,
        overflowX: 'hidden',
        height: '100%',
        [theme.breakpoints.up('md')]: {
            flexShrink: 0,
            whiteSpace: 'nowrap',
        }
    },
    list: {
        backgroundColor: theme.palette.primary.dark,
        overflowX: 'hidden',
        "&::-webkit-scrollbar": {
            width: 5,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "10px"
        },
    },
    drawerOpen: {
        height: '100%',
        overflowX: 'hidden',
        [theme.breakpoints.up('md')]: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            width: drawerWidth
        }
    },
    drawerClose: {
        height: '100%',
        overflowX: 'hidden',
        [theme.breakpoints.up('md')]: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(8),
        }
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    appVersion: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        textAlign: 'center',
        color: theme.palette.primary.light
    }
}));

export default function Sidebar({open, setOpen, items}) {
    const theme = useTheme();
    const classes = useStyles();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const history = useHistory();
    const [currentPath, setCurrentPath] = useState(history.location.pathname);

    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        history.listen((location) => setCurrentPath(location.pathname));
    }, [])

    return (
        <Drawer
            variant={isDesktop ? "permanent" : "temporary"}
            anchor={"top"}
            open={open}
            onClose={handleDrawerClose}
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
            sx={{
                "& .MuiPaper-root": {
                    backgroundColor: "unset",
                    borderBottom: 0
                }
            }}
        >
            <div className={classes.toolbar}>
                <IconButton onClick={handleDrawerClose} size="large">

                </IconButton>
            </div>
            <List className={clsx(classes.list, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}>
                {items.map(i => <HoverableListItem item={i} key={i.url} open={open} setOpen={setOpen}
                                                  selected={currentPath.toLowerCase().indexOf(i.url.toLowerCase().slice(0, -2)) === 0}/>
                )
                }
            </List>
            {
                open && <Box className={classes.appVersion}>{`Pin Bike frontend v${process.env.REACT_APP_VERSION}`}</Box>
            }

        </Drawer>
    );
}
