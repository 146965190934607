import {useGetOrganizationUsers} from "../../services/UserDataManager";
import {useParams} from "react-router-dom";
import UserTable from "../../components/tables/UserTable";
import React, {useContext, useEffect, useState} from "react";
import {useGetOrganizationCodes, useGetOrganizationSettings} from "../../services/ContentManager";
import {useTranslation} from "react-i18next";
import {getErrorMessage, put, USERS} from "../../services/Client";
import {useSnackbar} from "notistack";
import {useQueryClient} from "react-query";
import RenderCell from "../../components/cellRender/RenderCell";
import {numberFormatter} from "../../components/NTMXGrid";
import {UserContext} from "../App";
import {roles} from "../../services/ability";

export function OrganizationUsers({org}) {

    const userData = useContext(UserContext);
    let {id} = useParams();
    const {t} = useTranslation();
    let {codes, status: codesStatus} = useGetOrganizationCodes(id || org);
    let {users = [], status: userStatus} = useGetOrganizationUsers(id || org, userData.userType !== roles.ADMIN);
    let {settings} = useGetOrganizationSettings(id || org)
    const {enqueueSnackbar} = useSnackbar();
    let queryClient = useQueryClient();

    let [showSerialNumber, setShowSerialNumber] = useState(false)
    let [showIban, setShowIban] = useState(false)
    let [usersToDisplay, setUsersToDisplay] = useState([])

    useEffect(() => {
        if(users?.length && codes?.length) {
            let newUsers = []
            codes.forEach(c => {
                let u = users.find(u => u.uid === c.uid)
                u && newUsers.push({
                    activationDate: c.activationDate,
                    timezone: c.timezone,
                    endDate: c.endDate,
                    ...u
                })
            })
            setUsersToDisplay(newUsers)
        }
    }, [users, codes]);

    const customColumns = [
        {
            headerName: t('initiativeKm'),
            field: 'urbanKm',
            width: 340,
            renderCell: (params) => <RenderCell params={params} measureUnit="km" type="number"/>,
            ...numberFormatter
        },
        {
            headerName: t('points'),
            field: 'points',
            width: 340,
            renderCell: (params) => <RenderCell params={params} type="number"/>,
            ...numberFormatter
        },
        {
            headerName: t('sessions'),
            field: 'nSessions',
            width: 340,
            renderCell: (params) => <RenderCell params={params} type="number"/>,
            ...numberFormatter
        },
        {
            headerName: t('nationalKm'),
            field: 'nationalKm',
            width: 340,
            renderCell: (params) => <RenderCell params={params} measureUnit="km" type="number"/>,
            ...numberFormatter
        },
        {
            headerName: t('numberSessionHomeWork'),
            field: 'homeWorkSessions',
            width: 340,
            renderCell: (params) => <RenderCell params={params} type="number"/>,
            ...numberFormatter
        },
        {
            headerName: t('kmBikeForHomeWork'),
            field: 'homeWorkKm',
            width: 340,
            renderCell: (params) => <RenderCell params={params} measureUnit="km" type="number"/>,
            ...numberFormatter
        },
        {
            headerName: t('euro'),
            field: 'euro',
            width: 340,
            renderCell: (params) => <RenderCell params={params} measureUnit={t('currency')} type="number"/>,
            ...numberFormatter
        },
    ]

    useEffect(() => {
        if (settings.length !== 0) {
            if (settings.find(s => s.organizationSetting === "serialNumberRequirement")?.value === "true")
                setShowSerialNumber(true)
            if (settings.find(s => s.organizationSetting === "ibanRequirement")?.value === "true")
                setShowIban(true)
        }
    }, [settings])

    const saveEdit = (uid, field, value) => {
        enqueueSnackbar(t('saving...'), {variant: "info"});
        put(`${USERS}/${uid}`, {body: {[field]: value}})
            .then(() => {
                enqueueSnackbar(t('saved'), {variant: "success"});
            })
            .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
            .finally(() => queryClient.invalidateQueries([USERS, {oid: id || org}]));
    }

    return <UserTable
        users={usersToDisplay}
        status={(userStatus === "loading" || codesStatus === "loading") && "loading"}
        saveEdit={saveEdit} showIban={showIban}
        showSerialNumber={showSerialNumber} customColumns={customColumns}
        showOrganizationName={false} title={t('usersWithActiveCodes')}
        showActivationDate={true}
    />
}
