import { useParams } from "react-router-dom";
import { useGetSessionsByOrganizationAndFilter } from "../services/ContentManager";
import { SESSIONS } from "../services/Client";
import React, { useState } from "react";
import SessionApiTable from "components/tables/SessionApiTable";

export function SessionManagement() {

    let { id } = useParams();

    let [inputFilters, setInputFilters] = useState({
        "filters": [],
        "pagination": {
            "page": 0,
            "pageSize": 25
        },
        "sort": {
            "field": "startTime",
            "sort": "desc"
        }
    });
    var inputFiltersEncripted = btoa(JSON.stringify(inputFilters));
    const { sessions = {}, status } = useGetSessionsByOrganizationAndFilter(id, inputFiltersEncripted);

    const handleFilterModelChange = React.useCallback((filterModel) => {
        let filters = filterModel.items
            .map(f => {
                return {
                    "filter": f.columnField,
                    "operation": f.operatorValue,
                    "value": !f.value ? [] :
                        Array.isArray(f.value) ? f.value :
                            typeof f.value == "boolean" ? [f.value ? "true" : "false"] :
                                [f.value]
                }
            });
        setInputFilters(existingValues => ({
            // Retain the existing values
            ...existingValues,
            // update the current field
            ["filters"]: filters
        }))
    }, []);
    const handlePageChange = React.useCallback((newPage) => {
        setInputFilters(existingValues => ({
            ...existingValues,
            ["pagination"]: {
                "page": newPage,
                "pageSize": existingValues.pagination.pageSize
            }
        }));
    }, []);
    const handlePageSizeChange = React.useCallback((newPageSize) => {
        setInputFilters(existingValues => ({
            ...existingValues,
            ["pagination"]: {
                "page": 0,
                "pageSize": newPageSize
            }
        }))
    }, []);
    const handleSortModelChange = React.useCallback((newSortModel) => {
        if (newSortModel.length > 0)
            setInputFilters(existingValues => ({
                ...existingValues,
                ["sort"]: newSortModel[0]
            }))
    }, []);

    return <SessionApiTable
        sessions={sessions.content || []}
        loading={status !== 'success'}
        queryKey={[SESSIONS, { id, inputFiltersEncripted }]}
        onFilterModelChange={handleFilterModelChange}
        filterMode="server"
        onSortModelChange={handleSortModelChange}
        sortingMode="server"
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        paginationMode="server"
        rowCount={sessions.totalElements || 0} />

}
