import {useTranslation} from "react-i18next";
import React,{useState} from "react";
import BaseModal from "./BaseModal";
import Grid from "@mui/material/Grid";
import {Alert} from "@mui/material";
import {TextInputContainer} from "../forms/TextInput";

export default function EditAgeIntervalModal({open, onClose, onSubmit, infoMessage, warningMessage}) {

    const {t} = useTranslation();
    let [ageMin, setAgeMin] = useState();
    let [ageMax, setAgeMax] = useState();
    let [error, setError] = useState({});

    function submit() {
        if(!ageMin) {
            setError({ageMin: t('mandatoryField')})
            return
        }
        if(!ageMax) {
            setError({ageMax: t('mandatoryField')})
            return
        }
        if(ageMin > ageMax){
            setError({ageMax: t('invalidValue')})
            return
        }
        onSubmit(ageMin + " - " + ageMax);
        setError({});
        setAgeMin(null)
        setAgeMax(null)
        onClose();
    }

    return <BaseModal open={open} onClose={onClose} onSave={submit}>
        {infoMessage &&
            <Grid item xs={12} style={{marginBottom: "0.5rem", padding: "16px"}}>
                <Alert variant="outlined" severity="info">{infoMessage}</Alert>
            </Grid>
        }
        {warningMessage &&
            <Grid item xs={12} style={{marginBottom : "0.5rem", padding: "16px"}}>
                <Alert variant="outlined" severity="warning">{warningMessage}</Alert>
            </Grid>
        }
        <Grid container spacing={4} style={{margin: 0, width: "100%", marginBottom: "1rem"}}>

            <TextInputContainer xs={12} label={t('minAge')} value={ageMin} onTextChange={(value) => setAgeMin(value)}
                                error={error.ageMin} type={"number"} required min={0} max={100} step={1}/>
            <TextInputContainer xs={12} label={t('maxAge')} value={ageMax} onTextChange={(value) => setAgeMax(value)}
                                error={error.ageMax} type={"number"} required min={0} max={100} step={1}/>

            <div style={{width: "15rem"}}/>
        </Grid>
    </BaseModal>
}