import React, {useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import StartIconButton from "./StartIconButton";
import {useTranslation} from "react-i18next";

export default function ConfirmButton({buttonProps = {}, title, text, onConfirm, cancelText, confirmText, children}) {

	let [open, setOpen] = useState(false);
	const {t} = useTranslation();
	let handleClose = () => setOpen(false);
	let handleConfirm = () => {
		setOpen(false)
		onConfirm()
	}

	return <div>
		<StartIconButton onClick={() => setOpen(true)} {...buttonProps}>
			{children}
		</StartIconButton>
		<Dialog
			open={open}
			onClose={handleClose}
		>
			<DialogTitle>{title}</DialogTitle>
			{text &&
			<DialogContent>
				<DialogContentText>
					{text}
				</DialogContentText>
			</DialogContent>}
			<DialogActions>
				<Button onClick={handleClose} color="secondary">
					{cancelText || t('cancel')}
				</Button>
				<Button onClick={handleConfirm} color="primary" autoFocus>
					{confirmText || t('confirm')}
				</Button>
			</DialogActions>
		</Dialog>
	</div>;
}
