import Grid from "@mui/material/Grid";
import NTMXGrid, { timestampFormatter } from "../components/NTMXGrid";
import React, { useContext, useState } from "react";
import { Divider } from "@mui/material";
import StartIconButton from "../components/buttons/StartIconButton";
import CreateIcon from '@mui/icons-material/Create';
import CreateRankModal from "../components/modals/CreateRankModal";
import { deleteElem, getErrorMessage, ORGANIZATIONS, post, put, RANKINGS, RANKS } from "../services/Client";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import useGetSessionType from "../constants/sessionType";
import RenderCell from "../components/cellRender/RenderCell";
import dayjs from "dayjs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import RankAward from "./Rankings/RankAward";
import InfoRepetitionRankings from "../components/cellRender/InfoRepetitionRankings";
import RankInfo from "./Rankings/RankInfo";
import IconButton from "@mui/material/IconButton";
import useGetUsefulValues from "../constants/rankingUsefulValues";
import useGetRankingFilter from "../constants/rankingFilter";
import { useTranslation } from "react-i18next";
import RenderCellAgeInterval from "../components/cellRender/RenderCellAgeInterval";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmIconButton from "../components/buttons/ConfirmIconButton";
import { resources } from "../services/ability";
import { AbilityContext } from "../services/Can";
import { genderCode, getGenderCodeValue } from "../constants/genderCode";
import RenderCellRankFilter from "../components/cellRender/RenderCellRankFilter";
import { Close, KeyboardTab } from "@mui/icons-material";
import {checkRankingFilter} from "../services/checkRankingFilter";

export default function RanksManager({ ranks, loading, national = false, organizationId, customField }) {

    let [isCreating, setIsCreating] = useState(false);
    let [showInfo, setShowInfo] = useState(null);
    let [showAwards, setShowAwards] = useState(null);
    const { t } = useTranslation();
    const ability = useContext(AbilityContext);
    ranks = ranks.slice().sort((a, b) => a.startDate - b.startDate);
    const unexpiredRanks = ranks !== [] && ranks.filter((e) => dayjs(e.endDate).endOf('day') >= Date.now());
    const expiredRanks = ranks !== [] && ranks.filter((e) => dayjs(e.endDate).endOf('day') < Date.now());
    const usefulValues = useGetUsefulValues(national);
    const rankingFilter = useGetRankingFilter(customField);
    const { enqueueSnackbar } = useSnackbar();
    let queryClient = useQueryClient();
    const today = dayjs(new Date()).format("YYYY-MM-DD");
    const sessionType = useGetSessionType()
    const warningRankAlreadyStarted = t('warningRankAlreadyStarted')
    const key = national ? ["National", RANKS] : [ORGANIZATIONS, { id: organizationId }, RANKINGS];

    const getFilterValue = (params) => {
        if (params.row["filter"] !== null && params.row["filter"] !== undefined) {
            if (params.row["filter"] === 0) {
                return sessionType.find(st => st.id == params.value)?.name       //usa == e non ==== perchè uno dei due puo' essere una stringa e l'altro un numero
            }
            if (params.row["filter"] === 1) {
                return params.value
            }
            if (params.row["filter"] === 2) {
                return t(getGenderCodeValue(params.value));
            }
        }
        return "";
    }

    const defaultColumns = [
        {
            headerName: t('title'),
            field: 'title',
            width: 200,
            renderCell: (params) => <RenderCell params={params} saveEdit={dayjs(params.row.endDate).endOf('day') > Date.now() && saveEdit}
                required
                warningMessage={params.row.startDate < Date.now() && warningRankAlreadyStarted} />
        },
        {
            headerName: t('value'),
            field: 'value',
            width: 200,
            valueGetter: (params) => usefulValues.find(elem => elem.id === params.value) ? usefulValues.find(elem => elem.id === params.value)?.name : "",
            renderCell: (params) => <RenderCell params={params} saveEdit={dayjs(params.row.endDate).endOf('day') > Date.now() && saveEdit}
                type="select" options={usefulValues}
                warningMessage={params.row.startDate < Date.now() && warningRankAlreadyStarted}
                required />
        },
        {
            headerName: t('filter'),
            field: 'filter',
            width: 200,
            valueGetter: (params) => (params.value !== null && params.value !== undefined)
                ? rankingFilter.find(elem => checkRankingFilter(elem.id, params.value, params.row.filterValue))?.name : "",
            renderCell: (params) => <RenderCellRankFilter params={params} saveEdit={saveEditRank} rankingFilter={rankingFilter}
                warningMessage={params.row.startDate < Date.now() && warningRankAlreadyStarted} />
        },
        {
            headerName: t('valueFilter'),
            field: 'filterValue',
            width: 200,
            valueGetter: (params) => getFilterValue(params),
            renderCell: (params) => {
                if (params.row["filter"] === 0) return <RenderCell params={params}
                    saveEdit={dayjs(params.row.endDate).endOf('day') > Date.now() && saveEdit}
                    type="select"
                    options={sessionType}
                    warningMessage={params.row.startDate < Date.now() && warningRankAlreadyStarted}
                    required />

                if (params.row["filter"] === 1) return <RenderCellAgeInterval params={params}
                    saveEdit={dayjs(params.row.endDate).endOf('day') > Date.now() && saveEdit}
                    warningMessage={params.row.startDate < Date.now() && warningRankAlreadyStarted} />

                if (params.row["filter"] === 2) return <RenderCell params={params}
                    saveEdit={dayjs(params.row.endDate).endOf('day') > Date.now() && saveEdit}
                    type="select"
                    options={Object.values(genderCode).map(v => ({
                        id: v.id,
                        name: t(v.value)
                    }))}
                    warningMessage={params.row.startDate < Date.now() && warningRankAlreadyStarted}
                    required />
            }
        },
        {
            headerName: t('month'),
            field: 'month',
            width: 100,
            valueGetter: ({ row, field }) => {
                if (row.startDate) {
                    const month = new Date(row.startDate).toLocaleString('en-EN', { month: 'long' }).toLowerCase()
                    return t(month);
                }
                else return ""
            }
        },
        {
            headerName: t('year'),
            field: 'year',
            width: 100,
            valueGetter: ({ row, field }) => {
                if (row.startDate) return new Date(row.startDate).getFullYear()
                else return ""
            }
        },
        {
            headerName: t('startDate'),
            field: 'startDate',
            width: 200,
            renderCell: (params) => <RenderCell params={params} saveEdit={params.row.startDate > Date.now() && saveEdit}
                required type="date" />,
        },
        {
            headerName: t('endDate'),
            field: 'endDate',
            width: 200,
            renderCell: (params) => <RenderCell params={params} saveEdit={dayjs(params.row.endDate).endOf('day') > Date.now() && saveEdit}
                required type="date" min={today}
                warningMessage={params.row.startDate < Date.now() && warningRankAlreadyStarted} />,
        },
        {
            headerName: t('repetitions'),
            field: 'repeatNum',
            width: 200,
            renderCell: (params) => {
                const save = (id, field, newValue) => saveEditRepetitions(id, newValue, params.row.repeatType)
                return <>
                    {
                        params.row.repeatType !== 0 &&
                        <>
                            <RenderCell params={params} required type="number"
                                warningMessage={params.row.startDate < Date.now() && warningRankAlreadyStarted}
                                saveEdit={dayjs(params.row.endDate).endOf('day') > Date.now() && save} />
                            <InfoRepetitionRankings params={params} showValue={false} />
                        </>
                    }
                    {!params.row.closed &&
                        <ConfirmIconButton
                            onConfirm={() => closeRank(params.row.id)}
                            title={t('closeRankTitle')}
                            text={t('closeRankText')}
                        >
                            <KeyboardTab />
                        </ConfirmIconButton>
                    }
                </>
            }
        },
        {
            headerName: t('awardLimit'),
            field: 'awardsLimitForGroup',
            width: 160,
        },
        {
            headerName: ' ',
            field: "info",
            width: 160,
            renderCell: (params) => <>
                <IconButton onClick={() => setShowInfo(params.row)} size="large"> <VisibilityIcon /> </IconButton>
                <IconButton onClick={() => setShowAwards(params.row)} size="large"> <EmojiEventsIcon /> </IconButton>
                {/* params.row.startDate > Date.now() && */ !params.row.awardsAssigned && ability.can('read', resources.DELETE_RANKS) &&
                    <ConfirmIconButton
                        onConfirm={() => deleteRank(params.row.id)}
                        title={t('confirmDeletion')}
                        text={t('requestDefinitiveEliminationRank')}
                    >
                        <DeleteIcon />
                    </ConfirmIconButton>
                }
            </>
        }
    ];

    const deleteRank = (id) => {
        enqueueSnackbar(t('saving...'), { variant: "info" });
        deleteElem(RANKINGS, { elem: id, params: { force: false } })
            .then(() => enqueueSnackbar(t('saved'), { variant: "success" }))
            .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: "error" }))
            .finally(() => queryClient.invalidateQueries(key));
    }

    const closeRank = (id) => {
        enqueueSnackbar(t('saving...'), { variant: "info" });
        post(`${RANKINGS}/${id}/generate-next-repetition`)
            .then(() => enqueueSnackbar(t('closedRank'), { variant: "success" }))
            .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: "error" }))
            .finally(() => queryClient.invalidateQueries(key));
    }

    const createRank = (values) => {
        if (!national) {
            values.organization = parseInt(organizationId);
        }
        enqueueSnackbar(t('saving...'), { variant: "info" });
        post(RANKINGS, { body: values })
            .then(() => enqueueSnackbar(t('saved'), { variant: "success" }))
            .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: "error" }))
            .finally(() => queryClient.invalidateQueries(key));
    }

    const saveEdit = (id, field, newValue) => {
        if (field === "startDate" || field === "endDate") newValue = new Date(newValue).getTime()
        saveEditRank(id, { [field]: newValue })
    }

    const saveEditRank = (id, body) => {
        enqueueSnackbar(t('saving...'), { variant: "info" });
        put(RANKINGS, { body: body, elem: id })
            .then(() => {
                enqueueSnackbar(t('saved'), { variant: "success" });
                if (ranks.find(r => r.id === id))
                    Object.entries(body).forEach((elem) => {
                        ranks.find(r => r.id === id)[elem[0]] = elem[1];
                    })
                console.log(ranks.find(r => r.id === id))
            })
            .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: "error" }))
            .finally(() => queryClient.invalidateQueries(key));
    }

    const saveEditRepetitions = (id, repeatNum, repeatType) => {
        enqueueSnackbar(t('saving...'), { variant: "info" });
        put(RANKINGS, { body: { repeatNum, repeatType }, elem: id })
            .then(() => {
                enqueueSnackbar(t('saved'), { variant: "success" });
                if (ranks.find(r => r.id === id)) ranks.find(r => r.id === id)["repeatNum"] = repeatNum;
            })
            .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: "error" }))
            .finally(() => queryClient.invalidateQueries(key));
    }

    if (showInfo) return <RankInfo goBack={() => setShowInfo(null)} rankId={showInfo.id} national={national}
        customField={customField} queryClientKey={key} />
    if (showAwards) return <RankAward goBack={() => setShowAwards(null)} rank={showAwards} />

    return <Grid>
        <NTMXGrid
            key={"ranks-manager-table-active"}
            columns={defaultColumns}
            rows={unexpiredRanks || []}
            title={t('unexpiredRanks')}
            loading={loading}
            rightButton={<Grid container justifyContent={"flex-end"}>
                <StartIconButton onClick={() => setIsCreating(true)} title={t('createRanks')}
                    startIcon={<CreateIcon />} />
            </Grid>}
        />
        <Divider />
        <NTMXGrid
            key={"ranks-manager-table-expired"}
            columns={defaultColumns}
            rows={expiredRanks || []}
            loading={loading}
            title={t('expiredRanks')}
        />
        <CreateRankModal open={!!isCreating} onClose={() => setIsCreating(false)} onSubmit={createRank}
            usefulValues={usefulValues} rankingFilter={rankingFilter} />
    </Grid>

}
