export const genderCode = {
    MALE: {
        id: 1,
        value: 'male',
    },
    FEMALE: {
        id: 2,
        value: 'female',
    },
    UNSET: {
        id: 0,
        value: 'notDefined',
    },
    OTHER: {
        id: 3,
        value: 'other',
    },
}

/**
 * ritorna la stringa di traduzione del genere dato l'id
 * @param id
 * @returns {string}
 */
export function getGenderCodeValue(id) {
    if (id === null || id === undefined) {
        return genderCode.UNSET.value;
    }

    return (Object.values(genderCode).find(v => v.id == id)).value;
}

/**
 * ritorna l'id dell'enum del genere dato il value
 * @param value
 * @returns {number|null}
 */
export function getGenderCodeId(value) {
    if (!value) {
        return genderCode.UNSET.id;
    }

    return (Object.values(genderCode).find(v => v.value === value)).id;
}
