import {useTranslation} from "react-i18next";

export function useGetErrorMessages() {
    const {t} = useTranslation();

    return {
        MANDATORY: t('mandatoryField'),
        INVALID_URL: t('invalidUrl'),
        INVALID_PHONE: t('invalidPhone'),
        INVALID_VAT: t('invalidVat'),
        PASSWORD_NOT_MATCH: t('passwordNotMatch'),
        INVALID_IBAN: t('invalidIBAN'),
        ERROR_FORM: t('errorFormCompilation'),
        CREATION_ERROR_VENDOR: t('errorCreationVendor'),
        NEW_EMAIL_ERROR: t('newEmailError'),
        INCORRECT_PASSWORD: t('incorrectPassword'),
        SELECT_SHOP_MANDATORY: t('selectShopMandatory'),
        VENDOR_REGISTERED: t('vendorRegistered'),
    }
}