import {useTranslation} from "react-i18next";

export default function useGetRefundStatus() {
	const {t} = useTranslation();

	return [
		{
			id: 0,
			name: t('invalidSessionRefundDisabled')
		},
		{
			id: 1,
			name: t('amountRecognized')
		},
		{
			id: 2,
			name: t('partiallyRecognizedDueDailyThreshold')
		},
		{
			id: 3,
			name: t('partiallyRecognizedDueMonthlyThreshold')
		},
		{
			id: 4,
			name: t('partiallyRecognizedDueInitiativeThreshold')
		},
		{
			id: 5,
			name: t('notRecognizedDueDailyThreshold')
		},
		{
			id: 6,
			name: t('notRecognizedDueMonthlyThreshold')
		},
		{
			id: 7,
			name: t('notRecognizedDueInitiativeThreshold')
		},
		{
			id: 8,
			name: t('notRecognizedDueInitiativeThreshold')
		},
		{
			id: 9,
			name: t('partiallyRecognizedDueInitiativeThreshold')
		},
		{
			id: 10,
			name: t('notRecognizedDueAwardOrganizationLimit')
		},
		{
			id: 11,
			name: t('partiallyRecognizedDueInitiativeThreshold')
		},
	]
}
