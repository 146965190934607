import makeStyles from "@mui/styles/makeStyles";
import EditIcon from "@mui/icons-material/Edit";
import HoverableButton from "../layout/HoverableButton";
import React,{useState} from "react";
import BaseModal from "../modals/BaseModal";
import {useTranslation} from "react-i18next";
import {TextInputContainer} from "../forms/TextInput";
import Grid from "@mui/material/Grid";
import {useGetUsers} from "../../services/UserDataManager";

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.secondary.main
        }
    }
}));

export default function RenderEditableUsername({params, saveEdit}) {

    const {t} = useTranslation();
    let classes = useStyles();
    let users = useGetUsers();
    let [modal, setModal] = useState(false);
    let [value, setValue] = useState(params.row[params.field]);
    let [error, setError] = useState(null);

    function submit() {
        if(!value || value === "" ) {
            setError(t('mandatoryField'))
            return
        }
        if(users.some(u => u.username === value)) {
            setError(t('usernameAlreadyExist'))
            return
        }

        saveEdit(params.row.uid, params.field, value);
        setError(null);
        onClose();
    }

    function onClose() {
        setModal(false)
    }

    function onChange(newValue) {
        setValue(newValue);
        setError(null);
    }

    return <div>
        <HoverableButton
            item={{
                icon: <EditIcon/>,
                name: params.row[params.field] || ""
            }}
            onClick={() => setModal(true)}
            classes={{root: classes.root}}
        />
        <BaseModal open={modal} onClose={onClose} onSave={submit}>
            <Grid container spacing={4} style={{margin: 0, width: "100%"}}>

                <TextInputContainer xs={12} label={params.field} value={value} required
                                    onTextChange={onChange} error={error}/>

                <div style={{width: "15rem"}}/>
            </Grid>
        </BaseModal>
    </div>
}