import React from "react";
import {Grid} from "@mui/material";
import {TextItem} from "../TextItem";
import {unixToString} from "../../services/helper";
import makeStyles from '@mui/styles/makeStyles';
import useGlobalStyles from "../../services/useGlobalStyles";
import clsx from "clsx";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    textItem: {
        color: "white",
        borderColor: theme.palette.primary.light,
        height: "100%",
    },
}));

export default function CouponPreview({coupon}) {
    const classes = useStyles();
    const globalClasses = useGlobalStyles();
    const {t} = useTranslation();
    return (
        <Grid container direction={"row"}
              justifyContent="center"
              alignItems="center"
        >
            {/*--- tipologia --*/}
            <Grid item xs={12} md={4}
                  className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>

                <TextItem xs={12} className={classes.textItem}
                          label={t('awardType')}
                          value={t('voucher')}
                          color={"light"}
                          alignCenter
                />

            </Grid>

            {/*--- titolo --*/}
            <Grid item xs={12} md={4}
                  className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                <TextItem xs={12} className={classes.textItem}
                          label={t('description')}
                          value={coupon.name || ''}
                          color={"light"}
                          alignCenter
                />
            </Grid>
            {/*--- valore --*/}
            <Grid item xs={12} md={4}
                  className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                <TextItem xs={12} className={classes.textItem}
                          label={t('value')}
                          value={(coupon.value || '0') + t('currency')}
                          color={"light"}
                          alignCenter
                />
            </Grid>

        </Grid>
    )
}