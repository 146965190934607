import menuItems from "../constants/_nav";
import menuItemsVendor from "../constants/_navVendor";
import {resources, roles} from "./ability";
import {useGetOrganizationSettings, useGetVendorUnreadMessages} from "./ContentManager";

let useSetMenu = (ability, user) => {
    let items = [menuItems.dashboard];
    let {settings} = useGetOrganizationSettings(user.organization)

    items.push(menuItems.users);
    items.push(menuItems.sessions)
    if (ability.can('read', resources.NATIONALRANKS)) items.push(menuItems.nationalRanks)
    if (ability.can('read', resources.NATIONALACHIEVEMENTS)) items.push(menuItems.nationalAchievements)
    if (ability.can('read', resources.NATIONALACHIEVEMENTS)) items.push(menuItems.customAwards)
    if (ability.can('read', resources.DRINKINGFOUNTAINS)) items.push(menuItems.drinkingFountains)
    if (ability.can('read', resources.DEBUG)) items.push(menuItems.debug)
    if (ability.can('read', resources.REVOLUT)) items.push(menuItems.revolut)
    if (ability.can('read', resources.VENDOR)) items.push(menuItems.vendors)
    let hasVendorFunctionality = settings.find(s => s.organizationSetting === "hasVendorFunctionality");
    if (user.userType === roles.ADMIN || (hasVendorFunctionality && hasVendorFunctionality.value)) {
        items.push(menuItems.shops)
    }
    if (ability.can('read', resources.COUPONS)) items.push(menuItems.coupons)
    //non mostrare agli admin le opzioni degli uffici
    if (user.userType !== roles.ADMIN) {
        if (ability.can('read', resources.OFFICES)) items.push(menuItems.offices)
        if (ability.can('read', resources.OFFICES)) items.push(menuItems.officeArticles)
    }
    if (ability.can('read', resources.WELCOME_MESSAGES)) items.push(menuItems.welcomeMessages)
    if (ability.can('read', resources.SETTINGS)) items.push(menuItems.generalSettings)
    if (ability.can('read', resources.SETTINGS)) items.push(menuItems.generalChecklist)
    return items;
};

let useMenuVendor = (ability,uid) => {
    const {messages} = useGetVendorUnreadMessages(uid);
    let items = [];
    if (ability.can('read', resources.VENDOR)) {
        items.push(
            menuItemsVendor.couponRedeem,
            menuItemsVendor.shops,
            menuItemsVendor.articles,
            menuItemsVendor.couponList,
            menuItemsVendor.help,
            {...menuItemsVendor.messages, badge:messages }
        );
    }
    return items;
}

let setMenuVendor = (ability) => {
    let items = [];
    if (ability.can('read', resources.VENDOR)) {
        items.push(
            menuItemsVendor.couponRedeem,
            menuItemsVendor.shops,
            menuItemsVendor.articles,
            menuItemsVendor.couponList,
            menuItemsVendor.help,
            menuItemsVendor.messages
        );
    }
    return items;
};

export {useSetMenu, setMenuVendor, useMenuVendor};
