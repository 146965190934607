import {Grid, IconButton, Paper, Tooltip, Typography} from "@mui/material";
import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import {DeleteForever, Visibility, VisibilityOff} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import StorefrontIcon from '@mui/icons-material/Storefront';
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import useGlobalStyles from "../../services/useGlobalStyles";
import {SettingsTooltip} from "../forms/CustomTooltip";

/**
 * mostra un elemento cliccabile con logo testo e pulsante di elimina
 * @param item
 * @param onCLick
 * @param logo
 * @param text
 * @param onDelete
 * @param isAdmin indica se l'elemento è visualizzato dal pannello admin
 * @param isVisible
 * @param changeIsVisibile
 * @returns {JSX.Element}
 * @constructor
 */
export default function ClickableItem({item, onCLick, logo, text, onDelete, isAdmin, isVisible, changeIsVisibile}) {

    const {t} = useTranslation();
    const globalClasses = useGlobalStyles();

    return (

        <Grid item
              md={6}
              xs={12}
              className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}
        >
            {
                <Paper
                    sx={{
                        backgroundColor: !isVisible && 'secondary.light'
                    }}
                    className={globalClasses.paddingSmall}
                    style={{cursor: 'pointer'}}
                    onClick={(e) => {
                        e.preventDefault();
                        onCLick(item);
                    }}
                >
                    <Grid container
                          direction="row"
                          alignItems="center"
                          wrap='no-wrap'
                    >
                        <Grid item sx={{
                            textAlign: 'center', height: '8em', width:'8em',
                            background: `url("${logo}")`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            borderRadius: '5px'
                        }}>
                            {
                                !logo && <StorefrontIcon sx={{verticalAlign: 'middle', width:'100%', height:'100%', p:2}}/>
                            }
                        </Grid>
                        <Grid item sx={{overflow: 'hidden', ml:2}} >
                            {text}
                        </Grid>
                        <Grid item flexGrow={1}></Grid>
                        {
                            //gli admin non possono modificare
                            !isAdmin &&
                            <Grid item sx={{px:2}}>
                                <Grid container direction={'column'} alignItems={'center'}>
                                    <Grid item>
                                        {
                                            onDelete ?
                                                <IconButton
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onDelete(item);
                                                    }}>
                                                    <DeleteIcon/>
                                                </IconButton>
                                                :
                                                <Tooltip title={t('cantDeleteFirstShop')}>
                                                    <IconButton
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                        }}
                                                    >
                                                        <DeleteForever style={{opacity: 0.5}}/>
                                                    </IconButton>
                                                </Tooltip>
                                        }
                                    </Grid>
                                    <Grid item>
                                        {
                                            isVisible !== undefined &&
                                            <SettingsTooltip title={isVisible ? t('hide') : t('show')}>
                                                <IconButton
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        changeIsVisibile(item);
                                                    }}>
                                                    {isVisible ? <Visibility/> : <VisibilityOff/>}
                                                </IconButton>
                                            </SettingsTooltip>
                                        }
                                    </Grid>
                                </Grid>

                            </Grid>
                        }
                    </Grid>
                </Paper>
            }

        </Grid>
    );
}