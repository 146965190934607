import BaseModal from "./BaseModal";
import React from "react";
import { QrReader } from 'react-qr-reader'
import { Grid } from "@mui/material";


export default function ScanQrModal({ open, onClose, onScan, onError }) {
    return (
        <BaseModal open={open} onClose={onClose} >
            <Grid item xs={12} style={{ width: '50vw', maxHeight: '90vh' }}>
                <QrReader
                    delay={300}
                    onResult={(result, error) => {
                        if (!!result) {
                            onScan(result?.text);
                            onClose();
                        }
                        if (!!error?.message) {
                            onError(error);
                            onClose();
                        }
                    }}
                    style={{ width: '100%' }}
                    constraints={{
                        facingMode: "environment"
                    }}
                />
            </Grid>
        </BaseModal>
    );

}