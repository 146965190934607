import Grid from "@mui/material/Grid";
import makeStyles from '@mui/styles/makeStyles';
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddNewButton from "../../components/buttons/AddNewButton";
import React, { useState } from "react";
import { Avatar, IconButton } from "@mui/material";
import { useGetAchievementsAwards } from "../../services/ContentManager";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import AchievementAwardModal from "../../components/modals/AchievementAwardModal";
import { ACHIEVEMENTS, AWARDS, getErrorMessage, post, put } from "../../services/Client";
import { TextItem } from "../../components/TextItem";
import EditIcon from "@mui/icons-material/Edit";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { useTranslation } from "react-i18next";
import ResponsiveCircularLoading from "../../components/ResponsiveCircularLoading";


const useStyles = makeStyles(theme => ({
    backButton: {
        color: theme.palette.primary.main,
    },
    backButtonIcon: {
        margin: theme.spacing(1)
    },
    container: {
        padding: "2rem",
        backgroundColor: theme.palette.primary.light,
        width: "auto"
    },
    textItem: {
        height: "100%",
    },
    image: {
        height: "4rem",
        width: "4rem",
        marginRight: theme.spacing(2),
    },
}));

/**
 * Modale di visualizzazione e modifica del premio di una coppa
 * @param goBack
 * @param achievement seleziona l'award dell'achievement
 * @param overrideAwards imposta i dati dell'award al posto di quelli presi da db
 * @param forceEdit mostra il pulsante di modifica anche in caso di achievement scaduto
 * @returns {JSX.Element}
 * @constructor
 */
export default function AchievementAward({ goBack, achievement = {}, overrideAwards, onEdit, sx, forceEdit = false }) {

    const { t } = useTranslation();
    let classes = useStyles();
    let { awards = [], status } = useGetAchievementsAwards(achievement.id, !overrideAwards);
    awards = overrideAwards ?? awards;
    let [awardModal, setAwardModal] = useState(false);
    let [editing, setEditing] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    let queryClient = useQueryClient();

    const createAward = (values) => {
        values.achievement = achievement.id
        enqueueSnackbar(t('saving...'), { variant: "info" });
        post(AWARDS + "/" + ACHIEVEMENTS, { body: values })
            .then(() => enqueueSnackbar(t('saved'), { variant: "success" }))
            .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: "error" }))
            .finally(() => queryClient.invalidateQueries([AWARDS, { aid: achievement.id }]))
    }

    const onEditEnd = (values) => {
        enqueueSnackbar(t('saving...'), { variant: "info" });
        put(AWARDS + "/" + ACHIEVEMENTS + "/" + values.id, { body: values })
            .then(() => enqueueSnackbar(t('saved'), { variant: "success" }))
            .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: "error" }))
            .finally(() => queryClient.invalidateQueries([AWARDS, { aid: achievement.id }]));
    }

    return (
        <Grid container justifyContent="space-between">
            {goBack && <Grid container justifyContent="space-between">
                <Button onClick={goBack} className={classes.backButton}>
                    <ArrowBackIcon className={classes.backButtonIcon} />
                    {t('goBack')}
                </Button>
            </Grid>}
            <Grid container xs={12} className={classes.container} alignItems={"center"} justifyContent={"center"}
                sx={sx}>
                {
                    status === 'loading' && <ResponsiveCircularLoading />
                }
                {awards.length === 0 && status !== "loading" &&
                    (
                        (forceEdit || achievement.endDate > Date.now()) &&
                        <AddNewButton text={t('addAward')} onClick={() => setAwardModal(true)} />
                    )
                }
                {awards.length !== 0 &&
                    <Grid container xs={12} md={6}>
                        <Grid xs={8}>
                            <Avatar src={awards[0].imageUrl} alt="awardImage" className={classes.image}>
                                <EmojiEventsIcon fontSize={"large"} />
                            </Avatar>
                        </Grid>
                        {
                            (forceEdit || achievement.endDate > Date.now()) && <Grid item xs={4} style={{ textAlign: "right" }}>
                                <IconButton onClick={() => setEditing(true)} size="large">
                                    <EditIcon />
                                </IconButton>
                            </Grid>
                        }
                        <TextItem value={awards[0].name} label={t('name')} xs={12} md={12} />
                        <TextItem value={awards[0].description} label={t('description')} xs={12} md={12} />
                        {
                            (awards[0].type === 0 || awards[0].type === 1 || awards[0].type === 3 || awards[0].type === 5) &&
                            <TextItem value={awards[0].value}
                                label={awards[0].type === 1 ? t('pointsValue') : t('euroValue')} xs={12} md={12} />
                        }
                    </Grid>
                }
            </Grid>
            <AchievementAwardModal open={!!editing} onClose={() => setEditing(false)} onSubmit={onEdit || onEditEnd}
                defaultValues={awards[0]}
                warningMessage={achievement.startDate < Date.now() && t('warningAchievementAlreadyStarted')} />
            <AchievementAwardModal open={!!awardModal} onClose={() => setAwardModal(false)}
                onSubmit={onEdit || createAward} />
        </Grid>
    );
}