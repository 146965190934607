import styled from "@emotion/styled";
import { ArrowBack } from "@mui/icons-material";
import { Button, Card, CardContent, CardMedia, Grid, ImageList, ImageListItem, Pagination, Tooltip, tooltipClasses, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { MenuButton } from "../../components/buttons/MenuButton";
import InfoGrid from "../../components/InfoGrid";
import NTMImageInput from "../../components/NTMImageInput";
import NTMSelect from "../../components/NTMSelect";
import ListPagination from "../../components/Pagination";
import ResponsiveCircularLoading from "../../components/ResponsiveCircularLoading";
import ClickableItem from "../../components/VendorComponents/ClickableItem";
import { REFETCH_OPTIONS, useGetCategories, useGetParticipantShopsByOrganization, useGetShopsByOrganization } from "../../services/ContentManager";
import { formatAddress } from "../../services/helper";
import useGlobalStyles from "../../services/useGlobalStyles";
import Shop from "../Vendor/Shop/Shop";
import { useGetPublicShops } from "services/ContentOpenManager";
import StorefrontIcon from '@mui/icons-material/Storefront';
import { Divider } from "../../../node_modules/@mui/material/index";

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} placement='top' classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgba(255, 255, 255, 0.93)',
        color: 'black',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));


const ShopData = ({ shop, goBack }) => {
    const { t } = useTranslation();
    const { categories, status: categoriesStatus } = useGetCategories();
    const globalClasses = useGlobalStyles();
    return (
        <>
            <Grid container justifyContent="space-between" className={globalClasses.marginBottom}>
                <Button
                    onClick={goBack}
                    className={globalClasses.backButton}
                >
                    <ArrowBack className={globalClasses.backButtonIcon} />
                    {t('goBack')}
                </Button>
            </Grid>

            <Grid container direction={'column'} sx={{ px: 3, maxWidth: (theme) => theme.breakpoints.values.lg, margin: 'auto', gap: 2 }}>
                <Grid item >
                    <Typography variant="h4" align='center' color="primary" fontWeight={'bold'}>
                        {shop.name}
                    </Typography>
                </Grid>
                <Grid item >
                    <Box sx={{ backgroundImage: `url(${shop.logo})`, backgroundSize: 'cover', height: '15em', width: '15em', margin: 'auto' }}></Box>
                </Grid>
            </Grid>

            <InfoGrid
                sx={{ py: 3, maxWidth: (theme) => theme.breakpoints.values.lg, margin: 'auto' }}
                infos={[
                    {
                        field: 'claim',
                        label: t('claim'),
                        md: 6,
                        xs: 12
                    },
                    {
                        field: 'description',
                        label: t('description'),
                        md: 6,
                        xs: 12
                    },
                    {
                        field: 'website',
                        label: t('webAddress'),
                        md: 6,
                        xs: 12
                    },
                    {
                        field: 'phone',
                        label: t('phoneNumber'),
                        formatValue: (value, data) => `${data.phonePrefix ?? '-'} ${data.phone ?? '-'}`,
                        md: 6,
                        xs: 12
                    },
                    {
                        field: 'categories',
                        label: t('shopTag'),
                        formatValue: (value, data) => (shop.categories?.map(c => t(categories.find(cat => cat.id === c)?.translationKey ?? '-')).join(', ') ?? ''),
                        md: 6,
                        xs: 12
                    },
                    {
                        field: 'address',
                        label: t('address'),
                        formatValue: (value, data) => shop.isEcommerce ? t('ecommerce') : formatAddress(shop),
                        md: 6,
                        xs: 12
                    }
                ]} data={shop}
            />

            <Box sx={{ maxWidth: 800, py: 3, margin: 'auto' }}>
                <ImageList 
                    cols={
                        shop.images?.length > 3 ?
                         3 
                         : shop.images?.length
                        } 
                    gap={8}>
                    {shop.images?.map((item) => (
                        <ImageListItem key={item}>
                            <img
                                style={{ maxWidth: '20em', margin: 'auto' }}
                                src={item}
                                alt={'shop images'}
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Box>


        </>
    )
}

export default function OpenDataShops() {
    let { id: oid } = useParams();
    let isAll = oid == "all";
    let { shops, status } = useGetParticipantShopsByOrganization(oid, isAll);
    let { shops: allShops, status: allShopsStatus } = useGetPublicShops(isAll, REFETCH_OPTIONS);
    const [selectedShop, setSelectedShop] = useState(null);
    const { t } = useTranslation();

    // Array / Oggetti che contengono tutti i valori dei filtri disponibili
    const { categories, status: categoriesStatus } = useGetCategories();
    const [provinces] = useState([]);

    // Array che vengono manipolati in base ai filtri scelti
    const [categoryFilters, setCategoryFilters] = useState([]);
    const [provinceFilters, setProvinceFilters] = useState([]);

    // Se si tratta dell'iniziativa generale utilizzo gli shops recuperati da useGetPublicShops
    if (isAll && allShopsStatus) {
        shops = allShops;
        status = allShopsStatus;
    }

    // Popola l'array di province disponibili
    shops?.forEach(shop => {
        if(shop.province != null && shop.province !== "" && !provinces.includes(shop.province))
            provinces.push(shop.province);
    });

    /**
     * Filtrare gli shops prima di renderizzarli,
     * in modo da gestire correttamente la paginazione
     */
    const filteredShops = React.useMemo(() => {
        return shops.filter(s =>
            (categoryFilters.length === 0 || categoryFilters.some(f => (s.categories || []).includes(f)))
            &&
            (provinceFilters.length === 0 || provinceFilters.includes(s.province?? ''))
        );
    }, [shops, categoryFilters, provinceFilters]);

    function categoryName(id) {
        return t((categories.find(c => c.id === id) || {}).translationKey)
    }

    if (selectedShop)
        return <ShopData
            shop={{ ...selectedShop, addressInput: formatAddress(selectedShop) }}
            goBack={() => setSelectedShop(null)}
        />

    return (
        <>
            <Grid container justifyContent={'space-evenly'}>
                <Grid item margin={1.5} xs={12} sm={6} md={4}>
                    <Box sx={{ width: '100%', margin: 'auto' }}>
                        <NTMSelect
                            label={t('filterByCategory')}
                            items={categories.map(c => ({ value: c.id, text: t(c.translationKey) }))}
                            value={categoryFilters}
                            onChange={(value) => setCategoryFilters([...categoryFilters, value])}
                            onDelete={(value) => setCategoryFilters(categoryFilters.filter(f => f !== value))}
                            maxItemSize={categories.length}
                        />
                    </Box>
                </Grid>
                <Grid item margin={1.5} xs={12} sm={6} md={4}>
                    <Box sx={{ width: '100%', margin: 'auto' }}>
                        <NTMSelect
                            label={t('filterByProvince')}
                            items={provinces.map(p => ({ value: p, text: p }))}
                            value={provinceFilters}
                            onChange={(value) => setProvinceFilters([...provinceFilters, value])}
                            onDelete={(value) => setProvinceFilters(provinceFilters.filter(p => p !== value))}
                            maxItemSize={provinceFilters.length}
                        />
                    </Box>
                </Grid>
            </Grid>

            <Grid container
                direction="row"
                justifyContent='flex-start'
                alignItems='stretch'
                sx={{ py: 3, maxWidth: (theme) => theme.breakpoints.values.lg, margin: 'auto' }}
            >
                {
                    status !== 'success' && <ResponsiveCircularLoading></ResponsiveCircularLoading>
                }
                <ListPagination 
                    key={filteredShops.length}
                    paginationSize={4}
                    gridContainerSx={{ justifyContent: 'center' }}
                    paginationContainerSx={{ display: 'flex', justifyContent: 'center' }}
                    paginationSx={{ width: 'auto' }}
                    elements={filteredShops}
                    renderElement={
                        (s) => (
                            <Grid item lg={3} md={6} xs={12} sx={{ p: 2, maxWidth: 'calc(100vw - 96px)' }}>
                                <Card sx={{ '&:hover': { transform: 'scale(1.02)' }, transition: 'all 0.1s', cursor: 'pointer', height: '100%' }}
                                    variant={'outlined'}
                                    onClick={() => setSelectedShop(s)}
                                >

                                    {s.logo ? (
                                        <CardMedia
                                            component="img"
                                            sx={{
                                                width: '100%',
                                                height: { xs: '120px', sm: '140px' },
                                                objectFit: 'contain'
                                            }}
                                            image={s.logo}
                                            alt="shop image"
                                        />
                                    ) : (
                                        <Box
                                            sx={{
                                                height: { xs: '120px', sm: '140px' },
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <StorefrontIcon
                                                sx={{
                                                    width: '100%',
                                                    height: '140px',
                                                    p: 2,
                                                }}
                                            />
                                        </Box>
                                    )}
                                    <Divider sx={{ my: 1 }} />
                                    <CardContent>
                                        <LightTooltip
                                            title={s.categories?.map(categoryName).join(', ') ?? ''}>
                                            <Typography gutterBottom variant="caption" component="p" color="primary.main"
                                                sx={{ whiteSpace: 'noWrap', overflow: 'hidden', textOverflow: 'ellipsis', height: '1.7em', fontSize: { xs: '1.0em', lg: '0.75rem' } }}>
                                                {s.categories?.map(categoryName).join(', ') ?? ''}
                                            </Typography>
                                        </LightTooltip>

                                        <Typography variant="h5" align="center" fontWeight={'bold'} sx={{
                                            mt: 1.5,
                                            mb: 1,
                                            height: '2.7em',
                                            overflow: 'hidden',
                                            display: '-webkit-box',
                                            '-webkit-box-orient': 'vertical',
                                            '-webkit-line-clamp': '2',
                                            textOverflow: 'ellipsis',
                                        }}>
                                            {s.name}
                                        </Typography>

                                        <Typography variant="body1" align="center" color="primary.main">
                                            {t('address')}
                                        </Typography>
                                        <Typography variant="body2" align="center">
                                            {!s.isEcommerce ? formatAddress(s) : '-'}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>)}>
                </ListPagination>
            </Grid>

        </>
    )
}