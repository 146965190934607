import React, { useRef, useState } from "react";
import { ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis, ZAxis } from "recharts";
import { useTheme } from "@mui/material/styles";
import ResponsiveCircularLoading from "../ResponsiveCircularLoading";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Paper } from "@mui/material";
import { value } from "lodash/seq";
import { useResizeEffect } from "../../services/useBreakpoint";
import { TooltipContent } from "./NTMBarChart";

/**
 * Crea un chart a bolle
 * @param data {[]}
 * @param loading
 * @param label
 * @param data[].field {string}
 * @param data[].value {number}
 * @returns {JSX.Element}
 * @constructor
 */
export default function NTMBubbleChart({ data, loading, label }) {
    const theme = useTheme();
    const [range, setRange] = useState(getRange())
    useResizeEffect(() => {
        setRange(getRange());
    }, []);

    data = data ?? [];
    if (loading) return <ResponsiveCircularLoading />;

    data = data.map(d => ({ index: 0.5, ...d }));

    function getRange() {
        //return ((width - 5 * data.length) / data.length) * Math.max(...data.map(d => d.value || 0)) * 1.3;
        if (window.innerWidth >= theme.breakpoints.values.md)
            return 1000
        if (window.innerWidth >= theme.breakpoints.values.sm)
            return 500
        if (window.innerWidth >= theme.breakpoints.values.xs)
            return 100
        return 100
    }

    return (<Grid container rowGap={2}>
        <Grid item xs={12}>
            <Typography variant={'h6'}>{label}</Typography>
        </Grid>
        <Grid item xs={12}>
            {loading && <ResponsiveCircularLoading />}
            {!loading && <Box sx={{ marginLeft: '-60px' }}>
                <ResponsiveContainer width={"100%"} height={range*0.07 + 40}>
                    <ScatterChart
                        margin={{
                            top: 10, right: 0, bottom: 0, left: 0,
                        }}
                    >
                        <XAxis
                            type="category"
                            dataKey="field"
                            interval={'preserveStart'}
                            tickLine={false}
                            axisLine={false}
                        />
                        <YAxis
                            type="number"
                            dataKey="index"
                            tick={false}
                            tickLine={false}
                            axisLine={false}
                            label={false}
                            domain={[0, 1]}
                        />
                        <ZAxis type="number" dataKey="value"
                            domain={[0, Math.max(...data.map(d => d.value || 0))]}
                            range={[2, range]} />
                        <Tooltip cursor={{ strokeDasharray: '3 3' }} wrapperStyle={{ zIndex: 100 }}
                            content={<TooltipContent />} />
                        <Scatter data={data} fill={theme.palette.primary.main} />
                    </ScatterChart>
                </ResponsiveContainer>
            </Box>}
        </Grid>
    </Grid>);
}