import { AcUnit, Thunderstorm, ViewHeadline, WbCloudy, WbSunny } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export const weatherType = {
    all: null,
    rain: 'rain',
    snow: 'snow',
    mist: 'mist',
    clear: 'clear',
    clouds: 'clouds'
}

export const useWeather = () => {
    const { t } = useTranslation();
    return {
        [weatherType.all]: {
            icon: t('all'),
            tooltip: t('allWeather')
        },
        [weatherType.rain]: {
            icon: <Thunderstorm></Thunderstorm>,
            tooltip: t('rainy'),
        },
        [weatherType.snow]: {
            icon: <AcUnit></AcUnit>,
            tooltip: t('snowy'),
        },
        [weatherType.mist]: {
            icon: <ViewHeadline></ViewHeadline>,
            tooltip: t('misty'),
        },
        [weatherType.clear]: {
            icon: <WbSunny></WbSunny>,
            tooltip: t('sunny'),
        },
        [weatherType.clouds]: {
            icon: <WbCloudy></WbCloudy>,
            tooltip: t('cloudy'),
        }
    }
}

export default function useGetWeatherType() {

    const {t} = useTranslation();

    return [
        {
            id: 'rain',
            name: t('rainy'),
        },
        {
            id: 'snow',
            name: t('snowy'),
        },
        {
            id: 'mist',
            name: t('misty'),
        },
        {
            id: 'clear',
            name: t('sunny'),
        },
        {
            id: 'clouds',
            name: t('cloudy'),
        }
    ]
}