import React, { useEffect, useState } from "react";
import BaseModal from "./BaseModal";
import Grid from "@mui/material/Grid";
import TextInput, { TextInputContainer } from "../forms/TextInput";
import MenuItem from "@mui/material/MenuItem";
import useGetSessionType from "../../constants/sessionType";
import FileInput from "../forms/FileInput";
import { Alert, Avatar, Button, FormControlLabel, FormGroup, SvgIcon, Switch, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useGetAchievementsAwards } from "../../services/ContentManager";
import AchievementAward from "../../containers/Achievements/AchievementAward";
import ResponsiveCircularLoading from "../ResponsiveCircularLoading";
import GenderSelect from "../GenderSelect";
import { SettingsTooltip } from "../forms/CustomTooltip";
import { Add, EmojiEvents } from "@mui/icons-material";
import { firebaseStorage } from "../../firebase";
import axios from "axios";
import SearchIcons from "../SearchIcon";
import * as mui from '@mui/icons-material';
import styled from "@emotion/styled";
import { value } from "lodash/seq";
import ReactDOMServer from 'react-dom/server'
import { renderSvgFromReactDomElement, svgToString } from "../../services/helper";
import { saveIconFirebase } from "../../services/FirebaseManager";
import { useAchievementType } from "constants/achievementType";
import {isStringAndContainsSubstring} from "../../services/checkRankingFilter";

export const StyledSvgIcon = styled(SvgIcon)(({ theme }) => ({
    boxSizing: 'content-box',
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create(['background-color', 'box-shadow'], {
        duration: theme.transitions.duration.shortest,
    }),
    padding: theme.spacing(2),
    margin: theme.spacing(0.5, 0),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
}));

/**
 *
 * @param open
 * @param onClose
 * @param onSubmit
 * @param usefulValues
 * @param achievementsFilter
 * @param organizationId
 * @param infoLogoDimension
 * @param defaultValues se è impostato allora è una clone
 * @param cloneAward clona l'award
 * @returns {JSX.Element}
 * @constructor
 */
export default function CreateAchievementsModal({
    open,
    onClose,
    onSubmit,
    usefulValues,
    achievementsFilter,
    organizationId,
    infoLogoDimension,
    defaultValues,
    cloneAward
}) {

    const { t } = useTranslation();
    let [values, setValues] = useState({});
    let [error, setError] = useState({});
    const sessionType = useGetSessionType();
    const achievementType = useAchievementType();
    let { awards = [], status } = useGetAchievementsAwards(defaultValues.id, cloneAward);
    const [addIcon, setAddIcon] = useState(!!defaultValues.icon)

    useEffect(() => {
        let newValues = { ...defaultValues }
        delete newValues.id;
        newValues.startDate = dayjs(defaultValues.startDate).format("YYYY-MM-DD");
        newValues.endDate = dayjs(defaultValues.endDate).format("YYYY-MM-DD");
        setAddIcon(!!newValues.icon)
        setValues(newValues)
    }, [defaultValues])

    const genderOptions = [
        {
            id: "M",
            name: t('male')
        },
        {
            id: "F",
            name: t('female')
        }
    ]

    const save = async () => {

        let convertedValues = { ...values };
        convertedValues["startDate"] = Date.parse(values["startDate"])
        convertedValues["endDate"] = Date.parse(values["endDate"])

        if (!convertedValues.name) return setError({ name: t('mandatoryField') })
        if (!convertedValues.startDate) return setError({ startDate: t('mandatoryField') })
        if (!convertedValues.endDate) return setError({ endDate: t('mandatoryField') })
        if (!convertedValues.duration) return setError({ duration: t('mandatoryField') })
        if (convertedValues.duration < 0) return setError({ duration: t('invalidValue') })
        if (convertedValues.startDate > convertedValues.endDate) return setError({ endDate: t('endDateLessThanStartDate') })
        if (convertedValues.value === null || convertedValues.value === undefined) return setError({ value: t('mandatoryField') })
        if (!convertedValues.target) return setError({ target: t('mandatoryField') })
        if (convertedValues.target < 0) return setError({ target: t('invalidValue') })

        if (convertedValues.filter !== null && (convertedValues.filter === 0 || convertedValues.filter === 2) && (convertedValues.filterValue === null || convertedValues.filterValue === undefined)) {
            return setError({ filterValue: t('mandatoryField') })
        }
        if (convertedValues.filter !== null && convertedValues.filter === 1) {
            if (!convertedValues.minAge) return setError({ minAge: t('mandatoryField') })
            if (convertedValues.minAge < 1) return setError({ minAge: t('invalidValue') })
            if (!convertedValues.maxAge) return setError({ maxAge: t('mandatoryField') })
            if (convertedValues.maxAge < convertedValues.minAge) return setError({ maxAge: t('maxAgeLessThanMinAge') })
            convertedValues.filterValue = convertedValues.minAge + " - " + convertedValues.maxAge
            delete convertedValues.minAge
            delete convertedValues.maxAge
        }
        if (convertedValues.filter === '') delete convertedValues.filter

        // //clone del logo
        // if (defaultValues.logo) {
        //     //salva sul nuovo percorso
        //     let file = await axios.get(defaultValues.logo, {
        //         responseType: 'blob',
        //       });
        //     console.group();
        //     console.log('1', file.data);
        //     await firebaseStorage.ref('organizations/achievements').child(organizationId).put(file.data)
        //     console.log('Uploaded a blob or file!');
        //     console.groupEnd();
        // }

        //----Salva su file l'icona se non esiste
        if (!addIcon) {
            convertedValues.icon = null;
            convertedValues.color = null
        }
        if (convertedValues.icon) {
            if (!saveIconFirebase(mui, convertedValues.icon)) {
                setError({ icon: t('errorOccurred') });
                return
            }
        }

        onSubmit(convertedValues, awards[0] ? { ...awards[0], id: null, achievement: null } : null)//esiste un solo award
        close()
    }

    const close = () => {
        setValues({})
        setError({});
        onClose()
    }

    const onTextChange = (value, name) => {
        let newValues = { ...values };
        if (name === "filter") delete newValues.filterValue
        if (name === "filter" && isStringAndContainsSubstring(value, "cf-")) {
            newValues.filter = 3; //valore corrispondente a ENUM JOLLY che racchiude tutti i cluster
            newValues.filterValue = value.split("-")[1]; //inserisco l'id del custom_field come filterValue
        } else {
            newValues[name] = value;
        }
        setError({});
        setValues(newValues);
    }

    return <BaseModal open={open} onClose={close} onSave={save}>
        <Grid container spacing={4} style={{ margin: 0, width: "100%", marginBottom: "1rem" }}>

            <TextInputContainer xs={12} label={t('name')} value={values.name}
                type="string" required
                onTextChange={(value) => { onTextChange(value, "name") }}
                error={error.name} />

            <TextInputContainer xs={12} label={t('description')} value={values.description}
                type="string" multiline
                onTextChange={(value) => { onTextChange(value, "description") }}
                error={error.description} />

            <TextInputContainer xs={12} required label={t('startDate')} value={values.startDate}
                type="date"
                InputLabelProps={{ shrink: true }}
                onTextChange={(value) => { onTextChange(value, "startDate") }}
                error={error.startDate} />

            <TextInputContainer xs={12} required label={t('endDate')} value={values.endDate}
                type="date" min={values.startDate}
                InputLabelProps={{ shrink: true }}
                onTextChange={(value) => { onTextChange(value, "endDate") }}
                error={error.endDate} />

            <Grid item xs={12} style={{ padding: "16px" }}>
                <SettingsTooltip title={t('daysDurationExplain')}>
                    <div>
                        <TextInput required label={t('daysDuration')} value={values.duration}
                            type="number"
                            min={1} step={1}
                            onTextChange={(value) => { onTextChange(value, "duration") }}
                            error={error.duration} />
                    </div>
                </SettingsTooltip>
            </Grid>

            <TextInputContainer xs={12} required label={t('value')} value={values.value}
                options={usefulValues} select
                onTextChange={(value) => { onTextChange(value, "value") }}
                error={error.value}>
                {usefulValues.map(o => <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>)}
            </TextInputContainer>

            <TextInputContainer xs={12} required label={t('target')} value={values.target}
                type="number"
                min={1} step={1}
                onTextChange={(value) => { onTextChange(value, "target") }}
                error={error.target} />

            <TextInputContainer xs={12} label={t('filterCategory')} value={values.filter}
                options={achievementsFilter} select
                onTextChange={(value) => { onTextChange(value, "filter") }}
                error={error.filter}>
                <MenuItem key='' value=''>
                    <br />
                </MenuItem>
                {achievementsFilter.map(o => <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>)}
            </TextInputContainer>
            {
                values.filter !== null && values.filter === 0 &&

                <TextInputContainer xs={12} required label={t('filterValue')} value={values.filterValue}
                    options={sessionType} select
                    onTextChange={(value) => { onTextChange(value, "filterValue") }}
                    error={error.filterValue}>
                    {sessionType.map(o => <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>)}
                </TextInputContainer>
            }
            {
                values.filter !== null && values.filter === 1 &&
                <>
                    <TextInputContainer xs={6} required label={t('minAge')} value={values.minAge}
                        type="number"
                        min={1} step={1}
                        onTextChange={(value) => { onTextChange(value, "minAge") }}
                        error={error.minAge} />

                    <TextInputContainer xs={6} required label={t('maxAge')} value={values.maxAge}
                        type="number"
                        min={1} step={1}
                        onTextChange={(value) => { onTextChange(value, "maxAge") }}
                        error={error.maxAge} />
                </>
            }
            {
                values.filter !== null && values.filter === 2 &&
                <GenderSelect
                    xs={12} required value={values.filterValue}
                    error={error.filterValue}
                    onTextChange={(value) => { onTextChange(value, "filterValue") }}
                />
            }

            <TextInputContainer xs={12} required label={t('type')} value={values.type}
                options={achievementType} select
                onTextChange={(value) => { onTextChange(value, "type") }}
                error={error.type}>
                {achievementType.map(o => <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>)}
            </TextInputContainer>

            <Grid item xs={12} style={{ padding: "16px" }}>
                {!defaultValues.logo && <Alert severity="info">{infoLogoDimension}</Alert>}
            </Grid>
            <Grid item xs={12} style={{ padding: "16px" }}>
                {
                    defaultValues.logo ?
                        <Avatar src={defaultValues.logo} alt="awardImage" sx={{
                            height: "8rem",
                            width: "8rem",
                            margin: 'auto'
                        }} >
                            <EmojiEvents fontSize={"large"} />
                        </Avatar>
                        :
                        <FileInput folder={"organizations/achievements"} prefix={organizationId}
                            onRequestSave={(e) => setValues({ ...values, "logo": e })} label={t('dragImage')} />
                }
            </Grid>
            <Grid item xs={12} style={{ padding: "16px" }}>
                <FormGroup>
                    <FormControlLabel control={<Switch color="secondary" />} label={t('addIcon')} onChange={() => setAddIcon((addIcon) => !addIcon)} />
                </FormGroup>
                {addIcon && (
                    values.icon ?
                        <Grid container
                            sx={{
                                '&>svg.MuiSvgIcon-root': (theme) => ({
                                    color: values.color,
                                    border: error.icon && '2px solid ' + theme.palette.error.light,
                                })
                            }}
                        >
                            <StyledSvgIcon
                                component={mui[values.icon]}
                                fontSize="large"
                                tabIndex={-1}
                            />
                            <Button startIcon={<mui.Edit />} variant='text' color='secondary' onClick={() => setValues({ ...values, "icon": null })}>
                                {t('edit')}
                            </Button>
                            <Grid item xs={12}>
                                <Typography variant={'caption'} sx={{ color: 'error.light' }}>{error.icon}</Typography>
                            </Grid>
                        </Grid>
                        :
                        <SearchIcons selectIcon={(icon, color) => setValues({ ...values, icon: icon.importName, color: color })}></SearchIcons>
                )}
            </Grid>
            <Grid item xs={12} style={{ padding: "16px" }}>
                {
                    status !== 'loading' &&
                    <AchievementAward sx={{ borderRadius: 2, margin: 0 }} achievement={defaultValues}
                        overrideAwards={awards} onEdit={(values) => awards[0] = values} forceEdit />
                }
                {
                    status === 'loading' && <ResponsiveCircularLoading />
                }
            </Grid>
        </Grid>
    </BaseModal>
}