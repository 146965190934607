// Import necessari
import React from "react";
import { Alert, AlertTitle, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, Typography } from "@mui/material";
import TextInput from "../../../components/forms/TextInput";
import clsx from "clsx";
import { Chip, InputLabel, ListItemText, MenuItem, Radio, RadioGroup, Select } from "@mui/material";
import { EditCoordinates } from 'components/modals/EditCoordinatesModal';
import AutocompleteAddress from 'components/AutocompleteAddress';
import AlghoBot from "../../../components/VendorComponents/AlghoBot";

export default function NewVendorStep3({
    classes,
    data,
    allCategories,
    setData,
    onAddressInputChange,
    onAddressChange,
    loadingCoordinates,
    errors,
    setErrors,
    t,
    globalClasses,
}) {

    const ibanPatterns = {
        'AD': /^AD\d{2}[0-9]{4}[0-9]{4}[0-9]{12}$/,  // Andorra
        'AE': /^AE\d{2}[0-9]{4}[0-9]{15}$/,  // United Arab Emirates
        'AF': /^AF\d{2}[A-Z0-9]{4}\d{16}$/,  // Afghanistan
        'AG': /^AG\d{2}[A-Z]{4}\d{16}$/,  // Antigua and Barbuda
        'AI': /^AI\d{2}[A-Z]{4}\d{16}$/,  // Anguilla
        'AL': /^AL\d{2}[0-9]{8}[0-9]{16}$/,  // Albania
        'AM': /^AM\d{2}[A-Z]{4}\d{4}[A-Z0-9]{12}$/,  // Armenia
        'AO': /^AO\d{2}\d{21}$/,  // Angola
        'AR': /^AR\d{2}\d{4}\d{16}$/,  // Argentina
        'AS': /^AS\d{2}[A-Z]{4}\d{16}$/,  // American Samoa
        'AT': /^AT\d{2}\d{5}\d{11}$/,  // Austria
        'AU': /^AU\d{2}[A-Z]{4}\d{6}\d{6}$/,  // Australia
        'AW': /^AW\d{2}[A-Z]{4}\d{16}$/,  // Aruba
        'AX': /^AX\d{2}\d{6}\d{6}\d{6}$/,  // Åland Islands
        'AZ': /^AZ\d{2}[A-Z]{4}\d{20}$/,  // Azerbaijan
        'BA': /^BA\d{2}\d{3}\d{3}\d{8}\d{2}$/,  // Bosnia and Herzegovina
        'BB': /^BB\d{2}[A-Z]{4}\d{16}$/,  // Barbados
        'BD': /^BD\d{2}[A-Z]{4}\d{4}[0-9A-Z]{10}$/,  // Bangladesh
        'BE': /^BE\d{2}\d{3}\d{7}\d{2}$/,  // Belgium
        'BF': /^BF\d{2}\d{5}\d{5}$/,  // Burkina Faso
        'BG': /^BG\d{2}[A-Z]{4}\d{6}[A-Z0-9]{8}$/,  // Bulgaria
        'BH': /^BH\d{2}[A-Z]{4}\d{14}$/,  // Bahrain
        'BI': /^BI\d{2}[0-9]{3}[0-9]{4}[0-9]{16}$/,  // Burundi
        'BJ': /^BJ\d{2}\d{5}\d{5}$/,  // Benin
        'BL': /^BL\d{2}[A-Z]{4}\d{15}$/,  // Saint Barthélemy
        'BM': /^BM\d{2}[A-Z]{4}\d{16}$/,  // Bermuda
        'BN': /^BN\d{2}[A-Z]{4}\d{15}$/,  // Brunei
        'BO': /^BO\d{2}[A-Z]{4}\d{14}$/,  // Bolivia
        'BQ': /^BQ\d{2}[A-Z]{4}\d{16}$/,  // Bonaire, Sint Eustatius and Saba
        'BR': /^BR\d{2}\d{4}\d{4}\d{15}[A-Z0-9]{2}$/,  // Brazil
        'BS': /^BS\d{2}[A-Z]{4}\d{16}$/,  // Bahamas
        'BT': /^BT\d{2}[A-Z]{4}\d{6}[A-Z0-9]{12}$/,  // Bhutan
        'BV': /^BV\d{2}\d{3}\d{7}$/,  // Bouvet Island
        'BW': /^BW\d{2}\d{16}$/,  // Botswana
        'BY': /^BY\d{2}[A-Z0-9]{4}\d{4}[A-Z0-9]{16}$/,  // Belarus
        'BZ': /^BZ\d{2}[A-Z]{4}\d{17}$/,  // Belize
        'CA': /^CA\d{2}[A-Z]{4}\d{16}$/,  // Canada
        'CC': /^CC\d{2}[A-Z]{4}\d{16}$/,  // Cocos (Keeling) Islands
        'CD': /^CD\d{2}\d{23}$/,  // Democratic Republic of the Congo
        'CF': /^CF\d{2}\d{5}\d{5}$/,  // Central African Republic
        'CG': /^CG\d{2}\d{11}\d{5}$/,  // Congo
        'CH': /^CH\d{2}[A-Z0-9]{5}\d{12}$/,  // Switzerland
        'CI': /^CI\d{2}\d{5}\d{5}$/,  // Côte d'Ivoire
        'CK': /^CK\d{2}[A-Z]{4}\d{15}$/,  // Cook Islands
        'CL': /^CL\d{2}\d{4}\d{20}$/,  // Chile
        'CM': /^CM\d{2}\d{5}\d{6}\d{6}$/,  // Cameroon
        'CN': /^CN\d{2}\d{12}$/,  // China
        'CO': /^CO\d{2}[A-Z0-9]{4}\d{12}$/,  // Colombia
        'CR': /^CR\d{2}[0-9]{4}[0-9]{14}$/,  // Costa Rica
        'CU': /^CU\d{2}[A-Z0-9]{4}\d{16}$/,  // Cuba
        'CV': /^CV\d{2}[A-Z0-9]{4}\d{14}$/,  // Cape Verde
        'CW': /^CW\d{2}[A-Z]{4}\d{16}$/,  // Curaçao
        'CX': /^CX\d{2}[A-Z]{4}\d{16}$/,  // Christmas Island
        'CY': /^CY\d{2}[0-9]{3}[0-9]{5}[0-9]{16}$/,  // Cyprus
        'CZ': /^CZ\d{2}\d{4}\d{6}\d{10}$/,  // Czech Republic
        'DE': /^DE\d{2}\d{8}\d{10}$/,  // Germany
        'DJ': /^DJ\d{2}[0-9]{3}[0-9]{12}[0-9]{8}$/,  // Djibouti
        'DK': /^DK\d{2}\d{4}\d{10}$/,  // Denmark
        'DM': /^DM\d{2}[A-Z]{4}\d{20}$/,  // Dominica
        'DO': /^DO\d{2}[A-Z0-9]{4}\d{20}$/,  // Dominican Republic
        'DZ': /^DZ\d{2}\d{20}$/,  // Algeria
        'EC': /^EC\d{2}[A-Z]{6}\d{16}$/,  // Ecuador
        'EE': /^EE\d{2}\d{4}\d{12}$/,  // Estonia
        'EG': /^EG\d{2}[0-9]{4}[0-9]{14}[0-9]{7}$/,  // Egypt
        'EH': /^EH\d{2}[A-Z0-9]{4}\d{16}$/,  // Western Sahara
        'ER': /^ER\d{2}[A-Z0-9]{4}\d{19}$/,  // Eritrea
        'ES': /^ES\d{2}[0-9]{4}[0-9]{4}[0-9]{1}[0-9]{11}$/,  // Spain
        'ET': /^ET\d{2}[A-Z0-9]{4}\d{16}$/,  // Ethiopia
        'FI': /^FI\d{2}\d{6}\d{8}$/,  // Finland
        'FJ': /^FJ\d{2}[A-Z0-9]{4}\d{15}$/,  // Fiji
        'FM': /^FM\d{2}[A-Z]{4}\d{16}$/,  // Micronesia
        'FO': /^FO\d{2}[0-9]{4}[0-9]{10}$/,  // Faroe Islands
        'FR': /^FR\d{2}[0-9]{5}[0-9]{11}[A-Z0-9]{7}$/,  // France
        'GA': /^GA\d{2}\d{5}\d{5}$/,  // Gabon
        'GB': /^GB\d{2}[A-Z]{4}\d{6}\d{8}$/,  // United Kingdom
        'GD': /^GD\d{2}[A-Z]{4}\d{16}$/,  // Grenada
        'GE': /^GE\d{2}[A-Z]{2}\d{16}$/,  // Georgia
        'GF': /^GF\d{2}[A-Z]{4}\d{15}$/,  // French Guiana
        'GG': /^GG\d{2}[A-Z]{4}\d{16}$/,  // Guernsey
        'GH': /^GH\d{2}[A-Z0-9]{4}\d{10}$/,  // Ghana
        'GI': /^GI\d{2}[A-Z]{4}\d{15}$/,  // Gibraltar
        'GL': /^GL\d{2}[0-9]{4}[0-9]{10}$/,  // Greenland
        'GM': /^GM\d{2}[A-Z0-9]{4}\d{15}$/,  // Gambia
        'GN': /^GN\d{2}[A-Z0-9]{4}\d{15}$/,  // Guinea
        'GP': /^GP\d{2}[A-Z]{4}\d{15}$/,  // Guadeloupe
        'GQ': /^GQ\d{2}[A-Z0-9]{4}\d{15}$/,  // Equatorial Guinea
        'GR': /^GR\d{2}[0-9]{3}[0-9]{4}[0-9]{16}$/,  // Greece
        'GT': /^GT\d{2}[A-Z]{4}\d{20}$/,  // Guatemala
        'GU': /^GU\d{2}[A-Z]{4}\d{16}$/,  // Guam
        'GW': /^GW\d{2}[A-Z0-9]{4}\d{11}$/,  // Guinea-Bissau
        'GY': /^GY\d{2}[A-Z0-9]{4}\d{15}$/,  // Guyana
        'HK': /^HK\d{2}[A-Z0-9]{4}\d{15}$/,  // Hong Kong
        'HM': /^HM\d{2}[A-Z]{4}\d{16}$/,  // Heard Island and McDonald Islands
        'HN': /^HN\d{2}[A-Z]{4}\d{14}$/,  // Honduras
        'HR': /^HR\d{2}\d{7}\d{10}$/,  // Croatia
        'HT': /^HT\d{2}[A-Z0-9]{4}\d{16}$/,  // Haiti
        'HU': /^HU\d{2}[0-9]{4}[0-9]{8}[0-9]{12}$/,  // Hungary
        'ID': /^ID\d{2}[A-Z0-9]{3}\d{13}$/,  // Indonesia
        'IE': /^IE\d{2}[A-Z]{4}\d{6}\d{8}$/,  // Ireland
        'IL': /^IL\d{2}[0-9]{3}[0-9]{16}$/,  // Israel
        'IM': /^IM\d{2}[A-Z]{4}\d{15}$/,  // Isle of Man
        'IN': /^IN\d{2}[A-Z0-9]{4}\d{14}$/,  // India
        'IO': /^IO\d{2}[A-Z]{4}\d{15}$/,  // British Indian Ocean Territory
        'IQ': /^IQ\d{2}[A-Z0-9]{4}\d{15}$/,  // Iraq
        'IR': /^IR\d{2}[A-Z0-9]{4}\d{13}$/,  // Iran
        'IS': /^IS\d{2}[0-9]{4}[0-9]{6}[0-9]{12}$/,  // Iceland
        'IT': /^IT\d{2}[A-Z0-9]{1}[0-9]{5}[0-9]{4}[A-Z0-9]{13}$/,  // Italy
        'JE': /^JE\d{2}[A-Z]{4}\d{16}$/,  // Jersey
        'JM': /^JM\d{2}[A-Z]{4}\d{16}$/,  // Jamaica
        'JO': /^JO\d{2}[A-Z]{4}[0-9]{12}[0-9]{10}$/,  // Jordan
        'JP': /^JP\d{2}[A-Z0-9]{4}\d{15}$/,  // Japan
        'KE': /^KE\d{2}[A-Z0-9]{4}\d{16}$/,  // Kenya
        'KG': /^KG\d{2}[A-Z0-9]{4}\d{16}$/,  // Kyrgyzstan
        'KH': /^KH\d{2}[A-Z0-9]{4}\d{14}$/,  // Cambodia
        'KI': /^KI\d{2}[A-Z]{4}\d{16}$/,  // Kiribati
        'KM': /^KM\d{2}[A-Z0-9]{4}\d{13}$/,  // Comoros
        'KN': /^KN\d{2}[A-Z]{4}\d{16}$/,  // Saint Kitts and Nevis
        'KP': /^KP\d{2}[A-Z0-9]{4}\d{15}$/,  // North Korea
        'KR': /^KR\d{2}[A-Z0-9]{4}\d{16}$/,  // South Korea
        'KW': /^KW\d{2}[A-Z]{4}\d{22}$/,  // Kuwait
        'KY': /^KY\d{2}[A-Z]{4}\d{16}$/,  // Cayman Islands
        'KZ': /^KZ\d{2}\d{3}\d{13}$/,  // Kazakhstan
        'LA': /^LA\d{2}[A-Z0-9]{4}\d{14}$/,  // Laos
        'LB': /^LB\d{2}[A-Z0-9]{4}\d{20}$/,  // Lebanon
        'LC': /^LC\d{2}[A-Z]{4}\d{24}$/,  // Saint Lucia
        'LI': /^LI\d{2}[A-Z0-9]{5}\d{12}$/,  // Liechtenstein
        'LK': /^LK\d{2}[A-Z0-9]{4}\d{14}$/,  // Sri Lanka
        'LR': /^LR\d{2}[A-Z0-9]{4}\d{14}$/,  // Liberia
        'LS': /^LS\d{2}[A-Z0-9]{4}\d{14}$/,  // Lesotho
        'LT': /^LT\d{2}[A-Z0-9]{5}\d{11}$/,  // Lithuania
        'LU': /^LU\d{2}[A-Z0-9]{3}\d{13}$/,  // Luxembourg
        'LV': /^LV\d{2}[A-Z]{4}\d{13}$/,  // Latvia
        'LY': /^LY\d{2}[0-9]{5}[0-9]{16}$/,  // Libya
        'MA': /^MA\d{2}[A-Z0-9]{6}\d{13}$/,  // Morocco
        'MC': /^MC\d{2}[0-9]{5}[0-9]{18}$/,  // Monaco
        'MD': /^MD\d{2}[A-Z0-9]{2}\d{18}$/,  // Moldova
        'ME': /^ME\d{2}[0-9]{3}[0-9]{15}$/,  // Montenegro
        'MF': /^MF\d{2}[A-Z]{4}\d{15}$/,  // Saint Martin
        'MG': /^MG\d{2}[A-Z0-9]{4}\d{18}$/,  // Madagascar
        'MH': /^MH\d{2}[A-Z]{4}\d{16}$/,  // Marshall Islands
        'MK': /^MK\d{2}[0-9]{3}[0-9]{10}[0-9]{2}$/,  // North Macedonia
        'ML': /^ML\d{2}[A-Z0-9]{4}\d{11}$/,  // Mali
        'MM': /^MM\d{2}[A-Z0-9]{4}\d{16}$/,  // Myanmar
        'MN': /^MN\d{2}[0-9]{4}[0-9]{12}$/,  // Mongolia
        'MO': /^MO\d{2}[A-Z0-9]{4}\d{15}$/,  // Macau
        'MP': /^MP\d{2}[A-Z]{4}\d{16}$/,  // Northern Mariana Islands
        'MQ': /^MQ\d{2}[A-Z]{4}\d{15}$/,  // Martinique
        'MR': /^MR\d{2}[0-9]{5}[0-9]{5}[0-9]{13}$/,  // Mauritania
        'MS': /^MS\d{2}[A-Z]{4}\d{16}$/,  // Montserrat
        'MT': /^MT\d{2}[A-Z]{4}\d{5}\d{18}$/,  // Malta
        'MU': /^MU\d{2}[A-Z]{4}[0-9]{2}[A-Z0-9]{17}[A-Z]{3}$/,  // Mauritius
        'MV': /^MV\d{2}[A-Z0-9]{4}\d{14}$/,  // Maldives
        'MW': /^MW\d{2}[A-Z0-9]{4}\d{16}$/,  // Malawi
        'MX': /^MX\d{2}[A-Z]{4}\d{14}$/,  // Mexico
        'MY': /^MY\d{2}[A-Z]{4}\d{15}$/,  // Malaysia
        'MZ': /^MZ\d{2}[A-Z0-9]{4}\d{15}$/,  // Mozambique
        'NA': /^NA\d{2}[A-Z0-9]{4}\d{17}$/,  // Namibia
        'NC': /^NC\d{2}[A-Z]{4}\d{15}$/,  // New Caledonia
        'NE': /^NE\d{2}[A-Z0-9]{4}\d{15}$/,  // Niger
        'NF': /^NF\d{2}[A-Z]{4}\d{16}$/,  // Norfolk Island
        'NG': /^NG\d{2}[A-Z0-9]{4}\d{15}$/,  // Nigeria
        'NI': /^NI\d{2}[A-Z]{4}[0-9]{20}$/,  // Nicaragua
        'NL': /^NL\d{2}[A-Z]{4}\d{10}$/,  // Netherlands
        'NO': /^NO\d{2}[0-9]{4}[0-9]{7}$/,  // Norway
        'NP': /^NP\d{2}[A-Z]{4}\d{14}$/,  // Nepal
        'NR': /^NR\d{2}[A-Z]{4}\d{15}$/,  // Nauru
        'NU': /^NU\d{2}[A-Z]{4}\d{16}$/,  // Niue
        'NZ': /^NZ\d{2}[A-Z0-9]{4}\d{15}$/,  // New Zealand
        'OM': /^OM\d{2}[0-9]{4}[0-9]{15}$/,  // Sultanate of Oman
        'PA': /^PA\d{2}[A-Z]{4}\d{16}$/,  // Panama
        'PE': /^PE\d{2}[A-Z0-9]{4}\d{17}$/,  // Peru
        'PF': /^PF\d{2}[A-Z]{4}\d{15}$/,  // French Polynesia
        'PG': /^PG\d{2}[A-Z]{4}\d{16}$/,  // Papua New Guinea
        'PH': /^PH\d{2}[A-Z0-9]{4}\d{15}$/,  // Philippines
        'PK': /^PK\d{2}[A-Z0-9]{4}\d{16}$/,  // Pakistan
        'PL': /^PL\d{2}\d{8}\d{16}$/,  // Poland
        'PM': /^PM\d{2}[A-Z]{4}\d{15}$/,  // Saint Pierre and Miquelon
        'PN': /^PN\d{2}[A-Z]{4}\d{16}$/,  // Pitcairn Islands
        'PR': /^PR\d{2}[A-Z]{4}\d{15}$/,  // Puerto Rico
        'PT': /^PT\d{2}[0-9]{4}[0-9]{4}[0-9]{13}$/,  // Portugal
        'PW': /^PW\d{2}[A-Z]{4}\d{16}$/,  // Palau
        'PY': /^PY\d{2}[A-Z0-9]{4}\d{16}$/,  // Paraguay
        'QA': /^QA\d{2}[A-Z]{4}\d{21}$/,  // Qatar
        'RE': /^RE\d{2}[A-Z]{4}\d{15}$/,  // Réunion
        'RO': /^RO\d{2}[A-Z]{4}\d{16}$/,  // Romania
        'RS': /^RS\d{2}[0-9]{3}[0-9]{15}$/,  // Serbia
        'RU': /^RU\d{2}[0-9]{4}[0-9]{15}[0-9]{10}$/,  // Russia
        'RW': /^RW\d{2}[A-Z0-9]{4}\d{16}$/,  // Rwanda
        'SA': /^SA\d{2}[0-9]{4}[0-9]{16}$/,  // Saudi Arabia
        'SB': /^SB\d{2}[A-Z]{4}\d{16}$/,  // Solomon Islands
        'SC': /^SC\d{2}[A-Z0-9]{4}[0-9]{20}[A-Z]{3}$/,  // Seychelles
        'SD': /^SD\d{2}[0-9]{4}[0-9]{10}$/,  // Sudan
        'SE': /^SE\d{2}[0-9]{3}[0-9]{17}$/,  // Sweden
        'SG': /^SG\d{2}[A-Z]{4}\d{15}$/,  // Singapore
        'SH': /^SH\d{2}[A-Z]{4}\d{15}$/,  // Saint Helena
        'SI': /^SI\d{2}[0-9]{5}[0-9]{10}$/,  // Slovenia
        'SJ': /^SJ\d{2}[A-Z]{4}\d{15}$/,  // Svalbard and Jan Mayen
        'SK': /^SK\d{2}\d{4}\d{6}\d{10}$/,  // Slovakia
        'SL': /^SL\d{2}[A-Z0-9]{4}\d{11}$/,  // Sierra Leone
        'SM': /^SM\d{2}[A-Z0-9]{1,5}[0-9]{5}[0-9]{13}$/,  // San Marino
        'SN': /^SN\d{2}[A-Z0-9]{4}\d{15}$/,  // Senegal
        'SO': /^SO\d{2}[0-9]{4}[0-9]{15}$/,  // Somalia
        'SR': /^SR\d{2}[A-Z0-9]{4}\d{15}$/,  // Suriname
        'SS': /^SS\d{2}[A-Z0-9]{4}\d{18}$/,  // South Sudan
        'ST': /^ST\d{2}[0-9]{4}[0-9]{17}$/,  // Sao Tome and Principe
        'SV': /^SV\d{2}[A-Z]{4}\d{20}$/,  // El Salvador
        'SX': /^SX\d{2}[A-Z]{4}\d{16}$/,  // Sint Maarten
        'SY': /^SY\d{2}[A-Z]{4}\d{16}$/,  // Syria
        'SZ': /^SZ\d{2}[A-Z0-9]{4}\d{16}$/,  // Eswatini
        'TC': /^TC\d{2}[A-Z]{4}\d{16}$/,  // Turks and Caicos Islands
        'TD': /^TD\d{2}\d{5}\d{5}$/,  // Chad
        'TF': /^TF\d{2}[A-Z]{4}\d{15}$/,  // French Southern Territories
        'TG': /^TG\d{2}[A-Z0-9]{4}\d{15}$/,  // Togo
        'TH': /^TH\d{2}[A-Z0-9]{4}\d{15}$/,  // Thailand
        'TJ': /^TJ\d{2}[A-Z0-9]{4}\d{13}$/,  // Tajikistan
        'TK': /^TK\d{2}[A-Z]{4}\d{16}$/,  // Tokelau
        'TL': /^TL\d{2}[0-9]{3}[0-9]{15}[0-9]{1}$/,  // Timor-Leste
        'TM': /^TM\d{2}[A-Z0-9]{4}\d{16}$/,  // Turkmenistan
        'TN': /^TN\d{2}[A-Z0-9]{4}\d{16}$/,  // Tunisia
        'TO': /^TO\d{2}[A-Z]{4}\d{16}$/,  // Tonga
        'TR': /^TR\d{2}[A-Z0-9]{5}\d{1}[A-Z0-9]{16}$/,  // Turkey
        'TT': /^TT\d{2}[A-Z]{4}\d{15}$/,  // Trinidad and Tobago
        'TV': /^TV\d{2}[A-Z]{4}\d{15}$/,  // Tuvalu
        'TZ': /^TZ\d{2}[A-Z0-9]{4}\d{16}$/,  // Tanzania
        'UA': /^UA\d{2}[0-9]{6}[0-9]{10}[0-9]{9}$/,  // Ukraine
        'UG': /^UG\d{2}[A-Z0-9]{4}\d{16}$/,  // Uganda
        'UM': /^UM\d{2}[A-Z]{4}\d{16}$/,  // United States Minor Outlying Islands
        'US': /^US\d{2}[A-Z]{4}\d{15}$/,  // United States
        'UY': /^UY\d{2}[A-Z0-9]{4}\d{13}$/,  // Uruguay
        'UZ': /^UZ\d{2}[A-Z0-9]{4}\d{20}$/,  // Uzbekistan
        'VA': /^VA\d{2}[0-9]{4}[0-9]{14}$/,  // Vatican City
        'VC': /^VC\d{2}[A-Z]{4}\d{16}$/,  // Saint Vincent and the Grenadines
        'VE': /^VE\d{2}[A-Z0-9]{4}\d{20}$/,  // Venezuela
        'VG': /^VG\d{2}[A-Z]{4}\d{16}$/,  // British Virgin Islands
        'VI': /^VI\d{2}[A-Z]{4}\d{16}$/,  // U.S. Virgin Islands
        'VN': /^VN\d{2}[A-Z0-9]{4}\d{15}$/,  // Vietnam
        'VU': /^VU\d{2}[A-Z]{4}\d{16}$/,  // Vanuatu
        'WF': /^WF\d{2}[A-Z]{4}\d{15}$/,  // Wallis and Futuna
        'WS': /^WS\d{2}[A-Z]{4}\d{16}$/,  // Samoa
        'XA': /^XA\d{2}[A-Z0-9]{4}\d{16}$/,  // Unrecognized region (example)
        'XB': /^XB\d{2}[A-Z0-9]{4}\d{16}$/,  // Unrecognized region (example)
        'XC': /^XC\d{2}[A-Z0-9]{4}\d{16}$/,  // Unrecognized region (example)
        'XD': /^XD\d{2}[A-Z0-9]{4}\d{16}$/,  // Unrecognized region (example)
        'YE': /^YE\d{2}[A-Z0-9]{4}[0-9]{22}$/,  // Yemen
        'YT': /^YT\d{2}[A-Z]{4}\d{15}$/,  // Mayotte
        'ZA': /^ZA\d{2}[A-Z0-9]{4}\d{15}$/,  // South Africa
        'ZM': /^ZM\d{2}[A-Z0-9]{4}\d{16}$/,  // Zambia
        'ZW': /^ZW\d{2}[A-Z0-9]{4}\d{16}$/,  // Zimbabwe
    };

    const validateIBAN = (iban) => {

            // Extract country code
            const countryCode = iban.slice(0, 2);

            if (!ibanPatterns.hasOwnProperty(countryCode)) {
               return false;
            }

            // Validate country-specific pattern
            const pattern = ibanPatterns[countryCode];
            if (pattern && !pattern.test(iban)) {
                return false;
            }
            return true;
    };

    const validateSWIFT = (bic) => {
        const swiftRegex = /^[A-Z]{4}[A-Z]{2}[A-Z\d]{2}([A-Z\d]{3})?$/;
        return swiftRegex.test(bic);
    };

    const handleShopChange = (text, field) => {

        if (field === 'bic' || field === 'iban') {
            text = text.replace(/\s+/g, '').toUpperCase();
        }

        // Validate IBAN
        if (field === 'iban') {
            if (!validateIBAN(text)) {
                setErrors(errors => ({ ...errors, iban: t('invalidIBAN') }));
            } else {
                setErrors(errors => ({ ...errors, iban: '' }));
            }
        }

        // Validate SWIFT/BIC
        if (field === 'bic') {
            if (!validateSWIFT(text)) {
                setErrors(errors => ({ ...errors, bic: t('invalidBic') }));
            } else {
                setErrors(errors => ({ ...errors, bic: '' }));
            }
        }

        // Update the data state
        setData(data => ({
            ...data,
            firstShop: {
                ...data.firstShop,
                [field]: text
            }
        }));
    };

    // Handles checkbox changes
    const handleOnChangeCheckbox = (e, categories, field) => {
        setData(data => {
            let newData = { ...data };
            newData.firstShop[field] = [...(newData.firstShop[field] ?? [])];
            newData.firstShop[field] = categories;
            return newData;
        });
    };

    return (
        <Grid container
            direction="row"
            alignItems="flex-start"
            className={globalClasses.marginBottom}
        >
            <Grid item xs={12} className={globalClasses.marginBottom} />

            {/*--- nome negozio  --*/}
            <Grid item xs={12} md={12} className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                <TextInput
                    label={t('shopName')}
                    type="text"
                    color={'primary'}
                    value={data.firstShop.name || ''}
                    onTextChange={(text) => handleShopChange(text, 'name')}
                    error={errors.name || ''}
                    required
                />
            </Grid>

            {/*--- slogan negozio  --*/}
            <Grid item xs={12} md={6} className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                <TextInput
                    label={t('insertSlogan')}
                    type="text"
                    color={'primary'}
                    value={data.firstShop.claim || ''}
                    onTextChange={(text) => handleShopChange(text, 'claim')}
                    error={errors.claim || ''}
                    required
                />
            </Grid>

            {/*--- description negozio  --*/}
            <Grid item xs={12} md={6} className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                <TextInput
                    label={t('description')}
                    type="text"
                    color={'primary'}
                    value={data.firstShop.description || ''}
                    onTextChange={(text) => handleShopChange(text, 'description')}
                    error={errors.description || ''}
                    required
                />
            </Grid>

            {/*--- website negozio  --*/}
            <Grid item xs={12} md={6} className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                <TextInput
                    label={t('webAddress')}
                    type="text"
                    color={'primary'}
                    value={data.firstShop.website || ''}
                    onTextChange={(text) => handleShopChange(text, 'website')}
                    error={errors.website || ''}
                    required
                />
            </Grid>

            {/*--- categoria negozio  --*/}
            <Grid item xs={12} md={6} className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                <FormControl className={globalClasses.formControl}>
                    <InputLabel id="demo-multiple-chip-label">{t('shopTag')}</InputLabel>
                    <Select
                        label={t('webAddress')}
                        classes={{ root: classes.root }}
                        multiple
                        value={data.firstShop.categories || []}
                        onChange={(e) => { console.log(e); handleOnChangeCheckbox(e, e.target.value, 'categories') }}
                        renderValue={(selected) => (
                            <div style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={t(allCategories.find(category => category.id === value).translationKey)} />
                                ))}
                            </div>
                        )}
                        sx={{ borderRadius: 0, borderColor: '#32323a' }}
                    >
                        {allCategories.map((category) => (
                            <MenuItem key={category.id} value={category.id}>
                                <Checkbox checked={data.firstShop.categories?.indexOf(category.id) > -1} />
                                <ListItemText primary={t(category.translationKey)} />
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{t('canSelectMultipleTags')}</FormHelperText>
                    {errors && errors.categories &&
                        <Typography variant={'caption'} className={globalClasses.error}>
                            {errors && errors["categories"]}
                        </Typography>
                    }
                </FormControl>
            </Grid>

            {/*--- flag ecommerce negozio  --*/}
            <Grid item xs={12} className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                <Grid container>
                    <Grid item xs={12} md={6} className={globalClasses.marginBottom}>
                        <FormControl component="fieldset" className={globalClasses.formControl}>
                            <RadioGroup value={data.firstShop.isEcommerce ? '1' : '0'}
                                onChange={(e) => handleShopChange(e.target.value === '1', 'isEcommerce')}>
                                <FormControlLabel value={'1'} control={<Radio />} label={t('ecommerce')} />
                                <FormControlLabel value={'0'} control={<Radio />} label={t('physicalShop')} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {
                            !data.firstShop.isEcommerce &&
                            (
                                <Grid container direction={'column'}>
                                    {/*--- indirizzo --*/}
                                    <Grid item xs={12}
                                        className={clsx(globalClasses.marginBottom)}>
                                        <AutocompleteAddress
                                            label={t('address')}
                                            value={data.firstShop.addressInput || null}
                                            error={errors && errors.addressInput}
                                            onAddressInputChange={(address) => onAddressInputChange(address, 'addressInput')}
                                            onAddressSelect={(address, placeId) => onAddressChange(address, placeId, 'addressInput')}
                                        />
                                    </Grid>
                                    {
                                        <Grid item xs={12}
                                            className={clsx(globalClasses.marginBottom)}>
                                            <EditCoordinates
                                                defaultLat={data.firstShop.latitude}
                                                defaultLng={data.firstShop.longitude}
                                                radius={data.firstShop.radius}
                                                onSubmit={(lat, lng) => {
                                                    setData(data => ({
                                                        ...data,
                                                        firstShop: {
                                                            ...data.firstShop,
                                                            latitude: lat,
                                                            longitude: lng
                                                        }
                                                    }));
                                                }}
                                                loading={loadingCoordinates}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>

            {/*--- intestatario conto  --*/}
            <Grid item xs={12} md={6} className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                <TextInput
                    label={t('accountHolder')}
                    type="text"
                    color={'primary'}
                    value={data.firstShop.accountHolder || ''}
                    onTextChange={(text) => handleShopChange(text, 'accountHolder')}
                    error={errors.accountHolder || ''}
                />
            </Grid>

            {/*--- iban negozio  --*/}
            <Grid item xs={12} md={6} className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                <TextInput
                    label={t('iban')}
                    type="text"
                    color={'primary'}
                    value={data.firstShop.iban || ''}
                    onTextChange={(text) => handleShopChange(text, 'iban')}
                    error={errors.iban || ''}
                />
            </Grid>

            {/*--- swift negozio  --*/}
            <Grid item xs={12} md={6} className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                <TextInput
                    label={t('swift')}
                    type="text"
                    color={'primary'}
                    value={data.firstShop.bic || ''}
                    onTextChange={(text) => handleShopChange(text, 'bic')}
                    error={errors.bic || ''}
                />
            </Grid>

            <Grid item xs={12} style={{ marginBottom: "0.5rem", padding: "16px" }}>
                <Alert severity="warning">
                    <AlertTitle sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>{t('warning')}</AlertTitle>
                    {t('newVendorAlert')}
                </Alert>
            </Grid>

            <Grid item xs={12} classes={globalClasses.marginBottom} style={{ padding: "0 16px" }}>
                <FormControlLabel label={
                    <div>
                        <span>{t('vendorsTermsConsent')}</span>
                        {/* Vecchio URL non funzionante
                         <a href="https://www.pin.bike/termini-e-condizioni-esercenti/" target="_blank">{t('vendorsTermsLink')}</a>
                        */}
                        <a href="https://www.pin.bike/termini_utilizzo_esercenti/" target="_blank">{t('vendorsTermsLink')}</a>
                        
                        <span>.</span>
                    </div>
                }
                    control={
                        <Checkbox defaultChecked={false}
                            onChange={(e) => setData(data => ({ ...data, privacyTermsAccepted: e.target.checked }))}
                        />
                    }
                />
                <FormHelperText error={!!errors["privacyTermsAccepted"]}>
                    {errors["privacyTermsAccepted"] || ''}</FormHelperText>
            </Grid>
            <AlghoBot/>
        </Grid >
    );
}
