import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import clsx from "clsx";
import {Typography} from "@mui/material";

const useStyles = (color) => makeStyles(theme => ({
    box: {
        width: "100%",
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        margin: theme.spacing(0.5),
        borderBottom: `${theme.palette.secondary.main} 1px solid`,
        color: color === 'light' ? theme.palette.primary.dark : theme.palette.primary.light,
        fontWeight: "bold",
        flexGrow: 1,
    },
    label: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        color: theme.palette.text.primary,
    },
    textCenter: {
        textAlign: 'center'
    }
}))();

/**
 * mostra un elemento di testo
 * @param value
 * @param label
 * @param xs
 * @param md
 * @param className
 * @param color {'light' | 'primary'} light adata il testo per modal con sfondo rosso
 * @param alignCenter
 * @returns {JSX.Element}
 * @constructor
 */
export function TextItem({value, label, xs = 6, md = 6, className, color = 'light', alignCenter = false, sx, textValueFontSize = {xs: "inherit", md: "inherit", lg: "inherit"}}) {
    let innerClasses = useStyles(color);

    const item = (
        <Grid item xs={xs} md={md} sx={sx}>
            <Grid container alignItems={"flex-end"} style={{display: "flex"}}
                  className={alignCenter && innerClasses.textCenter}>

                <Typography variant="caption" className={innerClasses.label}
                            sx={{width: alignCenter? '100%' : undefined}}>{label}</Typography>
                <Box xs={xs} md={md}
                    className={clsx(innerClasses.box, className)}
                    sx={{ fontSize: { xs: textValueFontSize.xs, md: textValueFontSize.md, lg: textValueFontSize.lg } }}>
                    {value}
                </Box>

            </Grid>
        </Grid>
    )
    return (
        alignCenter ?
            <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                {
                    item
                }
            </Grid>
            :
            item
    );
}
