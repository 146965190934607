


export function isStringAndContainsSubstring(id, substring){
    return typeof(id) === 'string' && id.includes(substring);
}

export function checkRankingFilter(rankingFilterId, filter, filterValue) {
    //se l'id contiene la stringa cf- allora effettuo il confronto su cf-* dove * corrisponde al filterValue
    if (isStringAndContainsSubstring(rankingFilterId, "cf-")) {
        return rankingFilterId.split("-")[1] === filterValue;
    } else {
        // Altrimenti, confronta direttamente con il valore (ENUM) del filtro
        return rankingFilterId === filter;
    }
}