import React, {useContext} from "react";
import Vendor from "./Vendor";
import Shop from "./Shop/Shop.js";
import {firebaseAuth} from "../../firebase";
import {Redirect, Route, Switch, useHistory} from "react-router-dom";
import CircularLoading from "../../components/CircularLoading";
import ShopData from "../../components/VendorComponents/ShopData";
import {useGetShops, useGetVendors} from "../../services/ContentManager";
import {
    ARTICLE_LIST_ROUTE,
    ARTICLE_ROUTE, COUPON_LIST_ROUTE,
    COUPON_REDEEM_ROUTE,
    SHOP_ROUTE,
    SHOPS_LIST_ROUTE,
    VENDOR_ROUTE,
    ADVERTISEMENT_ROUTE
} from "../../constants/vendors";
import Article from "./Article/Article";
import Shops from "./Shop/Shops";
import ArticleHistory from "./Article/ArticleHistory";
import Articles from "./Article/Articles";
import CouponRedeem from "./CouponRedeem/CouponRedeem";
import CouponList from "../../components/VendorComponents/CouponList";
import CouponListVendor from "./CouponList/CouponListVendor";
import FormNewVendor from "./NewVendor/FormNewVendor";
import {resources} from "../../services/ability";
import can, {AbilityContext} from "../../services/Can";
import Advertisements from "./Advertisments";

export default function VendorRoutes() {
    let history = useHistory();
    const ability = useContext(AbilityContext);

    let uid = firebaseAuth.currentUser.uid;

    let {vendors: vendor, status} = useGetVendors(uid);
    let {shops, status: statusShops} = useGetShops(uid);

    //attendi che siano caricati i vendors
    if (status === 'loading' || statusShops === 'loading' || !uid)
        return <CircularLoading/>

    //usa la history per capire se viene selezionato uno Shop o un Insertion
    return (ability.can('read', resources.VENDOR) ?
            //se non ci sono Shop allora vuol dire che il vendor e' stato appena creato quindi mostro la pagina di creazione dello Shop
            shops.length === 0 ?
                <Shop uid={uid} isFirstShop={true}/>
                :
                <Switch>
                    {/*  --coupon redeem--  */}
                    <Route path={COUPON_REDEEM_ROUTE}>
                        <CouponRedeem
                            uid={uid}
                        />
                    </Route>

                    {/*  --shops--  */}
                    <Route path={SHOPS_LIST_ROUTE}>
                        <Shops
                            uid={uid}
                        />
                    </Route>
                    <Route path={SHOP_ROUTE + ':id?'}>
                        <Shop
                            uid={vendor.uid}
                        />
                    </Route>

                    {/*  --articles--  */}
                    <Route path={ARTICLE_LIST_ROUTE}>
                        <Articles
                            uid={vendor.uid}
                        />
                    </Route>
                    <Route path={ARTICLE_ROUTE + ':id?' + '/history'}>
                        <ArticleHistory uid={vendor.uid} goBack={() => history.goBack()}/>
                    </Route>
                    <Route path={ARTICLE_ROUTE + ':id?'}>
                        <Article
                            uid={vendor.uid}
                        />
                    </Route>

                    {/*  --coupon list--  */}
                    <Route path={COUPON_LIST_ROUTE}>
                        <CouponListVendor uid={vendor.uid}/>
                    </Route>

                    <Redirect exact from="/vendor" to={SHOPS_LIST_ROUTE}/>
                </Switch>
            :
            <Redirect exact to="/unutorized"/>
    );
}
