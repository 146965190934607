import React, {useEffect, useRef, useState} from "react";
import BaseModal from "./BaseModal";
import Grid from "@mui/material/Grid";
import MapContainer from "../MapContainer";
import {Circle,Marker} from "@react-google-maps/api";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import ResponsiveCircularLoading from "../ResponsiveCircularLoading";
import {DEFAULT_LAT, DEFAULT_LNG} from "../../constants/map";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: "bold",
        color: theme.palette.primary.light
    },
    titleBlack: {
        fontWeight: "bold",
        color: theme.text
    },
}));

export default function EditCoordinatesModal({open, defaultLat, defaultLng, onSubmit, onClose}) {
    return <MapElement open={open} defaultLat={defaultLat} defaultLng={defaultLng} isModal={true} onClose={onClose}
                       onSubmit={onSubmit}/>
}

export function EditCoordinates({defaultLat, defaultLng, radius, onSubmit, loading, disabled}) {
    return <MapElement defaultLat={defaultLat} defaultLng={defaultLng} radius={radius} isModal={false}
                       onSubmit={onSubmit} loading={loading} disabled={disabled}/>
}

function MapElement({open, defaultLat, defaultLng, radius, onSubmit, onClose, isModal, loading = false, disabled = false}) {
    //per far si che il componente sia quadrato
    const [width, setWidth] = useState();
    const ref = useRef();
    const {t} = useTranslation();
    useEffect(() => {
        window.addEventListener("resize", () => setWidth(ref.current && ref.current.clientWidth));
        return window.removeEventListener("resize", () => setWidth(ref.current && ref.current.clientWidth));
    }, []);

    useEffect(() => {
        if (ref && ref.current)
            setWidth(ref.current.clientWidth);
    }, []);

    let [lat, setLat] = useState(defaultLat)
    let [lng, setLng] = useState(defaultLng);

    useEffect(() => {
        setLat(defaultLat);
        setLng(defaultLng)
    }, [defaultLat, defaultLng])

    let classes = useStyles();

    useEffect(() => {
        if (open) {
            defaultLat ? setLat(defaultLat) : setLat(DEFAULT_LAT);
            defaultLng ? setLng(defaultLng) : setLng(DEFAULT_LNG);
        }
    }, [open]);

    const submit = () => {
        onSubmit(lat, lng);
        onClose();
    }

    const onDragEnd = (newPosition) => {
        setLat(newPosition.latLng.lat());
        setLng(newPosition.latLng.lng());
        if (!isModal) {//se non è una modale il submit lo faccio non appena viene completato il drag dell pin sulla mappa\
            onSubmit(newPosition.latLng.lat(), newPosition.latLng.lng());
        }
    }

    let position = {lat: lat || DEFAULT_LAT, lng: lng || DEFAULT_LNG}

    return isModal ?
        <BaseModal open={open} onClose={onClose} onSave={submit} fullWidth>
            <Typography variant="h5" align="center" gutterBottom className={classes.title}>
                {t('dragToNewPosition')}
            </Typography>
            <Typography variant="body2" align="center" gutterBottom className={classes.title}>
                {t('newPosition')}: {lat} - {lng}
            </Typography>
            <Grid container spacing={4} style={{margin: 0, width: "99.9%", height: "70vh", marginBottom: "1rem"}}>
                {
                    defaultLat && defaultLng ?
                        <MapContainer center={{lat: lat, lng: lng}}>
                            <Marker position={{lat: lat, lng: lng}} draggable onDragEnd={onDragEnd}/>
                        </MapContainer>
                        :
                        <ResponsiveCircularLoading/>
                }
            </Grid>
        </BaseModal>
        :
        <Grid>
            <Typography variant="h6" align="center" gutterBottom className={classes.titleBlack}>
                {t('dragToNewPosition')}
            </Typography>
            <Typography variant="subtitle2" align="center" gutterBottom>
                {t('newPosition')}: {lat} - {lng}
            </Typography>
            <Grid container spacing={4}
                  style={{
                      margin: 0,
                      width: "99.9%",
                      height: width > 10 ? (width >= 500 ? width / 2 : width) : '50vw',
                      marginBottom: "1rem"
                  }}
                  ref={ref}>
                {
                    (!defaultLat || !defaultLng) && !loading &&
                    <Grid container alignItems='center' alignContent={"center"} direction={'column'}
                          style={{
                              width: '100%',
                              height: '100%',
                              marginBottom: '-100%',
                              zIndex: 100,
                              background: '#ffffff94'
                          }}>
                        <Grid item xs={12}>
                            <Grid container alignItems={"center"} alignContent={"center"} direction={"row"}
                                  style={{width: '100%', height: '100%'}}>
                                <Grid item xs={12}>
                                    <Typography >{t('firstSelectAddress')}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {
                    loading && <ResponsiveCircularLoading style={{marginBottom: '-100%', zIndex: 100}}/>
                }
                <MapContainer center={position}>
                    {radius && <Circle center={position} radius={parseInt(radius)}/>}
                    <Marker position={position} draggable={!disabled}
                            onDragEnd={onDragEnd}/>
                </MapContainer>
            </Grid>
        </Grid>
}