import { Room, Visibility, VisibilityOff } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MultiButton from "../../components/buttons/MultiButton";
import StartIconButton from "../../components/buttons/StartIconButton";
import Info from "../../components/Info";
import { Pill } from "../../components/SettingsPills";
import useGetSessionType from "../../constants/sessionType";
import { useWeather, weatherType } from "../../constants/weatherType";
import Heatmap from "../Dashboard/Heatmap";
import MapLegend from "../../components/MapLegend";
import ResponsiveCircularLoading from "components/ResponsiveCircularLoading";

import PedalBikeIcon from '@mui/icons-material/PedalBike';
import ElectricBikeIcon from '@mui/icons-material/ElectricBike';
import ElectricScooterIcon from '@mui/icons-material/ElectricScooter';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

export default function OpenDataHeatmap({ interval, organization }) {
	//todo: filtri e dati di default per open data
	const { t } = useTranslation();
	let [filters, setFilters] = useState({
		isHomeWorkPath: null,
		minAge: 0,
		maxAge: 100,
		legTypes: null,
		minTime: "00:00",
		maxTime: "23:59",
		minDate: interval.start,
		maxDate: interval.end,
		weather: null
	});
	let sessionType = useGetSessionType();
	sessionType = sessionType.filter(st => st.id === 0 || st.id === 1);
	sessionType.unshift({ id: -1, name: t('all') });

	let [isShowingOrganizationArea, setIsShowingOrganizationArea] = useState(false);
	let [showMarkers, setShowMarkers] = useState({ startStop: false, pauseResume: false });

	const bikeTypeFilters = [
		{ key: t('all'), value: null },
		{ key: <PedalBikeIcon></PedalBikeIcon>, tooltip: t('bike'), value: 0 + "" },
		{ key: <ElectricBikeIcon></ElectricBikeIcon>, tooltip: t('eBike'), value: 1 + "" },
		{ key: <ElectricScooterIcon></ElectricScooterIcon>, tooltip: t('micromobility'), value: 2 + "" },
		{ key: <DirectionsWalkIcon></DirectionsWalkIcon>, tooltip: t('byFeet'), value: 3 + "" },
		{ key: <DirectionsCarIcon></DirectionsCarIcon>, tooltip: t('carpooling'), value: 4 + "" },
		{ key: <DirectionsBusIcon></DirectionsBusIcon>, tooltip: t('publicTransport'), value: 5 + "" },
	];

	const sessionsTypeFilters = [
		{ key: 'heatmapHomeWork', value: true },
		{ key: 'heatmapNoHomeWork', value: false },
		{ key: 'all', value: null },
	];

	const weather = useWeather();

	useEffect(() => {
		setFilters({ ...filters, minDate: interval.start, maxDate: interval.end });
	}, [interval]);

	if(!interval.start || !interval.end)
		return <ResponsiveCircularLoading />

	return (
		<Grid container>
			<Grid container justifyContent="space-between" xs={12}>
				<Grid item
					sx={{
						width: '25%',
						minWidth: t('hideInitiativeArea').length + 'ex'
					}}
				>
					<StartIconButton
						title={isShowingOrganizationArea ? t('hideInitiativeArea') : t('showInitiativeArea')}
						onClick={() => setIsShowingOrganizationArea(prevState => !prevState)}
						startIcon={isShowingOrganizationArea ? <VisibilityOff /> : <Visibility />} />
				</Grid>

				<Grid item alignSelf={'center'}
				sx={{
                	width: '50%',
                	minWidth: t('heatmapDescription').length + 'ex',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center'
                }}>
					<Typography variant="h6" color={'primary'}>{t('heatmapDescription')}</Typography>
				</Grid>

				<Grid item textAlign={'right'}
					sx={{
						width: '20%',
						minWidth: t('hidePartials').length + 'ex'
					}}
				>
					<StartIconButton title={showMarkers.startStop ? t('hidePartials') : t('showPartials')}
						onClick={() => setShowMarkers(value => value.startStop ? {
							startStop: false,
							pauseResume: false
						} : { startStop: true, pauseResume: false })}
						startIcon={<Room />} />
				</Grid>

				    <Tooltip title={t('partialInfo')}>
                        <IconButton sx={{ color: '#dd3333', marginRight: '15px' }}>
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>

			</Grid>
			<Heatmap filters={filters} showOrganizationArea={isShowingOrganizationArea} showMarkers={showMarkers}
				overrideOrganization={organization}>

				<MapLegend
					legendTitle={t('filters')}
					labels={[
						{
							title: <>{t('weather')} <Info>{t('weatherInfo')}</Info></>,
							actionButtons: {
								value: filters.weather,
								buttons: Object.values(weatherType).map(
									w => ({
										value: w,
										onClick: () => setFilters({ ...filters, weather: w }),
										icon: weather[w].icon,
										tooltip: weather[w].tooltip
									})
								)
							}
						},
						{
							title: t('vehicleType'),
							actionButtons: {
								value: filters.legTypes,
								buttons: bikeTypeFilters.map(
									option => ({
										value: option.value,
										onClick: () => setFilters({
											...filters,
											legTypes: option.value,
										}),
										icon: option.key,
										tooltip: option.tooltip
									})
								)
							}
						},
						{
							title: t('sessionFilter'),
							actionButtons: {
								value: filters.isHomeWorkPath,
								buttons: sessionsTypeFilters.map(
									option => ({
										value: option.value,
										onClick: () => setFilters({
											...filters,
											isHomeWorkPath: option.value,
										}),
										icon: t(option.key)
									})
								)
							}
						},
					]}
				></MapLegend>
			</Heatmap>
		</Grid>
	);
}
