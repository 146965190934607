import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import MapIcon from '@mui/icons-material/Map';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import EuroIcon from '@mui/icons-material/Euro';
import BugReportIcon from '@mui/icons-material/BugReport';
import BarChartIcon from '@mui/icons-material/BarChart';
import Business from '@mui/icons-material/Business';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import drinkingFountain from '../images/pin_drinking_fountain_no_background.png';
import Icon from "@mui/material/Icon";
import {EmojiEvents, Message, NoteOutlined, Settings, Store, FactCheck} from '@mui/icons-material';
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import {Trans} from "react-i18next";
import React from "react";

const dashboard = {
    name: <Trans i18nKey="dashboard"/>,
    url: "/dashboard",
    icon: <DashboardIcon/>
};

const users = {
    name: <Trans i18nKey="users"/>,
    url: "/users",
    icon: <PeopleIcon/>
}

const sessions = {
    name: <Trans i18nKey="sessions"/>,
    url: "/sessions",
    icon: <DirectionsBikeIcon/>
}

const nationalRanks = {
    name: <Trans i18nKey="nationalRank"/>,
    url: "/nationalRank",
    icon: <BarChartIcon/>
}

const nationalAchievements = {
    name: <Trans i18nKey="nationalAchievements"/>,
    url: "/nationalAchievements",
    icon: <EmojiEventsIcon/>
}

const drinkingFountains = {
    name: <Trans i18nKey="drinkingFountains"/>,
    url: "/drinkingFountains",
    icon: <Icon style={{textAlign: 'center'}}><img style={{display: 'flex', height: 'inherit'}} src={drinkingFountain}
                                                   alt={"drinkingFountain"}/></Icon>
}


const map = {
    name: <Trans i18nKey="map"/>,
    url: "/map",
    icon: <MapIcon/>
}

const revolut = {
    name: <Trans i18nKey="revolut"/>,
    url: "/revolut",
    icon: <EuroIcon/>
}

const debug = {
    name: <Trans i18nKey="debug"/>,
    url: "/debug",
    icon: <BugReportIcon/>
}

const coupons = {
    name: <Trans i18nKey="coupons"/>,
    url: "/coupons",
    icon: <NoteOutlined/>
}

const vendors = {
    name: <Trans i18nKey="vendors"/>,
    url: "/vendors",
    icon: <Store/>,
}

const shops = {
    name: <Trans i18nKey="shops"/>,
    url: "/shops",
    icon: <ShoppingCartIcon/>,
}


const customAwards = {
    name: <Trans i18nKey="customAwards"/>,
    url: "/customAwards",
    icon: <AssignmentIndIcon/>,
}

const officeArticles = {
    name: <Trans i18nKey="awardsInCatalog"/>,
    url: "/articles",
    icon: <EmojiEvents/>
}

const offices = {
    name: <Trans i18nKey="offices"/>,
    url: "/offices",
    icon: <Business/>,
}

const welcomeMessages = {
    name: <><Trans i18nKey='localNotifications'/></>,
    url: "/welcome-messages",
    icon: <Message/>,
}

const generalSettings = {
    name: <><Trans i18nKey='settings'/></>,
    url: "/settings",
    icon: <Settings/>,
}

const generalChecklist = {
    name: <><Trans i18nKey='checklist'/></>,
    url: "/checklist",
    icon: <FactCheck/>,
}

export default {
    dashboard,
    users,
    map,
    sessions,
    nationalRanks,
    nationalAchievements,
    drinkingFountains,
    revolut,
    coupons,
    debug,
    vendors,
    customAwards,
    shops,
    officeArticles,
    offices,
    welcomeMessages,
    generalSettings,
    generalChecklist
};

