import React, { useState } from "react";
import CircularLoading from "../CircularLoading";
import {
    Box,
    Button,
    Grid,
    Typography
} from "@mui/material";
import { removeImagesFirebase } from "../../services/FirebaseManager";
import { get, SHOPS } from "../../services/Client";
import { useSnackbar } from "notistack";
import { useGetCategories } from "../../services/ContentManager";
import { isEmpty, isValidPhone } from "../../services/helper";
import { useGetErrorMessages } from "../../constants/errorMessages";
import clsx from "clsx";
import { Album, ArrowBack } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { FORM_TYPE } from "../../constants/FormBuilder";
import FormBuilder from "../FormBuilder";
import useGlobalStyles from "../../services/useGlobalStyles";
import { urlEncode } from "../../services/couponRedeemManager";
import CopyableLink from "../CopyableLink";

/**
 * wrapper di formdata con le funzioni per gestire i negozi
 */
export default function ShopData({
    shop,
    setShop,
    onSave,
    goBack,
    disabled,
    defShop,
    isFirstShop = false,
    vendor,
    hideBankInfo
}) {
    const { enqueueSnackbar } = useSnackbar();
    const globalClasses = useGlobalStyles();
    const errorMessages = useGetErrorMessages();
    const { t } = useTranslation();

    const { categories: allCategories, status: categoriesStatus } = useGetCategories();

    const [images, setImages] = useState({
        imagesToDelete: [],
        addedImages: []
    });

    if (categoriesStatus !== 'success')
        return <CircularLoading />

    async function onDeleteLogo(image, index) {
        let isDeletable = false
        try {
            isDeletable = await get(`${SHOPS}/verifyLogoBeforeDelete`, {
                params: {
                    logo: image,
                    updatingShopId: shop.id
                }
            });
            if (isDeletable) {//se l'immagine che sto eliminando è uguale a quella del def Shop vuol dire che ho inserito i dati di default e quindi non devo eliminare la foto da firebase
                let newImages = { ...images };
                newImages.imagesToDelete.push(image);//eliminare alla fine solo quando salva
            }
        } catch (e) {
            enqueueSnackbar(t('errorOccurred'), { variant: "error" });
        }
        return isDeletable;
    }

    async function onDeleteImage(image, index) {
        let isDeletable = false
        try {
            isDeletable = await get(`${SHOPS}/verifyImageBeforeDelete`, {
                params: {
                    image: image,
                    updatingShopId: shop.id
                }
            });
            if (isDeletable) {//se l'immagine che sto eliminando è uguale a quella del def Shop vuol dire che ho inserito i dati di default e quindi non devo eliminare la foto da firebase
                let newImages = { ...images };
                newImages.imagesToDelete.push(image);//eliminare alla fine solo quando salva
            }
        } catch (e) {
            enqueueSnackbar(t('errorOccurred'), { variant: "error" });
        }
        return isDeletable;
    }

    function onAddImage(image) {
        let newImages = { ...images };
        newImages.addedImages.push(image);
        setImages(newImages);
        return true;
    }

    /**
     * imposta lo stato con i valori dello Shop di default
     * @param e
     */
    function handleCopyFromDefault(e) {
        if (!disabled) {
            //imposta data come defShop
            let newShop = { ...defShop };
            delete newShop.id;
            setShop({
                ...shop,
                ...newShop,
                primary: false,
                address: null,
                number: null,
                city: null,
                addressInput: null,
                longitude: null,
                latitude: null
            });
        }
    }

    function onError(newData, errors, valid) {
        console.log(errors);
        enqueueSnackbar(errorMessages.ERROR_FORM, { variant: "error" });
    }

    function save(data, stopLoading) {
        onSave({ ...data, ...images, publicLink: urlEncode(shop.publicLink || shop.name) }, stopLoading)
    }

    const fields = [
        {
            key: 'goBack',
            type: FORM_TYPE.customElement,
            customElement: <>
                {
                    !isFirstShop &&
                    <Grid container justifyContent="space-between" className={globalClasses.marginBottom}>
                        <Button
                            onClick={(e) => {
                                removeImagesFirebase(images.addedImages);//rimuovi le immagini quando si va indietro
                                goBack(e);
                            }}
                            className={globalClasses.backButton}
                        >
                            <ArrowBack className={globalClasses.backButtonIcon} />
                            {t('goBack')}
                        </Button>
                    </Grid>
                }
            </>
        },
        {
            key: 'firstShop',
            type: FORM_TYPE.customElement,
            customElement: <>
                {
                    isFirstShop &&
                    <Grid item xs={12} className={globalClasses.title}>
                        <Typography variant={'h5'}>{t('createFirstShop')}</Typography>
                        <Typography
                            variant={'caption'}> {t('canBeUsedAsModelForNextShops')}. <br /> {t('enteredDataCouldBeEdited')}
                        </Typography>
                    </Grid>
                }
            </>
        },
        ((!!shop.id && !disabled) && {
            key: 'publikLinkDesc',
            type: FORM_TYPE.customElement,
            customElement: <Grid item xs={12} className={globalClasses.title}>
                {t('shortLinkDescription')}:
                <Grid item xs={12} className={globalClasses.title}>
                    {shop.oldPublicLink ? <CopyableLink url={shop.oldPublicLink} /> :
                        <Typography>{t('fillFormFirst')}</Typography>}
                </Grid>
            </Grid>
        }),
        ((!!shop.id && !disabled) && {
            key: 'publicLink',
            type: FORM_TYPE.textInput,
            label: t('shortLink'),
            disabled: disabled,
            params: {
                helperText: t('shortLinkPersonalization'),
                startAdornment: shop.id + '-'
            },
            editValueBeforeChange: urlEncode,
            tooltip: t('saveFirst')
        }),
        !disabled && {
            key: 'dataShow',
            type: FORM_TYPE.customElement,
            customElement: <Grid item xs={12} className={globalClasses.title}>
                <Typography> {t('enteredDataWillBeDisplayedToUsers')} </Typography>
            </Grid>
        },
        !disabled && {
            key: 'defaultValues',
            type: FORM_TYPE.customElement,
            customElement: <>
                {
                    (defShop && !disabled) &&
                    <Grid container justifyContent="space-between" className={globalClasses.marginBottom}>
                        <Grid item>
                            <Button
                                onClick={handleCopyFromDefault}
                                className={globalClasses.backButton}
                            >
                                <Album className={globalClasses.backButtonIcon} />
                                {t('copyDataFromFirstShop')}
                            </Button>
                        </Grid>
                    </Grid>
                }
            </>
        },
        {
            key: 'name',
            label: t('shopName'),
            type: FORM_TYPE.textInput,
            disabled: disabled,
            params: { helperText: t('nameDisplayedToUsers') },
        },
        {
            key: 'claim',
            label: t('insertSlogan'),
            type: FORM_TYPE.textInput,
            disabled: disabled,
        },
        {
            key: 'description',
            label: t('description'),
            type: FORM_TYPE.textInput,
            disabled: disabled,
        },
        {
            key: 'website',
            label: t('webAddress'),
            type: FORM_TYPE.textInput,
            disabled: disabled,
        },
        {
            key: 'phone',
            label: t('phoneNumber'),
            type: FORM_TYPE.phone,
            disabled: disabled,
            validation: (data) => !isEmpty(data.phonePrefix) && (isEmpty(data.phone) ? errorMessages.MANDATORY : isValidPhone(data.phone)),
            customErrorMessage: errorMessages.INVALID_PHONE
        },
        {
            key: 'logo',
            label: t('logo'),
            type: FORM_TYPE.singleImage,
            params: {
                folder: 'shops/logos',
                onDeleteImage: !disabled && onDeleteLogo,
                onAddImage: !disabled && onAddImage,
                imageRatioLabel: !disabled && '1:1',
                imageDimensionLabel: !disabled && '256px x 256px'
            },
            disabled: disabled,
        },
        {
            key: 'images',
            label: t('shopImages'),
            type: FORM_TYPE.Images,
            params: {
                folder: 'shops/images',
                onDeleteImage: !disabled && onDeleteImage,
                onAddImage: !disabled && onAddImage,
                imageRatioLabel: !disabled && '2:1',
                imageDimensionLabel: !disabled && '600px x 300px'
            },
            disabled: disabled,
        },
        {
            key: 'categories',
            label: t('shopTag'),
            type: FORM_TYPE.categories,
            params: {
                categories: allCategories,
            },
            disabled: disabled,
        },
        {
            key: 'isEcommerce',
            type: FORM_TYPE.radioButtonSwitchContainer,
            params: {
                labelTrue: t('ecommerce'),
                labelFalse: t('physicalShop'),
                isInnerElementVisible: (isEcommerce) => !isEcommerce
            },
            disabled: disabled,
            customFields: [
                {
                    key: 'addressInput',
                    label: t('address'),
                    type: FORM_TYPE.addressAutocompleteWithMap,
                    disabled: disabled,
                    xs: 12,
                    md: 12,
                    validation: (data) => data.isEcommerce || (!data.isEcommerce && !isEmpty(data.addressInput))
                },
            ]
        },
        !hideBankInfo && {
            key: 'bankInfoDescription',
            type: FORM_TYPE.customElement,
            customElement: <Grid item xs={12} className={globalClasses.marginBottomSmall}>
                <Typography align={'center'} variant={"h6"}>{t('bankInfo')}</Typography>
                {
                    !disabled &&
                    <Typography className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)} align={'center'}
                        variant={'body1'}>
                        {t('bankInfoDescription')}
                    </Typography>
                }
            </Grid>
        },
        !hideBankInfo && {
            key: 'accountHolder',
            label: t('accountHolder'),
            type: FORM_TYPE.textInput,
            disabled: disabled,
            validation: (data) => !data.iban || (data.iban && !isEmpty(data.accountHolder)),
            required: false
        },
        !hideBankInfo && {
            key: 'iban',
            label: t('iban'),
            type: FORM_TYPE.textInput,
            disabled: disabled,
            required: false
        },
        !hideBankInfo && {
            key: 'bic',
            label: t('swift'),
            type: FORM_TYPE.textInput,
            disabled: disabled,
            validation: (data) => !data.iban || (data.iban && !isEmpty(data.bic)),
            required: false
        },
        !disabled && {
            key: 'bankInfoDisclaimer',
            type: FORM_TYPE.customElement,
            customElement: <Grid item xs={12} className={clsx(globalClasses.title, globalClasses.marginBottom)}>
                <Typography variant={"caption"}> {t('bankInfoDisclaimer')} </Typography>
            </Grid>
        },
        !disabled && {
            key: 'mandatoryFields',
            type: FORM_TYPE.customElement,
            customElement: <Typography variant={'caption'}>{t('mandatoryFields')}</Typography>
        },
        !disabled && {
            key: 'visible',
            label: (data) => data.visible ? t('shopVisible') : t('shopIsNotVisible'),
            type: FORM_TYPE.button,
            params: (data, setData) => ({
                onClick: () => !disabled && setData({ ...data, visible: !data.visible }),
                className: data.visible ? globalClasses.colorGreen : globalClasses.colorRed,
                disabled: disabled
            }),
            customElement: (data) => data.visible ? t("visible") : t("hidden")
        }
    ];

    return (
        <Box sx={{
            '& .Mui-disabled': {
                '-webkit-text-fill-color': 'black'
            }
        }}>
            <FormBuilder fields={fields} onSubmit={!disabled && save} onError={onError} formData={shop}
                setFormData={setShop} />
        </Box>
    );

}
