import { Box, Grid, Typography } from "@mui/material";
import { Pill } from "./SettingsPills";
import { useTranslation } from "react-i18next";
import MultiButton from "./buttons/MultiButton";
import Info from "./Info";

/**
 * componente absolute per mostrare la legenda su mappa
 * @param {legendTitle, labels:[{title, actionButtons:{value, buttons}}]} 
 * @returns 
 */
export default function MapLegend({ legendTitle, labels = [] }) {
    const { t } = useTranslation();

    return (
        <Box sx={{
            position: 'absolute',
            zIndex: 1,
            //marginTop: 'calc(16px + 3em)',
            width: { md: '30vw', xs: 'calc(100vw - 96px)' },
            minWidth: { md: '34em' },
        }}>
            <Pill lg={12} open={true} pill={{
                id: 0,
                title: legendTitle,
                component: <Grid container direction="column">
                    {
                        labels.map(
                            (label, i) => <div key={i}>
                                <Typography variant="subtitle1">{label.title}</Typography>
                                {
                                    label.actionButtons &&
                                    <MultiButton
                                        value={label.actionButtons.value}
                                        buttons={label.actionButtons.buttons}
                                    >
                                    </MultiButton>}
                            </div>
                        )
                    }
                </Grid>
            }} index={0} />
        </Box>
    )
}