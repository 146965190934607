import { Circle, InfoWindow, Marker, Polyline } from "@react-google-maps/api";
import React, { useMemo, useState } from "react";
import MapContainer from "../MapContainer";
import dayjs from "dayjs";
import startIcon from "../../images/icons/pin-start.svg"
import finishIcon from "../../images/icons/pin-stop.svg"
import pauseIcon from "../../images/icons/pin-pause.svg"
import resumeIcon from "../../images/icons/pin-resume.svg"
import skippedIcon from "../../images/icons/pin-skipped.svg"
import homeIcon from "../../images/icons/homeIcon.png"
import workIcon from "../../images/icons/workIcon.png"
import { partialType } from "../../constants/partialType";
import OrganizationAreaLayer from "./OrganizationAreaLayer";
import { LEG_COLOR, LEG_TYPE, useGetLegTypeTranslation } from "../../constants/legType";
import MapLegend from "../MapLegend";
import { useTranslation } from "react-i18next";
import ColoredLabel from "../ColoredLabel";

const StyledPolyline = ({ path, color }) => {
    return <Polyline path={path} options={{ strokeColor: color }} />
}

export default function MapSession({
    polylines,
    zoom,
    points,
    polylineType,
    homePoints,
    workPoints,
    isShowingPartialPoints,
    sessionPointsForArea,
    version,
    legs,
    isLayerVisible,
    cyclePaths,
    color
}) {

    let [infoWindow, setInfoWindow] = useState(null)
    let [homeAddressInfoWindow, setHomeAddressInfoWindow] = useState(null);
    const { t } = useTranslation();
    const legTypeTranslation = useGetLegTypeTranslation();

    

    const getPolylinePaths = (polylines, type) => {
        return {
            type: type,
            polyline: polylines.polyline && polylines.polyline.map(p => window.google.maps.geometry.encoding.decodePath(p)),
            rawPolyline: polylines.rawPolyline && polylines.rawPolyline.map(p => window.google.maps.geometry.encoding.decodePath(p)),
            gmapsPolyline: polylines.gmapsPolyline && polylines.gmapsPolyline.map(p => window.google.maps.geometry.encoding.decodePath(p))
        }
    }

    /*path=[
        {polyline:[
            {//gmapPolyline}
        ]}
    ]*/
    let paths = useMemo(() => {
        let paths = [];
        if (version === 2) {
            legs?.forEach(leg => {
                paths.push(getPolylinePaths(leg, leg.type));
            });
        } else {
            paths = [
                getPolylinePaths(polylines, LEG_TYPE.NONE)
            ];
        }
        return paths;
    }, [legs, polylines, version]);

    const center = useMemo(() => paths && paths[0]?.polyline ? paths[0].polyline[0][0] : undefined,
        [paths]
    );

    const dottedLineSymbol = {
        path: "M 0,-1 0,1",
        strokeOpacity: 0.5,
        scale: 2.5,
    };

    let tolerances = [];
    homePoints && homePoints.map(hp => {
        let tol = tolerances.findIndex(t => t.tolerance === hp.tolerance)
        if (tol !== -1) {
            tolerances[tol].organizations = tolerances[tol].organizations + " - \"" + hp.organization + "\""
        } else {
            tolerances.push({ tolerance: hp.tolerance, lat: hp.lat, lng: hp.lng, organizations: "\"" + hp.organization + "\"" })
        }
    })
    tolerances.sort((a, b) => b.tolerance - a.tolerance)

    const getPausePath = (path, index, paths) => {
        if (paths[index + 1]) {
            const dottedPath = [path[path.length - 1], paths[index + 1][0]]
            return <Polyline path={dottedPath}
                options={{
                    strokeOpacity: 0,
                    icons: [
                        {
                            icon: dottedLineSymbol,
                            offset: "0",
                            repeat: "13px",
                        },
                    ],
                }} />
        }
    }

    const getPolyline = () => {
        return (
            <div>
                {
                    /*path=[
                        {polyline:[
                            {}
                        ]}
                    ]*/
                    paths.map((p, index) => {
                        //polylineType è o polyline o rawPolyline o gmapsPolyline
                        //mostro la polyline del tipo definito da polylineType
                        let path = p[polylineType]
                        if (polylineType === 'rawPolyline' && !p[polylineType]) {//rowpolyline viene ricreata se non esiste
                            let newPath = []
                            points = points.slice().sort((a, b) => a.timestamp - b.timestamp);
                            points.forEach(p => {
                                newPath.push({ lat: p.latitude, lng: p.longitude })
                            })
                            return <StyledPolyline path={newPath} color={LEG_COLOR[p.type]} />
                        }
                        return (
                            path &&
                            <div key={index}>
                                {
                                    path.map(
                                        (innerPath, i) => {
                                            return (
                                                <div key={i}>
                                                    <StyledPolyline path={innerPath} color={LEG_COLOR[p.type]} />
                                                    {getPausePath(innerPath, i, path)}
                                                </div>
                                            )
                                        }
                                    )
                                }
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    return (
        <>
            {version === 2 &&
                <MapLegend
                    legendTitle={t('legend')}
                    labels={
                        Object.values(LEG_TYPE).filter(t => t !== -1).map(legType => ({
                            title: <ColoredLabel
                                color={LEG_COLOR[legType]}
                                label={legTypeTranslation[legType]}
                            />
                        })
                        )
                    }
                ></MapLegend>
            }
            <MapContainer zoom={zoom} center={center} isLayerVisible={isLayerVisible} cyclePaths={cyclePaths} color={color}>

                {getPolyline()}

                {points && points.map((p, index) => {
                    let icon = null
                    let zIndex = 0
                    if (p.type === partialType.START) {
                        icon = { url: startIcon, scaledSize: new window.google.maps.Size(35, 48) }
                        zIndex = 1000
                    }
                    if (p.type === partialType.END) {
                        icon = { url: finishIcon, scaledSize: new window.google.maps.Size(35, 48) }
                        zIndex = 1000
                    }
                    if (p.type === partialType.PAUSE) {
                        icon = { url: pauseIcon, scaledSize: new window.google.maps.Size(35, 48) }
                        zIndex = 999
                    }
                    if (p.type === partialType.RESUME) {
                        icon = { url: resumeIcon, scaledSize: new window.google.maps.Size(35, 48) }
                        zIndex = 999
                    }
                    if (p.type === partialType.SKIPPED) {
                        icon = { url: skippedIcon, scaledSize: new window.google.maps.Size(30, 42) }
                        zIndex = -1
                    }

                    if (isShowingPartialPoints || zIndex > 0)
                        return <Marker key={index} position={{ lat: p.latitude, lng: p.longitude }} onClick={() => setInfoWindow(index)}
                            icon={icon} zIndex={zIndex}>

                            {infoWindow && infoWindow === index &&
                                <InfoWindow onCloseClick={() => setInfoWindow(null)}>
                                    <div>{dayjs(new Date(p.timestamp)).format("HH:mm:ss DD/MM/YYYY")}</div>
                                </InfoWindow>}

                        </Marker>
                })}

                {
                    homePoints && tolerances.length !== 0 && tolerances.map((point, index) => {
                        return <div>

                            <Circle center={point} radius={point.tolerance}
                                onClick={(e) => setHomeAddressInfoWindow({ lat: e.latLng.lat(), lng: e.latLng.lng(), tolerance: point.tolerance })} />

                            {homeAddressInfoWindow && homeAddressInfoWindow.tolerance === point.tolerance &&
                                <InfoWindow onCloseClick={() => setHomeAddressInfoWindow(null)} position={homeAddressInfoWindow}>
                                    <div>{point.organizations}</div>
                                </InfoWindow>
                            }

                            <Marker key={index} position={point} icon={{ url: homeIcon, scaledSize: new window.google.maps.Size(48, 48) }} zIndex={999} />
                        </div>
                    })

                }

                {
                    workPoints && workPoints.map((point, index) => {
                        return <div>
                            <Circle center={point} radius={point.tolerance} />
                            <Marker key={index} position={point} icon={{ url: workIcon, scaledSize: new window.google.maps.Size(48, 48) }} zIndex={999} />
                        </div>
                    })
                }

                {
                    sessionPointsForArea.map(sp => {
                        return <OrganizationAreaLayer organizationId={sp.organizationId} />
                    })
                }

            </MapContainer>
        </>)
}
