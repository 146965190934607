import React, {useEffect, useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import { FormControlLabel, FormGroup, Grid, Slider, Switch, Typography, Checkbox } from "@mui/material";
import Heatmap from "./Heatmap";
import StartIconButton from "../../components/buttons/StartIconButton";
import SaveIcon from "@mui/icons-material/Save";
import Box from "@mui/material/Box";
import TextInput from "../../components/forms/TextInput";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import useGetSessionType from "../../constants/sessionType";
import { LEG_TYPE, useGetLegTypeTranslation } from "../../constants/legType";
import { HIDE_WIP, SHOW_WIP } from "../../constants/debug";
import NTMSelect from "../../components/NTMSelect";
import {useParams} from "react-router-dom";
import {getCyclePaths} from "../../services/ContentManager";
import {ColorPicker} from "mui-color";

const useStyles = makeStyles(theme => ({
    slider: {
        padding: theme.spacing(1)
    },
    sliderText: {
        paddingRight: "0.8rem",
    },
    select: {
        width: "12rem"
    },
    secondColumn: {
        paddingTop: "0.33rem"
    }
}));

export default function HeatmapContainer() {
    //todo: filtri e dati di default per open data
    const { t } = useTranslation();
    let classes = useStyles();
    const today = dayjs().format("YYYY-MM-DD");
    let [filters, setFilters] = useState({
        isHomeWorkPath: null,
        minAge: 14,
        maxAge: 65,
        type: -1,//deprecated
        minTime: "00:00",
        maxTime: "23:59",
        //minDate: dayjs(new Date(new Date(firstDayOfMonth).setMonth(firstDayOfMonth.getMonth() - 1))).format("YYYY-MM-DD"),
        //maxDate: dayjs(new Date(new Date(firstDayOfMonth).setDate(firstDayOfMonth.getDate() - 1))).format("YYYY-MM-DD"),
        minDate: dayjs().subtract(1, 'day').format("YYYY-MM-DD"),
        maxDate: dayjs().format("YYYY-MM-DD"),
        legTypes: null,
        legTypesSelect: [],//usato per mostrare la select non per mandare il filtro
    });
    let sessionType = useGetSessionType();
    sessionType = sessionType.filter(st => st.id === 0 || st.id === 1);
    sessionType.unshift({ id: -1, name: t('all') })
    const {id} = useParams();
    const [cyclePaths, setCyclePaths] = useState([]);

    //il primo è il valore che utilizzerà il ColorPicker, hex e alpha verranno usati per lo stile della mappa
    const [color, setColor] = useState({pickerValue: '#0b28e8', hex: '#0b28e8', alpha: 0.4});

    useEffect(() => {
        id && cyclePaths.length === 0
        && getCyclePaths(id).then(paths => setCyclePaths(paths));
    },[id])

    let [isShowingOrganizationArea, setIsShowingOrganizationArea] = useState(true)

    let defSavedFilters = { ...filters };
    delete defSavedFilters.legTypesSelect;
    let [savedFilters, setSavedFilters] = useState(defSavedFilters)

    let [showMarkers, setShowMarkers] = useState({ startStop: false, pauseResume: false });
    const [isLayerVisible, setIsLayerVisible] = useState(false);

    const legTypeTranslation = useGetLegTypeTranslation();

    const toggleLayerVisibility = () => {
        setIsLayerVisible(!isLayerVisible);
    };

    return (
        <Grid container >
            {<Grid container >
                <Grid item xs={10}>
                    <Grid container justifyContent={"space-evenly"} spacing={4}>
                        <Grid xs={12} md={4} item>
                            <FormControlLabel
                                control={
                                    <Switch checked={filters.isHomeWorkPath} color="primary"
                                        onChange={evt => setFilters({
                                            ...filters,
                                            isHomeWorkPath: evt.target.checked ? true : null
                                        })} />
                                }
                                label={t('onlyHomeWorkPath')}
                            />
                        </Grid>
                        <Grid xs={12} md={8} item className={classes.slider}>
                            <Grid container justifyContent={"center"} alignItems={"center"} direction={"row"}>
                                <Grid item>
                                    <Typography gutterBottom className={classes.sliderText}>{t('age')}</Typography>
                                </Grid>
                                <Grid>
                                    <Box sx={{ width: 300 }}>
                                        <Slider
                                            value={[filters.minAge, filters.maxAge]}
                                            onChange={(event, newValues) => setFilters({
                                                ...filters,
                                                minAge: newValues[0],
                                                maxAge: newValues[1]
                                            })}
                                            valueLabelDisplay="auto" max={110}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item md={8} xs={12}>
                            <Grid container justifyContent={"space-evenly"} spacing={4}>
                                <Grid item xs={6} md={6}>
                                    <TextInput required label={t('startPeriod')} value={filters.minDate} type="date"
                                        color="dark" max={today}
                                        onTextChange={value => {
                                            if (filters.maxDate < value.trim()) setFilters({
                                                ...filters,
                                                maxDate: value.trim(),
                                                minDate: value.trim()
                                            })
                                            else setFilters({ ...filters, minDate: value.trim() })
                                        }} />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <TextInput required label={t('endPeriod')} value={filters.maxDate} type="date"
                                        color="dark" max={today}
                                        onTextChange={value => {
                                            if (value.trim() < filters.minDate) setFilters({
                                                ...filters,
                                                minDate: value.trim(),
                                                maxDate: value.trim()
                                            })
                                            else setFilters({ ...filters, maxDate: value.trim() })
                                        }} />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <TextInput required label={t('timeSlotStart')} value={filters.minTime}
                                        type="time" color="dark"
                                        onTextChange={value => {
                                            if (filters.maxTime < value.trim()) setFilters({
                                                ...filters,
                                                maxTime: value.trim(),
                                                minTime: value.trim()
                                            })
                                            else setFilters({ ...filters, minTime: value.trim() })
                                        }} />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <TextInput required label={t('timeSlotEnd')} value={filters.maxTime} type="time"
                                        color="dark"
                                        onTextChange={value => {
                                            if (value.trim() < filters.minTime) setFilters({
                                                ...filters,
                                                minTime: value.trim(),
                                                maxTime: value.trim()
                                            })
                                            else setFilters({ ...filters, maxTime: value.trim() })
                                        }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Grid container justifyContent={"space-evenly"} spacing={4}>
                                <Grid item xs={12} md={12}>
                                    <NTMSelect
                                        color={'primary'}
                                        label={t('legType')}
                                        items={Object.values(LEG_TYPE)
                                            .filter(l => l !== LEG_TYPE.NONE)
                                            .map(l => ({ value: l + "", text: legTypeTranslation[l] }))
                                        }
                                        onChange={(value) => setFilters(() => {
                                            //...filters, legType: value === LEG_TYPE.NONE ? null : value 
                                            let newFilters = { ...filters };
                                            newFilters.legTypesSelect = newFilters.legTypesSelect || [];
                                            newFilters.legTypesSelect.push(value);
                                            return newFilters;
                                        })}
                                        value={
                                            filters.legTypesSelect || []
                                        }
                                        onDelete={(value) => setFilters(() => {
                                            let newFilters = { ...filters };
                                            newFilters.legTypesSelect = newFilters.legTypesSelect || [];
                                            newFilters.legTypesSelect = newFilters.legTypesSelect.filter(l => l !== value);
                                            return newFilters;
                                        })}
                                        required
                                    />
                                </Grid>
                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <StartIconButton onClick={() => setSavedFilters((f) => {
                        let newFilters = { ...filters };
                        //legTypesSelect è un valore momentaneo usato per visualizzazione. Alla GET viene inviato un filtro con leg type separati da virgola
                        if(newFilters.legTypesSelect.length === 0){
                            newFilters.legTypes = null
                        }else{
                            newFilters.legTypes = newFilters.legTypesSelect.join(',');
                        }

                        delete newFilters.legTypesSelect;
                        return newFilters;
                    })} title={t('save')}
                        startIcon={<SaveIcon />}
                        disabled={JSON.stringify({ ...filters, legTypes: filters.legTypesSelect.length === 0 ? null: filters.legTypesSelect.join(','),  legTypesSelect: undefined }) === JSON.stringify({ ...savedFilters, legTypesSelect: undefined })} />
                </Grid>
            </Grid>}


            <Grid container justifyContent="space-between" xs={12}>
                <Grid item
                      sx={{
                          width: '25%',
                          minWidth: t('hideInitiativeArea').length + 'ex'
                      }}
                >
                    <StartIconButton
                        title={isShowingOrganizationArea ? t('hideInitiativeArea') : t('showInitiativeArea')}
                        onClick={() => setIsShowingOrganizationArea(prevState => !prevState)}
                        startIcon={isShowingOrganizationArea ? <VisibilityOff/> : <Visibility/>}/>
                    { cyclePaths.length > 0 ?
                        <Grid container alignItems={"center"}>
                            <StartIconButton
                                onClick={toggleLayerVisibility}
                                title={isLayerVisible ? t('hideCyclePath') : t('showCyclePath')}
                                startIcon={isLayerVisible ? <VisibilityOff/> : <Visibility/>}/>
                            {isLayerVisible &&
                                <ColorPicker
                                    value={color.pickerValue}
                                    onChange={newColor => {
                                        setColor({
                                            pickerValue: newColor,
                                            hex: newColor.css.backgroundColor.slice(0,7),
                                            alpha: newColor.alpha.toFixed(1)
                                        })
                                    }}
                                    hideTextfield={true}
                                    hslGradient={false}/>
                            }
                        </Grid>
                        :
                        <StartIconButton
                            title={t('cyclePathsNotAvailable')}
                            disabled={true}
                        />
                    }
                </Grid>

                <Grid container
                    sx={{ width: '25%' }}
                    justifyContent={"right"}
                >
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={showMarkers.startStop} name="startStop"
                                    onChange={event => {
                                        setShowMarkers({ ...showMarkers, startStop: event.target.checked })
                                    }}
                                />
                            }
                            label={t('startStopPartials')}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={showMarkers.pauseResume} name="pauseResume"
                                    onChange={event => {
                                        setShowMarkers({ ...showMarkers, pauseResume: event.target.checked })
                                    }}
                                />
                            }
                            label={t('pauseResumePartials')}
                        />
                    </FormGroup>
                </Grid>

            </Grid>
            <Heatmap filters={savedFilters} showOrganizationArea={isShowingOrganizationArea} showMarkers={showMarkers} isLayerVisible={isLayerVisible} cyclePaths={cyclePaths} color={color}>

            </Heatmap>
        </Grid>
    );
}

