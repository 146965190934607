import {useParams} from "react-router-dom";
import {useGetOrganizationCodes} from "../../services/ContentManager";
import {useSnackbar} from "notistack";
import {useQueryClient} from "react-query";
import NTMXGrid, {timestampFormatter, timestampFormatterWithTimezone} from "../../components/NTMXGrid";
import React,{useContext,useState} from "react";
import GenerateCodesModal from "../../components/modals/GenerateCodesModal";
import {CODES,getErrorMessage,ORGANIZATIONS,post,put} from "../../services/Client";
import Grid from "@mui/material/Grid";
import StartIconButton from "../../components/buttons/StartIconButton";
import EditIcon from "@mui/icons-material/Edit";
import EditCodesModal from "../../components/modals/EditCodesModal";
import AddIcon from "@mui/icons-material/Add";
import RenderUserRedirect from "../../components/cellRender/RenderUserRedirect";
import dayjs from "dayjs";
import {AbilityContext} from "../../services/Can";
import {resources} from "../../services/ability";
import {useTranslation} from "react-i18next";

export default function Codes() {

    const {t} = useTranslation();
    let {id} = useParams();
    let {codes, status} = useGetOrganizationCodes(id);
    const {enqueueSnackbar} = useSnackbar();
    let queryClient = useQueryClient();
    let [isGenerating, setIsGenerating] = useState(false);
    let [isEditing, setIsEditing] = useState(false);
    let [selectedRows, setSelectedRows] = useState([]);
    const ability = useContext(AbilityContext);
    const canReadPrivateData = ability.can("read", resources.PRIVATEUSERDATA)
    codes = codes.slice().sort((a , b) => b.lastModifiedDate - a.lastModifiedDate);

    const defaultColumns = [
        {
            headerName: t('code'),
            field: 'code',
            width: 180,
        },
        {
            headerName: t('startDate'),
            field: 'startDate_date',
            width: 180,
            ...timestampFormatter
        },
        {
            headerName: t('endDate'),
            field: 'endDate_date',
            width: 180,
            ...timestampFormatter
        },
        {
            headerName: t('activationDate'),
            field: 'activationDate',
            width: 250,
            ...timestampFormatterWithTimezone
        },
        {
            headerName: t('deletedAt'),
            field: 'deletedDate',
            width: 250,
            ...timestampFormatter
        },
        {
            headerName: t('userID'),
            field: 'uid',
            width: 280
        },
        {
            headerName: t('username'),
            field: 'user',
            width: 200,
            renderCell: (params) => <RenderUserRedirect value={params.value} url={params.row.uid}/>
        },
        {
            headerName: t('email'),
            field: 'email',
            width: 220,
        },
    ];

    const generateCodes = (values) => {
        enqueueSnackbar(t('saving...'), {variant: "info"});
        post(ORGANIZATIONS+"/"+id+"/"+CODES, {body: values})
            .then(() => enqueueSnackbar(t('saved'), {variant: "success"}))
            .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
            .finally(() => queryClient.invalidateQueries([ORGANIZATIONS, {id: id}, CODES]));
    }

    const editCodes = (values) => {
        values = {...values, selectedEnrollments: selectedRows}
        enqueueSnackbar(t('saving...'), {variant: "info"});
        put(ORGANIZATIONS+"/"+id+"/"+CODES, {body: values})
            .then(() => enqueueSnackbar(t('saved'),{variant : "success"}))
            .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
            .finally(() => queryClient.invalidateQueries([ORGANIZATIONS, {id: id}, CODES]));
    }

    return <div>
        <NTMXGrid
            key={"manager-codes-tables"}
            checkboxSelection={canReadPrivateData}
            columns={defaultColumns}
            rows={codes || []}
            title={t('codes')}
            loading={status === "loading"}
            rightButton={canReadPrivateData && <Grid container justifyContent={"flex-end"}>
                            <StartIconButton onClick={() => setIsEditing(true)} title={t('edit')} startIcon={<EditIcon/>} disabled={selectedRows.length === 0}/>
                            <StartIconButton onClick={() => setIsGenerating(true)} title={t('generateCodes')} startIcon={<AddIcon/>}/>
                        </Grid>}
            onSelectionModelChange={(newSelection) => {
                setSelectedRows(newSelection);
            }}
            selectionModel={selectedRows}
        />
        {canReadPrivateData && <GenerateCodesModal open={!!isGenerating} onClose={() => setIsGenerating(false)} onSubmit={generateCodes}/>}
        {canReadPrivateData && <EditCodesModal open={!!isEditing} onClose={() => setIsEditing(false)} onSubmit={editCodes}/>}
    </div>
}
