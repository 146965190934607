import React, {useEffect, useState} from "react";
import {getCyclePaths, useGetOrganization} from "../../services/ContentManager";
import Grid from "@mui/material/Grid";
import GestureIcon from '@mui/icons-material/Gesture';
import CancelIcon from '@mui/icons-material/Cancel';
import StartIconButton from "../../components/buttons/StartIconButton";
import AddIcon from "@mui/icons-material/Add";
import AreaMap from "./AreaMap";
import SelectCitiesModal from "../../components/modals/SelectCitiesModal";
import {useParams} from "react-router-dom";
import {getErrorMessage, ORGANIZATIONS, put} from "../../services/Client";
import {useSnackbar} from "notistack";
import {useQueryClient} from "react-query";
import {useTranslation} from "react-i18next";
import TextInput from "../../components/forms/TextInput";
import {MenuItem} from "@mui/material";
import {markerType} from "../../constants/markerType";
import {MenuButton} from "../../components/buttons/MenuButton";
import NTMButton from "../../components/NTMButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {ColorPicker} from "mui-color";

export default function AreaManager() {
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();
    let queryClient = useQueryClient();
    let {id} = useParams();
    let {organization} = useGetOrganization(id);
    let [isEditingArea, setIsEditingArea] = useState(false);
    let [isAddingCity, setIsAddingCity] = useState(false);
    let [isDrawingArea, setIsDrawingArea] = useState(false);
    let [newIstat, setNewIstat] = useState();
    const [markersToShow, setMarkersToShow] = useState(markerType.REPORT);
    const [isLayerVisible, setIsLayerVisible] = useState(false);
    const [cyclePaths, setCyclePaths] = useState([]);

    //il primo è il valore che utilizzerà il ColorPicker, hex e alpha verranno usati per lo stile della mappa
    const [color, setColor] = useState({pickerValue: '#0b28e8', hex: '#0b28e8', alpha: 0.4});

    useEffect(() => {
        cyclePaths.length === 0
        && getCyclePaths(id).then(paths => setCyclePaths(paths));
    },[]);

    const putOrganization = (newData) => {
        enqueueSnackbar(t('saving...'), {variant: "info"});

        put(ORGANIZATIONS, {body: newData, elem: organization.id})
            .then(() => {
                enqueueSnackbar(t('saved'), {variant: "success"})
            })
            .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
            .finally(() => queryClient.invalidateQueries(ORGANIZATIONS, {id: organization.id}));
    }

    const saveAdding = (newIstat) => {
        setNewIstat(newIstat)
        setIsAddingCity(false)
    }

    const onPolygonComplete = (polygon) => {
        let newPolygon = [];
        let updatedGeojson = [];

        polygon.getPaths().forEach(p => p.forEach(pa => newPolygon.push(pa.toJSON())));

        if (newPolygon.length < 3) {
            polygon.visible = false;
            enqueueSnackbar(t('selectAtLeast3Points'), {variant: "error"});
            return;
        }

        if (organization.geojson) {
            updatedGeojson = JSON.parse(organization.geojson);
            updatedGeojson.push(newPolygon);
        } else updatedGeojson = [newPolygon];

        putOrganization({geojson: JSON.stringify(updatedGeojson)});

        setIsDrawingArea(false);
        polygon.visible = false;
    }

    const toggleLayerVisibility = () => {
        setIsLayerVisible(!isLayerVisible);
    };

    return <div>
        <Grid container justifyContent="space-between">
            <Grid item>
                {!isDrawingArea && !isEditingArea &&
                    <StartIconButton title={t('addCity')} onClick={() => setIsAddingCity(true)}
                                     startIcon={<AddIcon/>}/>}
            </Grid>
            <Grid item>
                {cyclePaths.length > 0 ? (
                    <Grid container alignItems="center">
                        <StartIconButton
                            onClick={toggleLayerVisibility}
                            title={isLayerVisible ? t('hideCyclePath') : t('showCyclePath')}
                            startIcon={isLayerVisible ? <VisibilityOff/> : <Visibility/>}
                        />
                        {isLayerVisible && (
                            <ColorPicker
                                value={color.pickerValue}
                                onChange={newColor => {
                                    setColor({
                                        pickerValue: newColor,
                                        hex: newColor.css.backgroundColor.slice(0, 7),
                                        alpha: newColor.alpha.toFixed(1)
                                    });
                                }}
                                hideTextfield={true}
                                hslGradient={false}
                            />
                        )}
                    </Grid>
                ) : (
                    <StartIconButton
                        title={t('cyclePathsNotAvailable')}
                        disabled={true}
                    />
                )}
            </Grid>
            <Grid item>
                {!isEditingArea && (isDrawingArea ?
                    <StartIconButton title={t('abort')} onClick={() => setIsDrawingArea(false)}
                                     startIcon={<CancelIcon/>}/>
                    : <StartIconButton title={t('drawNewArea')} onClick={() => setIsDrawingArea(true)}
                                       startIcon={<GestureIcon/>}/>)}
            </Grid>

        </Grid>
        <Grid container justifyContent={"center"}>
            {Object.values(markerType).map(option => <Grid item>
                <NTMButton
                    textVariant
                    onClick={() => {
                        setMarkersToShow(option)
                        if (isLayerVisible) setIsLayerVisible(!isLayerVisible)
                    }}
                    title={t(option)} selected={markersToShow === option}
                />
            </Grid>)}
            <Grid item>
                {/*<MenuButton*/}
                {/*    title={t(markersToShow)}*/}
                {/*    items={Object.values(markerType).map(type => ({value: type, text: t(type)}))}*/}
                {/*    onChange={setMarkersToShow}*/}
                {/*/>*/}
            </Grid>
        </Grid>

        <AreaMap
            putOrganization={putOrganization}
            newIstat={newIstat}
            isDrawingArea={isDrawingArea}
            onPolygonComplete={onPolygonComplete}
            setIsEditingArea={setIsEditingArea}
            markersToShow={markersToShow}
            isLayerVisible={isLayerVisible}
            cyclePaths={cyclePaths}
            color={color}
        />

        <SelectCitiesModal open={isAddingCity} onClose={() => setIsAddingCity(false)} onSubmit={saveAdding}/>
    </div>
}