export const FORM_TYPE = {
    customElement: 0,
    textInput: 1,
    textItem: 2,
    phone: 3,
    singleImage: 4,
    Images: 5,
    addressAutocomplete: 6,
    addressAutocompleteWithMap: 7,
    radioButtonSwitchContainer: 8,
    switchContainer: 9,
    categories: 10,
    select: 11,
    multiSelect: 12,
    dateInput: 13,
    button: 14,
}

export const VALIDATION_EXCLUDED_TYPES = [
    FORM_TYPE.customElement,
    FORM_TYPE.radioButtonSwitchContainer,
    FORM_TYPE.switchContainer,
    FORM_TYPE.button,
    FORM_TYPE.textItem,
]

export const DEFAULT_FIELD = {
    required: true,
    validation: null,
    customErrorMessage: null,
    params: {},
    customValue: null,
    customElement: {},
    xs: 12,
    md: 6,
    onCoordinatesFetched: () => {
    },
    editValueBeforeChange: (t) => t
};