import {
	Box,
	Divider,
	FormControlLabel,
	FormLabel,
	Grid,
	InputAdornment,
	MenuItem,
	Radio,
	RadioGroup,
	Switch,
	Typography
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import makeStyles from '@mui/styles/makeStyles';
import dayjs from "dayjs";
import React, { useEffect, useLayoutEffect, useState, version } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useGetBikeType } from "../../constants/bikeType";
import { useGetProfileUserEnrollments } from "../../services/UserDataManager";
import { SettingsTooltip } from "../forms/CustomTooltip";
import TextInput from "../forms/TextInput";
import BaseModal from "./BaseModal";
import { LEG_TYPE, legTypesEndings, useGetLegTypeTranslation } from "../../constants/legType";
import { Pill } from "../SettingsPills";
import { SHOW_WIP, TEST_DATA } from "../../constants/debug";
import Info from "../Info";
import AddNewButton from "../../components/buttons/AddNewButton";
import HoverableButton from "../layout/HoverableButton";
import { Add } from "@mui/icons-material";
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import RemoveIcon from '@mui/icons-material/Remove';
import OldCreateSessionModal from "./OldCreateSessionModal";
import { useGetSessionInfo } from "../../services/ContentManager";
import ResponsiveCircularLoading from "../ResponsiveCircularLoading";
import CircularLoading from "../CircularLoading";
import { getMax, getMin, roundUp } from "../../services/helper";

const useStyles = makeStyles(theme => ({
	switchLabel: {
		color: theme.palette.secondary.light,
		'&.Mui-focused': {
			color: theme.palette.secondary.light,
		}
	},
	divider: {
		color: theme.palette.secondary.main,
		marginBottom: "0.4rem"
	}
}));

export const repeatType = {
	NONE: "0",
	DAILY: "1",
	ROUND_TRIP: "2"
};
/**
 * 
 * Session è utilizzata solo per ottenere l'id, le info vengono prelevate in seguito
 * @returns 
 */


export default function CreateSessionModal({ open, onClose, onSubmit, session = {}, type = "create", multipleSessions = [] }) {
	const { t } = useTranslation();
	let classes = useStyles();

	const _defaultValues = {
		id: null,
		uid: null,
		version: null,
		nationalKm: 0,
		legs: [],//{id,startTime,endTime,nationalKm}
		legPoints: [],//{organizationId,distance,legId}
		sessionPoints: [],//{organizationId, homeWorkDistance}
		startTime: dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
		endTime: dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
	}
	let [defaultValues, setDefaultValues] = useState(_defaultValues);
	let [values, setValues] = useState(defaultValues);
	//todo: cambniare API
	let { session: sessionInfo, status: statusSession } = useGetSessionInfo(session.id, true);

	const { uid } = useParams();
	const { enrollments = [], status: statusEnrollment } = useGetProfileUserEnrollments(uid || session.uid);

	let [error, setError] = useState({});
	let [activeOrganizations, setActiveOrganizations] = useState([]);
	let [activeOrganizationsForMultipleSessions, setActiveOrganizationsForMultipleSessions] = useState([]);
	const legTranslation = useGetLegTypeTranslation();
	const [legType, setLegType] = useState(LEG_TYPE.BIKE);

	useEffect(() => {
		//----------------------trovare le organizzazioni attive al momento della sessione------------------
		let activeEnrollments = [];
		if (open) {
			if (type === "create") {
				//se è una create seleziono gli enrollment attive ora
				activeEnrollments = enrollments.filter(e => e.endDate >= dayjs(new Date()).valueOf() && e.startDate <= dayjs(new Date()).valueOf());
			} else {
				//se è una modifica seleziono gli enrollment attive nella durata della sessione
				activeEnrollments = enrollments.filter(e => e.endDate >= session.endTime && e.startDate <= session.endTime);
			}
			if (type === "massiveEdit") {
				activeEnrollments = [...new Map(enrollments.map(e => [e.organization, ({
					organization: e.organization,
					organizationTitle: e.organizationTitle
				})])).values()]
			}
			if (JSON.stringify(activeEnrollments) !== JSON.stringify(activeOrganizations)) {
				setActiveOrganizations(activeEnrollments);
			}

			//-----non supportato. Usato per il massive edit-----
			const compressedEnrollments = [...new Map(enrollments.map(e => [e.organization, ({
				organization: e.organization,
				organizationTitle: e.organizationTitle
			})])).values()]
			activeEnrollments = compressedEnrollments.filter(ce => {
				let toUse = 0
				enrollments.filter(e => e.organization === ce.organization).forEach(e => {
					multipleSessions.forEach(s => {
						if (e.endDate >= s.endTime && e.startDate <= s.endTime) {
							toUse++
						}
					})
				})
				return toUse === multipleSessions.length;
			})
			setActiveOrganizationsForMultipleSessions(activeEnrollments)
			//-------------------
		}
		//----------------------------------------------------------------------------

		//dopo che sono state definite le organizzazioni attive vengono impostate i valori da mostrare
		if (open && (type === 'create' || statusSession === 'success')) {//se è una create lo stato della query sulla sessione non sarà mai success
			let newDefaultValues = { ...defaultValues };
			//nella edit tutti i dati mostrati sono presi dalla session info che ha mandato il server
			if (type !== 'create' && sessionInfo) {
				newDefaultValues = {
					...newDefaultValues,
					id: sessionInfo.id,
					uid: sessionInfo.uid,
					version: sessionInfo.version,
					nationalKm: sessionInfo.nationalKm,
					legs: sessionInfo.legs?.sort((a, b) => a.counter - b.counter).map(l => ({
						...l,
						startTime: dayjs(l.startTime || new Date()).format('YYYY-MM-DDTHH:mm:ss'),
						endTime: dayjs(l.endTime || new Date()).format('YYYY-MM-DDTHH:mm:ss'),
						distance: roundUp(l.nationalKm, 2),
						legCounter: l.counter,
						duration: Math.floor(l.duration / 60),//la durata viene mostrata in minuti
					})) || [],//{id,startTime,endTime,nationalKm:distance,duration, type}
					legPoints: [],//{organizationId,distance, duration,legId}
					sessionPoints: [],//{organizationId, homeWorkDistance}
					//vengono solo mostrati
					startTime: dayjs(sessionInfo.startTime || new Date()).format('YYYY-MM-DDTHH:mm:ss'),
					endTime: dayjs(sessionInfo.endTime || new Date()).format('YYYY-MM-DDTHH:mm:ss'),
				}
			}
			//usato per la creazione di sessioni multiple
			if (type === "create") newDefaultValues.repeatType = 0;

			//Si devono mostrare tutte le organizzazioni attive, 
			//e settare i valori di quelle che hanno già un session point e leg point
			//uso activeEnrollments e non activeOrganization perchè activeOrganization è uno stato che in questo punto non si è ancora aggiornato
			activeEnrollments.forEach(a => {
				let foundedSp = (sessionInfo?.sessionPoints || []).find(sp => sp.organizationId === a.organization);
				let foundedLps = (sessionInfo?.legPoints || []).filter(lp => lp.organizationId === a.organization);
				newDefaultValues.sessionPoints.push({
					id: foundedSp ? foundedSp.id : null,
					organizationId: a.organization,
					homeWorkDistance: foundedSp ? foundedSp.homeWorkDistance : 0,
					homeWorkPath: foundedSp && foundedSp.homeWorkDistance ? foundedSp.homeWorkDistance > 0 : false,
				});
				//Ogni organizzazione ha tutte le leg (eventualmente con distance = 0)
				sessionInfo?.legs?.forEach(l => {
					let lp = foundedLps.find(lp => lp.legId === l.id);
					newDefaultValues.legPoints.push({
						...l,
						organizationId: a.organization,
						distance: lp ? roundUp(lp.distance, 2) : 0,
						duration: lp ? Math.floor(lp.duration / 60) : 0,
						legId: l.id,
						//TODO viene inviato legPoint.id nullo
						id: lp ? lp.id : null,
						legCounter: l.counter
					});
				});
			});
			setDefaultValues(newDefaultValues)
			setValues(newDefaultValues);

		} else if (type === "massiveEdit") {
			let newDefaultValues = { ...defaultValues };
			
			newDefaultValues = {
				...newDefaultValues,
				legs: [],
				legPoints: [],
				sessionPoints: [],
			}
			activeEnrollments.forEach(a => {
				newDefaultValues.sessionPoints.push({
					id: null,
					organizationId: a.organization,
					homeWorkDistance: 0,
					homeWorkPath: false,
				});
			});
			
			setDefaultValues(newDefaultValues)
			setValues(newDefaultValues);

		}
	}, [open, enrollments, sessionInfo]);

	const save = () => {
		let toSaveValues = JSON.parse(JSON.stringify(values));

		//------session points------
		if (toSaveValues.sessionPoints && toSaveValues.sessionPoints.length > 0 && activeOrganizations.length > 0) {
			//selezionare i session point relativi a active organizations
			toSaveValues.sessionPoints = activeOrganizations
				.filter(org => type === 'clone' || values?.sessionPoints?.find(sp => sp.organizationId === org.organization) !== undefined)
				.map(org => {
					let sp = values?.sessionPoints?.find(sp => sp.organizationId === org.organization);

					return {
						id: type === 'clone' ? null : sp.id,//se è una clone non ha id
						organizationId: sp.organizationId,
						homeWorkDistance: sp.homeWorkPath ? calculateTotalLegDistance(toSaveValues.legs) : 0,//questa funzione deve essere fatta prima che le leg vengano rimappate perchè lavora sul campo distance che in seguito viene eliminato
					}
			});
		} else {
			toSaveValues.sessionPoints = []
		}

		//------session start and end time------
		if (type === "create" || type === "clone") {//la edit non può modificare le date. La create prende le date di inizio e fine più lontane tra le leg
			//le date devono essere delle stringhe prima di passarle a setLegTimes, quindi devono essere calcolate prima della conversione in long
			calculateLegTimes(toSaveValues.legs, toSaveValues);//ricalcolo dei tempi delle leg
			toSaveValues.startTime = getMin(toSaveValues.legs, "startTime", true);
			toSaveValues.endTime = getMax(toSaveValues.legs, "endTime", true);
			toSaveValues.version = 2;
		}
		toSaveValues.startTime = dayjs(toSaveValues.startTime, "YYYY-MM-DDTHH:mm:ss").valueOf();
		toSaveValues.endTime = dayjs(toSaveValues.endTime, "YYYY-MM-DDTHH:mm:ss").valueOf();

		//------legs------
		toSaveValues.legs = toSaveValues.legs.map(leg => {

			// if (leg.startTime > leg.endTime) {
			// 	setError({ [errorKey(leg.id, null, leg.legCounter)]: { endTime: t('invalidField') } });
			// 	throw new Error('leg endTime invalidField');
			// }

			if (leg.distance === undefined || leg.distance < 0) {
				setError({ [errorKey(leg.id, null, leg.legCounter)]: { distance: t('requiredField') } });
				throw new Error('leg distance invalidField');
			}

			if (leg.duration === undefined || leg.duration < 0) {
				setError({ [errorKey(leg.id, null, leg.legCounter)]: { duration: t('requiredField') } });
				throw new Error('leg duration invalidField');
			}
			//sovrascrittura della leg per pulizia dei dati
			return {
				id: type === 'clone' ? null : leg.id,//se è una clone le leg non hanno id
				counter: leg.legCounter,
				startTime: dayjs(leg.startTime, "YYYY-MM-DDTHH:mm:ss").valueOf(),
				endTime: dayjs(leg.endTime, "YYYY-MM-DDTHH:mm:ss").valueOf(),
				nationalKm: leg.distance,//all'inizio viene settato distance = a nationalKm per essere conforme ai leg points
				duration: Number(leg.duration) * 60,//la durata viene salvata in secondi
				type: leg.type,
			}
		});

		//------leg points------
		if (toSaveValues.legPoints && activeOrganizations.length > 0) {
			//seleziona i legpoint relativi solamente alle organizzazioni attive
			let activeOrganizationIds = activeOrganizations.map(org => org.organization);
			toSaveValues.legPoints = values?.legPoints?.filter(lp => activeOrganizationIds.indexOf(lp.organizationId) >= 0).map(lp => {
				if (lp.distance < 0) {
					setError({ [errorKey(lp.legId, lp.organizationId, lp.legCounter)]: { distance: t('invalidField') } });
					throw new Error('leg point distance invalidField');
				}

				if (lp.duration < 0) {
					setError({ [errorKey(lp.legId, lp.organizationId, lp.legCounter)]: { duration: t('invalidField') } });
					throw new Error('leg point duration invalidField');
				}

				//sovrascrittura per pulizia dei dati
				return {
					id: type === 'clone' ? null : lp.id,//se è una clone non hanno id
					legId: type === 'clone' ? null : lp.legId,//se è una clone le leg non hanno id
					legCounter: lp.legCounter,
					organizationId: lp.organizationId,
					distance: lp.distance,
					duration: Number(lp.duration) * 60,//la durata viene salvata in secondi
				}
			});
		} else {
			toSaveValues.legPoints = [];
		}

		//------repeater, per ora disabilitato------
		if (toSaveValues.repeatType === repeatType.DAILY && (!toSaveValues.repetitions || toSaveValues.repetitions < 0)) {
			setError({ repetitions: t('invalidField') });
			return;
		}
		if (toSaveValues.repeatType === repeatType.ROUND_TRIP && toSaveValues.startTimeGoBack > toSaveValues.endTimeGoBack) {
			setError({ endTimeGoBack: t('invalidField') });
			return;
		}

		// if (toSaveValues.sessionPoints) {
		// 	let enrollmentInactiveId = -1
		// 	toSaveValues.sessionPoints.forEach(sp => {
		// 		if (type === "massiveEdit") {
		// 			if (parseInt(sp.distance) > 0 && !activeOrganizationsForMultipleSessions.find(a => a.organization === sp.organizationId))
		// 				enrollmentInactiveId = sp.organizationId
		// 		} else if (parseInt(sp.distance) > 0 && !enrollments.find(e => e.organization === sp.organizationId && e.startDate <= toSaveValues.startTime && e.endDate >= toSaveValues.endTime)) {
		// 			enrollmentInactiveId = sp.organizationId
		// 		}
		// 		if (sp.homeWorkPath) {
		// 			sp.homeWorkDistance = toSaveValues.nationalKm;
		// 		} else {
		// 			sp.homeWorkDistance = 0;
		// 		}
		// 		delete sp.homeWorkPath;
		// 	});
		// 	if (enrollmentInactiveId !== -1) {
		// 		setError({ [`${enrollmentInactiveId}Distance`]: t(type === "massiveEdit" ? 'almostOneSessionWithEnrollmentInactive' : 'enrollmentInactiveInThatPeriod') })
		// 		return
		// 	}
		// 	toSaveValues.sessionPoints = toSaveValues.sessionPoints.filter(sp => parseFloat(sp.distance) > 0)
		// }

		if (toSaveValues.repeatType === repeatType.ROUND_TRIP) {
			toSaveValues.startTimeGoBack = dayjs(toSaveValues.startTimeGoBack, "YYYY-MM-DDTHH:mm:ss").valueOf();
			toSaveValues.endTimeGoBack = dayjs(toSaveValues.endTimeGoBack, "YYYY-MM-DDTHH:mm:ss").valueOf();
		}
		if (session && session.startTime) {
			toSaveValues.startTime += session.startTime - dayjs(dayjs(session.startTime).format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss').valueOf();
		}

		//------new id------
		if (type !== "edit") delete toSaveValues.id;
		if (type === "massiveEdit") {
			delete toSaveValues.startTime;
			delete toSaveValues.endTime;
		}

		onSubmit(toSaveValues);
		close();
	};

	const onTextChange = (value, name, organizationId) => {
		setValues(values => {
			let newValues = (JSON.parse(JSON.stringify(values)));
			if (!organizationId) {
				newValues[name] = value;
				if (name === "repeatType") {
					if (value === repeatType.NONE) {
						delete newValues.repetitions;
						delete newValues.startTimeGoBack;
						delete newValues.endTimeGoBack;
					} else if (value === repeatType.DAILY) {
						newValues.repetitions = 1;
						delete newValues.startTimeGoBack;
						delete newValues.endTimeGoBack;
					} else {
						newValues.startTimeGoBack = newValues.startTime;
						newValues.endTimeGoBack = newValues.endTime;
						delete newValues.repetitions;
					}
				}
				if (name === "startTime" && (type === 'create' || type === "clone")) {//solo per la create e la clone (creazione di nuove leg da zero) è possibile modificare la data di inizio	calculateLegTimes(newValues.legs, newValues);
					newValues.endTime = getMax(newValues.legs, "endTime", true) || newValues.startTime;
					//cambiando le date cambiano anche gli enrollment a cui si aderisce
					const convertedEndTime = dayjs(newValues.endTime, "YYYY-MM-DDTHH:mm:ss").valueOf();
					setActiveOrganizations(enrollments.filter(e => e.endDate >= convertedEndTime && e.startDate <= convertedEndTime));
				}
			} else {
				newValues.sessionPoints.find(sp => sp.organizationId === organizationId)[name] = value;
			}
			setError({});
			return newValues;
		});
	};

	//viene settata la distanza delle rispettiva leg. Se organizationId non è settato viene settata la distanza delle leg
	const onLegTextChange = (value, legId, legCounter, organizationId, field) => {
		setValues(values => {
			let newValues = (JSON.parse(JSON.stringify(values)));
			let legs = newValues.legs;

			if (organizationId)
				legs = newValues.legPoints.filter(lp => lp.organizationId === organizationId);

			(legs.find(l => !!l.id ? l.id === legId : l.legCounter === legCounter) || [])[field] = value;//le leg nuove non hanno id ma leg count

			//modifica dei tempi se è una create
			if ((type === 'create' || type === "clone") && !organizationId) {
				calculateLegTimes(legs, newValues);
			}

			setError({});
			return newValues;
		});
	}

	/**
	 * Cicla su tutte le leg e imposta i tempi di inizio e fini, partendo dalla data di inizio della sessione e incrementando in base alla durata della leg.
	 * Le date devono essere stringhe nel formato YYYY-MM-DDTHH:mm:ss. NON Imposta le date di inizio e fine della sessione.
	 */
	function calculateLegTimes(legs, values) {
		let startTime = dayjs(values.startTime, "YYYY-MM-DDTHH:mm:ss");
		legs.sort((a, b) => a.legCounter < b.legCounter).forEach(l => {
			l.startTime = startTime.format("YYYY-MM-DDTHH:mm:ss");
			startTime = startTime
				.add(Number(l.duration), 'minute')
				.add(Number(l.duration) === 0 ? 1 : 0, 'millisecond');
			l.endTime = startTime.format("YYYY-MM-DDTHH:mm:ss");
		});
	}

	const onEditLegsType = () => {
		setValues(values => {
			let newValues = (JSON.parse(JSON.stringify(values)));
			newValues.legs.forEach(l => l["type"] = LEG_TYPE.BIKE);
			newValues.legPoints.forEach(lp => lp["type"] = LEG_TYPE.BIKE);

			setError({});
			return newValues;
		});
	}

	const onResetDistance = () => {
		setValues(values => {
			let newValues = (JSON.parse(JSON.stringify(values)));
			newValues.legs.forEach(l => l["distance"] = 0);
			newValues.legPoints.forEach(lp => lp["distance"] = 0);

			setError({});
			return newValues;
		});
	}

	const onAddLeg = (legType) => {
		setValues(v => {
			let newValues = (JSON.parse(JSON.stringify(values)));
			const startTime = getMax(newValues.legs, 'endTime', true) || newValues.startTime;//seleziono l'end time dell'ultima leg
			const endTime = dayjs(startTime, "YYYY-MM-DDTHH:mm:ss")
				.add(1, 'millisecond')
				.format("YYYY-MM-DDTHH:mm:ss");
			let newLeg = {
				type: legType,
				distance: 0,
				duration: 0,
				legCounter: (getMax(newValues.legs, 'legCounter') ?? 0) + 1,
				id: null,
				legId: null,
				startTime,
				endTime
			};
			newValues.legs.push(newLeg);
			//aggiungi una leg a ogni iniziativa
			activeOrganizations?.forEach(o => {
				newValues.legPoints.push({
					...newLeg,
					organizationId: o.organization,
				});
			})

			//modifica dei tempi se è una create
			if (type === "create" || type === "clone")
				calculateLegTimes(newValues.legs, newValues);

			return newValues;
		})
	}

	const close = () => {
		setValues({});
		setDefaultValues({ ..._defaultValues });
		setError({});
		onClose();
	};

	return <BaseModal open={open} onClose={close} onSave={save}>
		{
			(statusSession === 'loading' || statusEnrollment === 'loading') &&
			<CircularLoading></CircularLoading>
		}
		{
			<Grid container spacing={2} sx={{ padding: "12px" }}>
				<Grid item xs={12} textAlign={'center'} sx={{ mb: 1 }}>
					{
						type === "create" && <Typography vari>{t('createNewSession')}</Typography>
					}
					{
						type === "edit" && <Typography>{t('editSession')} "{session.id}"</Typography>
					}
					{
						type === "clone" && <Typography>{t('cloneSession')} "{session.id}"</Typography>
					}
					{
						type === "massiveEdit" && <Typography>{t('editSessions')}: {multipleSessions.length}</Typography>
					}
				</Grid>
				{type !== "massiveEdit" &&
					<>
						<Grid item xs={12} sx={{ mb: 2 }}>
							<Grid container alignItems={'center'}>
								<Grid item xs={6} textAlign={'center'}>
									{
										type === "create" || type === "clone" ?
											<TextInput label={t('start')} value={values.startTime}
												type="datetime-local" required
												onTextChange={(value) => {
													onTextChange(value, "startTime");
												}}
												error={error.startTime} />
											:
											<Typography>{t('start')}: {dayjs(getMin(values.legs, 'startTime', true) ?? values.startTime)?.format('DD/MM/YYYY HH:mm:ss')}</Typography>
									}
								</Grid>
								<Grid item xs={6} textAlign={'center'}>
									<Typography>{t('end')}: {dayjs(getMax(values.legs, 'endTime', true) ?? values.endTime)?.format('DD/MM/YYYY HH:mm:ss')}</Typography>
								</Grid>
							</Grid>
						</Grid>
					</>
				}
				<Grid item xs={12} sx={{ mb: -1 }}>
					<Grid container>
						<Grid item flexGrow={1}></Grid>
						<Grid item>
							<HoverableButton
								item={{
									icon: <PedalBikeIcon />,
									name: "Cambia tutte le Leg"
								}}
								onClick={() => onEditLegsType()}
								sxs={{
									root: {
										color: 'secondary.light',
										display: "flex",
										justifyContent: "center",
										p: 0,
										'&:hover': {
											color: 'secondary.main',
										}
									}
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} sx={{ mb: -1 }}>
					<Grid container>
						<Grid item flexGrow={1}></Grid>
						<Grid item>
							<HoverableButton
								item={{
									icon: <RemoveIcon />,
									name: "Azzera tutte le Leg"
								}}
								onClick={() => onResetDistance()}
								sxs={{
									root: {
										color: 'secondary.light',
										display: "flex",
										justifyContent: "center",
										p: 0,
										'&:hover': {
											color: 'secondary.main',
										}
									}
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={6} sx={{ mt: 2, mb: -1 }}>
					<Grid container>
						<Grid item flexGrow={1}></Grid>
						<Grid item>
							<HoverableButton
								item={{
									icon: <Add />,
									name: t('addLeg')
								}}
								onClick={() => onAddLeg(legType)}
								sxs={{
									root: {
										color: 'secondary.light',
										display: "flex",
										justifyContent: "center",
										p: 2,
										'&:hover': {
											color: 'secondary.main',
										}
									}
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={6} sx={{ mt: 2, mb: -1 }}>
					<TextInput label={t('legType')}
						value={legType + ''}
						select
						onTextChange={(value) => {
							setLegType(value);
						}}
						error={error.legType}>
						{Object.values(LEG_TYPE).filter(t => t !== LEG_TYPE.NONE).map(t =>
							<MenuItem
								key={t}
								value={t + ''}
							>
								{legTranslation[t]}
							</MenuItem>)
						}
					</TextInput>
				</Grid>
				<Grid item xs={12}>
					<Pill lg={12} open={true} expandIconColor="secondary" pill={{
						id: 0,
						title: <PillTitle
							title={t('nationalKm')}
							legs={values?.legs}
						></PillTitle>,
						component: <LegKmTextInputs
							legs={values.legs}
							onLegTextChange={onLegTextChange}
							error={error}
						></LegKmTextInputs>
					}} index={-1} />
				</Grid>
				{
					//TODO: capire se questa logica deve essere reimplementata per le leg
					false && values.repeatType !== undefined && <>
						<Grid item xs={12}>
							<FormControl component="fieldset">
								<FormLabel component="legend"
									sx={{ color: "text.primary", '&.Mui-focused': { color: "text.primary" } }}>
									{t('repetition')}
								</FormLabel>
								<RadioGroup row value={values.repeatType}
									onChange={(event) => onTextChange(event.target.value, "repeatType")}>
									<FormControlLabel value={repeatType.NONE} label={t('none')}
										control={<Radio sx={{
											color: "primary.light",
											'&.Mui-checked': { color: "secondary.main" }
										}} />} />
									<FormControlLabel value={repeatType.DAILY} label={t('daily')}
										control={<Radio sx={{
											color: "primary.light",
											'&.Mui-checked': { color: "secondary.main" }
										}} />} />
									<FormControlLabel value={repeatType.ROUND_TRIP} label={t('roundTrip')}
										control={<Radio sx={{
											color: "primary.light",
											'&.Mui-checked': { color: "secondary.main" }
										}} />} />
								</RadioGroup>
							</FormControl>
						</Grid>
						{
							values.repeatType === repeatType.DAILY &&
							<Grid item xs={12}>
								<SettingsTooltip title={t('oneRepetitionImpliesTwoSessions')}>
									<div>
										<TextInput label={t('repeatNum')} value={values.repetitions}
											type="number" required step={1} min={0}
											onTextChange={(value) => {
												onTextChange(value, "repetitions");
											}}
											error={error.repetitions} />
									</div>
								</SettingsTooltip>
							</Grid>
						}
						{
							values.repeatType === repeatType.ROUND_TRIP &&
							<>
								<Grid item xs={6}>
									<TextInput label={t('startGoBack')} value={values.startTimeGoBack}
										type="datetime-local" required
										onTextChange={(value) => {
											onTextChange(value, "startTimeGoBack");
										}}
										error={error.startTimeGoBack} />
								</Grid>
								<Grid item xs={6}>
									<TextInput label={t('endGoBack')} value={values.endTimeGoBack}
										type="datetime-local" required
										onTextChange={(value) => {
											onTextChange(value, "endTimeGoBack");
										}}
										error={error.endTimeGoBack} />
								</Grid>
							</>
						}
					</>
				}
				{
					values && activeOrganizations.map(org => {
						let organizationTitle = org.organizationTitle;
						let legs = values?.legPoints?.filter(leg => leg.organizationId === org.organization) || [];
						legs.sort((a, b) => a.legCounter - b.legCounter);
						let sessionPoint = values?.sessionPoints?.find(sp => sp.organizationId === org.organization);
						return <>
							{
								legs && sessionPoint &&
								<Pill lg={12} open={true} expandIconColor="secondary" pill={{
									id: 0,
									title: <PillTitle
										title={organizationTitle}
										legs={legs}
										switchHomeWork={<FormControlLabel
											className={classes.switchLabel}
											control={<Switch checked={sessionPoint.homeWorkPath}
												onChange={event => {
													onTextChange(event.target.checked, "homeWorkPath", org.organization)
												}}
												color="secondary" />}
											label={<> {t('homeWorkPath')} <Info>{t('homeWorkPathDescription')}</Info> </>}
										/>}
									></PillTitle>,
									component: <LegKmTextInputs
										legs={legs}
										onLegTextChange={onLegTextChange}
										organizationId={org.organization}
										error={error}
									></LegKmTextInputs>
								}} index={org.organization} />
							}
						</>;
					})
				}
			</Grid>
		}
	</BaseModal>;
}


const calculateTotalLegDistance = (legs) => {
	return legs?.reduce((acc, leg) => Number(acc) + Number(leg.distance ?? 0), 0).toFixed(2)
}

const PillTitle = ({ title, legs, switchHomeWork }) => {
	return <Grid container alignItems={'center'}>
		<Grid item xs={5} sx={{
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		}}>
			{title}
		</Grid>
		<Grid item xs={2} sx={{ px: '2px' }}>
			{calculateTotalLegDistance(legs)} km
		</Grid>
		{
			switchHomeWork ?
				<Grid item xs={5} onClick={event => event.stopPropagation()}
					sx={{ textAlign: 'right' }}
				>
					{
						switchHomeWork
					}
				</Grid>
				:
				<Grid item xs={5}>
					<Box sx={{ height: 'calc(22px + 1em)', width: '20px', p: 1 }}></Box>
				</Grid>
		}
	</Grid>
}

const LegKmTextInputs = ({ legs, onLegTextChange, organizationId, error }) => {
	const { t } = useTranslation();
	const legTranslation = useGetLegTypeTranslation();

	return <Grid container spacing={1}>
		{
			legs?.map((leg) => {
				return <Grid item xs={12} key={[organizationId ?? 0, leg.legCounter ?? 0, leg.id ?? 0]}>
					<Grid container alignItems={'center'}>

						<Grid item xs={7}>
							<Grid container alignItems='center'>
								<Grid item xs={5.14} textAlign={'center'}>
									{`${t('leg')} ${leg.legCounter}`}
								</Grid>
								<Grid item xs={6.86}>
									{`${legTranslation[leg.type]}`}
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={5}>
							<Grid container alignItems='center'>
								<Grid item xs={12} sx={{ pt: 0 }}>
									<Box sx={{
										'& .MuiFormControl-root .MuiOutlinedInput-root': {
											'& fieldset': { borderColor: '#0000' },
											'& input': { p: 1 }
										}
									}}
									>
										<TextInput
											//label={`${organizationId ? t('initiativeKm') : t('communityKm')}`}
											value={leg.distance}
											type="number" step={1} min={0}
											startAdornment={<InputAdornment position="start">{`${organizationId ? t('initiativeKm') : t('communityKm')}`}</InputAdornment>}
											onTextChange={(value) => {
												onLegTextChange(value, leg.id, leg.legCounter, organizationId, 'distance');
											}}
											error={error[errorKey(leg.id, organizationId, leg.legCounter)]?.distance}
										/>
									</Box>
								</Grid>
								<Grid item xs={12} sx={{ pt: 0 }}>
									<Box sx={{
										'& .MuiFormControl-root .MuiOutlinedInput-root': {
											'& fieldset': { borderColor: '#0000' },
											'& input': { p: 1 }
										}
									}}
									>
										<TextInput
											//label={`${organizationId ? t('initiativeKm') : t('communityKm')}`}
											value={leg.duration}
											type="number" step={1} min={0}
											startAdornment={<InputAdornment position="start">{t('minutes')}</InputAdornment>}
											onTextChange={(value) => {
												onLegTextChange(value, leg.id, leg.legCounter, organizationId, 'duration');
											}}
											error={error[errorKey(leg.id, organizationId, leg.legCounter)]?.duration}
										/>
									</Box>
								</Grid>
							</Grid>
						</Grid>

						{
							// !!showDate &&
							// <>
							// 	<Grid item xs={2}>
							// 	</Grid>
							// 	<Grid item xs={5} sx={{ mt: 2 }}>
							// 		<Box sx={{ '& .MuiFormControl-root .MuiOutlinedInput-root fieldset': { borderColor: '#0000' } }}>
							// 			<TextInput label={t('start')} value={leg.startTime}
							// 				type="datetime-local" required
							// 				onTextChange={(value) => {
							// 					onLegTextChange(value, leg.id, leg.legCounter, organizationId, 'startTime');
							// 				}}
							// 				error={error[leg.id + organizationId]?.startTime} />
							// 		</Box>

							// 	</Grid>
							// 	<Grid item xs={5} sx={{ mt: 2}}>
							// 		<Box sx={{ '& .MuiFormControl-root .MuiOutlinedInput-root fieldset': { borderColor: '#0000' } }}>
							// 			<TextInput label={t('end')} value={leg.endTime}
							// 				type="datetime-local" required
							// 				onTextChange={(value) => {
							// 					onLegTextChange(value, leg.id, leg.legCounter, organizationId, 'endTime');
							// 				}}
							// 				error={error[leg.id + organizationId]?.endTime} />
							// 		</Box>

							// 	</Grid>
							// </>
						}
					</Grid>
				</Grid>
			})
		}
	</Grid>
}

function errorKey(lid, organizationId, legCounter) {
	return `lid:${lid || ""} oid:${organizationId || ""} count:${legCounter || ""}`
}