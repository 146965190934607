export const currency = [
    {
        code: '€',
        alternativeCode: '€',
        label: 'Euro'
    },
    {
        code: 'kr',
        alternativeCode: 'kr',
        label: 'Kronor'
    }
]
