import RenderFields from "./RenderFields";
import React from "react";
import Grid from "@mui/material/Grid";
import NTMSelect from "../../../components/NTMSelect";
import { useGetCities } from "../../../services/ContentManager";
import { CircularProgress } from "@mui/material";
import { useAwardLimitFields, useOpenDataFields, useVendorsManagerFields } from "./settingsFields";
import { useTranslation } from "react-i18next";
import { useState } from "react";


export default function OpenDataSettings({ values, setValues }) {

    let fields = useOpenDataFields(values, setValues);

    const onTextChange = (prop) => (value) => {
        let newValues = { ...values };
        newValues[prop] = value;
        setValues(newValues);
    }


    return <Grid container spacing={4} style={{ margin: 0, width: "100%", marginBottom: "0.5rem" }}>

        <RenderFields fields={fields} values={values} onTextChange={onTextChange} />

    </Grid>;
}
