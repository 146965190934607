import React, {useEffect, useState} from "react";
import BaseModal from "./BaseModal";
import TextInput from "../forms/TextInput";
import NTMSelect from "../NTMSelect";
import {useGetArticles, useGetOrganization, useGetOrganizations} from "../../services/ContentManager";
import ResponsiveCircularLoading from "../ResponsiveCircularLoading";
import SelectInput from "../forms/SelectInput";
import {Grid} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {useGetErrorMessages} from "../../constants/errorMessages";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
            '& fieldset': {
                borderColor: theme.palette.secondary.main,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.secondary.main,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.secondary.main,
                borderWidth: "3px"
            },
        },
    },
    input: {
        minWidth: "11rem",
        height: "3vh",
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.dark,
        '&:focused': {
            borderColor: theme.palette.secondary.main,
        },
        '&:hover': {
            color: theme.palette.secondary.main,
        },
        '&::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: theme.palette.secondary.main,
            opacity: 1 /* Firefox */
        },
        '&:-ms-input-placeholder': { /* Internet Explorer 10-11 */
            color: theme.palette.secondary.main,
        },
        '&::-ms-input-placeholder': { /* Microsoft Edge */
            color: theme.palette.secondary.main,
        }
    },
    grid: {
        padding: theme.spacing(2)
    }
}));

export default function SelectArticleModal({open, onClose, onSubmit, articles, currentArticleId}) {
    let classes = useStyles();
    articles = articles.filter(a => a.id !== currentArticleId);
    const [aid, setAid] = useState(articles[0] && articles[0].id);
    const [error, setError] = useState('');
    const errorMessages = useGetErrorMessages();
    const {t} = useTranslation();

    const save = () => {
        setError('');
        if (aid) {
            onSubmit(articles.find(a => a.id + '' === aid + ''));
            onClose();
        } else {
            setError(errorMessages.MANDATORY);
        }
    }

    return <BaseModal open={open} onClose={onClose} onSave={save}>
        <Grid container spacing={4} style={{margin: 0, width: "100%", marginBottom: "1rem"}}>
            <Grid item xs={12}>
                <SelectInput
                    value={aid}
                    label={t("articleToCopy")}
                    onChange={value => setAid(value)}
                    labelColor="secondary"
                    inputClass={classes.input}
                    rootClass={classes.root}
                    error={error}
                >

                    {articles.map(o => <option key={o.id} value={o.id}>{o.title}</option>)}

                </SelectInput>
            </Grid>
        </Grid>
    </BaseModal>
}
