import {Paper} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {useGetOrganizations, useGetOrganizationSettings} from "../services/ContentManager";
import {createAdmin,deleteUser,editUser,synchronizeUsers,useGetUsers} from "../services/UserDataManager";
import UserTable from "../components/tables/UserTable";
import RenderUserEnrollments, {formatUserEnrollments} from "../components/cellRender/RenderUserEnrollments";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import {getErrorMessage, post, USERS} from "../services/Client";
import AddIcon from "@mui/icons-material/Add";
import StartIconButton from "../components/buttons/StartIconButton";
import AddUserModal from "../components/modals/AddUserModal";
import {UserContext} from "./App";
import {roles} from "../services/ability";
import AlertModal from "../components/modals/AlertModal";

export function UserManagement() {

    const {t} = useTranslation();
    let users = useGetUsers();
    let {organizations = []} = useGetOrganizations()
    let {settings} = useGetOrganizationSettings(organizations[0] && organizations[0].id)
    const {enqueueSnackbar} = useSnackbar();
    const user = useContext(UserContext);

    let [showSerialNumber, setShowSerialNumber] = useState(false)
    let [showIban, setShowIban] = useState(false)
    let [isAddingAdmin, setIsAddingAdmin] = useState(false);
    const [alert, setAlert] = useState({
        callBack: () => {
        }, message: null, open: false
    });

    const customColumn = [
        {
            field: 'enrollments',
            headerName: t('initiatives'),
            width: 250,
            //hide: true,
            valueFormatter: ({value}) => value ? formatUserEnrollments(value, t) : "",
            renderCell: ({value, colDef}) => value && <RenderUserEnrollments enrollments={value} width={colDef.width}/>
        }
    ]

    useEffect(() => {
        if (organizations.length !== 0) {
            if (organizations.length === 1) {
                if (settings.length !== 0 && settings.find(s => s.organizationSetting === "serialNumberRequirement").value === "true") setShowSerialNumber(true)
                if (settings.length !== 0 && settings.find(s => s.organizationSetting === "ibanRequirement").value === "true") setShowIban(true)
            } else {
                setShowSerialNumber(true)
                setShowIban(true)
            }
        }
    }, [settings])

    const saveEdit = (uid, field, value) => {
        enqueueSnackbar(t('saving...'), {variant: "info"});
        return editUser(uid, field, value)
            .then(() => enqueueSnackbar(t('saved'), {variant: "success"}))
            .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
    }

    const createNewAdmin = (values) => {
        let newAdmin = {...values}
        if (newAdmin.birthDate) newAdmin.birthDate = new Date(newAdmin.birthDate).getTime();
        enqueueSnackbar(t('saving...'), {variant: "info"});
        createAdmin(newAdmin)
            .then(() => {
                synchronizeUsers();
                enqueueSnackbar(t('saved'), {variant: "success"});
            })
            .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
    }

    const deleteUsr = (uid) => {
        enqueueSnackbar(t('saving...'), {variant: "info"});
        deleteUser(uid)
            .then(() => {
                synchronizeUsers();
                enqueueSnackbar(t('deleted'), {variant: "success"});
            })
            .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
    }

    const openDeleteAlert = (user) => {
        setAlert({
            open: true,
            callBack: () => deleteUsr(user.uid),
            message: <>{t('deleteUserAlertMsg')} <br/>{t('email')}: {user.email} <br/>UID: {user.uid}</>
        });
    }

    const sendEmailValidation = (uid) => {
        enqueueSnackbar(t('sending...'), {variant: "info"});
        post(`${USERS}/${uid}/sendEmailVerification`)
            .then(() => enqueueSnackbar(t('sent'), {variant: "success"}))
            .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
    }

    const openSendEmailAlert = (uid) => {
        setAlert({open: true, callBack: () => sendEmailValidation(uid), message: `${t('sendEmailAlertMsg')}`});
    }

    return <Paper style={{padding: "2rem"}}>
        <UserTable users={users} saveEdit={saveEdit}
                   allowEditEmail
                   sendEmailValidation={openSendEmailAlert}
                   deleteUser={openDeleteAlert}
                   showIban={showIban}
                   showSerialNumber={showSerialNumber}
                   customColumns={customColumn}
                   status={users.length === 0 && "loading"}
                   rightButton={
                       user.userType === roles.ADMIN &&
                       <StartIconButton onClick={() => setIsAddingAdmin(true)} title={t('addAdmin')}
                                        startIcon={<AddIcon/>}/>
                   }
                   selectUsersByEmailVisible
        />
        <AddUserModal open={!!isAddingAdmin} onClose={() => setIsAddingAdmin(false)} onSave={createNewAdmin}/>
        <AlertModal open={alert.open} onClose={() => setAlert({open: false})} onSave={alert.callBack}
                    message={alert.message}/>
    </Paper>

}
