import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { firebaseDatabase } from "../firebase";
import { useSnackbar } from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmIconButton from "../components/buttons/ConfirmIconButton";
import { languages } from "../constants/lenguages";
import { Grid, IconButton, Paper } from "@mui/material";
import NTMXGrid from "../components/NTMXGrid";
import StartIconButton from "../components/buttons/StartIconButton";
import { Add, Edit } from "@mui/icons-material";
import WelcomeMessageModal from "../components/modals/WelcomeMessageModal";
import { messagesFirebaseRef, messagesFirebaseRefParent } from "../constants/messages";
import { useSynchronizedData } from "../services/FirebaseManager";
import dayjs from "dayjs";

export default function WelcomeMessages() {
    const { t } = useTranslation();
    const [ref, setRef] = useState(messagesFirebaseRef.welcomeMessages);
    let firebaseRef = firebaseDatabase.ref(ref);
    let [messages, setMessages] = useSynchronizedData(firebaseRef, [ref]);
    const { enqueueSnackbar } = useSnackbar();
    const [editingMessage, setEditingMessage] = useState(null);

    const updateTimestamp = () => {
        //update last update in firebase
        return firebaseDatabase.ref(messagesFirebaseRefParent[ref]).update({ lastUpdate: Math.trunc(dayjs(new Date()).valueOf() / 1000) })
    }

    const onDelete = (params) => {
        enqueueSnackbar(t('saving...'), { variant: "info" });
        updateTimestamp()
            .then(() =>
                firebaseRef.update({
                    [params.row.key]: null,
                }))
            .then(() => {
                enqueueSnackbar(t('deleted'), { variant: "success" });
            }).catch(() => enqueueSnackbar(t('errorSavingData'), { variant: "error" }));
    }

    const onEdit = (key, message, ref) => {
        enqueueSnackbar(t('saving...'), { variant: "info" });
        updateTimestamp()
            .then(() => {
                console.log(ref);
                if (!key)
                    key = firebaseDatabase.ref(ref).push().key;

                return firebaseDatabase.ref(ref).child(key).update(message)
            })
            .then(() => {
                enqueueSnackbar(t('saved'), { variant: "success" });
            }).catch(() => enqueueSnackbar(t('errorSavingData'), { variant: "error" }));
    }

    const changeRef = (newRef) => {
        if (newRef !== ref) {
            setMessages([]);
            setRef(newRef);
        }
    }

    const col = [
        {
            headerName: '#',
            field: 'key',
            width: 100,
            sortable: false,
            renderCell: (params) => <>
                <ConfirmIconButton
                    onConfirm={() => onDelete(params)}
                    title={t('confirmDeletion')}
                    text={t('requestDefinitiveElimination')}
                >
                    <DeleteIcon />
                </ConfirmIconButton>
                <IconButton
                    onClick={() => setEditingMessage(params.row)}
                >
                    <Edit />
                </IconButton>
            </>
        },
    ];

    col.push(...(languages.map(l => ({
        headerName: l.label,
        field: l.code,
        minWidth: 300,
    }))));

    return <Paper style={{ padding: "2rem" }}>

        <NTMXGrid
            rightButton={
                <Grid container justifyContent={"flex-end"} direction={'column'}>
                    <Grid container justifyContent={"flex-end"}>
                        <StartIconButton
                            onClick={() => setEditingMessage({})}
                            title={t("new")}
                            startIcon={<Add />} />
                    </Grid>
                    <Grid container justifyContent={"flex-end"}>
                        <Grid item xs={4} md={"auto"}>
                            <StartIconButton
                                title={t('welcomeMessages')}
                                onClick={() => changeRef(messagesFirebaseRef.welcomeMessages)}
                                selected={ref === messagesFirebaseRef.welcomeMessages}
                            />
                        </Grid>
                        {process.env.REACT_APP_ENVIRONMENT !== "production" && <Grid item xs={4} md={"auto"}>
                            <StartIconButton
                                title={t('returnNotifications')}
                                onClick={() => changeRef(messagesFirebaseRef.returnNotifications)}
                                selected={ref === messagesFirebaseRef.returnNotifications}
                            />
                        </Grid>}
                    </Grid>
                </Grid>
            }
            key={"welcome-messages"}
            columns={col}
            rows={messages}
            title={`${t('localNotifications')}`}
            getRowId={(row) => messages && row.key}
        />
        <WelcomeMessageModal message={editingMessage} onClose={() => {
            setEditingMessage(null)
        }} onEdit={onEdit} firebaseRef={ref} />
    </Paper>
}