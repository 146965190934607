import * as mui from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import MuiPaper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { styled } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';
import { Index as FlexSearchIndex } from 'flexsearch';
import { ColorBox, ColorButton, ColorPicker } from 'mui-color';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import synonyms from '../constants/synonyms';
import TextInput from './forms/TextInput';
import ListPagination from './Pagination';

const UPDATE_SEARCH_INDEX_WAIT_MS = 220;

if (process.env.NODE_ENV !== 'production') {
    Object.keys(synonyms).forEach((icon) => {
        if (!mui[icon]) {
            console.warn(`The icon ${icon} no longer exists. Remove it from \`synonyms\``);
        }
    });
}

function selectNode(node) {
    // Clear any current selection
    const selection = window.getSelection();
    selection.removeAllRanges();

    // Select code
    const range = document.createRange();
    range.selectNodeContents(node);
    selection.addRange(range);
}

const StyledIcon = styled('span')(({ theme }) => ({
    display: 'inline-flex',
    flexDirection: 'column',
    color: theme.palette.text.secondary,
    margin: '0 4px',
    '& > div': {
        display: 'flex',
    },
    '& > div > *': {
        flexGrow: 1,
        fontSize: '.6rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'center',
        width: 0,
    },
}));

const StyledSvgIcon = styled(SvgIcon)(({ theme }) => ({
    boxSizing: 'content-box',
    cursor: 'pointer',
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create(['background-color', 'box-shadow'], {
        duration: theme.transitions.duration.shortest,
    }),
    padding: theme.spacing(2),
    margin: theme.spacing(0.5, 0),
    '&:hover': {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[1],
    },
}));

const Icons = React.memo(function Icons(props) {
    const { icons, handleOpenClick } = props;

    const handleLabelClick = (event) => {
        selectNode(event.currentTarget);
    };

    return (
        <Box sx={{ height: '50vh', overflowY: 'auto' }} >
            <ListPagination
                paginationSize={40}
                elements={icons}
                renderElement={
                    (icon) => {
                        /* eslint-disable jsx-a11y/click-events-have-key-events */
                        return (
                            <StyledIcon key={icon.importName}>
                                <StyledSvgIcon
                                    component={icon.Component}
                                    fontSize="large"
                                    tabIndex={-1}
                                    onClick={handleOpenClick}
                                    title={icon.importName}
                                />
                                <div>
                                    {/*  eslint-disable-next-line jsx-a11y/no-static-element-interactions -- TODO: a11y */}
                                    <div onClick={handleLabelClick}>{icon.importName}</div>
                                </div>
                                {/* eslint-enable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */}
                            </StyledIcon>
                        );
                    }
                }
            ></ListPagination>
        </Box>
    );
});

Icons.propTypes = {
    handleOpenClick: PropTypes.func.isRequired,
    icons: PropTypes.array.isRequired,
};

const CanvasComponent = styled(Box)(({ theme }) => ({
    fontSize: 210,
    marginTop: theme.spacing(2),
    color: theme.palette.text.primary,
    backgroundSize: '30px 30px',
    backgroundColor: 'transparent',
    backgroundPosition: '0 0, 0 15px, 15px -15px, -15px 0',
    backgroundImage:
        theme.palette.mode === 'light'
            ? 'linear-gradient(45deg, #e6e6e6 25%, transparent 25%), linear-gradient(-45deg, #e6e6e6 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #e6e6e6 75%), linear-gradient(-45deg, transparent 75%, #e6e6e6 75%)'
            : 'linear-gradient(45deg, #595959 25%, transparent 25%), linear-gradient(-45deg, #595959 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #595959 75%), linear-gradient(-45deg, transparent 75%, #595959 75%)',
}));

const DialogDetails = React.memo(function DialogDetails(props) {
    const { open, selectedIcon, handleClose, selectIcon } = props;
    const [color, setColor] = React.useState('#000');
    const [error, setError] = useState('')

    const handleChange = useCallback(
        debounce((color) => {
            setColor(color.css.backgroundColor)
        }, 100),
        []
    );

    const reg=/^#([0-9a-f]{3}){1,2}$/i;
    const close = () => {
        if(!reg.test(color))
        {
            setError(t('wrongColor'))
            return;
        }

        selectIcon(selectedIcon, color); 
        handleClose()
    }

    const { t } = useTranslation();

    return (
        <Dialog maxWidth="sm" open={open} onClose={handleClose}>
            {selectedIcon ? (
                <React.Fragment>
                    <DialogTitle>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container rowGap={2}>
                            <Grid item xs>
                                <Grid container justifyContent="center" sx={{ '& svg.MuiSvgIcon-root': { color: color } }}>
                                    <CanvasComponent component={selectedIcon.Component} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" alignItems={'center'}>
                                    <Typography>{t('chooseColor')}</Typography>
                                </Grid>
                                <Grid container justifyContent="center" alignItems={'center'}>
                                    <Grid item auto>
                                        <ColorPicker
                                            disableAlpha
                                            onChange={handleChange}
                                            value={color}
                                            hideTextfield
                                        />
                                    </Grid>
                                    <Grid item auto>
                                        <TextInput
                                            startAdornment={'#'}
                                            onTextChange={(text) => setColor('#' + text.substring(0,6))}
                                            value={color.replace('#', '')}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' onClick={close}>{t('select')}</Button>
                        <Button onClick={handleClose}>{t('close')}</Button>
                    </DialogActions>
                </React.Fragment>
            ) : (
                <div />
            )}
        </Dialog>
    );
});

DialogDetails.propTypes = {
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedIcon: PropTypes.object,
};

const Paper = styled(MuiPaper)(({ theme }) => ({
    position: 'sticky',
    top: 80,
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    width: '100%',
}));

function formatNumber(value) {
    return new Intl.NumberFormat('en-US').format(value);
}

const Input = styled(InputBase)({
    marginLeft: 8,
    flex: 1,
});

const searchIndex = new FlexSearchIndex({
    tokenize: 'full',
});

const allIconsMap = {};
const allIcons = Object.keys(mui)
    .sort()
    .map((importName) => {
        let theme;
        if (importName.indexOf('Outlined') !== -1) {
            theme = 'Outlined';
        } else {
            return null
        }
        /* else if (importName.indexOf('TwoTone') !== -1) {
            theme = 'Two tone';
        } else if (importName.indexOf('Rounded') !== -1) {
            theme = 'Rounded';
        } else if (importName.indexOf('Sharp') !== -1) {
            theme = 'Sharp';
        } else {
            theme = 'Filled';
        } */

        const name = importName.replace(/(Outlined|TwoTone|Rounded|Sharp)$/, '');
        let searchable = name;
        if (synonyms[searchable]) {
            searchable += ` ${synonyms[searchable]}`;
        }
        searchIndex.addAsync(importName, searchable);

        const icon = {
            theme,
            importName,
            name,
            Component: mui[importName],
        };
        allIconsMap[importName] = icon;
        return icon;
    }).filter((i) => !!i);

/**
 * Returns the last defined value that has been passed in [value]
 */
function useLatest(value) {
    const latest = React.useRef(value);
    React.useEffect(() => {
        if (value !== undefined && value !== null) {
            latest.current = value;
        }
    }, [value]);
    return value ?? latest.current;
}

export default function SearchIcons({ selectIcon }) {
    const [keys, setKeys] = React.useState(null);
    const [selectedIcon, setSelectedIcon] = useState('');
    const [query, setQuery] = useState('');
    const { t } = useTranslation();

    const handleOpenClick = React.useCallback(
        (event) => {
            setSelectedIcon(event.currentTarget.getAttribute('title'));
        },
        [setSelectedIcon],
    );

    const handleClose = React.useCallback(() => {
        setSelectedIcon('');
    }, [setSelectedIcon]);

    const updateSearchResults = React.useMemo(
        () =>
            debounce((value) => {
                if (value === '') {
                    setKeys(null);
                } else {
                    searchIndex.searchAsync(value, { limit: 3000 }).then((results) => {
                        setKeys(results);

                        // Keep track of the no results so we can add synonyms in the future.
                        if (value.length >= 4 && results.length === 0) {
                            window.ga('send', {
                                hitType: 'event',
                                eventCategory: 'material-icons',
                                eventAction: 'no-results',
                                eventLabel: value,
                            });
                        }
                    });
                }
            }, UPDATE_SEARCH_INDEX_WAIT_MS),
        [],
    );

    React.useEffect(() => {
        updateSearchResults(query);
        return () => {
            updateSearchResults.clear();
        };
    }, [query, updateSearchResults]);

    const icons = React.useMemo(
        () =>
            (keys === null ? allIcons : keys.map((key) => allIconsMap[key])),
        [keys],
    );

    const dialogSelectedIcon = useLatest(
        selectedIcon ? allIconsMap[selectedIcon] : null,
    );

    return (
        <Grid container sx={{ minHeight: 500, justifyContent: 'center' }}>
            <Grid item xs={12} sm={9}>
                <TextInput startAdornment={
                    <IconButton sx={{ padding: '10px' }} aria-label="search">
                        <SearchIcon />
                    </IconButton>
                }
                    autoFocus
                    value={query}
                    onTextChange={(text) => setQuery(text)}
                    placeholder={t("SearchIcons...")}
                    inputProps={{ 'aria-label': 'search icons' }}
                >

                </TextInput>
                <Typography sx={{ mb: 1 }}>{`${formatNumber(
                    icons.length,
                )} matching results`}</Typography>
                <Icons icons={icons} handleOpenClick={handleOpenClick} />
            </Grid>
            <DialogDetails
                open={!!selectedIcon}
                selectedIcon={dialogSelectedIcon}
                handleClose={handleClose}
                selectIcon={selectIcon}
            />
        </Grid>
    );
}