import CouponList from "../../../components/VendorComponents/CouponList";
import React from "react";
import {useGetVendorCoupons} from "../../../services/ContentManager";
import {useTranslation} from "react-i18next";
import CircularLoading from "../../../components/CircularLoading";
import {AWARD_TYPE} from "../../../constants/awardType";

export default function CouponListVendor({uid}) {
    const {t} = useTranslation();
    let {coupons, status: statusCoupons} = useGetVendorCoupons(uid);
    if (statusCoupons === 'loading') return <CircularLoading/>;

    return (
        <CouponList
            coupons={coupons.filter(c => c.awardType === AWARD_TYPE.SHOP)}
            title={t('voucher')}
        />
    );
}