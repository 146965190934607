import {useTranslation} from "react-i18next";

export const AWARD_TYPE = {
    MONEY: 0, POINTS: 1, CITYHALL: 2, SHOP: 3, COMMUNITY: 4, VIA_MAIL: 5
}

export const REFUNDABLE_AWARD_TYPE = [AWARD_TYPE.MONEY, AWARD_TYPE.SHOP, AWARD_TYPE.VIA_MAIL];

export function useAwardTypeNames() {
    const {t} = useTranslation();

    return [
        t('moneyToBeCleared'),
        t('points'),
        t('toBeCollectedInMunicipality'),
        t('toBeCollectedInShop'),,
        t('awardsFromCommunity'),
        t('codeToSendViaEmail')
    ]
}

export function useAwardType() {
    const {t} = useTranslation();

    return [
        {
            id: 0,
            name: t('moneyToBeCleared')
        },
        {
            id: 1,
            name: t('points'),
        },
        {
            id: 2,
            name: t('toBeCollectedInMunicipality'),
        },
        {
            id: 3,
            name: t('toBeCollectedInShop')
        },
        {
            id: 5,
            name: t('codeToSendViaEmail')
        },
    ]
}
