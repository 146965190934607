import { useTranslation } from "react-i18next";
import { LEG_TYPE, legTypesEndings, useGetLegTypeTranslation } from "../../constants/legType";
import { SHOW_WIP } from "../../constants/debug";

export function useAlgorithmFields(values, setValues) {
    const { t } = useTranslation();

    return [
        {
            type: "number",
            name: "VALID_PARTIAL_QTY",
            label: t('DEFAULT_VALID_PARTIAL_QTY'),
            min: 0,
            step: 0.01,
            xs: 6,
            required: true
        },
        {
            type: "number",
            name: "VALID_PARTIAL_DEVIATION",
            label: t('DEFAULT_VALID_PARTIAL_DEVIATION'),
            min: 0,
            step: 0.01,
            xs: 6,
            required: true
        },
        {
            type: "number",
            name: "SPEED_THRESHOLD",
            label: t('DEFAULT_SPEED_THRESHOLD'),
            startAdornment: "km/h",
            min: 0,
            step: 1,
            xs: 6,
            required: true
        },
        {
            type: "number",
            name: "DISTANCE_THRESHOLD",
            label: t('DEFAULT_DISTANCE_THRESHOLD'),
            startAdornment: "m",
            min: 0,
            step: 1,
            xs: 6,
            required: true
        },
        {
            type: "number",
            name: "TIME_PEAK_THRESHOLD",
            label: t('DEFAULT_TIME_PEAK_THRESHOLD'),
            startAdornment: "min",
            min: 0,
            step: 1,
            xs: 6,
            required: true
        },
        {
            type: "number",
            name: "ACCELERATION_PEAK_THRESHOLD",
            label: t('DEFAULT_ACCELERATION_PEAK_THRESHOLD'),
            startAdornment: "ms^2",
            min: 0,
            step: 1,
            xs: 6,
            required: true
        },
        {
            type: "number",
            name: "CO2",
            label: t('CO2_UNIT'),
            startAdornment: "g",
            min: 0,
            step: 1,
            xs: 6,
            required: true
        },
        {
            type: "number",
            name: "ON_FOOT_THRESHOLD",
            label: t('DEFAULT_ON_FOOT_THRESHOLD'),
            startAdornment: "km/h",
            min: 0,
            step: 1,
            xs: 6,
            required: true
        },
        {
            type: "number",
            name: "MICROMOBILITY_THRESHOLD",
            label: t('MICROMOBILITY_THRESHOLD'),
            startAdornment: "km/h",
            min: 0,
            step: 1,
            xs: 6,
            required: true
        },
        {
            type: "number",
            name: "MIN_LEG_DURATION",
            label: t('MIN_LEG_DURATION'),
            startAdornment: "s",
            min: 0,
            step: 1,
            xs: 6,
            required: true
        },
    ]
}

export function useVisualizationsCostFields(values, setValues) {
    const { t } = useTranslation();

    return [
        {
            type: "number",
            name: "advertisement_visualization_cost",
            label: t('advertisement_visualization_cost'),
            startAdornment: t("points"),
            min: 0,
            step: 0.1,
            xs: 12,
            required: true
        },
        {
            type: "number",
            name: "advertisement_warning_threshold",
            label: t('advertisement_warning_threshold'),
            startAdornment: t("points"),
            min: 0,
            step: 0.1,
            xs: 12,
            required: true
        }
    ]
}

export function useKmToPointCoefficientFields(values, setValues, checkError) {
    const { t } = useTranslation();
    const legTranslation = useGetLegTypeTranslation();


    let fields = [
        {
            type: "number",
            name: "pointsPerKmCommunityBike",
            label: legTranslation[LEG_TYPE.BIKE],
            tooltip: t('pointsPerKmExplain'),
            startAdornment: t('points'),
            min: 0,
            required: true,
            xs: 6
        },
        {
            type: "number",
            name: "pointsPerKmCommunityElectricBike",
            label: legTranslation[LEG_TYPE.ELECTRIC_BIKE],
            tooltip: t('pointsPerKmExplain'),
            startAdornment: t('points'),
            min: 0,
            required: true,
            xs: 6
        },
        {
            type: "number",
            name: "pointsPerKmCommunityFoot",
            label: legTranslation[LEG_TYPE.FOOT],
            tooltip: t('pointsPerKmExplain'),
            startAdornment: t('points'),
            min: 0,
            required: true,
            xs: 6
        },
        {
            type: "number",
            name: "pointsPerPassengerCommunityCarpooling",
            label: legTranslation[LEG_TYPE.CARPOOLING],
            tooltip: t('pointsPerPassengerExplain'),
            startAdornment: t('points'),
            min: 0,
            required: true,
            xs: 6
        },
        {
            type: "number",
            name: "pointsPerKmCommunityMicromobility",
            label: legTranslation[LEG_TYPE.MICROMOBILITY],
            tooltip: t('pointsPerKmExplain'),
            startAdornment: t('points'),
            min: 0,
            required: true,
            xs: 6
        }, 
        {
            type: "number",
            name: "pointsPerKmCommunityUgo",
            label: legTranslation[LEG_TYPE.UGO],
            tooltip: t('pointsPerKmExplain'),
            startAdornment: t('points'),
            min: 0,
            required: true,
            xs: 6
        },
        {
            type: "number",
            name: "pointsPerKmCommunitySharingServices",
            label: legTranslation[LEG_TYPE.SHARING_SERVICES],
            tooltip: t('pointsPerKmExplain'),
            startAdornment: t('points'),
            min: 0,
            required: true,
            xs: 6
        }, {
            type: "number",
            name: "pointsPerKmCommunityPublicTransport",
            label: legTranslation[LEG_TYPE.PUBLIC_TRANSPORT],
            startAdornment: t('points'),
            tooltip: t('pointsPerKmExplain'),
            min: 0,
            required: true,
            xs: 6
        }
    ];

    return fields;
}