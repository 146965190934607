import {Grid, Typography} from "@mui/material";
import TextInput from "../forms/TextInput";
import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import {DEFAULT_PREFIX} from "../../constants/vendors";
import PhonePrefixesSelect from "./PhonePrefixesSelect";
import {useTranslation} from "react-i18next";
import useGlobalStyles from "../../services/useGlobalStyles";

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%'
    },
    error: {
        border: '1px solid ' + theme.palette.primary.main
    },
    colorRed: {
        color: theme.palette.primary.main
    }
}));

/**
 * mostra i campi del vendor
 * @param handleOnChange call back function prende in input (testo, campo)
 * @param vendor
 * @param errors
 * @param hidePhone nascondi il campo telefono
 * @returns {JSX.Element}
 * @constructor
 */
export default function VendorData({handleOnChange, vendor, errors = {}, hidePhone = false}) {

    const {t} = useTranslation();
    const classes = useStyles();
    const globalClasses = useGlobalStyles();

    if (!vendor.phonePrefix) {
        vendor.phonePrefix = DEFAULT_PREFIX;
    }

    return (
        <Grid container
              direction="row"
              alignItems="flex-start"
              className={globalClasses.marginBottom}
        >
            <Grid item xs={12} md={6} className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    sx={{marginTop: 'calc(1rem + 13px)'}}
                >
                    {
                        !hidePhone &&
                        <>
                            {/*--- telefono --*/}
                            <Grid item xs={12}
                                  className={clsx(globalClasses.marginBottom)}>
                                <Grid container
                                      direction="row"
                                      alignItems="flex-start">
                                    <Grid item xs={4}>
                                        <PhonePrefixesSelect
                                            prefix={vendor.phonePrefix}
                                            onChange={(text) => handleOnChange(text, 'phonePrefix')}
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextInput
                                            label={t('phoneNumber')}
                                            type="text"
                                            color={'primary'}
                                            value={vendor.phone || ''}
                                            onTextChange={(text) => handleOnChange(text, 'phone')}
                                            error={errors.phone || ''}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    }
                    {/*--- ragione sociale --*/}
                    <Grid item xs={12} className={clsx(globalClasses.marginBottom)}>
                        <TextInput
                            label={t('businessName')}
                            type="text"
                            color={'primary'}
                            value={vendor.businessName || ''}
                            onTextChange={(text) => handleOnChange(text, 'businessName')}
                            error={errors.businessName || ''}
                            required
                        />
                    </Grid>
                    {/*--- p Iva --*/}
                    <Grid item xs={12} className={clsx(globalClasses.marginBottom)}>
                        <TextInput
                            label={t('vatNumber')}
                            type="text"
                            color={'primary'}
                            value={vendor.vatNumber || ''}
                            onTextChange={(text) => handleOnChange(text, 'vatNumber')}
                            error={errors.vatNumber || ''}
                            helperText={t('insertFirst2CharToFindCountry')}
                            required
                        />
                    </Grid>
                </Grid>
            </Grid>

            {/*--- sede legale --*/}
            <Grid item xs={12} md={6} className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                <Typography className={clsx(errors.address && classes.colorRed)} xs={{mb:1}}>
                    {t('legalOffice')}
                </Typography>
                {(errors.address || errors.postalCode) && <Typography variant={'caption'}
                                               className={clsx(classes.colorRed, globalClasses.marginBottomSmall)}>{errors.address?? errors.postalCode}</Typography>}
                <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    sx={{mt:1}}>
                    <Grid item xs={7} md={9} className={globalClasses.marginBottom}>
                        <TextInput
                            label={t('address')}
                            type="text"
                            color={'primary'}
                            value={vendor.address || ''}
                            onTextChange={(text) => handleOnChange(text, 'address')}
                            required
                            error={!!(errors.address || errors.postalCode)}
                        />
                    </Grid>
                    <Grid item xs={5} md={3} className={globalClasses.marginBottom}>
                        <TextInput
                            label={t('houseNumber')}
                            type="text"
                            color={'primary'}
                            value={vendor.number || ''}
                            onTextChange={(text) => handleOnChange(text, 'number')}
                            required
                            error={!!(errors.address || errors.postalCode)}
                        />
                    </Grid>
                    <Grid item xs={7} md={7} className={globalClasses.marginBottom}>
                        <TextInput
                            label={t('city')}
                            type="text"
                            color={'primary'}
                            value={vendor.city || ''}
                            onTextChange={(text) => handleOnChange(text, 'city')}
                            required
                            error={!!(errors.address || errors.postalCode)}
                        />
                    </Grid>
                    <Grid item xs={5} md={2} className={globalClasses.marginBottom}>
                        <TextInput
                            label={t('postalCode')}
                            type="text"
                            color={'primary'}
                            value={vendor.postalCode || ''}
                            onTextChange={(text) => handleOnChange(text, 'postalCode')}
                            required
                            error={!!(errors.address || errors.postalCode)}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} className={globalClasses.marginBottom}>
                        <TextInput
                            label={t('province')}
                            type="text"
                            color={'primary'}
                            value={vendor.province || ''}
                            onTextChange={(text) => handleOnChange(text, 'province')}
                            required
                            error={!!(errors.address || errors.postalCode)}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextInput
                            label={t('country')}
                            type="text"
                            color={'primary'}
                            value={vendor.country || ''}
                            onTextChange={(text) => handleOnChange(text, 'country')}
                            required
                            error={!!(errors.address || errors.postalCode)}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid className={globalClasses.marginBottom}/>
        </Grid>
    );
}
